import { colors, deviceWidth, headerHeight, isWeb } from '../../common';
import React from 'react';
import { View } from 'react-native';
import { Defs, Ellipse, Mask, Rect, Svg } from 'react-native-svg';
import { selfieStyles } from './selfieStyles';

export const selfieCameraMask = ( id: string, isInvert?: boolean) => {
  const container = selfieStyles.imagePreview;
  const width = container.width;
  const x = deviceWidth / 2;
  return (
    <View style={selfieStyles.maskContainer}>
      <Svg height='100%' width='100%'>
        <Defs>
          <Mask id={`mask-${id}`} height='200%' width='200%'>
            <Rect height='100%' width='100%' fill='#fff' />
            <Ellipse
              cx={x}
              cy={container.marginTop + container.height + headerHeight}
              rx={width}
              ry={container.height}
            />
          </Mask>
        </Defs>
        <Rect
          height='100%'
          width='100%'
          fill={isInvert ? colors.white : colors.darkTransparent}
          mask={`url(#mask-${id})`}
          fillOpacity={isWeb ? '1' : '0'}
        />
      </Svg>
    </View>
  );
};
