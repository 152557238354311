import { deviceWidth } from '../../common';
import PicturePreview from '../../components/PicturePreview';
import { size } from 'lodash';
import React from 'react';
import { View } from 'react-native';
import styles from './styles';
import { IReceiptImageViewProps } from './types';
import { RNCarousel, RNPagination } from "../../modules/RNCarousel";

const ReceiptImagesView: React.FC<IReceiptImageViewProps> = (props) => {
    const [activeSlide, setActiveSlide] = React.useState(0);
    const { uploads } = props.receipt || {};
    const carouselRef: any = React.useRef();

    return <View style={styles.container}>
        <RNCarousel
            data={uploads}
            sliderWidth={deviceWidth}
            itemWidth={deviceWidth}
            renderItem={({ item, index }: any) => (
                <PicturePreview
                    picture={item}
                    resizeMode={props.resizeMode}
                    key={index}
                />
            )}
            onSnapToItem={(index: number) => setActiveSlide(index)}
            ref={carouselRef}
        />
        <RNPagination
            tappableDots={!!carouselRef.current}
            carouselRef={carouselRef.current}
            dotsLength={size(uploads)}
            activeDotIndex={activeSlide}
            dotStyle={styles.dotStyle}
            inactiveDotOpacity={0.4}
            inactiveDotScale={0.6}
        />
    </View>
}

export default ReceiptImagesView;
