import { StyleSheet } from 'react-native';
import { sketchSize, colors } from '../../common';

export default StyleSheet.create({
  touchable: {
    width: sketchSize(90)
  },
  spinner: {
    height: sketchSize(90)
  },
  fill: {
    flex: 1
  },
  container: {
    paddingVertical: 0
  },
  refundMethodContainer: {
    backgroundColor: colors.transparent
  },
  refundMethodsList: {
    paddingBottom: sketchSize(300)
  },
  noteContainer: {
    marginTop: sketchSize(40)
  },
  swipeButton: {
    flex: 1,
    width: sketchSize(200),
    justifyContent: 'center',
    alignItems: 'center'
  },
  swipeButtonWrap: {
    backgroundColor: colors.red,
    flex: 1
  }
});
