import React from 'react';
import { View, SafeAreaView } from 'react-native';
import styles from './styles';
import { IBodyContainerProps } from './types';
import { isIOS } from '../../common';

export const BodyContainer: React.FC<IBodyContainerProps> = props => {
  const content = (
    <View
      style={[styles.container, styles.paddingContainer, props.containerStyle]}
    >
      {props.children}
    </View>
  );
  if (props.useSafeArea && isIOS) {
    return (
      <SafeAreaView
        style={[
          styles.container,
          styles.paddingContainer,
          props.containerStyle,
        ]}
      >
        {content}
      </SafeAreaView>
    );
  }
  return content;
};
