import { IApiUploadFile } from '../../api-store';
import { constants, globalStyles, images } from '../../common';
import { EmptyPagePlaceholder, UploadActonSheet } from '../../components';
import { EEmptyPageButtonTypes } from '../../components/EmptyPagePlaceholder/types';
import { INavigationScreenProps } from '../../navigation';
import {
  PassportCameraSceneName,
  PassportPreviewSceneName,
} from '../../navigation/names';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import { RNFastImage } from '../../modules/RNFastImage';

export const PassportCameraPlaceholder: React.FC<INavigationScreenProps> = (
  props
) => {
  const actionSheetRef = React.useRef<any>(null);

  const handleOnTakePhoto = () => {
    props.navigation.replace(PassportCameraSceneName, props.route.params);
  };

  const handleOnUploadPhoto = (document: IApiUploadFile) => {
    if (document?.uri) {
      props.navigation.navigate(PassportPreviewSceneName, {
        ...(props.route.params || {}),
        document,
        retakeLabel: constants.tryAgain,
        isPdf: true,
      });
    }
  };

  const handleAddPassport = () => {
    actionSheetRef.current?.show();
  };

  if (!props.navigation.isFocused()) return null;
  return (
    <>
      <View style={StyleSheet.absoluteFill}>
        <EmptyPagePlaceholder
          onButtonPress={handleAddPassport}
          title={constants.passportCameraPlaceholderTitle}
          message={constants.passportCameraPlaceholderMessage}
          buttonType={EEmptyPageButtonTypes.DEFAULT}
          buttonText={constants.addPassport}
          labelContainerStyle={{ flex: 0.4 }}
          iconComponent={
            <RNFastImage
              resizeMode='contain'
              style={globalStyles.defaultPlaceholderIcon}
              source={images.passportPhotoIcon}
            />
          }
          buttonTestID={`documentCameraPlaceholderBtn`}
        />
      </View>
      <UploadActonSheet
        ref={actionSheetRef}
        onTakePhoto={handleOnTakePhoto}
        onUploadFile={handleOnUploadPhoto}
      />
    </>
  );
};
