import { StyleSheet } from 'react-native';
import colors from '../../common/colors';
import { headerHeight, sketchSize } from '../../common/metrics';

export default StyleSheet.create({
  container: {
    flex: 1,
  },
  infoContainer: {
    flex: 1,
    marginTop: sketchSize(40),
  },
  contentContainer: {
    flex: 3,
    justifyContent: 'space-around',
  },
  refundBreakdownItemsContainer:{
    flex: 0.8,
    justifyContent: 'space-around',
    marginHorizontal: sketchSize(40)
  },
  calendarListContainer: {
    backgroundColor: colors.white,
    flex: 1,
  },
  dateSelectorMainTextStyle: {
    color: colors.darkGrey,
  },
  whiteText: {
    color: colors.white,
  },
  darkText: {
    color: colors.darkGrey,
  },
  dateSelectorContainerStyle: {
    justifyContent: 'center',
  },
  calendarButtonWrapper: {
    marginHorizontal: sketchSize(50),
    marginVertical: sketchSize(50),
    marginTop: sketchSize(40),
  },
  backgroundImage: {
    flex: 0.3,
    width: '100%',
    backgroundColor: colors.white,
    overflow: 'hidden',
    alignSelf: 'center',
    marginTop: sketchSize(40),
  },
  dateInput: {
    margin: 0,
    padding: 0,
    height: sketchSize(28),
    borderWidth: 0,
    alignItems: 'flex-start',
  },
  questionLabel: {
    color: colors.lightGrey,
    marginBottom: sketchSize(20),
    marginTop: sketchSize(30),
  },
  textInput: {
    flex: 1,
    justifyContent: 'flex-start',
  },
  selectButton: {
    margin: sketchSize(20),
  },
  defaultPlaceholder: {
    width: sketchSize(50),
    height: sketchSize(50),
    borderRadius: sketchSize(50),
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.accent,
  },
  defaultPlaceholderIcon: {
    width: sketchSize(24),
  },
  countryNameContainer: {
    marginTop: sketchSize(40),
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  countryFlagImage: {
    width: sketchSize(122),
    height: sketchSize(80),
    borderRadius: sketchSize(15),
    overflow: 'hidden'
  },
  countrySelector: {
    marginTop: sketchSize(39),
    marginBottom: sketchSize(15),
  },
  fromToSelector: {
    marginTop: sketchSize(39),
    marginBottom: sketchSize(15),
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  fromToSelectorSpacedAround: {
    justifyContent: 'space-around',
    marginBottom: sketchSize(60),
  },
  fromToSelectorCentered: {
    justifyContent: 'center',
  },
  selectorFromButton: {
    width: '35%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  separatorImage: {
    flex: 1,
    width: sketchSize(208),
    height: sketchSize(65),
    alignItems: 'center',
    justifyContent: 'center',
  },
  selectorToButton: {
    width: '35%',
    borderWidth: 0,
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  permanentlyLiveContainer: {
    flexDirection: 'row',
    marginTop: sketchSize(39),
    marginBottom: sketchSize(40),
    justifyContent: 'space-around',
  },
  saveButton: {
    margin: sketchSize(20),
  },
  fillImageContainer: {
    flex: 1,
    justifyContent: 'flex-end',
  },
  fillImage: {
    width: '100%',
    flex: 0.75,
  },
  emptyLabelCentred: {
    justifyContent: 'center',
  },
  dateContainer: {
    marginLeft: sketchSize(18),
  },
  headerWithStackedDetailsContainer: {
    flexDirection: 'row'
  },
  spacerInDateContainer: {
    height: sketchSize(15),
  },
  buttonContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  refundBreakdownInfo:{
    flex: 1,
  },
  calendarButtonContainer: {
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  emptyContainer: {
    marginTop: headerHeight,
  },
  labelContainer: {
    flex: 0.2,
    alignItems: 'center',
    justifyContent: 'center',
  },
  editButton: { flex: 1, alignItems: 'flex-end' },
  editButtonCenter: { alignItems: 'center', width: '100%', height: sketchSize(63) },
  emptyTripContainer: {
    flex: 1
  },
});
