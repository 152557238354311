import { ICountry } from '../../api-store';
import { constants, getCountryCode } from '../../common';
import { BE, GB_NIR } from '../../common/constants/common';
import { IReceiptAgreeCondition } from './types';

export const getReceiptAgreeConditions = (
  country?: ICountry
): IReceiptAgreeCondition[] => {
  let agreement = constants.receipt.agreement;
  const code = getCountryCode(country);
  if (code === GB_NIR) {
    agreement = constants.receipt.agreementGbNir;
  }
  if (code === BE) {
    agreement = constants.receipt.agreementBe;
  }
  return agreement.map((v: string, i: number) => {
    return {
      key: `check-${i}`,
      description: v,
    };
  });
};
