import { filter, get, keyBy } from 'lodash';
import { airvatClient } from '../../common/api';
import {
  analyticsEvent,
  createApiUploadFile,
  getActiveTrip,
  getActiveTripId,
} from '../../common/utils';
import { createSetAction, createUnsetAction } from '../helpers';
import { EDirname, IApiUploadFile, IReceipt, ITrip } from '../types';
import { handleAuthError } from './auth';
import { uploadFile } from './uploads';

export const addReceipt =
  (pictures: IApiUploadFile[]) => async (dispatch: any, getState: any) => {
    console.log('addReceipt', pictures);
    const state = getState();
    const trip = getActiveTrip(state) as ITrip;
    const uploadIds = [];
    for (let p of pictures) {
      const uploadsId = await uploadFile(
        createApiUploadFile(p, EDirname.RECEIPTS)
      )(dispatch, getState);
      uploadIds.push(uploadsId);
    }
    await airvatClient.post<IReceipt>('receipts', {
      uploadIds,
      tripId: trip.id,
      countryId: trip.country?.id,
    });
    await getReceipts()(dispatch, getState);
    analyticsEvent('receipt_add', { trip_country: trip.country.name });
  };

export const getReceipts = () => async (dispatch: any, getState: any) => {
  const state = getState();
  const tripId = getActiveTripId(state);
  if (!tripId) return;
  const responseData = await airvatClient
    .get<IReceipt[]>('receipts', { params: { tripId } })
    .catch((e: any) => handleAuthError(e)(dispatch, getState));
  const filtered = filter(responseData || []) as IReceipt[];
  const storeData = keyBy<IReceipt>(filtered, 'id');
  const path = `user.trips.${tripId}.receipts`;
  const action = createSetAction(path, storeData);
  dispatch(action);
};

export const getReceipt =
  (receiptId: string) => async (dispatch: any, getState: any) => {
    const state = getState();
    const tripId = getActiveTripId(state);
    if (!tripId) return;
    const url = `receipts/${receiptId}`;
    const responseData = await airvatClient
      .get<IReceipt | IReceipt[]>(url, { params: { tripId } })
      .catch((e: any) => handleAuthError(e)(dispatch, getState));
    const path = `user.trips.${tripId}.receipts.${receiptId}`;
    const action = createSetAction(path, responseData);
    dispatch(action);
  };

export const deleteReceipt =
  (receiptId: number) => async (dispatch: any, getState: any) => {
    const state = getState();
    const tripId = getActiveTripId(state);
    const receipt = get(
      state,
      `api.user.trips.${tripId}.receipts.${receiptId}`
    );
    if (receipt?.id) {
      const url = `receipts/${receiptId}`;
      await airvatClient
        .delete(url)
        .catch((e: any) => handleAuthError(e)(dispatch, getState));
    }
    const path = `user.trips.${tripId}.receipts.${receiptId}`;
    const action = createUnsetAction(path);
    dispatch(action);
  };
