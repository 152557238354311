import { constants } from '../../common';
import { AvIcon } from '../../components';
import { TIconName } from '../../components/AvIcon/icons';
import { EReceiptStatus } from '../../api-store';

export const receiptStatusToString = (
  status: EReceiptStatus,
  isTitle?: Boolean,
  isLocked?: Boolean,
) => {
  if(isLocked){
    return constants.receipt.underReview
  }
  switch (status) {
    case EReceiptStatus.Rejected:
      return constants.receipt.declined;
    case EReceiptStatus.Accepted:
      return constants.receipt.verified;
    case EReceiptStatus.Pending:
      if (isTitle) {
        return constants.receipt.processingReceipt;
      }
      return constants.receipt.pending;
    default:
      return constants.receiptIncomplete;
  }
};

export const getReceiptStatusIcon = (
  status: EReceiptStatus,
  width: number,
  fill: string
) => {
  let name: TIconName = 'Notify';
  switch (status) {
    case EReceiptStatus.Accepted:
      name = 'CheckboxChecked';
      break;
    case EReceiptStatus.Pending:
      name = 'Clock';
      break;
  }
  return AvIcon({
    name,
    width,
    fill,
  });
};
