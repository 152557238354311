import React from 'react';
import styles from './styles';
import { ICheckboxProps } from './types';
import { CustomButton } from '../CustomButton';
import { AvIcon } from '../AvIcon';

export function Checkbox(props: ICheckboxProps) {
  const { checked, onPress, locked, containerStyle, testID } = props;
  const iconComponent = AvIcon({
    name: checked ? 'CheckboxChecked' : 'Checkbox',
    width: 90,
  });
  if (locked) {
    return iconComponent;
  }
  return (
    <CustomButton
      onPress={onPress}
      buttonStyle={[styles.touchable, containerStyle]}
      iconComponent={iconComponent}
      testID={testID}
    />
  );
}
