import { StyleSheet } from 'react-native';
import { sketchSize, colors, headerHeight, isSmallDevice, isWeb, deviceHeight } from '../../common';

export default StyleSheet.create({
  container: {
    flex: 1,
  },
  scrollContainer: { paddingBottom: sketchSize(120, true), flexGrow: 1 },
  imageContainer: {
    maxWidth: sketchSize(450),
    borderRadius: sketchSize(40),
    backgroundColor: colors.white,
  },
  addReceiptIcon: {
    alignSelf: 'center',
  },
  buttonText: {
    color: colors.white,
    fontWeight: 'bold',
  },
  imgPlaceholder: {
    maxWidth: sketchSize(450),
    height: sketchSize(700),
    backgroundColor: colors.white,
    borderRadius: sketchSize(40),
    overflow: 'hidden',
  },
  receiptLabelRegular: {
    alignSelf: 'center',
  },
  receiptTopSection: {
    marginVertical: sketchSize(40),
    flexDirection: 'row',
    alignContent: 'center',
  },
  receiptMiddleSection: {
    flexDirection: 'row',
    alignContent: 'flex-start',
    marginBottom: sketchSize(60),
  },
  addPhotoButton: {
    flex: 1,
    alignSelf: 'center',
  },
  receiptDateData: {
    flex: 0.7,
    alignContent: 'center',
  },
  imagePreview: {
    marginTop: sketchSize(40),
    width: '90%',
    alignSelf: 'center',
    borderRadius: sketchSize(60),
    height: '100%'
  },
  imagesPreview: {
    width: '90%',
    alignSelf: 'center',
    borderRadius: sketchSize(60),
    height: '100%'
  },
  buttonsContainer: {
    margin: sketchSize(40),
    marginBottom: sketchSize(80),
  },
  darkText: {
    color: colors.darkGrey,
  },
  goodsDescription: {
    flex: 1,
    marginRight: sketchSize(40),
  },
  serialNumberItemContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: sketchSize(30),
    marginHorizontal: sketchSize(80),
    borderBottomColor: colors.lightGrey,
    borderBottomWidth: StyleSheet.hairlineWidth,
  },
  rightTitleContainerStyle: {
    justifyContent: 'flex-end',
  },
  receiptRow: {
    backgroundColor: colors.white,
  },
  receiptContent: {
    paddingBottom: sketchSize(80),
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  receiptContentRejectReason: {
    paddingBottom: sketchSize(300, true),
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  receiptStatusContainer: {
    flexDirection: 'row',
  },
  receiptStatus: {
    flex: 1,
  },
  receiptsList: {
    paddingBottom: sketchSize(400),
    height: isWeb ? 4 * (deviceHeight / 5) : undefined,
    overflow: isWeb ? 'scroll' : undefined
  },
  receiptRowTouch: {
    flexDirection: 'row',
    padding: sketchSize(40),
    height: sketchSize(200),
  },
  receiptTitleContent: {
    marginRight: sketchSize(20),
  },
  receiptTouchContent: {
    flexDirection: 'row',
    flex: 1,
    marginLeft: sketchSize(20),
  },
  detailsWithLabelGroup: {
    marginBottom: sketchSize(25)
  },
  receiptStatusIconInRow: {
    marginTop: sketchSize(15),
  },
  swipeButton: {
    flex: 1,
    width: sketchSize(240),
    justifyContent: 'center',
    alignItems: 'center',
  },
  swipeButtonWrap: {
    backgroundColor: colors.red,
    flex: 1,
  },
  description: {
    flex: 1,
    marginLeft: sketchSize(40),
  },
  descriptionTitle: {
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: sketchSize(80),
  },
  descriptionWithIconWrap: {
    marginVertical: sketchSize(24),
  },
  descriptionWithIcon: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  descriptionWithMargin: {
    marginBottom: sketchSize(30, true),
  },
  addItemFromReceipt: {
    flex: 1,
    marginVertical: sketchSize(30, true),
  },
  descriptionWord: {
    color: colors.dark,
  },
  conditionsContainer: {
    flex: 1,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: sketchSize(30),
  },
  agreeRow: {
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: sketchSize(60),
  },
  agreeButtonContainer: {
    marginTop: sketchSize(80),
  },
  placeholderAgreeIcon: {
    height: sketchSize(600, true),
    width: sketchSize(600),
    alignSelf: 'center',
  },
  editIcon: {
    width: sketchSize(80),
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: sketchSize(40),
  },
  plusButton: {
    height: sketchSize(80),
    width: sketchSize(80),
    backgroundColor: colors.accent,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: sketchSize(40),
  },
  disabledButton: {
    backgroundColor: colors.blueGrey,
  },
  itemButton: {
    height: sketchSize(80),
    width: sketchSize(80),
    marginLeft: sketchSize(40),
  },
  minusButton: {
    height: sketchSize(80),
    width: sketchSize(80),
    backgroundColor: colors.red,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: sketchSize(40),
    marginLeft: sketchSize(40),
  },
  inputContainer: {
    marginBottom: sketchSize(40),
    marginHorizontal: sketchSize(80),
  },
  serialNumberInputContainer: {
    marginHorizontal: sketchSize(80),
  },
  labelContainer: {
    marginHorizontal: sketchSize(80),
    marginBottom: sketchSize(40),
  },
  priceInputContainer: { width: sketchSize(340) },
  bottomButtonWrapper: {
    marginHorizontal: sketchSize(50),
    marginVertical: sketchSize(50),
    marginTop: sketchSize(40),
  },
  searchStoresEmpty: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  photoPlaceholder: {
    marginHorizontal: sketchSize(80),
    overflow: 'hidden',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.hawkesBlue,
    borderRadius: sketchSize(30),
    marginBottom: sketchSize(40),
    flexGrow: 1,
    width: undefined,
    height: undefined,
    minHeight: sketchSize(646),
  },
  photoImage: {
    flex: 1,
  },
  photoContainer: {
    width: '100%',
    height: '100%',
    position: 'absolute',
  },
  itemsLoading: {
    padding: sketchSize(40),
  },
  labelOther: {
    marginHorizontal: sketchSize(80),
  },
  addItemScrollContainer: { paddingBottom: sketchSize(240), flexGrow: 1 },
  logo: {
    marginTop: headerHeight / 2,
    marginBottom: sketchSize(isSmallDevice ? 100 : 150),
  },
  dotListItemMargin: {
    marginHorizontal: sketchSize(60),
  },
  dotListItemLabel: {
    marginVertical: sketchSize(60),
  },
  titleStyle: {
    marginTop: sketchSize(40),
  },
  howCanHelpYou: {
    marginVertical: sketchSize(60),
    marginHorizontal: sketchSize(40),
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  howCanHelpYouLabel:{
    flex:1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  dotStyle: {
    width: sketchSize(30),
    height: sketchSize(30),
    borderRadius: sketchSize(15),
    backgroundColor: colors.dark,
  },
  showInShop: {
    marginBottom: sketchSize(isSmallDevice ? 20 : 40, true)
  },
});
