import { colors, globalStyles } from '../../common';
import { images } from '../../common/images';
import { AvIcon, CustomButton, Label } from '../../components';
import { map } from 'lodash';
import React from 'react';
import { View } from 'react-native';
import { RNFastImage } from '../../modules/RNFastImage';
import { AvNoteHOC } from './AvNoteHOC';
import styles from './styles';
import { IAvNoteProps } from './types';

const AvNoteViewComponent: React.FC<IAvNoteProps> = ({
  text: noteText,
  useWarning,
  ...props
}) => {
  if (!props.isVisible) {
    return null;
  }
  const propsText: any = typeof noteText === 'string' ? [noteText] : noteText;
  return (
    <View style={[styles.container, props.containerStyle]}>
      <View style={[styles.noteContainer, globalStyles.shadow]}>
        {props.component ? (
          props.component
        ) : (
          <>
            {!props.isNotHeader && (
              <View style={styles.noteHeader}>
                <AvIcon name={'Info'} width={70} fill={colors.light} />
                {props.isNotCross ? (
                  <View style={styles.crossButton} />
                ) : (
                  <CustomButton
                    buttonStyle={styles.crossButton}
                    iconComponent={
                      <AvIcon name={'Cross'} width={29} fill={colors.light} />
                    }
                    onPress={props.onSetIsVisible}
                  />
                )}
              </View>
            )}
            {map(propsText, (text, i) => {
              return (
                <View key={i} style={styles.labelWrap}>
                  {useWarning ? (
                    <RNFastImage
                      source={images.warning}
                      style={styles.warningIcon}
                    />
                  ) : null}
                  <Label
                    useFormatter
                    text={text}
                    type={'H1Dark'}
                    style={styles.bottomLabel}
                    {...props}
                  />
                </View>
              );
            })}
          </>
        )}
      </View>
    </View>
  );
};

export const AvNote = AvNoteHOC(AvNoteViewComponent);
