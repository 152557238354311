import { colors } from '../../common/colors';
import { sketchSize } from '../../common/metrics';
import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  flex:{
    flex:1
  },
  container: {
    flex:1,
    backgroundColor: colors.white,
    ...StyleSheet.absoluteFillObject
  },
  headerContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: sketchSize(130),
  },
  titleContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  spacer: {
    width: sketchSize(144),
    justifyContent: 'center',
    alignItems: 'center',
  },
  avatar: {
    width: sketchSize(128),
  },
  accentHeader: {
    borderBottomWidth: 0,
    backgroundColor: colors.white,
    borderBottomColor: 'transparent',
    shadowOpacity: 0,
    elevation: 0,
  },
  transparentHeader: {
    backgroundColor: 'transparent',
    borderBottomColor: 'transparent',
    elevation: 0,
  },
});
