export const createSetAction = (path: string, data?: any) => {
  return { type: 'SET', path, data };
};

export const createUnsetAction = (path: string) => {
  return { type: 'UNSET', path };
};

export const clearObject = (obj: any) => {
  if (!obj) return obj;
  return JSON.parse(JSON.stringify(obj));
};
