import { AirvatApiClient } from '../services/airvat-api';
import { ENV } from './env';

const LOCAL_HOST = 'http://localhost:8080';
// android emulator http://10.0.2.2:8080
export const HOST_PATH = '/v2/atlatos'
export const HOST = 'https://:env.airvat.dev'.replace(':env', ENV.ENV);
// for LOCAL_HOST use __DEV__ or false
export const API_URL = false ? LOCAL_HOST : HOST + HOST_PATH;
export const API_WS_URL = API_URL.replace('https:', 'ws:');
export const IS_DEV = ENV.ENV == 'development';
export const airvatClient = new AirvatApiClient(API_URL);
