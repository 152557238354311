export const RNConfig = {
  GOOGLE_OAUTH_TOKEN: '',
  IOS_CLIENT_ID: '',

  APP_VERSION: '1.2.61',
  BUILD_NUMBER: '102061',
  WEB_REVISION: '1',
  ENV: process.env.REACT_APP_ENV,

  GOOGLEPLAY_URL: '',
  APPSTORE_URL: '',
};
