import { StyleSheet } from 'react-native';

import { sketchSize } from '../../common/metrics';
import { colors } from '../../common/colors';

export default StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white
  },
  paddingContainer: {
    paddingHorizontal: sketchSize(50),
    paddingVertical: sketchSize(50),
    paddingTop: sketchSize(40)
  }
});
