import { colors, headerHeight, isSmallDevice, isWeb, sketchSize } from '../../common';
import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  container: {
    flex: 1
  },
  logoContainer: {
    flex: 0.3,
    justifyContent: 'center'
  },
  bottom: {
    flex: 0.7
  },
  logo: {
    marginTop: headerHeight / 2,
    marginBottom: sketchSize(isSmallDevice ? 0 : 150),
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: sketchSize(30)
  },
  description: {
    flex: 1,
    marginLeft: sketchSize(40)
  },
  descriptionWithIcon: {
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  descriptionWord: {
    color: colors.dark
  },
  terms: {
    marginTop: sketchSize(40),
    alignItems: 'center'
  },
  termsRow: {
    margin: sketchSize(10),
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  darkText: {
    color: colors.dark
  },
  buttonsContainer: {
    marginTop: sketchSize(40)
  },
  gradient: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    top: 0,
    bottom: 0
  },
  createAccountButton: {
    backgroundColor: colors.dark
  },
  fillButton: {
    width: '100%',
    marginVertical: sketchSize(30)
  },
  fillButtonLink: {
    width: '100%',
  },
  conditionsContainer: {
    flex: 1.8,
    justifyContent: 'space-around'
  },
  agreeButtonContainer: {
    flex: 1,
    justifyContent: 'flex-end'
  },
  fillImageContainer: {
    flex: 1,
    justifyContent: 'flex-end'
  },
  fillImage: {
    width: '100%',
    height: sketchSize(1281)
  },
  forgotLinksContainer: {
    marginTop: sketchSize(200)
  },
  agree:{
    marginVertical: sketchSize(30),
    paddingHorizontal: isWeb ? 0 : sketchSize(50),
    backgroundColor: 'white'
  }
});
