import { IApiFile, IApiUploadFile } from '../../api-store';
import { createApiUploadFile } from '../../common';
import PicturePreview from '../../components/PicturePreview';
import { first } from 'lodash';
import React from 'react';
import styles from './styles';
import { IReceiptImageViewProps } from './types';

class ReceiptImageView extends React.Component<IReceiptImageViewProps> {
  render() {
    const { uploads } = this.props.receipt || {};
    const upload = first(uploads) as IApiFile;
    const picture: IApiUploadFile = createApiUploadFile(upload);
    return <PicturePreview picture={picture} style={styles.container} resizeMode={this.props.resizeMode} />;
  }
}

export default ReceiptImageView;
