import React, { useImperativeHandle } from 'react';
import { useActionSheet } from '@expo/react-native-action-sheet';

export const RNActionSheet = React.forwardRef(
  ({ options, cancelButtonIndex, onPress }: any, forwardedRef) => {
    const { showActionSheetWithOptions } = useActionSheet();
    const show = () => {
      showActionSheetWithOptions(
        {
          options,
          cancelButtonIndex,
          destructiveButtonIndex: cancelButtonIndex,
        },
        onPress
      );
    };
    const ref = React.useRef({ show });
    useImperativeHandle(forwardedRef, () => ref.current);
    return null;
  }
);

export const RNActionSheetCustom = React.forwardRef(
  ({ children }: any, ref) => {
    return null;
  }
);
