import { constants } from '../../common';
import NetInfo, { NetInfoSubscription } from '@react-native-community/netinfo';
import React from 'react';
import { Animated, AppState, AppStateStatus, NativeEventSubscription } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Label } from '../Labels';
import { MESSAGE_CONTAINER_HEIGHT, styles } from './styles';

export class NetInfoMessage extends React.Component {
  showErrorAnim = new Animated.Value(-MESSAGE_CONTAINER_HEIGHT);
  isConnected: boolean = true;
  appState: AppStateStatus | undefined;
  netInfoListener: NetInfoSubscription | undefined;
  appStateListener: NativeEventSubscription | undefined;

  handleAppStateChange = (nextAppState: AppStateStatus) => {
    if (
      !/active/.test(this.appState || '') &&
      nextAppState === 'active' &&
      !this.isConnected
    ) {
      this.handleShowError(false);
    }
    this.appState = nextAppState;
  };

  handleShowError = (e: any) => {
    this.isConnected = !!e?.isConnected;
    if (this.isConnected) return;
    Animated.sequence([
      Animated.spring(this.showErrorAnim, {
        toValue: -MESSAGE_CONTAINER_HEIGHT / 10,
        useNativeDriver: true,
      }),
      Animated.delay(2000),
      Animated.spring(this.showErrorAnim, {
        toValue: -MESSAGE_CONTAINER_HEIGHT,
        useNativeDriver: true,
      }),
    ]).start(() => (this.isConnected = true));
  };

  componentDidMount() {
    this.appStateListener = AppState.addEventListener('change', this.handleAppStateChange);
    this.netInfoListener = NetInfo.addEventListener(
      this.handleShowError
    );
  }

  componentWillUnmount() {
    this.appStateListener?.remove?.();
    this.netInfoListener && this.netInfoListener();
  }

  componentDidUpdate() {
    this.handleShowError(this.isConnected);
  }

  public render() {
    return (
      <Animated.View
        style={[
          styles.container,
          { transform: [{ translateY: this.showErrorAnim }] },
        ]}
      >
        <SafeAreaView edges={['top']} style={styles.fill}>
          <Label
            white
            centered
            type='H2Light'
            text={constants.alerts.noInternet}
          />
        </SafeAreaView>
      </Animated.View>
    );
  }
}
