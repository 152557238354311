import { rotate } from '@meedwire/react-native-image-rotate';
import { filter, get, map, orderBy, size } from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import { Image, LayoutAnimation, View } from 'react-native';
import RNFetchBlob from 'react-native-blob-util';
import { RNDocumentScanner } from '../../modules/RNDocumentScanner';
import { SafeAreaView } from 'react-native-safe-area-context';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import {
  deleteReceipt,
  EReceiptStatus,
  getTrip,
  IReceipt,
} from '../../api-store';
import { DOTS_DATE_FORMAT } from '../../api-store/constants';
import {
  constants,
  fadeLayout,
  getActiveTripId,
  getCountryCode,
  getCurrency,
  getStatusColor,
  globalStyles,
  isAndroid,
  isIOS,
  isWeb,
  linkActions,
  localStorage,
  metrics,
  sketchSize,
  waitForAsync,
} from '../../common';
import { amountFormat } from '../../common/amountHelpers';
import {
  AvModal,
  AvNote,
  CustomButton,
  EmptyPagePlaceholder,
  Label,
  List,
  SupportButton,
  TouchableView,
  UploadActonSheet,
} from '../../components';
import { avAlert, avConfirm } from '../../components/AvAlert/alertActions';
import { checkCameraPermissions } from '../../components/CameraContainer';
import { getIsFilledUserPassport } from '../../containers/Home/state';
import { tripIsAfterDate } from '../../containers/Trip/tripHelpers';
import * as SceneNames from '../../navigation/names';
import { IDefaultState } from '../../store/types';
import ReceiptAgree from './ReceiptAgree';
import { getReceiptStatusIcon, receiptStatusToString } from './receiptUtils';
import styles from './styles';
import { IReceiptsListProps, IReceiptsListState } from './types';
import { RNYoutubePlayer } from '../../modules/YoutubePlayer';
import { RNPermissionsAndroid } from '../../modules/RNPermissionsAndroid';
import { RNSwipeable } from '../../modules/RNSwipeable';

class ReceiptsListComponent extends Component<
  IReceiptsListProps,
  IReceiptsListState
> {
  actionSheetRef: any;

  state = {
    isRefreshing: false,
    isUploadingFile: false,
    isShowAgreeModal: false,
    deletedReceipts: {},
    isShowInShop: false,
    isMounted: true,
  };

  navigateToEditTrip = () => {
    this.setState({ isUploadingFile: false });
    this.props.navigation.navigate(SceneNames.EditTripSceneName, {
      tripId: this.props.currentTrip.id,
    });
  };

  navigateToReceipt = (receipt: IReceipt) => () => {
    if (!receipt.id) return;
    this.props.navigation.navigate(SceneNames.ReceiptDetailsSceneName, {
      receiptId: receipt.id,
    });
  };

  handleError = (e: any) => {
    this.props.avAlert('', e.message);
    this.setState({ isUploadingFile: false });
  };

  handleSubmitDeleteReceipt = (receiptId: number) => async () => {
    try {
      this.setState({
        deletedReceipts: { ...this.state.deletedReceipts, [receiptId]: true },
      });
      await this.props.deleteReceipt(receiptId);
      this.props.getTrip(this.props.currentTrip.id);
    } catch (e) {
      this.handleError(e);
    }
  };

  handleOnDeleteReceipt = (receiptId: number) => () => {
    this.props.avConfirm(
      '',
      constants.alerts.deleteReceipt.message,
      this.handleSubmitDeleteReceipt(receiptId)
    );
  };

  showInShop = async () => {
    localStorage('isShowInShop', { value: true }).set();
    this.props.navigation.navigate(SceneNames.AirvatInfoCheckoutSceneName);
  };

  addInvoice = async () => {
    const { departureDate, id } = this.props.currentTrip || {};

    if (!id) {
      return this.props.avAlert('', constants.home.startPage, () => {
        this.props.navigation.navigate(SceneNames.SelectTripCountrySceneName);
      });
    }

    if (tripIsAfterDate(departureDate)) {
      const { title, body } = constants.receipt.cannotChangeModal;
      return this.props.avConfirm(title, body, this.navigateToEditTrip);
    }

    if (!this.props.isFilledPassport) {
      return this.props.avAlert('', constants.alerts.notFilledPassport, () => {
        this.props.navigation.navigate(SceneNames.PassportInfoSceneName);
      });
    }

    this.setState({ isUploadingFile: true });
    const isAgree = await localStorage(`trip-receipts-agree-${id}`).get();
    if (!isAgree) {
      this.setState({ isUploadingFile: false, isShowAgreeModal: true });
    } else {
      this.actionSheetRef?.show();
    }
  };

  handleAgree = () => {
    this.setState(
      { isUploadingFile: true, isShowAgreeModal: false },
      async () => {
        await waitForAsync(400);
        this.actionSheetRef?.show();
      }
    );
  };

  handleNotAgree = () => {
    this.setState({ isShowAgreeModal: false });
  };

  handleNavigateToCamera = () => {
    if (isWeb) {
      return this.props.navigation.navigate(SceneNames.ReceiptCameraSceneName);
    }
    checkCameraPermissions().then(async (): Promise<void> => {
      if (isIOS) {
        this.setState({ isUploadingFile: false });
        return this.props.navigation.navigate(
          SceneNames.ReceiptCameraSceneName
        );
      }
      if (
        isAndroid &&
        (await RNPermissionsAndroid.request(
          RNPermissionsAndroid.PERMISSIONS.CAMERA
        )) !== RNPermissionsAndroid.RESULTS.GRANTED
      ) {
        return this.handleError(
          new Error(constants.invoiceScannerAndroidPermissions)
        );
      }
      return RNDocumentScanner.scanDocument({ croppedImageQuality: 60 })
        .then(async ({ scannedImages }) => {
          if (!scannedImages) return;
          const pictures: string[] = [];
          for (let i = 0; i < scannedImages.length; i++) {
            const path = scannedImages[i];
            await new Promise(
              (
                resolve: (uri: string) => void,
                reject: (error: Error) => void
              ) =>
                Image.getSize(
                  path,
                  async (width, height) => {
                    console.log('width > height', width, height);
                    if (width > height) {
                      const content = await RNFetchBlob.fs.readFile(
                        path,
                        'base64'
                      );
                      return rotate({ type: 'base64', content, angle: 90 })
                        .then(resolve)
                        .catch(reject);
                    }
                    resolve(path);
                  },
                  reject
                )
            )
              .then(pictures.push)
              .catch((e) => {
                console.log(JSON.stringify(e));
                pictures.push(path);
              });
          }
          this.setState({ isUploadingFile: false });
          this.props.navigation.navigate(
            SceneNames.ReceiptImagePreviewSceneName,
            {
              pictures,
              retakeLabel: constants.tryAgain,
            }
          );
        })
        .catch(this.handleError);
    });
  };

  handleOnUploadPhoto = async (picture: any) => {
    this.setState({ isUploadingFile: false });
    if (picture?.uri) {
      this.props.navigation.navigate(SceneNames.ReceiptImagePreviewSceneName, {
        pictures: [picture],
        retakeLabel: constants.tryAgain,
      });
    }
  };

  handleOnRefreshReceipts = async () => {
    this.setState({ isRefreshing: true });
    try {
      if (this.props.currentTrip.id) {
        await this.props.getTrip(this.props.currentTrip.id);
      }
    } catch (e) {}
    this.setState({ isRefreshing: false });
  };

  receiptsFilter = (receipt: IReceipt) => {
    return !!receipt && !(this.state.deletedReceipts as any)[receipt.id];
  };

  componentDidMount() {
    this.setState({ isMounted: true });
    if (this.props.currentTrip.id) {
      this.props.getTrip(this.props.currentTrip.id);
    }
  }

  componentWillUnmount(): void {
    this.setState({ isMounted: false });
  }

  componentDidUpdate(
    prevProps: IReceiptsListProps,
    prevState: IReceiptsListState
  ) {
    const prevKeys = map(prevProps.receipts, (r) => r.id).join();
    const keys = map(this.props.receipts, (r) => r.id).join();
    if (prevKeys !== keys && isIOS) {
      LayoutAnimation.configureNext(fadeLayout);
    }
    if (!this.state.isShowInShop) {
      localStorage('isShowInShop')
        .get()
        .then((data: any) => {
          data?.value &&
            this.state.isMounted &&
            this.setState({ isShowInShop: true });
        });
    }
  }

  render() {
    const data = orderBy(
      filter(this.props.receipts, this.receiptsFilter),
      ['statusCode', 'cratedAt'],
      'desc'
    );
    const rejected = filter(data, { statusCode: EReceiptStatus.Rejected });
    const notRejected = filter(
      data,
      (v) => v.statusCode !== EReceiptStatus.Rejected
    );
    const sortedReceipts = [
      ...orderBy(notRejected, 'cratedAt'),
      ...orderBy(rejected, 'cratedAt'),
    ];
    const buttonsProps = [
      {
        accent: true,
        isUppercase: true,
        text: constants.showInShop,
        onPress: this.showInShop,
        testID: 'showInShopBtn',
        buttonStyle: styles.showInShop,
      },
      {
        accent: true,
        isUppercase: true,
        text: constants.addInvoice,
        onPress: this.addInvoice,
        testID: 'addReceiptBtn',
        isEnabled: this.state.isShowInShop || !!size(data),
      },
    ];

    return (
      <>
        {size(sortedReceipts) ? (
          <View style={styles.container}>
            <List
              data={sortedReceipts}
              renderItem={this.renderRow}
              useMask
              contentContainerStyle={styles.receiptsList}
              ListFooterComponent={this.renderNote()}
              refreshing={this.state.isRefreshing}
              onRefresh={this.handleOnRefreshReceipts}
              keyExtractor={(item, i) => `${item.id}_${i}`}
            />
            <SafeAreaView
              edges={['bottom']}
              style={[globalStyles.absoluteBottomButton, { width: '100%' }]}
            >
              {buttonsProps.map((v, i) => (
                <CustomButton key={`btn${i}`} {...v} />
              ))}
            </SafeAreaView>
          </View>
        ) : (
          this.renderEmptyPagePlaceholder(buttonsProps)
        )}
        <AvModal
          navigation={this.props.navigation}
          title={constants.receiptAgree}
          isVisible={this.state.isShowAgreeModal}
          onBackdropPress={this.handleNotAgree}
        >
          <ReceiptAgree
            navigation={this.props.navigation}
            onAgree={this.handleAgree}
            onClose={this.handleNotAgree}
            trip={this.props.currentTrip}
          />
        </AvModal>
        <UploadActonSheet
          ref={(r: any) => (this.actionSheetRef = r)}
          onTakePhoto={this.handleNavigateToCamera}
          onUploadFile={this.handleOnUploadPhoto}
        />
      </>
    );
  }

  renderNote = () => {
    return (
      <>
        <AvNote
          isNotHeader
          name="withInfo"
          text={[
            constants.receipt.withInfo,
            constants.receipt.onlyValidReceipts,
          ]}
          linkActions={linkActions(this.props.navigation)}
        />
        <SupportButton />
      </>
    );
  };

  rightButtons = (onPress: () => void, index: number) => [
    <View style={styles.swipeButtonWrap}>
      <TouchableView
        onPress={onPress}
        testID={`receiptItemDeleteTouch${index}`}
        style={styles.swipeButton}
      >
        <Label white text={constants.labels.delete} />
      </TouchableView>
    </View>,
  ];

  renderRow = ({ item, index }: { item: IReceipt; index: number }) => {
    if (!item) return null;
    const statusColor = getStatusColor(item.statusCode);
    const opacity = item.statusCode !== EReceiptStatus.Accepted ? 0.5 : 1;

    const isAccepted = item.statusCode === EReceiptStatus.Accepted;
    const rejectReason =
      item.statusCode === EReceiptStatus.Rejected && item.statusMessage;
    const isLocked = !!item.resourceLock?.id;
    const title = isAccepted
      ? item.storeName
      : rejectReason
      ? rejectReason
      : receiptStatusToString(item.statusCode || 0, true, isLocked);
    const receiptAmount = amountFormat(
      isAccepted ? item.paymentDetails?.invoiceAmount || 0 : 0
    );
    const amount = `${getCurrency(
      getCountryCode(this.props.currentTrip?.country)
    )} ${receiptAmount}`;
    const createdAt = moment(item.createdAt).format(DOTS_DATE_FORMAT);

    return (
      <RNSwipeable
        autoClose
        style={styles.receiptRow}
        disable={isLocked}
        rightButtonWidth={sketchSize(240)}
        rightButtons={this.rightButtons(
          this.handleOnDeleteReceipt(item.id),
          index
        )}
      >
        <TouchableView
          onPress={this.navigateToReceipt(item)}
          style={styles.receiptRowTouch}
          testID={`receiptItemTouch${index}`}
        >
          <View style={styles.receiptStatusIconInRow}>
            {getReceiptStatusIcon(item.statusCode, 42, statusColor)}
          </View>
          <View style={[styles.receiptTouchContent, { opacity }]}>
            <View style={[styles.container, styles.receiptTitleContent]}>
              <Label
                type="H1LargeSemiBold"
                text={title}
                useFormatter
                numberOfLines={1}
                style={{ overflow: 'hidden' }}
              />
              <Label text={createdAt} type="H2Light" />
            </View>
            <Label type="H1LargeSemiBold" text={amount} />
          </View>
        </TouchableView>
      </RNSwipeable>
    );
  };

  renderEmptyPagePlaceholder = (buttonsProps: any) => {
    const message: React.ReactNode = (
      <View style={{ marginHorizontal: 40 }}>
        <Label
          centered
          useFormatter
          text={constants.home.onlyUK}
          type="H1Dark"
          linkActions={linkActions(this.props.navigation)}
        />
      </View>
    );

    return (
      <EmptyPagePlaceholder
        messageComponent={message}
        buttons={buttonsProps}
        iconComponent={
          <View
            style={{
              flex: 1,
              marginVertical: 16,
              width: '100%',
              backgroundColor: '#000',
              justifyContent: 'center',
              minHeight: sketchSize(700, true),
            }}
          >
            <RNYoutubePlayer
              height={sketchSize(700, true)}
              width={metrics.deviceWidth}
              videoId={'tAdTKoifUrQ'}
            />
          </View>
        }
      />
    );
  };
}

const mapStateToProps = (state: IDefaultState) => {
  const tripId = getActiveTripId(state);
  const rejectReasons = get(state, 'api.rejectReasons');
  const tripPath = `api.user.trips.${tripId}`;
  const currentTrip = get(state, tripPath, {});
  const receiptsPath = `api.user.trips.${tripId}.receipts`;
  const receipts = get(state, receiptsPath, {});
  const isFilledPassport = getIsFilledUserPassport(state);
  const userId = state.api.user?.id;

  return {
    isFilledPassport,
    receipts,
    currentTrip,
    rejectReasons,
    userId,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      avAlert,
      avConfirm,
      deleteReceipt,
      getTrip,
    },
    dispatch
  );
};

export default compose<any>(connect(mapStateToProps, mapDispatchToProps))(
  ReceiptsListComponent
);
