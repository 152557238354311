import moment from 'moment';

export const parseCardExpiryDate = (value: string) => {
  const date = moment(value, 'MM/YY');
  return { cardExpiryMonth: `${date.format('MM')}`, cardExpiryYear: `${date.format('YY')}` };
};

export const cardExpiryDateMask = (value: string) => {
  if (!value) return value;
  let date = value.replace(/^[2-9]/, '0');
  if (date.length > 2) {
    date = date.replace(/^(\d{2})\/?(\d{1,2})?$/, '$1/$2');
  }
  return date;
};

export const cardNumberMask = (value: string) => {
  if (!value) return value;
  let cardNumber = value
    .replace(/^(\d{4})\s?(\d{4})\s?(\d{4})\s?(\d{4})/, '$1 $2 $3 $4')
    .replace(/\s{2,}/, ' ');
  return cardNumber;
};
