import { CommonActions } from '@react-navigation/native';
import { get } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import {
  IUserAddress,
  addAddress,
  getTrips,
  updateAddress,
} from '../../../api-store';
import { getCountryCode } from '../../../common';
import { constants } from '../../../common/constants';
import { FR } from '../../../common/constants/common';
import { inputConfigs } from '../../../common/inputConfigs';
import { AvForm, CustomButton } from '../../../components';
import { avAlert } from '../../../components/AvAlert/alertActions';
import { AvFormInput } from '../../../components/AvForm/types';
import { getDefaultRefundMethod } from '../../../containers/Home/state';
import { getCurrentTrip } from '../../../containers/Trip/tripState';
import {
  AddRefundMethodSceneName,
  AddTripTransportNodeSceneName,
  AddressConfirmationSceneName,
  HomeSceneName,
  SelectTripCountrySceneName,
} from '../../../navigation/names';
import { getNavigationFirstScreenName } from '../../../navigation/utils';
import { IDefaultState } from '../../../store/types';
import {
  getIsConfirmAddressDeclined,
  getIsNeedConfirmAddress,
} from '../documentsState';
import styles from './styles';
import { IAddressScreenProps } from './types';

export class AddressComponent extends React.PureComponent<IAddressScreenProps> {
  state = { isLoading: false };

  onFormSubmit = async (data: Partial<IUserAddress>) => {
    this.setState({ isLoading: true });
    let name = HomeSceneName;
    data.lineAdditional = data.lineAdditional || undefined;
    try {
      if (this.props.address?.id) {
        await this.props.updateAddress(this.props.address?.id, { ...data });
      } else {
        await this.props.addAddress(data);
      }
      this.setState({ isLoading: false });
      const newTrip = this.props.route?.params?.newTrip || this.props.currentTrip;
      const isFrTrip = getCountryCode(newTrip?.country) === FR;
      if (isFrTrip && this.props.route?.params?.isNeedAddressUpdate) {
        return this.props.navigation.dispatch(
          CommonActions.reset({
            index: 2,
            routes: [
              { name: HomeSceneName },
              { name: SelectTripCountrySceneName },
              {
                name: AddTripTransportNodeSceneName,
                params: this.props.route?.params,
              },
            ],
          })
        );
      } else if (this.props.route.params?.useGoBack) {
        this.props.navigation.goBack();
      } else if (isFrTrip && this.props.isNeedAddressConfirmation) {
        name = AddressConfirmationSceneName;
      } else if (!this.props.isExistsDefaultRefundMethod) {
        name = AddRefundMethodSceneName;
      } else if (this.props.initSceneName !== name) {
        name = this.props.initSceneName;
      }

      const routes = [{ name, params: this.props.route?.params }];
      const resetParams = { index: 0, routes };
      const navigationAction = CommonActions.reset(resetParams);
      this.props.navigation.dispatch(navigationAction);
    } catch (e) {
      this.setState({ isLoading: false });
      this.props.avAlert('', e.message);
    }
  };

  goToConfirmation = () => {
    this.props.navigation.navigate(AddressConfirmationSceneName, {
      hideSkip: true,
    });
  };

  skip = () => {
    this.props.navigation.dispatch(
      CommonActions.reset({
        index: 0,
        routes: [{ name: HomeSceneName }],
      })
    );
  };

  render() {
    const { address, navigation } = this.props;
    const useSkip = !address?.id && !navigation.canGoBack();

    const inputs: AvFormInput[] = [
      { ...inputConfigs.residenceCountry, country: address?.country },
      inputConfigs.phone,
      inputConfigs.address,
      inputConfigs.addressAdditional,
      inputConfigs.postcode,
      inputConfigs.residenceCity,
    ];

    return (
      <AvForm
        inputs={inputs}
        data={address}
        onSubmit={this.onFormSubmit}
        buttonText={constants.labels.save}
        isLoading={this.state.isLoading}
        useSkip={useSkip}
        onSkip={this.skip}
        BeforeSaveButtonComponent={this.renderConfirmAddressButton()}
      />
    );
  }

  renderConfirmAddressButton = () => {
    const newTrip = this.props.route?.params?.newTrip || this.props.currentTrip;
    const isFrTrip = getCountryCode(newTrip?.country) === FR;
    if (isFrTrip && this.props.isNeedAddressConfirmation) {
      return (
        <CustomButton
          link
          text={constants.confirmAddress}
          onPress={this.goToConfirmation}
          textStyle={styles.redLink}
          buttonStyle={styles.nextButton}
          testID="avFormAddressConfirmBtn"
        />
      );
    }
  };
}

const mapStateToProps = (state: IDefaultState) => {
  const user = get(state, 'api.user');
  const isNeedAddressConfirmation =
    getIsConfirmAddressDeclined(state.api.user) ||
    getIsNeedConfirmAddress(state.api.user);
  const isExistsDefaultRefundMethod = !!getDefaultRefundMethod(state);
  const initSceneName = getNavigationFirstScreenName(
    state,
    'address confirmation'
  );
  const currentTrip = getCurrentTrip(state);
  return {
    ...user,
    currentTrip,
    initSceneName,
    isNeedAddressConfirmation,
    isExistsDefaultRefundMethod
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      avAlert,
      updateAddress,
      addAddress,
      getTrips,
    },
    dispatch
  );
};

export const Address = compose<any>(
  connect(mapStateToProps, mapDispatchToProps)
)(AddressComponent);
