import { colors, constants, openChat } from '../../common';
import { AvIcon, CustomButton, Label } from '../../components';
import React from 'react';
import { View } from 'react-native';
import styles from './styles';
import { ISupportButtonProps } from './types';

export const SupportButton: React.FC<ISupportButtonProps> = ({}) => {
  return (
    <View style={styles.howCanHelpYou}>
      <Label
        text={constants.howCanHelpYou}
        style={styles.howCanHelpYouLabel}
        centered
        type={'H1Light'}
      />
      <CustomButton
        accent
        isRounded
        iconComponent={AvIcon({ name: 'Chat', fill: colors.white })}
        onPress={openChat}
        testID={'howCanHelpYouBtn'}
      />
    </View>
  );
};
