import { constants, globalStyles, isAndroid } from '../../common';
import { CustomButton } from '../../components';
import PicturePreview from '../../components/PicturePreview';
import React from 'react';
import { View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import styles from './styles';
import {
  IAnyImagePreviewProps
} from './types';

class AnyImagePreview extends React.Component<
  IAnyImagePreviewProps
> {

  render() {
    return (
      <SafeAreaView edges={['top', 'bottom']} style={styles.container}>
        <View style={styles.container}>
          <PicturePreview
            picture={this.props.picture}
            style={styles.imagePreview}
          />
          <View style={styles.buttonsContainer}>
            <CustomButton
              accent
              inverted
              isUppercase
              text={
                this.props.retakeLabel || constants.labels.retake
              }
              onPress={this.props.onRetake}
              buttonStyle={globalStyles.buttonsBottomSpace}
              testID='addressCameraReTakePictureBtn'
              isEnabled={!this.props.isLoading}
            />
            <CustomButton
              accent
              isUppercase
              text={constants.labels.save}
              isLoading={this.props.isLoading}
              onPress={this.props.onComplete}
              buttonStyle={isAndroid && globalStyles.buttonsBottomSpace}
              testID='addressCameraSavePictureBtn'
            />
          </View>
        </View>
      </SafeAreaView>
    );
  }
}

export default AnyImagePreview
