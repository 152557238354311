import { colors, globalStyles, openChat } from '../../common';
import { AvModal } from '../../components/AvModal';
import { Label } from '../../components/Labels';
import { IDefaultState } from '../../store/types';
import React, { FunctionComponent } from 'react';
import { ActivityIndicator, StyleSheet, View } from 'react-native';
import { connect } from 'react-redux';
import { Blur } from './blurView';
import { renderButtons } from './renderButtons';
import styles from './styles';
import { IAvAlertProps, IAvLoaderProps } from './types';

export const AvAlertComponent: FunctionComponent<IAvAlertProps> = ({
  title,
  message,
  buttons,
  isVisible,
  onBackdropPress,
}) => {
  if (!buttons || !isVisible) return null;
  const handleOnBackdropPress =
    onBackdropPress || buttons[buttons.length - 1].onPress;
  return (
    <>
      <Blur style={StyleSheet.absoluteFill} />
      <AvModal
        onBackdropPress={handleOnBackdropPress}
        isVisible={isVisible}
        modalStyle={styles.modalContainer}
      >
        <View style={styles.wrapper}>
          <View style={[styles.alertContainer, globalStyles.shadow]}>
            <View>
              {title ? (
                <Label
                  type='H1Dark'
                  centered
                  text={title}
                  style={styles.label}
                  testID='avAlertTitle'
                  bold
                />
              ) : null}
              <Label
                type='H1Dark'
                centered
                text={message}
                style={styles.label}
                testID='avAlertMsg'
                useFormatter
              />
            </View>
            <View style={styles.buttonsContainer}>
              {renderButtons(buttons)}
            </View>
          </View>
        </View>
      </AvModal>
    </>
  );
};

const AvLoaderComponent: FunctionComponent<IAvLoaderProps> = ({
  message,
  isVisible,
}) => {
  return (
    <AvModal
      onBackdropPress={() => null}
      isVisible={isVisible}
      modalStyle={styles.modalContainer}
    >
      <Blur style={StyleSheet.absoluteFill} blurType='dark'>
        <View style={styles.wrapper}>
          <View style={[styles.loaderContainer, globalStyles.shadow]}>
            <View style={styles.activityContainer}>
              <ActivityIndicator size='large' color={colors.dark} />
            </View>
            {message ? (
              <Label
                type='H1Dark'
                text={message}
                style={styles.bottomLabel}
                linkActions={{ openChat }}
                useFormatter
                centered
              />
            ) : null}
          </View>
        </View>
      </Blur>
    </AvModal>
  );
};

const alertMapStateToProps = (state: IDefaultState) => ({
  ...state.alert,
});

export const AvAlert = connect(alertMapStateToProps)(AvAlertComponent);

const loaderMapStateToProps = (state: IDefaultState) => ({
  ...state.loader,
});

export const AvLoader = connect(loaderMapStateToProps)(AvLoaderComponent);

export * from './alertActions';
export * from './loaderActions';
