import React from 'react';
import { View } from 'react-native';
import Webcam from 'react-webcam';
import {
  colors,
  constants,
  deviceHeight,
  deviceWidth,
  globalStyles,
  images,
} from '../common';
import { EmptyPagePlaceholder, Label } from 'components';
import { EEmptyPageButtonTypes } from 'components/EmptyPagePlaceholder/types';
import { RNFastImage } from './RNFastImage';

export const AppRNCamera = React.forwardRef(
  ({ captureAudio, style, type, children }: any, ref: any) => {
    const webcamRef = React.useRef<Webcam>(null);
    const [isNotFound, setIsNotFound] = React.useState(false);

    if (ref) {
      ref.current = {
        takePictureAsync: async () => {
          const base64 = webcamRef.current?.getScreenshot();
          return {
            base64,
          };
        },
      };
    }

    const cameraStyle: any = {
      backgroundColor: '#000',
      position: 'absolute',
      border: 0,
      height: '100%',
      width: '100%',
    };

    if (isNotFound) {
      return (
        <View
          style={[
            cameraStyle,
            { justifyContent: 'center', alignItems: 'center', padding: 40 },
          ]}
        >
          <Label
            style={{ color: colors.white, marginBottom: 10 }}
            text={constants.cameraPermissions.title}
            centered
          />
          <Label
            style={{ color: colors.white }}
            text={constants.cameraPermissions.messageWeb}
            centered
          />
        </View>
      );
    }

    return (
      <View style={style}>
        <Webcam
          audio={captureAudio}
          screenshotFormat="image/png"
          ref={webcamRef}
          style={cameraStyle}
          videoConstraints={{
            height: deviceWidth,
            width: deviceHeight,
            facingMode: { exact: type === 'front' ? 'user' : 'environment' },
          }}
          screenshotQuality={0.6}
          onUserMediaError={() => {
            setIsNotFound(true);
          }}
        />
        {children}
      </View>
    );
  }
);

export interface RNTakePictureResponse {}
export interface RNTakePictureOptions {}
