import notifee, { AndroidNotificationSetting } from '@notifee/react-native';
import { CommonActions, NavigationContainer, useNavigation } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { reduce } from 'lodash';
import * as React from 'react';
import { LayoutAnimation, StatusBar } from 'react-native';
import { isEmulatorSync } from 'react-native-device-info';
import {
  PERMISSIONS,
  RESULTS,
  request,
  requestNotifications,
} from 'react-native-permissions';
import { enableScreens } from 'react-native-screens';
import SplashScreen from 'react-native-splash-screen';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import {
  IUser,
  getCountriesForTrip,
  getSettings,
  logOut,
  loginWithToken,
  setDeviceToken,
  setIntercomNotify,
} from '../api-store';
import {
  codePushCheckForUpdate,
  constants,
  fadeLayout,
  getActiveTripId,
  isAndroid,
  isIOS,
  isWeb,
  localStorage,
  waitForAsync,
} from '../common';
import { fbAnalytics, fbFirebase } from '../common/fbAnalytics';
import { avAlert } from '../components/AvAlert/alertActions';
import { Cache } from '../components/WebSiteBrowser/Cache';
import Preloader from '../containers/Preloader';
import { RNIntercom, RNIntercomEvents } from '../modules/RNIntercom';
import {
  RNPushNotification,
  RNPushNotificationIOS,
} from '../modules/RNPushNotification';
import { IDefaultState } from '../store/types';
import { baseNavigationConfig } from './config';
import accountStack from './navigators/accountStack';
import { airvatInfoStack } from './navigators/airvatInfoStack';
import authStack from './navigators/authStack';
import documentsStack from './navigators/documentsStack';
import legalInfoStack from './navigators/legalInfoStack';
import moreStack from './navigators/moreStack';
import receiptsStack from './navigators/receiptsStack';
import refundMethodsStack from './navigators/refundMethodsStack';
import tripStack from './navigators/tripStack';
import { IAppNavigatorProps, IAppStackNavigatorProps } from './types';
import { getNavigationFirstScreenName, renderNavigationScreens } from './utils';
import { usePrevious } from '../hooks/usePrevious';
import moment from 'moment';

const requestTrackingPermissions = async () => {
  if (isIOS) {
    try {
      const result = await request(PERMISSIONS.IOS.APP_TRACKING_TRANSPARENCY);
      return RESULTS.GRANTED === result;
    } catch {
      return false;
    }
  }
  return true;
};

const requestPushNotification = async () => {
  try {
    const { status } = await requestNotifications(['alert', 'badge', 'sound']);
    return RESULTS.GRANTED === status || RESULTS.LIMITED === status;
  } catch {
    return false;
  }
};

const localNotificationSchedule = () => {
  localStorage('localNotificationSchedule')
    .get()
    .then((res: any) => {
      if (res?.isSetted) return;
      RNPushNotification.cancelAllLocalNotifications();
      RNPushNotification.localNotificationSchedule({
        message: `👋 ${constants.airvatUsers160}`,
        date: moment().add(2, 'day').toDate(),
      });
      localStorage('localNotificationSchedule', { isSetted: true }).set();
    });
};

enableScreens();
const Stack = createStackNavigator();

const linking = {
  prefixes: [constants.configs.deepLink, 'https://*.airvat.com'],
  config: {
    screens: {},
  },
};

const STACK = {
  ...authStack,
  ...tripStack,
  ...receiptsStack,
  ...documentsStack,
  ...refundMethodsStack,
  ...accountStack,
  ...airvatInfoStack,
  ...legalInfoStack,
  ...moreStack,
};

const AppNavigator = (props: IAppNavigatorProps) => {
  const routeNameRef = React.useRef<any>();
  const navigationRef = React.useRef<any>();
  const [isAppInit, setIsAppInit] = React.useState(false);
  const [isCacheComplete, setIsCacheComplete] = React.useState(isWeb);
  const [isUserInit, setIsUserInit] = React.useState(false);
  const [appInitError, setAppInitError] = React.useState<Error | null>(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isShowError, setIsShowError] = React.useState(false);
  const [progressMessage, setProgressMessage] = React.useState(
    constants.updating
  );

  const handleOnErrorUserInit = (e: any) => {
    setAppInitError(e);
    setIsShowError(true);
    setIsLoading(false);
    props.logOut();
  };

  const appInit = async () => {
    try {
      setIsLoading(true);
      await props.getCountriesForTrip();
      await props.getSettings();
      try {
        await codePushCheckForUpdate();
      } catch {}
      setProgressMessage(constants.loading);
      const user = await localStorage<IUser>('user').get();
      if (user?.refreshToken) {
        await props.loginWithToken(user.refreshToken);
      }
      if (!isWeb) {
        await requestTrackingPermissions().then((isGranted) => {
          if (!isGranted) return;
          fbFirebase.analytics().setAnalyticsCollectionEnabled(true);
        });
        await requestPushNotification().then((isGranted) => {
          if (!isGranted || isEmulatorSync()) return;
          RNPushNotification.configure({
            onRegister: ({ token }) => {
              console.log('onRegister pushToken', token);
              props.setDeviceToken(token);
              RNIntercom.sendTokenToIntercom(token);
            },
            onNotification: (notification) => {
              console.log('notification', notification);
              notification.finish(RNPushNotificationIOS.FetchResult.NoData);
            },
            onAction: (notification) => {
              console.log('action', notification.action);
              console.log('notification', notification);
            },
            onRegistrationError: (e) => {
              handleOnErrorUserInit(e?.message || 'error');
            },
            permissions: {
              alert: true,
              badge: true,
              sound: true,
            },
            popInitialNotification: true,
            requestPermissions: isAndroid,
          });
          if (isAndroid) {
            notifee.getNotificationSettings().then((settings) => {
              if (
                settings.android.alarm == AndroidNotificationSetting.ENABLED
              ) {
                localNotificationSchedule();
              } else {
                notifee.openAlarmPermissionSettings();
              }
            });
          } else {
            localNotificationSchedule();
          }
        });
      }
      setIsShowError(false);
      setIsLoading(false);
      setIsUserInit(true);
      await waitForAsync(600);
      if (isIOS) {
        LayoutAnimation.configureNext(fadeLayout);
      }
      setIsAppInit(true);
    } catch (e) {
      handleOnErrorUserInit(e);
    }
  };

  React.useEffect(() => {
    appInit();
    if (!isWeb) {
      StatusBar.setHidden(false);
      SplashScreen.hide();
    }
  }, []);

  React.useEffect(() => {
    RNIntercom.setBottomPadding(30);
    const countListener = RNIntercom.addEventListener(
      RNIntercomEvents.IntercomUnreadCountDidChange,
      (response) => {
        props.setIntercomNotify(Number(response.count) > 0);
      }
    );
    return countListener?.remove;
  }, []);

  const reduceLinking = (obj: any, { path }: any, name: string) => {
    if (path) {
      obj[name] = path;
    }
    return obj;
  };

  linking.config.screens = reduce(STACK, reduceLinking, {});

  return (
    <>
      {isWeb ? null : <Cache onComplete={setIsCacheComplete} />}
      <NavigationContainer
        linking={linking}
        ref={navigationRef}
        onReady={() => {
          routeNameRef.current = navigationRef.current?.getCurrentRoute().name;
        }}
        onStateChange={() => {
          const previousRouteName = routeNameRef.current;
          const currentRouteName =
            navigationRef.current?.getCurrentRoute().name;

          console.log('onStateChange', {
            previousRouteName,
            currentRouteName,
          });
          if (previousRouteName !== currentRouteName) {
            fbAnalytics().logScreenView({
              screen_name: currentRouteName,
              screen_class: currentRouteName,
            });
          }
          routeNameRef.current = currentRouteName;
        }}
      >
        <StackNavigator {...props} isAppInit={isUserInit} />
      </NavigationContainer>
      {(!isAppInit || !isCacheComplete) && (
        <Preloader
          onUpdate={appInit}
          error={appInitError}
          isLoading={isLoading}
          isShowError={isShowError}
          progressMessage={progressMessage}
        />
      )}
    </>
  );
};

const StackNavigator: React.FC<IAppStackNavigatorProps> = (props) => {
  const prevProps = usePrevious<IAppStackNavigatorProps>(props);
  const navigation = useNavigation<any>();
  const routeName = navigation.getCurrentRoute()?.name;

  React.useEffect(() => {
    if (props.isAppInit) {
      navigation.dispatch(
        CommonActions.reset({
          index: 0,
          routes: [
            {
              name: props.initSceneName,
            },
          ],
        })
      );
    }
  }, [props.isAppInit]);

  React.useEffect(() => {
    if (
      props.isAppInit &&
      prevProps?.initSceneName !== props.initSceneName &&
      routeName !== props.initSceneName
    ) {
      navigation.dispatch(
        CommonActions.reset({
          index: 0,
          routes: [
            {
              name: props.initSceneName,
            },
          ],
        })
      );
    }
  }, [
    props.isAppInit,
    prevProps?.initSceneName,
    props.initSceneName,
    routeName,
  ]);

  return (
    <Stack.Navigator detachInactiveScreens screenOptions={baseNavigationConfig}>
      {renderNavigationScreens(Stack, STACK)}
    </Stack.Navigator>
  );
};

const mapStateToProps = (state: IDefaultState) => {
  const isAuth = !!state.api?.user?.isAuth;
  const isActiveTrip = !!getActiveTripId(state);
  const initSceneName = getNavigationFirstScreenName(state, 'navigation');
  return {
    isAuth,
    isActiveTrip,
    initSceneName,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      getSettings,
      getCountriesForTrip,
      loginWithToken,
      avAlert,
      logOut,
      setDeviceToken,
      setIntercomNotify,
    },
    dispatch
  );
};

export default compose<any>(connect(mapStateToProps, mapDispatchToProps))(
  AppNavigator
);

export * from './types';
