import { isArray, reduce } from 'lodash';
import React from 'react';

export const RNPdf = ({ source, style, onLoadComplete, onError }: any) => {
  React.useEffect(() => {
    setTimeout(() => {
      onLoadComplete?.();
    }, 1000);
  }, []);
  const webStyle = isArray(style) ? reduce(
    style,
    (res: any, v: any) => {
      return { ...res, ...v };
    },
    {}
  ) : style;  
  console.log(source?.uri);
  
  return (
    <object
      data={source?.uri || source}
      type="application/pdf"
      style={webStyle}
      onLoad={onLoadComplete}
      onError={onError}
    />
  );
};
