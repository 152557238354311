import { getActiveTrip } from '../../common';
import { airvatClient } from '../../common/api';
import { IDefaultState } from '../../store/types';
import { filter, get, keyBy, map } from 'lodash';
import { createSetAction, createUnsetAction } from '../helpers';
import { ITrip } from '../types';
import { handleAuthError } from './auth';
import { getReceipts } from './receipts';

const createTripData =
  (
    { transportNode, country, ...trip }: ITrip
  ) => {
    if (!trip.extraProps) {
      trip.extraProps = {};
    }
    trip.countryId = country?.id;
    trip.isActive = true;
     console.log('createTripData', trip);
    return trip;
  };

export const addTrip =
  (trip: ITrip) => async (dispatch: any, getState: any) => {
    try {
      const requestData = createTripData(trip);
      const { id } = await airvatClient.post<ITrip>(`trips`, requestData);
      await getTrip(id)(dispatch, getState);
    } catch (e) {
      handleAuthError(e)(dispatch, getState);
    }
  };

export const updateTrip =
  (tripId: number, trip: ITrip) =>
    async (dispatch: any, getState: any) => {
      try {
        if (!tripId) return;
        const requestData = createTripData(trip);
        await airvatClient.put<ITrip>(`trips/${tripId}`, requestData);
        await getTrip(tripId)(dispatch, getState);
      } catch (e) {
        handleAuthError(e)(dispatch, getState);
      }
    };

export const getTrips = () => async (dispatch: any, getState: any) => {
  const data = await airvatClient
    .get<ITrip[]>('trips')
    .catch((e: any) => handleAuthError(e)(dispatch, getState));
  const state: IDefaultState = getState();
  const withReceipts = map(data || [], v => {
    const receipts = keyBy(
      filter(map(get(state, `api.user.trips.${v.id}.receipts`)), r => !r.deleted), 'id');
    return { ...v, receipts };
  });
  const stateActiveTrip = getActiveTrip(state);
  const tripsObject = keyBy<ITrip>(withReceipts.filter(v => v.id !== stateActiveTrip?.id), 'id');
  if (stateActiveTrip) {
    tripsObject[stateActiveTrip.id] = stateActiveTrip;
  }
  const action = createSetAction(`user.trips`, tripsObject);
  dispatch(action);
};

export const getTrip =
  (tripId: number) => async (dispatch: any, getState: any) => {
    try {
      await getReceipts()(dispatch, getState);
      const data = await airvatClient.get<ITrip>(`trips/${tripId}`);
      setTrip(tripId, data)(dispatch, getState);
      return data;
    } catch (e) {
      handleAuthError(e)(dispatch, getState);
    }
  };

export const deleteTrip =
  (tripId: number) => async (dispatch: any, getState: any) => {
    const url = `trips/${tripId}`;
    await airvatClient
      .delete(url)
      .catch((e: any) => handleAuthError(e)(dispatch, getState));
    const path = `user.trips.${tripId}`;
    const action = createUnsetAction(path);
    dispatch(action);
  };

export const setTrip =
  (tripId: number, data: ITrip) =>
    async (dispatch: any, getState: any) => {
      const state: IDefaultState = getState();
      data.receipts = keyBy(
        filter(map(get(state, `api.user.trips.${tripId}.receipts`)), v => !v.deleted),
        'id'
      );
      const trips = map(state.api.user?.trips, v => {
        return { ...v, isActive: false }
      })
      const tripsObject = keyBy(trips, 'id');
      tripsObject[tripId] = data;
      const action = createSetAction('user.trips', tripsObject);
      dispatch(action);
    };
