import { colors, metrics, sketchSize } from '../../common';
import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
  },
  borderRow: {
    borderTopWidth: StyleSheet.hairlineWidth,
    borderColor: colors.light
  },
  row: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  label: {
    margin: sketchSize(60),
  },
  groupLabel:{
    marginLeft: sketchSize(50),
    marginTop: sketchSize(80),
  },
  groupLabelItems:{
    marginTop: sketchSize(80),
    marginRight: sketchSize(50),
  },
  tripCarousel:{
    marginTop: sketchSize(50),
    marginBottom: sketchSize(40),
  },
  list: {
    paddingBottom: sketchSize(83),
    height: metrics.deviceHeight
  },
  listContainerStyle: {
    paddingBottom: sketchSize(140),
    minHeight: metrics.deviceHeight
  },
  nextIconContainer: {
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
    flexDirection:'row'
  },
  copyRefButton: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: metrics.sketchSize(40),
  },
  copyRefLabel: { marginRight: metrics.sketchSize(30) },
  noteContainer: { marginBottom: metrics.sketchSize(40) },
  logOutContainer: {
    flex: 0.7,
    justifyContent: 'flex-end',
  },
  appVersion: {
    marginTop: sketchSize(60),
  },
  myAccountButtonContainer: {
    paddingTop: sketchSize(80),
    paddingBottom: sketchSize(60)
  },
});
