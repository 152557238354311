import { constants, getCountryCode, images } from '../../common';
import { GB_NIR } from '../../common/constants/common';
import React from 'react';
import { AirvatInfoMainBlock } from './AirvatInfoMainBlock';
import { AirvatInfoPaymentFooter } from './AirvatInfoPaymentFooter';
import { IAirvatInfoSlideItem } from './types';
import { ICountry } from '../../api-store';

export const address = constants.airvatFullAddress;

export const getAirvatInfoSlides = (
  tripCountry?: ICountry
): IAirvatInfoSlideItem[] => {
  let shop = {
    title: constants.shop,
    icon: images.shopIcon,
    message: constants.shopMessages,
  };
  let generate = {
    title: constants.generate,
    icon: images.phoneBarcode,
    message: constants.generateMessages,
  };
  let validate = {
    title: constants.validate,
    icon: images.terminalBarcodeScan,
    message: constants.validateMessages,
  };
  let refund = {
    title: constants.receive,
    icon: images.cardsIcon,
    message: constants.receiveMessage,
    footerComponent: (isSmall?: boolean) =>
      React.createElement(AirvatInfoPaymentFooter, { isSmall }),
  };
  const code = getCountryCode(tripCountry);
  if (code === GB_NIR) {
    shop = {
      ...shop,
      message: constants.shopMessagesGbNir,
    };
    generate = {
      title: constants.print,
      icon: images.printIcon,
      message: constants.printMessages,
    };
    validate = {
      title: constants.stamp,
      icon: images.stampedTaxFormIcon,
      message: constants.stampGbMessages,
    };
    refund = {
      ...refund,
      message: constants.receiveMessageGbNir,
    };
  }

  return [
    {
      component: AirvatInfoMainBlock,
    },
    {
      title: constants.signUp,
      icon: images.passportPhotoIcon,
      message: constants.passportMessages,
    },
    shop,
    {
      title: constants.snap,
      icon: images.receiptPhotoIcon,
      message: constants.snapMessages,
    },
    generate,
    validate,
    refund,
  ];
};

export const getCustomsInfo = (tripCountry?: ICountry) => {
  const code = getCountryCode(tripCountry);
  switch (code) {
    case GB_NIR:
      return {
        icon: images.customsIcon,
        message: constants.airvatInfoCustomsInspectionGbNir,
      };
  }
  return {
    icon: images.customsIcon,
    message: constants.airvatInfoCustomsInspection,
  };
};

export const AIRVAT_INFO_GOODS_SLIDES = [
  {
    navigationTitle: constants.headers.goodsEligible,
    icons: [
      { icon: 'Clothes', label: constants.clothes },
      { icon: 'Jewellery', label: constants.jewellery },
      { icon: 'Cosmetics', label: constants.cosmetics },
      { icon: 'Electronics', label: constants.electronics },
    ],
    listItemIcon: 'CheckboxChecked',
    listItems: constants.airvatInfoGoodsSlidesEligible,
  },
  {
    navigationTitle: constants.headers.goodsNotEligible,
    icons: [
      { icon: 'OnlineShopping', label: constants.onlineShopping },
      { icon: 'Books', label: constants.books },
      { icon: 'ChildrenClothes', label: constants.childrenClothes },
      { icon: 'Tickets', label: constants.tickets },
    ],
    listItemIcon: 'Notify',
    listItems: constants.airvatInfoGoodsSlidesNotEligible,
  },
];

export const AIRVAT_INFO_INVALID_RECEIPTS = constants.airvatInfoInvalidReceipts;
