import { IReferralDashboard } from '../api-store';
import { IApiState } from './types';

export const apiState: IApiState = {
  user: {
    id: 0,
    code: '',
    email: '',
    isAuth: false,
    createdAt: '',
    deleted: false,
    updatedAt: '',
    locale: 'en',
    trips: {},
    paymentInfo: {},
    intercomHash: ''
  },
  tripCountries: [],
  transportNodes: [],
  service: {
    MIN_CLIENTS_VERSIONS: { value: { mobileApp: '' } },
    REFERRALS: { value: { reward: 5 } }
  },
  referralDashboard: {} as IReferralDashboard,
};
