import { emailChange, logOut } from '../../api-store';
import { constants, isIOS, metrics } from '../../common';
import { isEmail } from '../../common/stringValidator';
import { AvTextInput, BodyContainer, CustomButton } from '../../components';
import { avAlert, avConfirm } from '../../components/AvAlert/alertActions';
import { AuthLoginSceneName, AuthSceneName } from '../../navigation/names';
import { IDefaultState } from '../../store/types';
import { CommonActions } from '@react-navigation/native';
import { get } from 'lodash';
import React, { Component } from 'react';
import { Keyboard, KeyboardAvoidingView, View } from 'react-native';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import styles from './styles';
import { IEmailFormProps, IEmailFormState } from './types';

class EmailUpdateComponent extends Component<IEmailFormProps, IEmailFormState> {
  passwordRef: any;

  state = {
    email: { value: '', isValid: true },
    isLoading: false,
  };

  toggleIsLoading = () => this.setState({ isLoading: !this.state.isLoading });

  handleOnUpdateEmail = async () => {
    if (!this.isValidFields()) return;
    Keyboard.dismiss();
    this.props.avConfirm(
      '',
      constants.account.emailUpdateConfirm,
      this.handleOnUpdateEmailSubmit
    );
  };

  handleOnUpdateEmailSubmit = async () => {
    this.toggleIsLoading();
    const { email } = this.state;
    this.props
      .emailChange(email.value)
      .then(async () => {
        this.toggleIsLoading();
        await this.props.logOut();
        this.props.navigation.dispatch(
          CommonActions.reset({
            index: 1,
            routes: [
              { name: AuthSceneName },
              {
                name: AuthLoginSceneName,
                params: {
                  email: email.value,
                },
              },
            ],
          })
        );
      })
      .catch((e: any) => {
        this.toggleIsLoading();
        this.props.avAlert('', e.message);
      });
  };

  isValidFields = (): boolean => {
    const { email } = this.state;
    return !!(email.isValid && email.value);
  };

  render() {
    return (
      <KeyboardAvoidingView
        {...(isIOS ? { behavior: 'padding' } : {})}
        style={styles.container}
        keyboardVerticalOffset={metrics.keyboardOffset}>
        <BodyContainer useSafeArea>
          <View style={styles.container}>
            <AvTextInput
              key='userEmail'
              label={constants.account.newEmail}
              otherTextInputProps={{
                placeholder: constants.labels.emailPlaceholder,
                defaultValue: this.props.email,
                returnKeyType: 'done',
                keyboardType: 'email-address',
              }}
              isValid={[isEmail]}
              onValidationChange={(value, isValid) =>
                this.setState({ email: { value, isValid } })
              }
              errorText={constants.account.emailValidError}
              testID='emailInp'
            />
          </View>
          <CustomButton
            text={constants.labels.change}
            isUppercase
            accent
            isEnabled={this.isValidFields()}
            isLoading={this.state.isLoading}
            onPress={this.handleOnUpdateEmail}
            testID='onChangeEmailBtn'
          />
        </BodyContainer>
      </KeyboardAvoidingView>
    );
  }
}

const mapStateToProps = (state: IDefaultState) => {
  const email = get(state, 'api.user.email', '');
  return { email };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      avAlert,
      avConfirm,
      logOut,
      emailChange,
    },
    dispatch
  );
};

export const EmailUpdate = compose<any>(
  connect(mapStateToProps, mapDispatchToProps)
)(EmailUpdateComponent);
