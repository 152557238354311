import { StyleSheet } from 'react-native';
import { sketchSize, colors, fonts } from '../../common';

const styles = StyleSheet.create({
  HeroTitle: {
    fontSize: sketchSize(52),
    fontFamily: fonts.averta.semibold,
    color: colors.accent,
    marginHorizontal: sketchSize(39)
  },
  Button: {
    fontSize: sketchSize(48),
    fontFamily: fonts.averta.bold,
    color: colors.white
  },
  ButtonDark: {
    fontSize: sketchSize(48),
    fontFamily: fonts.averta.bold,
    color: colors.black
  },
  SubtitleSemibold: {
    fontSize: sketchSize(48),
    fontFamily: fonts.averta.semibold,
    color: colors.black
  },
  SubtitleRegular: {
    fontSize: sketchSize(48),
    fontFamily: fonts.averta.regular,
    color: colors.santasGrey
  },
  H1LargeSemiBold: {
    fontSize: sketchSize(56),
    fontFamily: fonts.averta.semibold,
    color: colors.black
  },
  H1Light: {
    fontSize: sketchSize(55),
    fontFamily: fonts.averta.regular,
    color: colors.light
  },
  H1Alert: {
    fontSize: sketchSize(55),
    fontFamily: fonts.averta.regular,
    color: colors.red
  },
  H1Dark: {
    fontSize: sketchSize(55),
    fontFamily: fonts.averta.regular,
    color: colors.black
  },
  H1Bold: {
    fontSize: sketchSize(55),
    fontFamily: fonts.averta.semibold,
    color: colors.black
  },
  H2Light: {
    fontSize: sketchSize(44),
    fontFamily: fonts.averta.regular,
    color: colors.light
  },
  H2Dark: {
    fontSize: sketchSize(44),
    fontFamily: fonts.averta.regular,
    color: colors.black
  },
  H2Alert: {
    fontSize: sketchSize(44),
    fontFamily: fonts.averta.regular,
    color: colors.red,
    marginVertical: sketchSize(10)
  },
  H3Bold: {
    fontSize: sketchSize(40),
    fontFamily: fonts.averta.bold,
    color: colors.black
  },
  H3SemiBold: {
    fontSize: sketchSize(40),
    fontFamily: fonts.averta.semibold,
    color: colors.black
  },
  H3SemiBoldAlert: {
    fontSize: sketchSize(40),
    fontFamily: fonts.averta.semibold,
    color: colors.red
  },
  H3Regular: {
    fontSize: sketchSize(40),
    fontFamily: fonts.averta.regular,
    color: colors.light
  },

  CalculatorBold: {
    fontSize: sketchSize(72),
    lineHeight: sketchSize(89),
    fontFamily: fonts.averta.bold,
    color: colors.black
  },
  CalculatorSemibold: {
    fontSize: sketchSize(52),
    lineHeight: sketchSize(64),
    fontFamily: fonts.averta.semibold,
    color: colors.black
  },
  CalculatorCaption: {
    fontSize: sketchSize(48),
    lineHeight: sketchSize(59),
    fontFamily: fonts.averta.bold,
    color: colors.accent
  },
  CalculatorLight: {
    fontSize: sketchSize(150),
    fontFamily: fonts.averta.light,
    color: colors.light
  },
  CalculatorLightDark: {
    fontSize: sketchSize(150),
    fontFamily: fonts.averta.light,
    color: colors.black
  },
  CalendarCountryAlphaCodeExtraBold: {
    fontSize: sketchSize(120),
    fontFamily: fonts.averta.extrabold,
    color: colors.black,
    marginLeft: sketchSize(22)
  },
  CalendarDateDayBold: {
    fontSize: sketchSize(120),
    fontFamily: fonts.averta.bold,
    color: colors.black
  },
  CalendarDateMonthYearExtraBold: {
    fontSize: sketchSize(48),
    fontFamily: fonts.averta.extrabold,
    color: colors.black
  },
  CalendarDateMonthYear: {
    fontSize: sketchSize(48),
    fontFamily: fonts.averta.semibold,
    color: colors.white
  },

  TripCardAmountLight: {
    fontSize: sketchSize(126),
    fontFamily: fonts.averta.light,
    color: colors.white
  },
  TripCardCountryAlphaCodeBold: {
    fontSize: sketchSize(65),
    fontFamily: fonts.averta.bold,
    color: colors.white
  },
  TripCardDateDaySemiBold: {
    fontSize: sketchSize(65),
    fontFamily: fonts.averta.semibold,
    color: colors.black
  },
  TripCardDateMonthExtraBold: {
    fontSize: sketchSize(23),
    fontFamily: fonts.averta.extrabold,
    color: colors.black
  },
  TripCardDateYearBold: {
    fontSize: sketchSize(23),
    fontFamily: fonts.averta.bold,
    color: colors.black,
    marginTop: sketchSize(6)
  },

  HeaderAmountLight: {
    fontSize: sketchSize(200),
    fontFamily: fonts.averta.light,
    color: colors.white
  },
  HeaderAmount: {
    fontSize: sketchSize(140),
    fontFamily: fonts.averta.regular,
    color: colors.white
  },
  HeaderCcySemiBold: {
    fontSize: sketchSize(55),
    fontFamily: fonts.averta.semibold,
    color: colors.white
  },
  HeaderCountryAlphaCodeBold: {
    fontSize: sketchSize(140),
    fontFamily: fonts.averta.bold,
    color: colors.white
  },
  HeaderCountryAlphaCode: {
    fontSize: sketchSize(100),
    fontFamily: fonts.averta.light,
    color: colors.white
  },
  HeaderDateDayBold: {
    fontSize: sketchSize(80),
    fontFamily: fonts.averta.bold,
    color: colors.black
  },
  HeaderDateMonthExtraBold: {
    fontSize: sketchSize(44),
    fontFamily: fonts.averta.extrabold,
    color: colors.black,
    marginTop: sketchSize(12)
  },
  ReceiptAmountLargeRegular: {
    fontSize: sketchSize(80),
    fontFamily: fonts.averta.regular,
    color: colors.black
  },

  AccountUserNameTitle: {
    fontSize: sketchSize(72),
    fontFamily: fonts.averta.regular,
    color: colors.black
  },

  Centered: {
    textAlign: 'center'
  },
  Right: {
    textAlign: 'right'
  },
  Accent: {
    color: colors.accent
  },
  Error: {
    color: colors.red
  },
  White: {
    color: colors.white
  },
  Dark: {
    color: colors.black
  },
  Link: {
    color: colors.link,
    textDecorationLine:'underline'
  },
  Light: {
    color: colors.light,
    fontFamily: fonts.averta.light
  },
  Bold: {
    fontFamily: fonts.averta.bold
  },
  Semibold: {
    fontFamily: fonts.averta.semibold
  },

  UpperCase: { textTransform: 'uppercase'},

  FromToLabel: {
    color: colors.white,
    fontWeight: 'bold'
  },
  warningIcon: {
    width: sketchSize(50),
    height: sketchSize(50)
  },
});

export default styles;