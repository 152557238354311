import { colors, constants } from '../../common';
import {
  AirvatInfoCheckout,
  AirvatInfoCustomsInspection,
  AirvatInfoGoodsAbove,
  AirvatInfoHowItWorks,
  AirvatInfoTaxForm
} from '../../containers/AirvatInfo';
import { commonStackNavigatorConfig } from '../../navigation/config';
import {
  AirvatInfoCheckoutSceneName,
  AirvatInfoCustomsInspectionSceneName,
  AirvatInfoGoodsAboveSceneName,
  AirvatInfoHowItWorkSceneName,
  AirvatInfoTaxFormSceneName
} from '../../navigation/names';

export const airvatInfoStack = {
  [AirvatInfoHowItWorkSceneName]: {
    screen: AirvatInfoHowItWorks,
    navigationOptions: (props: any) => ({
      title: constants.headers.howItWorks,
      ...commonStackNavigatorConfig(props),
    }),
  },
  [AirvatInfoTaxFormSceneName]: {
    screen: AirvatInfoTaxForm,
    navigationOptions: (props: any) => ({
      title: constants.headers.taxform,
      ...commonStackNavigatorConfig(props),
    }),
  },
  [AirvatInfoGoodsAboveSceneName]: {
    screen: AirvatInfoGoodsAbove,
    navigationOptions: (props: any) => ({
      title: constants.more.goodsAbove,
      ...commonStackNavigatorConfig(props),
    }),
  },
  [AirvatInfoCheckoutSceneName]: {
    screen: AirvatInfoCheckout,
    navigationOptions: (props: any) => ({
      ...commonStackNavigatorConfig({...props, fill: colors.white}),
      headerStyle: {
        backgroundColor: colors.accent,
        borderBottomWidth: 0,
        borderBottomColor: colors.accent,
        shadowOpacity: 0,
        elevation: 0,
      },
    }),
  },
  [AirvatInfoCustomsInspectionSceneName]: {
    screen: AirvatInfoCustomsInspection,
    navigationOptions: (props: any) => ({
      title: constants.headers.customsInspection,
      ...commonStackNavigatorConfig(props),
    }),
  },
};
