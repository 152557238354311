import { colors, sketchSize } from '../../../common';
import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  container: {
    flex: 1,
    overflow: 'visible',
  },
  placeholderIcon: {
    height: sketchSize(400, true),
    width: sketchSize(400, true),
    alignSelf: 'center',
  },
  conditionsContainer: {
    flex: 1,
  },
  dotListItemMargin: {
    marginHorizontal: sketchSize(60),
  },
  dotListItemLabel: {
    marginVertical: sketchSize(60, true),
  },
  agreeButtonContainer: {
    marginTop: sketchSize(40, true),
  },
  nextButton: {
    marginBottom: sketchSize(40, true),
  },
  redLink: { color: colors.red },
});
