import { IUserPassport, addDocument, getTrips } from '../../../api-store';
import { DOTS_DATE_FORMAT } from '../../../api-store/constants';
import { colors, getCountryCode, isWeb } from '../../../common';
import { constants } from '../../../common/constants';
import { FR } from '../../../common/constants/common';
import { inputConfigs } from '../../../common/inputConfigs';
import { AvForm } from '../../../components';
import { avAlert } from '../../../components/AvAlert/alertActions';
import inputStyles from '../../../components/AvForm/styles';
import { AvFormInput } from '../../../components/AvForm/types';
import {
  getCurrentTrip,
  getIsNeedAskPassportAddressUpdate,
} from '../../../containers/Trip/tripState';
import {
  AddressConfirmationSceneName,
  AddressDeclineSceneName,
  AddressSceneName,
  HomeSceneName,
  SelectTripCountrySceneName,
} from '../../../navigation/names';
import { IDefaultState } from '../../../store/types';
import { CommonActions } from '@react-navigation/routers';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import {
  getIsConfirmAddressDeclined,
  getIsFilledAddress,
  getIsNeedConfirmAddress,
} from '../documentsState';
import {
  IPassportDetailsScreenProps,
  IPassportDetailsScreenState,
} from './types';
import { RNDatePicker } from 'modules/RNDatePicker';

const MAX_BIRTH_DATE = moment().subtract(16, 'y').toDate();
const MIN_BIRTH_DATE = moment('1901-01-01').toDate();

const MAX_EXPIRY_DATE = moment().add(99, 'y').toDate();
const MIN_EXPIRY_DATE = moment().add(1, 'd').toDate();
const EXPIRE_DATE = moment().add(10, 'y').toDate();

const inputs: AvFormInput[] = [
  inputConfigs.issuedByCountryId,
  inputConfigs.firstName,
  inputConfigs.lastName,
  inputConfigs.passportNumber,
];
export class PassportDetailsComponent extends React.PureComponent<
  IPassportDetailsScreenProps,
  IPassportDetailsScreenState
> {
  state = {
    isLoading: false,
    isBirthDateModalVisible: false,
    isExpiryDateModalVisible: false,
    birthDate: undefined,
    expiryDate: undefined,
  };

  onFormSubmit = async (document: IUserPassport) => {
    this.setState({ isLoading: true });
    try {
      const years = Math.round(
        moment.duration(moment().diff(moment(document.birthDate))).asYears()
      );
      if (years < 16) {
        this.setState({ isLoading: false });
        return this.handleError(new Error(`You must be at least 16 years old`));
      }
      const params = this.props.route.params || {};
      await this.props.addDocument(document, params.document, params.selfie);
      this.setState({ isLoading: false });
      const newTrip =
        this.props.route?.params?.newTrip || this.props.currentTrip;
      const isFrTrip = getCountryCode(newTrip?.country) === FR;
      let routes = [{ name: HomeSceneName, params }];
      const isNeedConfirmAddress = getIsNeedConfirmAddress(this.props);
      const isAddressConfirmDeclined = getIsConfirmAddressDeclined(this.props);
      if (isAddressConfirmDeclined) {
        routes = [{ name: AddressDeclineSceneName, params }];
      } else if (isFrTrip && isNeedConfirmAddress) {
        routes = [{ name: AddressConfirmationSceneName, params }];
      } else if (!this.props.isFilledAddress) {
        routes = [{ name: AddressSceneName, params }];
      } else if (this.props.isNeedAddressUpdate && isFrTrip) {
        return this.props.navigation.dispatch(
          CommonActions.reset({
            index: 3,
            routes: [
              { name: HomeSceneName },
              { name: SelectTripCountrySceneName },
              {
                name: AddressSceneName,
                params: { ...params, isNeedAddressUpdate: true },
              },
            ],
          })
        );
      }
      this.props.navigation.dispatch(CommonActions.reset({ index: 0, routes }));
    } catch (e) {
      this.handleError(e);
    }
  };

  handleError = (e: any) => {
    this.setState({ isLoading: false });
    this.props.avAlert('', e.message);
  };

  handleChangeBirthDate = (birthDate?: Date) => {
    this.setState({ birthDate, isBirthDateModalVisible: false });
  };

  handleChangeExpiryDate = (expiryDate?: Date) => {
    this.setState({ expiryDate, isExpiryDateModalVisible: false });
  };

  renderBirthDatePicker = () => {
    return (
      <RNDatePicker
        modal
        mode="date"
        locale="en"
        androidVariant="nativeAndroid"
        open={this.state.isBirthDateModalVisible}
        date={this.state.birthDate || MAX_BIRTH_DATE}
        minimumDate={MIN_BIRTH_DATE}
        maximumDate={MAX_BIRTH_DATE}
        onConfirm={this.handleChangeBirthDate}
        textColor={colors.darkGrey}
        onCancel={() => {
          this.setState({
            isBirthDateModalVisible: false,
          });
        }}
        title={constants.myDetails.birthDateLabel}
        confirmText={constants.labels.select}
        cancelText={constants.labels.cancel}
        theme="light"
      />
    );
  };

  renderExpiryDatePicker = () => {
    return (
      <RNDatePicker
        modal
        mode="date"
        locale="en"
        androidVariant="nativeAndroid"
        open={this.state.isExpiryDateModalVisible}
        date={this.state.expiryDate || EXPIRE_DATE}
        minimumDate={MIN_EXPIRY_DATE}
        maximumDate={MAX_EXPIRY_DATE}
        onConfirm={this.handleChangeExpiryDate}
        textColor={colors.darkGrey}
        onCancel={() => {
          this.setState({
            isExpiryDateModalVisible: false,
          });
        }}
        title={constants.myDetails.expiryDateLabel}
        confirmText={constants.labels.select}
        cancelText={constants.labels.cancel}
        theme="light"
      />
    );
  };

  render() {
    const birthDateInput = {
      ...inputConfigs.birthDate,
      onTouchStart: () => {
        this.setState({
          isBirthDateModalVisible: true,
        });
      },
      containerStyle: inputStyles.inputContainer,
      value: this.state.birthDate
        ? moment(this.state.birthDate).format(DOTS_DATE_FORMAT)
        : '',
    };

    const expiryDateInput = {
      ...inputConfigs.expiryDate,
      onTouchStart: () => {
        this.setState({
          isExpiryDateModalVisible: true,
        });
      },
      containerStyle: inputStyles.inputContainer,
      value: this.state.expiryDate
        ? moment(this.state.expiryDate).format(DOTS_DATE_FORMAT)
        : '',
    };

    const allInputs = [...inputs, birthDateInput, expiryDateInput];

    return (
      <>
        <AvForm
          inputs={allInputs}
          onSubmit={this.onFormSubmit}
          buttonText={constants.labels.save}
          isLoading={this.state.isLoading}
        />
        {this.renderBirthDatePicker()}
        {this.renderExpiryDatePicker()}
      </>
    );
  }
}

const mapStateToProps = (state: IDefaultState) => {
  const user = state.api.user;
  const isFilledAddress = getIsFilledAddress(user);
  const currentTrip = getCurrentTrip(state);
  const { isNeedAskPassportAddress, isForceNeedUpdatePassportAddress } =
    getIsNeedAskPassportAddressUpdate(state);
  return {
    ...(user || {}),
    currentTrip,
    isFilledAddress,
    isNeedAddressUpdate:
      isNeedAskPassportAddress || isForceNeedUpdatePassportAddress,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      avAlert,
      addDocument,
      getTrips,
    },
    dispatch
  );
};

export const PassportDetails = compose<any>(
  connect(mapStateToProps, mapDispatchToProps)
)(PassportDetailsComponent);
