import { StyleSheet } from 'react-native';

import colors from '../../../../common/colors';
import { isWeb, sketchSize } from '../../../../common/metrics';

export default StyleSheet.create({
  container: {
    justifyContent: 'flex-end'
  },
  cardButtonContainer: {
    width: sketchSize(620),
    height: sketchSize(350),
    justifyContent: 'flex-end',
    marginLeft: 0,
    marginRight: sketchSize(50),
    marginTop: sketchSize(40),
    // marginBottom: sketchSize(70)
  },
  cardContainer: {
    width: sketchSize(550),
    height: sketchSize(309),
    alignItems: 'center',
    justifyContent: 'center',
    overflow:'visible'
  },
  cardDateContainer: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  cardStackedDetails: {
    flexDirection: 'row'
  },
  cardStackedDetailsDate: {
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: sketchSize(10)
  },
  cardPlaneIcon: {
    transform: [{ rotateZ: '45deg' }]
  },
  tripList: {
    width: '100%',
    alignSelf: 'flex-end',
    overflow: 'visible'
  },
  tripListContainer: {
    paddingHorizontal: sketchSize(40),
    overflow: 'visible'
  },
  addTripButton: {
    width: sketchSize(383),
    height: sketchSize(350),
    marginLeft: 0,
    overflow: 'visible',
    marginTop: sketchSize(40),
  },
  cardRefundCurrency: {
    position: 'absolute',
    left: sketchSize(-52),
    top: isWeb ? sketchSize(7) : sketchSize(-7)
  },
  cardRefundAmount: {},
  cardAmountContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  cardCompletedLabel: {
    backgroundColor: colors.white,
    borderTopLeftRadius: sketchSize(31),
    borderTopRightRadius: sketchSize(31),
    padding: sketchSize(10),
    paddingHorizontal: sketchSize(49)
  },
  cardStatusLabel: {
    fontSize: sketchSize(34)
  },
  cardCrossButton: {
    width: sketchSize(63),
    height: sketchSize(63),
    overflow: 'visible',
  },
  cardCrossButtonContainer: {
    right: sketchSize(-30),
    top: sketchSize(-30),
    position: 'absolute',
    overflow: 'visible'
  }
});
