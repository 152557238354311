import { colors, headerHeight, isSmallDevice } from '../../common';
import React from 'react';
import { View } from 'react-native';
import { Defs, Mask, Rect, Svg } from 'react-native-svg';
import { documentsStyles } from './documentsStyles';
import { PassportSvgMask, PassportSvgMaskWrap } from './PassportSvgMask';

const HEIGHT = (isSmallDevice ? 460 : 560) + headerHeight;
const viewBox = `-21 0 420 420`;
const g = { y: headerHeight };
export const documentsCameraMask = (
  id: string,
  isInvert?: boolean,
  isShowPlaceholder?: boolean
) => {
  return (
    <View style={documentsStyles.maskContainer}>
      <Svg preserveAspectRatio="xMidYMid meet" height="100%" width="100%">
        <Defs>
          <Mask id={`mask-${id}`} height="200%" width="200%">
            <Rect height="100%" width="100%" fill="#fff" />
            <PassportSvgMaskWrap
              width="100%"
              height={HEIGHT}
              viewBox={viewBox}
              g={g}
            />
          </Mask>
        </Defs>
        <Rect
          height="100%"
          width="100%"
          fill={isInvert ? colors.white : colors.darkTransparent}
          mask={`url(#mask-${id})`}
        />
        {isShowPlaceholder && (
          <PassportSvgMask
            fill="rgba(255,255,255,0.5)"
            width="100%"
            height={HEIGHT}
            viewBox={viewBox}
            g={g}
          />
        )}
      </Svg>
    </View>
  );
};
