import { maxDesktopWidth, ENV } from 'common';
import { isMobile } from 'react-device-detect';
import { AppRegistry } from 'react-native';
import App from './App';

if (ENV.ENV !== 'development') {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}

console.log('ENV', ENV);

AppRegistry.registerComponent('App', () => App);

const rootTag = document.getElementById('root');

if (!isMobile) {
  rootTag.style.maxWidth = maxDesktopWidth + 'px';
}

AppRegistry.runApplication('App', {
  rootTag,
});
