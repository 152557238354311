export const HomeSceneName = 'Home';
export const EditTripSceneName = 'EditTrip';
export const TripInfoSceneName = 'TripInfo';
export const AuthSceneName = 'Auth';
export const AddBankAccountSceneName = 'AddBankAccount';
export const AddPayPalAccountSceneName = 'AddPayPalAccount';
export const AddWeChatAccountSceneName = 'AddWeChatAccount';
export const AddMasterCardSceneName = 'AddMasterCard';
export const AddVisaCardSceneName = 'AddVisaCard';
export const AddressSceneName = 'Address';
export const AddressConfirmationSceneName = 'AddressConfirmation';
export const AddressDeclineSceneName = 'AddressDecline';
export const EmailUpdateSceneName = 'EmailUpdate';
export const ReceiptDetailsSceneName = 'ReceiptDetails';
export const ReceiptImagePreviewSceneName = 'ReceiptImagePreview';
export const ReceiptCameraSceneName = 'ReceiptCamera';
export const StampedFormCameraSceneName = 'StampedFormCamera';
export const StampedFormImagePreviewSceneName = 'StampedFormImagePreview';
export const PassportInfoSceneName = 'PassportInfo';
export const PassportDeclineSceneName = 'PassportDecline';
export const PasswordUpdateSceneName = 'PasswordUpdate';
export const MyRefundMethodsSceneName = 'MyRefundMethods';
export const AddRefundMethodSceneName = 'AddRefundMethod';
export const PreloaderSceneName = 'Preloader';
export const AuthLoginSceneName = 'AuthLogin';
export const AuthSignUpSceneName = 'AuthSignUp';
export const AuthForgotEmailSentSceneName = 'AuthForgotEmailSent';
export const AgreementSwitchSceneName = 'AgreementScene';
export const AgreementSwitchNavigatorName = 'AgreementSwitchNavigator';
export const AgreementStackNavigatorName = 'AgreementStackNavigator';
export const TermsConditionsSceneName = 'TermsConditions';
export const PrivacyPolicySceneName = 'Privacy policy';
export const AirvatInfoTaxFormSceneName = 'AirvatInfoTaxForm';
export const AirvatInfoGoodsAboveSceneName = 'AirvatInfoGoodsAbove';
export const AirvatInfoCheckoutSceneName = 'AirvatInfoCheckout';
export const AirvatInfoHowItWorkSceneName = 'AirvatInfoHowItWork';
export const AirvatInfoCustomsInspectionSceneName =
  'AirvatInfoCustomsInspection';

export const HomeStackNavigatorName = 'HomeStackNavigator';
export const HomeTabsNavigatorName = 'HomeTabsNavigator';
export const AuthStackNavigatorName = 'AuthStackNavigator';
export const AccountStackNavigatorName = 'AccountStackNavigator';
export const InfoStackNavigatorName = 'InfoStackNavigator';

export const MoreNavigatorName = 'MoreNavigatorName';
export const MoreSceneName = 'More';
export const RefundCalculatorSceneName = 'RefundCalculator';
// export const RateUsSceneName = 'RateUs';
export const ReferralDashboardSceneName = 'ReferralDashboard';
export const AirvatFranceKbisSceneName = 'AirvatFranceKbis';

export const PassportCameraPlaceholderSceneName = 'PassportCameraPlaceholder';
export const PassportCameraSceneName = 'PassportCamera';
export const PassportPreviewSceneName = 'PassportPreview';
export const PassportDetailsSceneName = 'PassportDetails';
export const SelfieCameraSceneName = 'SelfieCamera';
export const SelfieCameraPlaceholderSceneName = 'SelfieCameraPlaceholder';
export const SelfiePreviewSceneName = 'SelfiePreview';

export const AddTripDatesSceneName = 'AddTripDates';
export const AddTripTransportNodeSceneName = 'AddTripTransportNode';
export const SelectTripCountrySceneName = 'SelectTripCountry';

export const AddressConfirmationImagePreviewSceneName =
  'AddressConfirmationImagePreview';
export const AddressConfirmationCameraSceneName = 'AddressConfirmationCamera';
