import {
  EDocumentStatus,
  IUser,
  IUserAddress,
  IUserPassport,
} from '../../api-store';
import { IDefaultState } from '../../store/types';
import { some, get } from 'lodash';

export function getPassport(state: IDefaultState): IUserPassport | undefined {
  return get(state, 'api.user.document');
}

export const getIsFilledAddress = (user?: IUser): boolean => {
  const addressKeys = ['id', 'city', 'country.id', 'line', 'postcode'];
  const address = user?.address;
  return (
    !!address && some(addressKeys, (k: keyof IUserAddress) => !!get(address, k))
  );
};

export const getIsNeedConfirmAddress = (user?: IUser) => {
  const isFilled = getIsFilledAddress(user);
  const isDocumentNotUploaded = !user?.address?.uploadsId;
  const isEu = !!user?.document?.country?.isEu;
  return isEu && isFilled && isDocumentNotUploaded;
};

export const getIsConfirmAddressDeclined = (user?: IUser): boolean => {
  return user?.address?.statusId === EDocumentStatus.DECLINED;
};
