import { colors, isAndroid, sketchSize } from '../../common';
import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: sketchSize(93),
    backgroundColor: colors.white,
    justifyContent: 'flex-start',
  },
  titleStyle: {
    paddingTop: sketchSize(55),
  },
  locationContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: sketchSize(142.35),
  },
  fill: {
    flex: 1,
  },
  locationImage: {
    width: sketchSize(120),
    height: sketchSize(100),
  },
  inputStyle: {
    height: sketchSize(isAndroid ? 200 : 186),
    flex: undefined,
    textAlign: 'right',
    textAlignVertical: 'bottom',
    paddingBottom: 0,
  },
  inputWrapStyle: {
    height: sketchSize(isAndroid ? 200 : 186),
    paddingBottom: sketchSize(isAndroid ? 25 : 55),
  },
  inputStylePadding: {
    paddingTop: sketchSize(71),
  },
  inputExtraWrapper: {
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
  },
  colorIron: {
    color: colors.iron,
  },
  backColorIron: {
    backgroundColor: colors.iron,
  },
  pigIconWrap: { width: sketchSize(105) },
});
