import { ENV } from '../env';

export const vatNumber = `VAT no. 312092250`;
export const airvatAddressVat = `*Airvat ltd,\n20-22 Wenlock Road,\nLondon, N1 7GU, UK\n${vatNumber}*`;
export const airvatAddressCountryNonVat = `*Airvat ltd,\n20-22 Wenlock Road,\nLondon, N1 7GU,\nUnited Kingdom*`;
export const airvatAddressCountry = `*Airvat Ltd.\n20-22 Wenlock Road\nLondon, N1 7GU\nEngland\n${vatNumber}*`;
export const airvatAddressCountryFr = `*Airvat EURL\n10 rue de Penthièvre,\n75008, Paris\nSIRET 90084709600014* (optional information)\n*TVA FR 50900847096* (optional information)`;
export const airvatTagsAddressCountry = `\n${airvatAddressCountry.replace(
  /(\*)+/g,
  `<b>`
)}\n`;
export const airvatTagsAddressCountryFr = `\n${airvatAddressCountryFr.replace(
  /(\*)+/g,
  `<b>`
)}\n`;
export const airvatTagsAddressCountryNonVat = `\n${airvatAddressCountryNonVat.replace(
  /(\*)+/g,
  `<b>`
)}\n`;

export const airvatAddressIt = `Airvat ltd\n20-22 Wenlock Road\nLondre, N17GU\nRegno Unito\np.IVA: 00311079990\nPEC: airvat@pec.it`;

export const infoMail = ENV.INFO_EMAIL;
export const GB = `GB`;
export const GB_NIR = `GB-NIR`;
export const FR = `FR`;
export const BE = `BE`;
