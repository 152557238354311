import { avAlert } from '../../../components/AvAlert/alertActions';
import {
  CameraContainer,
  CameraRef,
  CAMERA_OPTIONS,
  ITakePictureResponse,
} from '../../../components/CameraContainer';
import { AddressConfirmationImagePreviewSceneName } from '../../../navigation/names';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { IAddressConfirmationCameraViewProps } from './types';

export const AddressConfirmationCameraView: React.FC<IAddressConfirmationCameraViewProps> = props => {
  const handleOnSavePicture = (cameraRef: CameraRef) => {
    if (!cameraRef || !cameraRef.current) return;
    cameraRef.current
      .takePictureAsync(CAMERA_OPTIONS)
      .then((picture: ITakePictureResponse) => {
        props.navigation.navigate(AddressConfirmationImagePreviewSceneName, {
          ...(props.route.params || {}),
          picture,
        });
      })
      .catch(handleOnSavePictureError);
  };

  const handleOnSavePictureError = (e: Error) => {
    props.avAlert('', e.message);
  };

  return !props.navigation.isFocused() ? null : (
    <CameraContainer
      testIdPrefix='address'
      onTakePicture={handleOnSavePicture}
    />
  );
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      avAlert,
    },
    dispatch
  );
};

export const AddressConfirmationCamera = compose(
  connect(null, mapDispatchToProps)
)(AddressConfirmationCameraView);
