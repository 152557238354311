import { EDirname } from "../../api-store";
import { constants } from "../../common";
import { createApiUploadFile } from "../../common/utils";
import { avAlert } from "../../components/AvAlert/alertActions";
import { avLoader } from "../../components/AvAlert/loaderActions";
import { CameraPicturePreview } from "../../components/CameraContainer";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { documentsCameraMask } from "./documentsCameraMask";
import { documentsContentView } from "./documentsContentView";
import { handleOnSavePicture } from "./documentsServices";
import { IDocumentPreviewProps } from "./types";
import AnyImagePreview from "../../components/AnyImagePreview";

export const PassportPreviewView: React.FC<IDocumentPreviewProps> = (props) => {
  const picture = props.route.params?.document;
  const isPdf = !!props.route.params?.isPdf;

  const onSave = () => {
    handleOnSavePicture(props, picture);
  };

  const onRetake = () => {
    props.navigation.goBack();
  };

  if (isPdf) {
    return (
      <AnyImagePreview
        onComplete={onSave}
        onRetake={onRetake}
        isLoading={false}
        retakeLabel={props.route.params?.retakeLabel}
        picture={createApiUploadFile(picture, EDirname.DOCUMENTS)}
      />
    );
  }

  return (
    <CameraPicturePreview
      {...props}
      onSave={onSave}
      onRetake={onRetake}
      picture={createApiUploadFile(picture, EDirname.DOCUMENTS)}
      imageMask={documentsCameraMask('document-preview', true)}
      testIdPrefix="document"
      retakeLabel={props.route.params?.retakeLabel}
      imageContent={documentsContentView(
        "",
        constants.passportPreviewPageMessage,
        true
      )}
    />
  );
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      avAlert,
      avLoader,
    },
    dispatch
  );
};

export const PassportPreview = compose(connect(null, mapDispatchToProps))(
  PassportPreviewView
);
