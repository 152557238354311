import { constants } from '../../common';
import { avAlert } from '../../components/AvAlert/alertActions';
import { avLoader } from '../../components/AvAlert/loaderActions';
import { CameraContainer } from '../../components/CameraContainer';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { documentsCameraMask } from './documentsCameraMask';
import { documentsContentView } from './documentsContentView';
import { handleOnTakePicture } from './documentsServices';
import { IDocumentCameraProps } from './types';

export const PassportCameraView: React.FC<IDocumentCameraProps> = (props) => {
  if (!props.navigation.isFocused()) return null;
  return (
    <CameraContainer
      onTakePicture={handleOnTakePicture(props)}
      cameraMask={documentsCameraMask('document-camera', false, true)}
      cameraContent={documentsContentView(
        constants.passportCameraPageTitle,
        constants.passportCameraPageMessage
      )}
      testIdPrefix="document"
      cameraType="back"
      cameraFlashMode="off"
    />
  );
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      avAlert,
      avLoader,
    },
    dispatch
  );
};

export const PassportCamera = compose(connect(null, mapDispatchToProps))(
  PassportCameraView
);
