import { images, isAndroid, isWeb } from '../../common';
import { Text } from 'react-native';
import { RNFastImage } from '../../modules/RNFastImage';
import { labelCommonProps } from './helpers';
import { ILabelProps } from './types';
import styles from './styles';
import { RNTouchableOpacity } from '../../modules/RNGestureHandler';
import React from 'react';

const renderWarnIcon: React.FC<ILabelProps> = (props) => {
  if (props.warnIcon) {
    return (
      <RNFastImage
        source={images.warning}
        style={styles.warningIcon}
        resizeMode="contain"
      />
    );
  }
};

const renderTouchForTests: React.FC<ILabelProps> = (props) => {
  if (!isAndroid && props.onPress && !isWeb) {
    const testID =
      props.testID || (props.onPress?.name && `${props.onPress?.name}Btn`);
    return (
      <RNTouchableOpacity
        testID={testID}
        onPress={props.onPress}
        style={{
          width: 10,
          height: 10,
          backgroundColor: 'red',
        }}
      />
    );
  }
};

export const renderText: React.FC<ILabelProps> = (props) => {
  const commonProps = labelCommonProps(props)
  return (
    <Text
      {...commonProps}
      style={[commonProps.style,props.onPress ? {margin:0} : undefined]}
      allowFontScaling={false}
      adjustsFontSizeToFit={false}
      testID={props.testID}
      minimumFontScale={1}
    >
      {renderWarnIcon(props)}
      {isWeb && props.onPress ? (
        <span onClick={props.onPress}>
           {props.text || ''}
        </span>
      ) : (
        props.text || ''
      )}
      {renderTouchForTests(props)}
    </Text>
  );
};
