import { StyleSheet } from 'react-native';

import { colors, sketchSize } from '../common';

export default StyleSheet.create({
  homeTabNavigator: {
    borderTopWidth: 0,
    shadowColor: colors.dark,
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5
  },
  whiteTitle: {
    color: colors.white
  },
  headerLeftContainerStyle: {
    marginLeft: sketchSize(39),
  }
});
