import { constants } from '../../common';
import {
  alertResetAction,
  alertSetAction,
  alertToggleAction,
} from './alertActionTypes';

const onClose = (onPress?: () => void) => (dispatch: any) => () => {
  dispatch(alertResetAction());
  onPress && onPress();
};

export const avAlert =
  (
    title: string,
    message: string,
    cancelOnPress?: () => void,
    isSuccess?: boolean
  ) =>
  (dispatch: any) => {
    const buttons = [
      {
        text: constants.labels.ok,
        onPress: onClose(cancelOnPress)(dispatch),
        accent: isSuccess,
      },
    ];
    dispatch(alertSetAction({ title, message, buttons }));
  };

export const avAlertToggle = (isVisible: boolean) => (dispatch: any) => {
  dispatch(alertToggleAction({ isVisible }));
};

export const avConfirm =
  (
    title: string,
    message: string,
    cancelOnPress: () => void,
    onPress?: () => void,
    isSuccess?: boolean
  ) =>
  (dispatch: any) => {
    const buttons = [
      {
        text: constants.labels.yes,
        onPress: onClose(cancelOnPress)(dispatch),
        accent: isSuccess,
      },
      {
        text: constants.labels.no,
        onPress: onClose(onPress)(dispatch),
        accent: isSuccess,
      },
    ];

    dispatch(alertSetAction({ title, message, buttons }));
  };
