import { RNConfig } from '../modules/RNConfig';

export const ENV = {
  INFO_EMAIL: 'info@airvat.com',
  GOOGLE_OAUTH_TOKEN: String(RNConfig.GOOGLE_OAUTH_TOKEN),

  APP_VERSION: RNConfig.APP_VERSION,
  IOS_CLIENT_ID: String(RNConfig.IOS_CLIENT_ID),

  GOOGLEPLAY_URL: RNConfig.GOOGLEPLAY_URL,
  APPSTORE_URL: RNConfig.APPSTORE_URL,

  ENV: String(RNConfig.ENV),

  LANG: 'EN',

  WEB_REVISION: RNConfig.WEB_REVISION,
};
