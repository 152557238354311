import { debounce, size } from 'lodash';
import React from 'react';
import { ActivityIndicator, View } from 'react-native';
import { ICountry } from '../../api-store';
import { airvatClient, colors, constants, isWeb } from '../../common';
import { RNFastImage } from '../../modules/RNFastImage';
import { AvTextInput } from '../AvTextInput';
import { Label } from '../Labels';
import { List } from '../List';
import { TouchableView } from '../TouchableView';
import styles from './styles';
import { ISelectCountryProps, ISelectCountryState } from './types';

const limit = isWeb ? 9999 : 50;
export class SelectCountry extends React.Component<
  ISelectCountryProps,
  ISelectCountryState
> {
  countryName = '';
  state: ISelectCountryState = {
    errorText: '',
    countries: undefined,
    isLoading: false,
    isMounted: false,
    hasMoreLoad: false,
    isMoreLoading: false,
  };

  handleError = (e: any) => {
    this.setState({ errorText: e.message });
  };

  setCountries = (countries?: ICountry[]) => {
    const errorText = countries && size(countries) ? '' : constants.errorSearch;
    this.state.isMounted &&
      this.setState({
        countries: size(countries) ? countries : undefined,
        isLoading: false,
        isMoreLoading: false,
        errorText,
      });
  };

  loadCountries = async (offset: number, countryName?: string) => {
    const params: any = {
      size: limit,
      page: Math.ceil(offset / limit),
      sort: 'name',
      direction: 'asc',
    };
    if (this.props.isEu !== undefined) {
      params.isEu = this.props.isEu;
    }
    if (countryName || this.countryName) {
      params.name = countryName || this.countryName;
    }
    if (this.props.isAddress) {
      params.isEu = false;
    }
    if (this.props.tripOnly) {
      params.isAllowedForTrip = true;
    }
    try {
      const { results } = await airvatClient.get<{ results: ICountry[] }>(
        'i18n/countries',
        { params }
      );
      const hasMoreLoad = size(results || []) === limit;
      this.setState({ hasMoreLoad });
      return results;
    } catch (e) {
      this.handleError(e);
    }
  };

  getMoreCountries = async () => {
    const offset = size(this.state.countries);
    if (!this.state.hasMoreLoad || this.state.isLoading || offset < limit)
      return;
    this.setState({ isMoreLoading: true });
    const countries = await this.loadCountries(offset, this.countryName);
    const resultCountries = [
      ...(this.state.countries || []),
      ...(countries || []),
    ];
    this.setCountries(resultCountries);
  };

  getCountries = async (countryName?: string) => {
    this.setState({ isLoading: true, countries: undefined });
    const countries = await this.loadCountries(0, countryName);
    this.setCountries(countries);
  };

  getTripCountries = async () => {
    this.setState({ isLoading: true, countries: undefined });
    try {
      const { results } = await airvatClient.get<{ results: ICountry[] }>(
        'i18n/countries',
        { params: { isAllowedForTrip: true, sort: 'name', direction: 'asc' } }
      );
      this.setCountries(results);
    } catch (e) {
      this.setState({ isLoading: false });
      this.handleError(e);
    }
  };

  debounceSearch = debounce(this.getCountries, 400);

  handleOnSearch = (countryName: string) => {
    this.countryName = countryName;
    this.debounceSearch(countryName);
  };

  componentDidMount() {
    this.setState({
      isMounted: true,
    });
    if (this.props.tripOnly) {
      this.getTripCountries();
    } else {
      this.getCountries();
    }
  }

  componentWillUnmount() {
    this.setState({ isMounted: false });
  }

  render() {
    return (
      <View style={styles.containerStyle}>
        {!this.props.tripOnly && (
          <AvTextInput
            key="selectCountry"
            showSearchIcon
            otherTextInputProps={{
              onChangeText: this.handleOnSearch,
              placeholder: constants.placeholders.countries,
            }}
            isForceShowErrorText
            errorText={this.state.errorText}
            testID={`countrySearchInp`}
          />
        )}
        <List
          useMask
          testID={'selectCountryScroll'}
          data={this.state.countries}
          renderItem={this.renderItem}
          keyExtractor={(item: ICountry, i: number) => `${item.id}${i}`}
          scrollEnabled={!this.props.tripOnly}
          contentContainerStyle={styles.listContent}
          onEndReached={this.getMoreCountries}
          onEndReachedThreshold={0.2}
          ListFooterComponent={
            <View style={styles.listFooterSpacer}>
              <ActivityIndicator
                animating={this.state.isMoreLoading}
                color={colors.dark}
              />
            </View>
          }
          ListEmptyComponent={
            <ActivityIndicator
              animating={this.state.isLoading}
              color={colors.dark}
            />
          }
        />
      </View>
    );
  }

  renderItem = ({ item, index }: { item: ICountry; index: number }) => {
    const imageSource = item.source ? item.source : { uri: item.flagUri };

    return (
      <TouchableView
        style={styles.stepWrap}
        onPress={() => {
          this.props.onItemPress(item);
        }}
        testID={`countryItemTouch${index}`}
      >
        <View style={styles.step}>
          <RNFastImage
            resizeMode="cover"
            style={styles.listItemImage}
            source={imageSource}
          />
          <View style={styles.stepTitle}>
            <Label type={'H1Dark'} text={item.name} numberOfLines={2} />
          </View>
        </View>
      </TouchableView>
    );
  };
}
