import { EDocumentStatus } from '../../../api-store';
import { waitForAsync } from '../../../common';
import { constants } from '../../../common/constants';
import { avAlert } from '../../../components/AvAlert/alertActions';
import { HomeSceneName } from '../../../navigation/names';
import { CommonActions } from '@react-navigation/native';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Passport } from './Passport';
import { IPassportDeclineProps, IPassportPreviewProps } from './types';

const PassportDeclineComponent: React.FC<IPassportDeclineProps> = props => {
  React.useEffect(() => {
    waitForAsync(600).then(() => {
      props.avAlert('', constants.invalidDocument);
    });
  }, []);

  const handleDocumentUpdate = ({ document }: IPassportPreviewProps) => {
    if (document && document?.status !== EDocumentStatus.DECLINED) {
      props.navigation.dispatch(
        CommonActions.reset({
          index: 0,
          routes: [{ name: HomeSceneName }],
        })
      );
    }
  };

  return <Passport {...props} onUpdate={handleDocumentUpdate} />;
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      avAlert,
    },
    dispatch
  );
};

export const PassportDecline = compose<any>(connect(null, mapDispatchToProps))(
  PassportDeclineComponent
);
