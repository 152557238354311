import { colors } from '../../../../common/colors';
import { isIphoneSmall, sketchSize } from '../../../../common/metrics';
import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  container: {
    width: sketchSize(502),
    marginRight: sketchSize(30),
    marginBottom: sketchSize(isIphoneSmall ? 10 : 20)
  },
  photoContainer: {
    flexGrow: 1,
    width: undefined,
    marginRight: 0
  },
  containerWithNoMarginRight: {
    marginRight: 0
  },
  containerWithNoMarginBottom: {
    marginBottom: 0
  },
  fieldNameContainer: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  fieldNameContainerSpaced: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  fieldNameContainerWithIncreasedMarginBottom: {
    marginBottom: isIphoneSmall ? sketchSize(10) : sketchSize(20)
  },
  verificationStatusContainer: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  verifiedText: {
    marginLeft: sketchSize(19)
  },
  fieldName: {
    marginRight: sketchSize(20)
  },
  fieldNamePassport: {
    marginBottom: 0
  },
  photoPlaceholder: {
    overflow: 'hidden',
    alignItems: 'center',
    justifyContent: 'center',
    width: sketchSize(502),
    height: sketchSize(323),
    backgroundColor: colors.hawkesBlue,
    borderRadius: sketchSize(30)
  },
  photoPlaceholderFullSize: {
    flexGrow: 1,
    width: undefined,
    height: undefined,
    minHeight: sketchSize(646)
  },
  photoDocumentContainer: {
    width: '100%',
    height: '100%',
    position: 'absolute',
  },
  photoDocumentImage: {
    flex: 1
  },
  fieldText: {
    marginTop: sketchSize(20)
  }
});
