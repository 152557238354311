import { StyleSheet } from 'react-native';

import { colors } from '../../../../common/colors';
import { sketchSize } from '../../../../common/metrics';

export default StyleSheet.create({
  containerStyle: {
    flex: 1,
    padding: sketchSize(39),
    backgroundColor: colors.white,
    justifyContent: 'space-between'
  },
  stepWrap: {
    height: sketchSize(200),
    backgroundColor: colors.white,
    alignItems: 'center',
    paddingHorizontal: sketchSize(5),
    flexDirection: 'row'
  },
  stepIcon: {marginRight: sketchSize(30)},
  listFooterSpacer: {
    height: sketchSize(90)
  },
});
