import React from 'react';
import { renderText } from './renderText';
import { ILabelProps } from './types';

export const formattedLabel = (
  props: ILabelProps,
  key: number,
  linkAction?: () => void
) => {
  const TextLabel = renderText;
  return (
    <TextLabel
      {...props}
      type={props.type || 'H2Light'}
      key={key}
      onPress={linkAction}
    />
  );
};
