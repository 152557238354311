import { EDirname, updateAddress } from "../../../api-store";
import { uploadFile } from "../../../api-store/actions/uploads";
import { createApiUploadFile } from "../../../common";
import AnyImagePreview from "../../../components/AnyImagePreview";
import { avAlert } from "../../../components/AvAlert/alertActions";
import { ITakePictureResponse } from "../../../components/CameraContainer";
import { getDefaultRefundMethod } from "../../../containers/Home/state";
import { AddRefundMethodSceneName, HomeSceneName } from "../../../navigation/names";
import { IDefaultState } from "../../../store/types";
import { CommonActions } from "@react-navigation/native";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import {
  IAddressConfirmationImagePreviewProps,
  IAddressConfirmationImagePreviewState,
} from "./types";

class AddressConfirmationImagePreview extends React.Component<
  IAddressConfirmationImagePreviewProps,
  IAddressConfirmationImagePreviewState
> {
  state = { isFileUploading: false };

  complete = async () => {
    this.setState({ isFileUploading: true });
    try {
      const picture = this.props.route?.params?.picture as ITakePictureResponse;
      const uploadsId = await this.props.uploadFile(
        createApiUploadFile(picture, EDirname.ADDRESSES)
      );
      await this.props.updateAddress(Number(this.props.user.address?.id), {
        uploadsId,
      });
      const sceneName = !this.props.isExistsDefaultRefundMethod
        ? AddRefundMethodSceneName
        : HomeSceneName;
      this.setState({ isFileUploading: false });
      this.props.navigation.dispatch(
        CommonActions.reset({
          index: 0,
          routes: [
            {
              name: sceneName,
              params: this.props.route.params,
            },
          ],
        })
      );
    } catch (e) {
      this.handleError(e);
    }
  };

  handleError = (e: any) => {
    this.setState({ isFileUploading: false });
    this.props.avAlert("", e.message);
  };

  handleOnRetake = () => {
    this.props.navigation.goBack();
  };

  render() {
    return (
      <AnyImagePreview
        onComplete={this.complete}
        onRetake={this.handleOnRetake}
        isLoading={this.state.isFileUploading}
        retakeLabel={this.props.route.params?.retakeLabel}
        picture={this.props.route.params?.picture}
      />
    );
  }
}

const mapStateToProps = (state: IDefaultState) => {
  const user = state.api.user;
  const isExistsDefaultRefundMethod = !!getDefaultRefundMethod(state);
  return {
    isExistsDefaultRefundMethod,
    user,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      avAlert,
      uploadFile,
      updateAddress,
    },
    dispatch
  );
};

export default compose<any>(connect(mapStateToProps, mapDispatchToProps))(
  AddressConfirmationImagePreview
);
