import { ITransportNode, ITrip } from '../../api-store';
import { constants, getCountryCode } from '../../common';
import { BE, GB_NIR } from '../../common/constants/common';
import { INavigationScreenProps } from '../../navigation';
import { AddTripDatesSceneName } from '../../navigation/names';
import React, { useLayoutEffect } from 'react';
import SelectTransportNode from './components/SelectTransportNode';

const AddTripTransportNode: React.FC<INavigationScreenProps> = (props) => {
  const newTrip: ITrip | undefined = props.route?.params?.newTrip;
  const countryCode = getCountryCode(newTrip?.country);
  const isGbNir = countryCode === GB_NIR;

  useLayoutEffect(() => {
    const title = isGbNir
      ? constants.transportNodes.flyFrom
      : constants.transportNodes.departureFrom;
    props.navigation.setOptions({
      title,
    });
  }, [isGbNir]);

  const transportNodeSelect = (transportNode: ITransportNode) => {
    const isBe = newTrip?.country?.alpha2Code === BE;
    const params = {
      newTrip: {
        ...(newTrip || {}),
        transportNodeCode: transportNode?.code,
        departureTerminalCode: transportNode?.code,
        transportNode,
      },
      isDefaultEnabled: isBe
        ? (transportNode?.code || '').startsWith('OTR_')
        : true,
    };
    props.navigation.navigate(AddTripDatesSceneName, params);
  };

  return (
    <SelectTransportNode
      onSelectItem={transportNodeSelect}
      country={newTrip?.country}
    />
  );
};

export default AddTripTransportNode;
