import React from 'react';
import { connect } from 'react-redux';
import { IDefaultState } from '../../store/types';
import { AppVersionView } from './AppVersionView';
import { IAppVersionProps } from './types';

const AppVersionComponent: React.FC<IAppVersionProps> = props => {
  return <AppVersionView {...props} />;
};

export const AppVersion = connect((state: IDefaultState) => ({
  appVersion: state.api.service.MIN_CLIENTS_VERSIONS.value.mobileApp,
}))(AppVersionComponent);
