import { loaderReducer } from '../components/AvAlert/loaderReducer';
import { combineReducers } from 'redux';
import { apiReducer } from '../api-store';
import { alertReducer } from '../components/AvAlert/alertReducer';

const rootReducer = combineReducers({
  alert: alertReducer,
  loader: loaderReducer,
  api: apiReducer,
});

export default rootReducer;
