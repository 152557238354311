import { HOST, HOST_PATH } from '../common';
import React from 'react';
import { io } from 'socket.io-client';

const socket = io(HOST, {
  transports: ['websocket'],
  path: HOST_PATH + '/socket.io',
});

export const useWebSocket = <T>(url: string) => {
  const [message, setMessage] = React.useState<T>();

  const onMessage = (value: T) => {
    setMessage(value);
  };

  const onError = (e: any) => {
    console.error('WS', e.message, e.description);
  };

  const on = () => {
    socket.on(url, onMessage);
    socket.on('connect_error', onError);
  };

  const off = () => {
    socket.off(url, onMessage);
    socket.off('connect_error', onError);
  };

  console.log('WS', socket.connected, message && JSON.stringify(message), url);

  return { message, on, off, socket };
};
