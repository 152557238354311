import { colors } from '../../common/colors';
import {
  deviceHeight,
  deviceWidth,
  headerHeight,
  isSmallDevice,
  sketchSize,
} from '../../common/metrics';
import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  container: { flex: 1, width: '100%', border: 0 },
  listGradientContainer: {
    paddingBottom: sketchSize(300, true),
    marginTop: sketchSize(40, true),
  },
  centredVertContainer: { flex: 1, justifyContent: 'center' },
  centredContainer: { flex: 1, justifyContent: 'center', alignItems: 'center' },
  stretchContainer: { flex: 1, alignItems: 'stretch' },
  imageWrap: {
    flex: isSmallDevice ? 1 : 1.2,
    marginVertical: sketchSize(isSmallDevice ? 40 : 0),
    justifyContent: 'center',
    alignItems: 'center',
  },
  dotListWrap: { marginTop: sketchSize(60, true) },
  image: {
    width: sketchSize(800),
    height: sketchSize(isSmallDevice ? 600 : 750, true),
  },
  smallImage: {
    width: sketchSize(350),
    height: sketchSize(350, true),
    marginHorizontal: sketchSize(40),
  },
  messageWrap: {
    marginTop: sketchSize(60),
    marginHorizontal: sketchSize(120),
  },
  dotStyle: {
    width: sketchSize(30),
    height: sketchSize(30),
    borderRadius: sketchSize(15),
    backgroundColor: colors.dark,
  },
  margin: { marginHorizontal: sketchSize(75) },
  paymentBlock: {
    justifyContent: 'space-evenly',
    alignItems: 'center',
    flexDirection: 'row',
    marginVertical: sketchSize(90),
    marginHorizontal: sketchSize(120),
  },
  goodsIconsWrap: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  goodsIconsRowWrap: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  goodsItemWrap: {
    width: '80%',
    flexDirection: 'row',
    marginHorizontal: sketchSize(isSmallDevice ? 50 : 100),
  },
  goodsItem: {
    flex: 1,
    marginHorizontal: sketchSize(isSmallDevice ? 20 : 40),
    marginBottom: sketchSize(isSmallDevice ? 40 : 80),
  },
  goodsItemIcon: {
    justifyContent: 'flex-start',
    marginTop: sketchSize(5),
  },
  iconItem: {
    width: sketchSize(isSmallDevice ? 230 : 300),
    height: sketchSize(440, true),
    marginHorizontal: sketchSize(120),
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconItemLabel: {
    flex: 1,
    marginTop: sketchSize(40),
  },
  bottomTaxFormImage: {
    width: '100%',
    height: sketchSize(1500),
  },
  bottomReceiptImage: {
    width: '100%',
    height: sketchSize(1750),
  },
  buttomImageLabel: {
    marginVertical: 0,
    marginBottom: sketchSize(60),
  },
  eligibleTopLabel: {
    marginHorizontal: sketchSize(70),
    marginBottom: sketchSize(60),
  },
  eligibleLabel: {
    marginHorizontal: sketchSize(70),
    marginVertical: sketchSize(60),
  },
  centredFlex: {
    flex: 1,
    justifyContent: 'center',
  },
  dotListMargin: {
    flex: 1,
    marginHorizontal: sketchSize(70),
    justifyContent: 'space-evenly',
  },
  dotListBottomMargin: {
    flex: 1,
    marginHorizontal: sketchSize(70),
    justifyContent: 'space-evenly',
    marginBottom: sketchSize(60),
  },
  listItemMargin: {
    marginRight: sketchSize(60),
  },
  dotListItemMargin: {
    marginHorizontal: sketchSize(60),
  },
  dotListItemLabel: {
    marginVertical: sketchSize(60),
  },
  vatLabel: {
    marginVertical: sketchSize(40),
  },
  hwItemContainer: {
    flexDirection: 'row',
    marginBottom: sketchSize(90),
    marginRight: sketchSize(40),
  },
  hwCantered: { justifyContent: 'center', alignItems: 'center' },
  hwBottomButton: { width: sketchSize(400) },
  marginBottom: { marginBottom: sketchSize(40, true) },
  airvatInfoHowItWorksWrap: { paddingBottom: sketchSize(100) },
  airvatInfoHowItWorksItemWrap: {
    width: sketchSize(400),
    justifyContent: 'center',
    alignItems: 'center',
  },
  airvatInfoHowItWorksItem: { alignSelf: 'center', flex: 1 },
  airvatInfoHowItWorksItemFooter: {
    flex: 1,
    marginBottom: sketchSize(90),
    marginRight: sketchSize(40),
  },
  margin20: { margin: sketchSize(20) },
  row: {
    flexDirection: 'row',
  },
  marginTop200: { marginTop: sketchSize(200, true) },
  logo: {
    marginTop: headerHeight / 2,
    marginBottom: sketchSize(isSmallDevice ? 100 : 150),
  },
  infoCheckoutContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: sketchSize(20),
  },
  infoCheckoutNavigationButton: {
    width: sketchSize(80),
    height: sketchSize(70),
    marginLeft: sketchSize(30),
  },
  webView: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
  },
  cache: {
    width: 0,
    height: 0,
    position: 'absolute',
    top: 0,
    left: 0,
  },
});
