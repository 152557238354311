import { createFileName, createMime, isWeb } from '../../common';
import { airvatClient } from '../../common/api';
import { IApiUploadFile } from '../types';
import { IApiFile } from './../types';
import { handleAuthError } from './auth';

export const uploadFile =
  (file: IApiUploadFile) => async (dispatch: any, getState: any) => {
    console.log('uploadFile', file);
    const filename = createFileName(String(file.uri), file.dirname);
    const type = createMime(String(file.base64 || file.uri), file.type);
    let body: any;
    if (file.uri) {
      if (isWeb) {
        const formData = new FormData();
        const response = await fetch(file.uri);
        const blob = await response.blob();
        formData.append('file', new File([blob], filename, { type: blob.type }));
        formData.append('dirname', file.dirname);
        console.log(
          'uploadFile FormData',
          formData.getAll('file'),
          formData.getAll('dirname')
        );
        body = formData;
      } else {
        body = [
          {
            name: 'file',
            filename,
            type,
            data: airvatClient.uploadWrap(file.uri, filename),
          },
          {
            name: 'dirname',
            data: file.dirname,
          },
        ];
      }
    } else if (file.base64) {
      const buff = await fetch(file.base64).then(function (res) {
        return res.arrayBuffer();
      });
      const bufferFile = new File([buff], `${file.dirname + Date.now()}.png`, {
        type: 'image/png',
      });
      const formData = new FormData();
      formData.append('file', bufferFile);
      formData.append('dirname', file.dirname);
      console.log(
        'uploadFile FormData',
        formData.getAll('file'),
        formData.getAll('dirname')
      );
      body = formData;
    }

    console.log('uploadFile body', body);

    const fileResponse = await airvatClient
      .upload<IApiFile>(`uploads/${file.dirname}`, body)
      .catch((e: any) => handleAuthError(e)(dispatch, getState));
    console.log('fileResponse', fileResponse);
    return fileResponse?.id;
  };
