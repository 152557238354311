import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { ENV } from './env';

const firebaseConfig =
  ENV.ENV === 'development'
    ? {
        apiKey: 'AIzaSyAJ2vkAyXUtK2r-6dlPeStQtWQto43Iihw',
        authDomain: 'airvat-dev.firebaseapp.com',
        databaseURL: 'https://airvat-dev.firebaseio.com',
        projectId: 'airvat-dev',
        storageBucket: 'airvat-dev.appspot.com',
        messagingSenderId: '755571202205',
        appId: '1:755571202205:web:a3d981a0f974ec340cdc74',
        measurementId: 'G-QJJ8HT83TR',
      }
    : {
        apiKey: 'AIzaSyABMM_aMi8bGbXXQYS9yxUVmLoYEbmMt2E',
        authDomain: 'airvat-production.firebaseapp.com',
        databaseURL: 'https://airvat-production.firebaseio.com',
        projectId: 'airvat-production',
        storageBucket: 'airvat-production.appspot.com',
        messagingSenderId: '735983107153',
        appId: '1:735983107153:web:98bf031720c6589fa8cc70',
        measurementId: 'G-R9LEX85DLW',
      };

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const fbAnalytics = () => {
  return {
    setAnalyticsCollectionEnabled: () => {},
    logScreenView: () => {},
    logEvent: (event: string, data: any) => logEvent(analytics, event, data),
  };
};
export const fbFirebase = { analytics: fbAnalytics };
