import { sketchSize, colors } from '../../common';
import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  container: {
    margin: sketchSize(40),
    marginBottom: 0,
  },
  fill: {
    flex: 1,
  },
  noteContainer: {
    borderRadius: sketchSize(40),
    padding: sketchSize(40),
    backgroundColor: colors.white,
  },
  crossButton: {
    width: sketchSize(60),
    height: sketchSize(60),
  },
  noteHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: sketchSize(10),
  },
  bottomLabel: {
    flex: 1,
  },
  labelWrap: {
    marginVertical: sketchSize(20, true),
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  warningIcon: {
    width: sketchSize(50),
    height: sketchSize(60),
    marginRight: sketchSize(20),
  },
});
