import { constants, isIOS, localStorage, setStatusBar } from '../../common';
import React from 'react';
import { Alert, StyleSheet, View } from 'react-native';
import { AppRNCamera } from '../../modules/RNCamera';
import {
  check,
  openSettings,
  PERMISSIONS,
  RESULTS,
} from 'react-native-permissions';
import { SafeAreaView } from 'react-native-safe-area-context';
import { AvIcon } from '../AvIcon';
import { CustomButton } from '../CustomButton';
import { ANDROID_CAMERA_PERMISSION } from './constants';
import { cameraContainerStyles } from './styles';
import { ICameraViewProps } from './types';

export const checkCameraPermissions = async () => {
  const storageKey = 'camera-permissions';
  const isNextRun = await localStorage(storageKey).get();
  if (!isNextRun) {
    await localStorage(storageKey, true).set();
  }
  const cameraPermissions = isIOS
    ? PERMISSIONS.IOS.CAMERA
    : PERMISSIONS.ANDROID.CAMERA;
  const compare = isIOS ? RESULTS.BLOCKED : RESULTS.DENIED;

  return new Promise((resolve, reject) => {
    check(cameraPermissions).then(result => {
      if (result === compare && isNextRun) {
        Alert.alert(
          constants.cameraPermissions.title,
          constants.cameraPermissions.message,
          [
            {
              text: constants.cameraPermissions.reject,
              onPress: () => reject(),
              style: 'cancel',
            },
            {
              text: constants.cameraPermissions.resolve,
              onPress: () => {
                reject();
                openSettings();
              },
            },
          ],
          { cancelable: false }
        );
      } else {
        resolve(new Error('Camera error'));
      }
    });
  });
};

export const CameraContainer = (props: ICameraViewProps) => {  
  const cameraRef: any = React.useRef();

  const {
    cameraType,
    cameraFlashMode,
    onTakePicture,
    cameraContent,
    cameraMask,
    Placeholder,
    testIdPrefix
  } = props;

  React.useEffect(() => {
    return () => {
      setStatusBar(false);
    }
  }, []);

  React.useEffect(() => {
    setStatusBar(!Placeholder);
  }, [Placeholder]);

  return (
    <AppRNCamera
      ref={cameraRef}
      captureAudio={false}
      style={cameraContainerStyles.container}
      type={cameraType || "back"}
      flashMode={cameraFlashMode || "on"}
      androidCameraPermissionOptions={ANDROID_CAMERA_PERMISSION}
    >
      <View style={StyleSheet.absoluteFill}>{cameraMask}</View>
      <View style={cameraContainerStyles.container} />
      <SafeAreaView edges={['bottom']}>
        {cameraContent}
        <CustomButton
          isRounded
          onPress={() => onTakePicture(cameraRef)}
          iconComponent={<AvIcon name={'Radials'} width={180} />}
          buttonStyle={cameraContainerStyles.button}
          testID={`${testIdPrefix}CameraTakePictureBtn`}
        />
      </SafeAreaView>
      {!!Placeholder && <Placeholder />}
    </AppRNCamera>
  );
};
