import { StyleSheet } from 'react-native';

import colors from '../../common/colors';
import { isWeb, sketchSize } from '../../common/metrics';

export default StyleSheet.create({
  buttonMain: {
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    width: isWeb ? '90%' : sketchSize(833),
    height: sketchSize(140),
    borderRadius: sketchSize(23)
  },
  buttonRound: {
    width: sketchSize(153),
    height: sketchSize(153),
    borderRadius: sketchSize(153)
  },
  accentButton: {
    backgroundColor: colors.accent
  },
  invertedButton: {
    backgroundColor: colors.white
  },
  disabledButton: {
    backgroundColor: colors.blueGrey
  },
  spinner: {
    height: sketchSize(153)
  },
  uppercase: {
    textTransform: 'uppercase'
  }
});
