import { colors } from '../../common';
import { deviceHeight, isWeb, sketchSize } from '../../common/metrics';
import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  container: {
    flex: 1,
    overflow: 'visible',
  },
  bodyContainer: { paddingHorizontal: 0, paddingBottom: 0 },
  overflow: {
    flex: 1,
    overflow: 'visible',
    backgroundColor: colors.white,
  },
  scrollContainer: {
    paddingBottom: sketchSize(280, true),
    height: isWeb ? deviceHeight - 200 : undefined,
  },
  marginRight: {
    marginRight: sketchSize(80),
  },
  input2Container: { flex: 1, marginHorizontal: sketchSize(80) },
  inputContainer: { marginHorizontal: sketchSize(80) },
  inputLeftContainer: { flex: 0.5, marginHorizontal: sketchSize(80) },
  counterButton: {
    width: sketchSize(90),
    height: sketchSize(90),
    borderRadius: sketchSize(45),
  },
  counterContainer: {
    width: sketchSize(360),
    marginHorizontal: sketchSize(80),
  },
  counterWrap: {
    marginVertical: sketchSize(24),
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  detailsWithLabelGroup: {
    marginBottom: sketchSize(25),
  },
  withSkip: {
    marginBottom: sketchSize(40, true),
  },
});
