import {
  analyticsEventRegistration,
  intercomAuth,
  localStorage,
} from '../../common';
import { airvatClient } from '../../common/api';
import { locale } from '../../common/constants';
import { RNGoogleSignin } from '../../modules/RNGoogleSignin';
import { get } from 'lodash';
import { Platform } from 'react-native';

import { createSetAction, createUnsetAction } from '../helpers';
import { IUser, EAuthProvider } from '../types';
import { userInit } from './users';
import { IDefaultState } from '../../store/types';
import moment from 'moment';
import { RNIntercom } from '../../modules/RNIntercom';
import { RNLoginManager } from '../../modules/rnfbsdk';
import { avAlert } from '../../components/AvAlert/alertActions';

const userReset = () => async (dispatch: any) => {
  airvatClient.resetTokens();
  await localStorage('user')?.remove?.();
  const action = createUnsetAction('user');
  dispatch(action);
};

export const logOut = (withAlert?: boolean) => async (dispatch: any, getState: any) => {
  const token = String(airvatClient.accessToken);
  const state = getState();
  const provider = get(state, 'user.provider') as EAuthProvider;
  switch (provider) {
    case EAuthProvider.FACEBOOK:
      RNLoginManager.logOut();
      break;
    case EAuthProvider.GOOGLE:
      await RNGoogleSignin.signOut();
      break;
  }
  await RNIntercom.logout();
  airvatClient.resetTokens();
  if (airvatClient.accessToken) {
    await airvatClient.post('auth/logout', { token });
  }
  await userReset()(dispatch);
  if (withAlert) {
    avAlert('', 'Your auth token has expired!')(dispatch);
  }
};

export const loginWithPassword =
  (user: Partial<IUser>) =>
  async (dispatch: any, getState: () => IDefaultState) => {
    const state = getState();
    const url = 'auth/login';
    const email = user.email?.toLowerCase();
    const deviceToken = state.api?.deviceToken;
    const requestData = {
      ...user,
      email,
      locale,
      provider: EAuthProvider.PASSWORD,
      platform: Platform.OS,
      deviceToken,
    };
    const storeData = await airvatClient
      .resetTokens()
      .post<IUser>(url, requestData);
    await localStorage('user', storeData).set();
    const fullUser = await userInit(storeData)(dispatch, getState);
    fullUser && (await intercomAuth(fullUser));
    return storeData;
  };

export const loginWithToken =
  (refreshToken: string) => async (dispatch: any, getState: any) => {
    try {
      if (!refreshToken) return userReset()(dispatch);
      const data = {
        token: refreshToken,
      };
      const config = { params: { locale } };
      const storeData = await airvatClient.post<IUser>(
        `auth/token`,
        data,
        config
      );
      const action = createSetAction('user', storeData);
      dispatch(action);
      const fullUser = await userInit(storeData)(dispatch, getState);
      fullUser && (await intercomAuth(fullUser));
    } catch (e) {
      await userReset()(dispatch);
      throw e;
    }
  };

export const signUpWithPassword =
  ({ refCode, ...requestData }: Partial<IUser>) =>
  async (dispatch: any, getState: any) => {
    const state = getState();
    const url = 'auth/register';
    const platform = Platform.OS;
    const provider = EAuthProvider.PASSWORD;
    const email = requestData.email?.toLowerCase();
    const deviceToken = state.api?.deviceToken;
    const data: any = {
      ...requestData,
      email,
      provider,
      platform,
      locale,
      deviceToken,
    };
    if (refCode) {
      data.refCode = refCode;
    }
    await airvatClient.resetTokens().post(url, data);
    analyticsEventRegistration(data);
  };

export const passwordReset =
  (userEmail: string) => async (dispatch: any, getState: any) => {
    const email = userEmail.toLowerCase();
    return airvatClient.post('auth/password', { email });
  };

export const passwordChange =
  (password: string) => async (dispatch: any, getState: any) => {
    return airvatClient.put('auth/password', { password });
  };

export const emailChange =
  (newEmail: string) => async (dispatch: any, getState: any) => {
    const email = (newEmail || '').toLowerCase();
    return airvatClient.post('auth/email', { email });
  };

export const providerAuth =
  (provider: EAuthProvider, code: string, refCode?: string) =>
  async (dispatch: any, getState: any) => {
    const url = 'auth/provider';
    const platform = Platform.OS;
    const state = getState();
    const deviceToken = state.api?.deviceToken;
    const data: any = {
      code,
      provider,
      platform,
      locale,
      deviceToken,
    };
    if (refCode) {
      data.refCode = refCode;
    }
    const user = await airvatClient.resetTokens().post<IUser>(url, data);
    const { accessToken, isAgreeAccepted, createdAt } = user || {};
    if (accessToken) {
      const action = createSetAction('user', user);
      dispatch(action);
      await localStorage('user', user).set();
      const fullUser = await userInit(user)(dispatch, getState);
      fullUser && (await intercomAuth(fullUser));
    }
    if (!isAgreeAccepted && moment(createdAt).isSame(moment(), 'day')) {
      analyticsEventRegistration(user);
    }
    return accessToken;
  };

export const agreeAccepted = () => async (dispatch: any, getState: any) => {
  const state: IDefaultState = getState();
  const storeUser = state.api?.user || {};
  const users = await airvatClient
    .put<IUser[]>(`users/${storeUser.id}`, { isAgreeAccepted: true })
    .catch((e: any) => handleAuthError(e)(dispatch, getState));
  if (!users) return;
  const action = createSetAction('user', { ...storeUser, ...(users[0] || {}) });
  dispatch(action);
};

export const handleAuthError = (e: any) => (dispatch: any, getState: any) => {
  if (e.response?.data?.code === 401 || e.response?.data?.code === 403) {
    logOut(true)(dispatch, getState);
  }
};

export const setDeviceToken =
  (deviceToken: string) => async (dispatch: any, getState: any) => {
    const state: IDefaultState = getState();
    const stateDeviceToken = state.api?.deviceToken;
    if (stateDeviceToken !== deviceToken) {
      const action = createSetAction('deviceToken', deviceToken);
      dispatch(action);
    }
  };
