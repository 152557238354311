import { colors } from '../../common';
import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
  },
  emptyFillPageImage: {
    width: '100%',
    flex: 1,
  },
  bodyContainer: {
    flex: 1,
  },
});
