import { colors, deviceHeight, isWeb, sketchSize } from '../../common';
import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  container: {
    flex: 1,
    paddingBottom: sketchSize(60, true),
  },
  blockContainer: {
    flexGrow: 1,
    minHeight: sketchSize(1000, true),
    marginHorizontal: sketchSize(40),
  },
  authoriseContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginHorizontal: sketchSize(40),
    marginVertical: sketchSize(40),
  },
  authoriseInfoContainer: {
    marginHorizontal: sketchSize(40),
  },
  taxformEmailInputContainer: {
    marginHorizontal: sketchSize(70),
    marginTop: sketchSize(40),
  },
  authoriseNotifyIcon: {
    marginRight: sketchSize(20),
  },
  defaultPlaceholderIcon: {
    maxHeight: '50%',
  },
  fill: {
    flex: 1,
    justifyContent: 'flex-start',
  },
  infoContainer: {
    flex: 1,
  },
  infoLabel: {
    marginTop: sketchSize(80),
    marginBottom: sketchSize(60),
    justifyContent: 'center',
  },
  infoLabelOtr: { flex: 0.24 },
  infoContentContainer: { flex: 1, marginBottom: sketchSize(60, true) },
  noteContainer: {
    marginTop: 0,
  },
  dot: {
    width: sketchSize(20),
    height: sketchSize(20),
    borderRadius: sketchSize(20),
    backgroundColor: colors.light,
    marginTop: sketchSize(20),
    marginRight: sketchSize(20),
  },
  dotListItemLabel: {
    marginBottom: sketchSize(60),
    marginLeft: sketchSize(50),
  },
  listGradientContainer: { paddingBottom: sketchSize(300, true) },
  buttonContainer: {
    marginBottom: sketchSize(83),
  },
  taxformHasBeen: {
    marginTop: sketchSize(80),
    marginHorizontal: sketchSize(120),
  },
  barcodeNotScanning: {
    marginTop: sketchSize(40),
    fontSize: sketchSize(48),
  },
  barcodeContainer: { justifyContent: 'center', alignItems: 'center' },
  barcodeFullScreenIcon: {
    position: 'absolute',
    top: sketchSize(-10),
    right: sketchSize(40),
    backgroundColor: colors.white,
    padding: sketchSize(16),
  },
  barcodeFullScreenContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  barcodeFullScreenRotate: {
    transform: [{ rotate: '90deg' }],
    justifyContent: 'center',
    alignItems: 'center',
  },
  taxformMail: { marginBottom: sketchSize(40) },
  scrollWrap: {
    paddingBottom: sketchSize(200),
    paddingTop: sketchSize(20),
    height: isWeb ? deviceHeight - 200 : undefined,
  },
  taxformButton: { marginVertical: sketchSize(80), width: sketchSize(865) },
  stampedButton: { marginBottom: sketchSize(80), width: sketchSize(865) },
  imagePreview: {
    marginTop: sketchSize(40),
    width: '90%',
    flex: 0.97,
    alignSelf: 'center',
    borderRadius: sketchSize(60),
  },
  buttonsContainer: {
    margin: sketchSize(40),
    marginBottom: 0,
  },
});
