import React from 'react';
import { CustomButton } from '../CustomButton';
import styles from './styles';
import { IHeaderButtonProps } from './types';
import { renderIcon } from './renderIcon';

export const renderButton: React.FC<IHeaderButtonProps> = (
  props: IHeaderButtonProps
) => {
  if (props.buttonComponent) {
    return props.buttonComponent;
  }
  
  if (props.onPress) {
    return (
      <CustomButton
        onPress={props.onPress}
        buttonStyle={styles.spacer}
        iconComponent={renderIcon(props) as any}
        testID={props.testID}
      />
    );
  }
  return renderIcon(props);
};
