import { constants } from "../../common";
import { values } from "lodash";
import React, { useRef } from "react";
import { View } from "react-native";
import { WebSiteBrowser } from ".";
import styles from "./styles";
import { ICacheProps } from "./types";

export const Cache: React.FC<ICacheProps> = ({ onComplete }) => {
  const loadedIndex = useRef(0);
  const links = values(constants.browser);
  const lastIndex = links.length - 1;

  const handleComplete = () => {
    if (loadedIndex.current === lastIndex) {
      loadedIndex.current = 0;
      onComplete && onComplete(true);
    }
    loadedIndex.current++;
  };

  return (
    <>
      {links.map((uri) => {
        return (
          <View key={uri} style={styles.cache} pointerEvents="none">
            <WebSiteBrowser
              key={uri}
              uri={uri}
              onLoaded={handleComplete}
              onError={handleComplete}
            />
          </View>
        );
      })}
    </>
  );
};
