import {
  EDirname,
  IApiUploadFile,
  ICountry,
  ITaxform,
  ITrip,
} from '../../api-store/types';
import {
  analyticsEvent,
  constants,
  createApiUploadFile,
  ENV,
  getActiveTrip,
  getActiveTripId,
  getCountryCode,
  isCompleteTaxform,
  isWeb,
  openUrl,
  waitForAsync,
} from '../../common';
import { airvatClient } from '../../common/api';
import { FR, BE } from '../../common/constants/common';
import { avAlert } from '../../components/AvAlert/alertActions';
import { avLoader } from '../../components/AvAlert/loaderActions';
import { IDefaultState } from '../../store/types';
import { get } from 'lodash';
import { handleAuthError } from './auth';
import { uploadFile } from './uploads';

const getGeneratedFormText = (country?: ICountry) => {
  const code = getCountryCode(country);
  if (code === FR || code === BE) {
    return constants.taxforms.generatingTaxFormCustomsItSystem;
  }
  return constants.taxforms.generatingTaxForm;
};

export const checkIsShowTaxformLoader =
  (taxform?: ITaxform) =>
  (dispatch: any, state: IDefaultState): boolean => {
    const isShowLoader = !!taxform && !isCompleteTaxform(taxform);
    if (isShowLoader) {
      const trip = get(state, `api.user.trips.${taxform.tripId}`);
      trip && avLoader(getGeneratedFormText(trip.country), true)(dispatch);
    } else {
      avLoader('', false)(dispatch);
    }
    return isShowLoader;
  };

export const generateTaxForm = () => async (dispatch: any, getState: any) => {
  const state: IDefaultState = getState();
  const tripId = getActiveTripId(state);
  const trip = getActiveTrip(state) as ITrip;
  try {
    avLoader(getGeneratedFormText(trip.country), true)(dispatch);
    await airvatClient
      .post('taxforms', { tripId })
      .catch((e: any) => handleAuthError(e)(dispatch, getState));
    const invoiceAmount = trip.paymentDetails?.invoiceAmount || 0;
    analyticsEvent('taxform_generate', {
      trip_country: trip.country.name,
      invoice_amount: invoiceAmount,
    });
  } catch (e) {
    avLoader('', false)(dispatch);
    await waitForAsync(600);
    avAlert('', e.message)(dispatch);
  }
};

export const uploadStampedForm =
  (taxformId: number, pictures: IApiUploadFile[]) =>
  async (dispatch: any, getState: any) => {
    for (let p of pictures) {
      const uploadId = await uploadFile(
        createApiUploadFile(p, EDirname.TAXFORMS)
      )(dispatch, getState);
      await airvatClient
        .put(`taxforms/${taxformId}`, {
          uploadId,
        })
        .catch((e: any) => handleAuthError(e)(dispatch, getState));
    }
  };

export const getTaxformPdf =
  (taxformId: number) => async (dispatch: any, getState: any) => {
    if(isWeb){
      const userCode = getState().api?.user?.code;
      return openUrl(`https://${ENV.ENV}.airvat.dev/v2/atlatos/public/taxform?id=${taxformId}&code=${userCode}`);
    }
    const url = `taxforms/${taxformId}?format=pdf`;
    const fileName = `Airvat_tax-free-form_${taxformId}.pdf`;
    const path = await airvatClient
      .download(url, fileName)
      .catch((e: any) => handleAuthError(e)(dispatch, getState));
    return path || '';
  };
