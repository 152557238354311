import { ActionSheetProvider } from '@expo/react-native-action-sheet';
import { GoogleOAuthProvider } from '@react-oauth/google';
import React from 'react';
import {
  SafeAreaProvider,
  initialWindowMetrics,
} from 'react-native-safe-area-context';
import { Provider } from 'react-redux';
import { AvAlert, AvLoader, NetInfoMessage } from './components';
import { AppStateUpdater, AppVersion } from './containers';
import { RNGestureHandlerRootView } from './modules/RNGestureHandler';
import AppNavigator from './navigation';
import { store } from './store';

class App extends React.Component {
  render() {
    return (
      <GoogleOAuthProvider
        clientId={
          '735983107153-hp2637vo82ugbbmkb5llrp3884p5n8c3.apps.googleusercontent.com'
        }
      >
        <RNGestureHandlerRootView style={{ flex: 1 }}>
          <ActionSheetProvider>
            <SafeAreaProvider initialMetrics={initialWindowMetrics}>
              <Provider store={store}>
                <AppNavigator />
                <AppVersion />
                <NetInfoMessage />
                <AvLoader />
                <AvAlert />
                <AppStateUpdater />
              </Provider>
            </SafeAreaProvider>
          </ActionSheetProvider>
        </RNGestureHandlerRootView>
      </GoogleOAuthProvider>
    );
  }
}

export default App;
