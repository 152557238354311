import { StyleSheet } from 'react-native';
import { colors, sketchSize } from '../../../common';

export default StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: sketchSize(30, true)
  },
  textContainer: {
    flex: 1
  },
  touchable: {
    height: sketchSize(150),
    width: sketchSize(150),
    borderRadius: sketchSize(150),
    backgroundColor: colors.white,
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: sketchSize(30),
    overflow: 'hidden'
  },
  touchableLeft: {
    marginLeft: 0,
  },
  buttonsContainer:{
    backgroundColor: colors.semiWhite,
    borderRadius: sketchSize(154),
    padding: sketchSize(10),
    flexDirection: 'row',
    alignItems: 'center',
  }
});
