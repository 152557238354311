import { set, unset, cloneDeep } from 'lodash';
import { apiState } from './state';

export type TApiStoreType = 'SET' | 'UNSET';

export interface IApiStoreAction {
  type: TApiStoreType;
  path: string;
  data?: any;
}

export const apiReducer = (state = apiState, action: IApiStoreAction) => {
  const { type, path, data } = action;
  if (!type || !path) return state;
  let newState = cloneDeep(state);
  switch (type) {
    case 'SET':
      const setState = set(newState, path, data);
       console.log('apiReducer =>', { type, path, data, state, setState });
      return setState;
    case 'UNSET':
      unset(newState, path);
       console.log('apiReducer =>', { type, path, state, newState });
      return newState;
  }
  return state;
};
