import { colors } from '../../common';
import React from 'react';
import { AvIcon } from '../AvIcon';
import styles from './styles';
import { IHeaderIconProps } from './types';

export const renderIcon: React.FC<IHeaderIconProps> = (
  props: IHeaderIconProps
) => {
  if (props.icon) {
    return props.icon;
  }
  if (props.iconName) {
    return (
      <AvIcon
        name={props.iconName}
        width={props.iconSize || 60}
        fill={props.iconColor || colors.light}
        containerStyle={styles.spacer}
      />
    );
  }
  return null;
};
