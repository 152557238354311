export const colors = {
  accent: "#48d3b3",
  accentDark: "#589dbd",
  dark: "#48485e",
  grey: "#A5A5AE",
  light: "#8390a0",
  lightGrey: "#B2B9C1",
  blueGrey: "#B9C8CD",
  semiWhite: "#edf5f7",
  white: "#ffffff",
  black: "#000000",
  red: "#ED1C24",
  darkGrey: "#48485E",
  santasGrey: "#A1A1AA",
  lightGreyBackground: "#f7f8f9",
  lightTransparent: "rgba(72, 72, 94, 0.2)",
  darkTransparent: "rgba(72, 72, 94, 0.5)",
  whiteGrey: "#F3F5F8",
  hawkesBlue: "#DADDE6",
  midGrey: "#E5E6E9",
  iron: "#A2ACB8",
  transparent: "rgba(255, 255, 255, 0.0)",
  link: "#0b00ef",
};

export default colors;
