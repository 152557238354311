import { StyleSheet } from 'react-native';
import { sketchSize } from '../../common';

export default StyleSheet.create({
  container: {
    flex: 1,
    overflow: 'visible',
  },
  imagePreview: {
    marginTop: sketchSize(40),
    width: '90%',
    flex: 0.97,
    alignSelf: 'center',
    borderRadius: sketchSize(60),
    height: '100%'
  },
  buttonsContainer: {
    margin: sketchSize(40),
    marginBottom: 0,
  },
});
