import { StyleSheet } from 'react-native';
import { colors, sketchSize } from '../../common';
import styles from '../../components/Labels/styles';

export default StyleSheet.create({
  container: {
    height: sketchSize(245)
  },
  containerWithNoLabel: {
    height: sketchSize(190)
  },
  textInputWrapper: {
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderColor: colors.light,
    height: sketchSize(118),
    flexDirection: 'row',
    alignItems: 'center'
  },
  textInput: {
    flex: 1,
    paddingVertical: sketchSize(14),
    ...styles.H1Dark
  },
  textInputWithLeftIcon: {
    paddingLeft: sketchSize(50)
  },
  textInputWithPadding: {
    paddingLeft: sketchSize(50)
  },
  errorBorder: {
    borderBottomColor: colors.red
  },
  activeBorder: {
    borderBottomWidth: sketchSize(5),
    borderBottomColor: colors.dark
  },
  phonePlus: {
    marginRight: sketchSize(8)
  },
});
