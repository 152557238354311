import {
  addRefundMethod,
  getUser,
  IBankAccountOption,
  IPayPalOption,
  IRefundMethod
} from '../../../api-store';
import {
  ERefundMethodProvider,
  IBankAccountValidation,
  IWeChatPayOption,
} from '../../../api-store/types';
import { constants, getActiveTripId } from '../../../common';
import { avAlert, avConfirm } from '../../../components/AvAlert/alertActions';
import { getIsFilledAddress } from '../../../containers/AddDetails/documentsState';
import {
  getDefaultRefundMethod,
  getIsFilledUserPassport,
} from '../../../containers/Home/state';
import { HomeSceneName } from '../../../navigation/names';
import { IDefaultState } from '../../../store/types';
import { CommonActions } from '@react-navigation/native';
import { get } from 'lodash';
import React from 'react';
import { Keyboard } from 'react-native';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { parseCardExpiryDate } from '../refundHelpers';

const mapStateToProps = (state: IDefaultState) => {
  const defaultRefundMethod = getDefaultRefundMethod(state);
  const bankAccountValidation = get(
    state,
    'api.bankAccountValidation'
  ) as IBankAccountValidation[];
  const isFilledAddress = getIsFilledAddress(state.api.user);
  const isFilledPassport = getIsFilledUserPassport(state);
  const isValidToAddMethod = isFilledPassport && isFilledAddress;
  const tripId = getActiveTripId(state);
  const currentTrip = get(state, `api.user.trips.${tripId}`);
  return {
    isValidToAddMethod,
    defaultRefundMethod,
    bankAccountValidation,
    currentTrip,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      avAlert,
      avConfirm,
      addRefundMethod,
      getUser,
    },
    dispatch
  );
};

export const AddRefundHOC = (RefundView: any) =>
  compose<any>(connect(mapStateToProps, mapDispatchToProps))(
    class HocComponent extends React.Component<any> {
      state = {
        isLoading: false,
      };

      handleError = (e: Error) => {
        this.setState({ isLoading: false });
        Keyboard.dismiss();
        this.props.avAlert('', e.message);
      };

      setDefaultRefundMethod = async (method: Partial<IRefundMethod>) => {
        try {
          this.setState({ isLoading: true });
          await this.props.addRefundMethod(method);
          this.setState({ isLoading: false });
          this.props.navigation.dispatch(
            CommonActions.reset({
              index: 0,
              routes: [
                {
                  name: HomeSceneName,
                },
              ],
            })
          );
        } catch (e) {
          this.handleError(e);
        }
      };

      createPayPalMethod = (methodOptions: IPayPalOption) => {
        const data: IPayPalOption = {
          email: methodOptions.email,
        };
        const method: Partial<IRefundMethod> = {
          provider: ERefundMethodProvider.Paypal,
          data,
        };
        this.setDefaultRefundMethod(method);
      };

      createBankAccountMethod = (methodOptions: IBankAccountOption) => {
        const data: IBankAccountOption = {
          accountNumber: methodOptions.accountNumber,
          bicOrSwift: methodOptions.bicOrSwift,
        };
        const method: Partial<IRefundMethod> = {
          provider: ERefundMethodProvider.Bank,
          data,
        };
        this.setDefaultRefundMethod(method);
      };

      createCardMethod = async (fields: {
        cardNumber: string;
        cardExpiry: string;
      }) => {
        if (!this.props.isValidToAddMethod) {
          return this.handleError(new Error(constants.alerts.passportData));
        }
        this.setState({ isLoading: true });
        const parsedCardExpiry = parseCardExpiryDate(fields.cardExpiry);
        const data: any = {
          cardNumber: fields.cardNumber,
          ...parsedCardExpiry,
        };
        const method: Partial<IRefundMethod> = {
          provider: ERefundMethodProvider.Card,
          data,
        };
        await this.setDefaultRefundMethod(method);
      };

      createWeChatMethod = (methodOptions: IWeChatPayOption) => {
        const data: IWeChatPayOption = {
          wechatId: methodOptions.wechatId,
        };
        const method: Partial<IRefundMethod> = {
          provider: ERefundMethodProvider.Wechat,
          data,
        };
        this.setDefaultRefundMethod(method);
      };

      render() {
        const method: IRefundMethod = this.props.route.params?.method || {};
        const provider = this.props.route.params?.provider || method.provider;
        const { bankAccountValidation } = this.props;
        const isFormDisabled = this.props.route.params?.isFormDisabled;
        const viewProps: any = {
          ...this.state,
          isFormDisabled,
          provider,
          method,
          bankAccountValidation,
          createPayPalMethod: this.createPayPalMethod,
          createBankAccountMethod: this.createBankAccountMethod,
          createCardMethod: this.createCardMethod,
          createWeChatMethod: this.createWeChatMethod,
        };
        return React.createElement(RefundView, viewProps);
      }
    }
  );
