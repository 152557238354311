import { colors, isSmallDevice, sketchSize } from '../../common';
import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  container: {
    flex: 1,
  },
  amountContainer: {
    height: sketchSize(isSmallDevice ? 300 : 430),
  },
  paddingContainer: {
    paddingHorizontal: sketchSize(50),
    paddingVertical: sketchSize(50),
    paddingTop: sketchSize(40),
  },
  rowContainerItem: {
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: sketchSize(40),
  },
  noteContainer: { marginBottom: sketchSize(40), marginHorizontal: 0 },
  copyRefButton: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  copyRefLabel: { marginRight: sketchSize(30), color: colors.white },
});
