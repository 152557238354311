import React from 'react';
import { View } from 'react-native';
import { Label } from '../Labels';
import { renderButtonFromProps } from './renderButtonFromProps';
import styles from './styles';
import { IHeaderProps } from './types';
import { SafeAreaView } from 'react-native-safe-area-context';

export const Header: React.FC<IHeaderProps> = props => {
  const title = props.title || '';
  return (
    <SafeAreaView edges={['top', 'bottom']} style={[styles.container, props.style]}>
      {title ? (
        <View style={[styles.headerContainer, props.headerContainerStyle]}>
          <View style={styles.spacer}>
            {renderButtonFromProps(props, 'left')}
          </View>
          <View style={styles.titleContainer}>
            <Label text={title} type={props.titleLabelType || 'HeroTitle'} centered />
          </View>
          <View style={styles.spacer}>
            {renderButtonFromProps(props, 'right')}
          </View>
        </View>
      ) : null}
      {props.children}
    </SafeAreaView>
  );
};
