import React from 'react';
import { G, Path, Rect, Svg } from 'react-native-svg';

export const PassportSvgMask = ({ g, ...props }:any) => (
  <Svg {...props}>
    <G {...g}>
      <Path
        d='M360.02,254.59H14.98C6.72,254.59,0,247.87,0,239.61V14.98C0,6.72,6.72,0,14.98,0h345.04C368.28,0,375,6.72,375,14.98
					v224.63C375,247.87,368.28,254.59,360.02,254.59z M14.98,2.4C8.04,2.4,2.4,8.04,2.4,14.98v224.63c0,6.94,5.64,12.58,12.58,12.58
					h345.04c6.94,0,12.58-5.64,12.58-12.58V14.98c0-6.94-5.64-12.58-12.58-12.58H14.98z'
      />

      <Path
        d='M105.73,168.1H33.5c-6.51,0-11.81-5.3-11.81-11.81V55.31c0-6.51,5.3-11.81,11.81-11.81h72.23
					c6.51,0,11.81,5.3,11.81,11.81V156.3C117.54,162.81,112.24,168.1,105.73,168.1z M33.5,45.9c-5.19,0-9.41,4.22-9.41,9.41V156.3
					c0,5.19,4.22,9.41,9.41,9.41h72.23c5.19,0,9.41-4.22,9.41-9.41V55.31c0-5.19-4.22-9.41-9.41-9.41H33.5z'
      />

      <Rect x='1.2' y='199.77' width='372.6' height='2.4' />

      <Rect x='136.11' y='56.38' width='55.81' height='3.37' />

      <Path
        d='M135.51,77.79c0,1.83,1.93,3.31,4.3,3.31h96.38c2.38,0,4.3-1.48,4.3-3.31V69.6c0-1.83-1.93-3.31-4.3-3.31h-96.38
						c-2.38,0-4.3,1.48-4.3,3.31V77.79z'
      />

      <Rect x='136.11' y='92.6' width='58.11' height='3.37' />

      <Path
        d='M135.51,114.01c0,1.83,1.91,3.31,4.27,3.31h69.96c2.36,0,4.27-1.48,4.27-3.31v-8.19c0-1.83-1.91-3.31-4.27-3.31h-69.96
						c-2.36,0-4.27,1.48-4.27,3.31V114.01z'
      />

      <Rect x='136.11' y='131.11' width='78.51' height='3.37' />

      <Path
        d='M135.51,153.18c0,1.83,1.35,3.31,3.02,3.31h145.64c1.67,0,3.02-1.48,3.02-3.31v-8.19c0-1.83-1.35-3.31-3.02-3.31
						H138.53c-1.67,0-3.02,1.48-3.02,3.31V153.18z'
      />
    </G>
  </Svg>
);

export const PassportSvgMaskWrap = ({ g, ...props }: any) => (
  <Svg {...props}>
    <G {...g}>
      <Path
        d='M360.02,254.59H14.98C6.72,254.59,0,247.87,0,239.61V14.98C0,6.72,6.72,0,14.98,0h345.04C368.28,0,375,6.72,375,14.98
				v224.63C375,247.87,368.28,254.59,360.02,254.59z'
      />
    </G>
  </Svg>
);
