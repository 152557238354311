import { airvatClient } from '../../common/api';
import { IDefaultState } from '../../store/types';
import { get, keyBy, map, reduce } from 'lodash';
import { createSetAction } from '../helpers';
import { IRefundMethod } from '../types';
import { handleAuthError } from './auth';

export const addRefundMethod =
  (defaultRefundMethod: IRefundMethod) =>
  async (dispatch: any, getState: any) => {
    const requestData = { ...defaultRefundMethod, isDefault: true };
    const state = getState();
    const url = `payment-info`;
    const newMethod = await airvatClient
      .post<IRefundMethod>(url, requestData)
      .catch((e: any) => handleAuthError(e)(dispatch, getState));
    const userRefundMethods = resetDefaultRefundMethods(state);
    if (newMethod) {
      userRefundMethods[newMethod.id] = newMethod;
    }
    const action = createSetAction(`user.paymentInfo`, userRefundMethods);
    dispatch(action);
    return newMethod;
  };

export const getRefundMethods = () => async (dispatch: any, getState: any) => {
  const paymentInfo = await airvatClient
    .get<IRefundMethod[]>('payment-info')
    .catch((e: any) => handleAuthError(e)(dispatch, getState));
  if (paymentInfo) {
    const userRefundMethods = keyBy<IRefundMethod>(paymentInfo, 'id');
    const action = createSetAction(`user.paymentInfo`, userRefundMethods);
    dispatch(action);
  }
};

export const setDefaultRefundMethod =
  (defaultRefundMethod: Partial<IRefundMethod>) =>
  async (dispatch: any, getState: any) => {
    const requestData = {
      ...defaultRefundMethod,
      isDefault: true,
    } as IRefundMethod;
    const state = getState();
    const url = `payment-info/${requestData.id}`;
    airvatClient
      .put<IRefundMethod>(url, requestData)
      .catch((e: any) => handleAuthError(e)(dispatch, getState));
    const userRefundMethods = resetDefaultRefundMethods(state);
    userRefundMethods[requestData.id] = requestData;
    const action = createSetAction(`user.paymentInfo`, userRefundMethods);
    dispatch(action);
  };

export const deleteRefundMethod =
  (refundMethodId: IRefundMethod['id']) => (dispatch: any, getState: any) => {
    const state: IDefaultState = getState();
    const url = `payment-info/${refundMethodId}`;
    airvatClient
      .delete(url)
      .catch((e: any) => handleAuthError(e)(dispatch, getState));
    const userRefundMethods = map(state.api.user.paymentInfo).filter(
      v => v.id !== refundMethodId
    );
    const paymentInfo = keyBy<IRefundMethod>(userRefundMethods, 'id');
    const action = createSetAction(`user.paymentInfo`, paymentInfo);
    dispatch(action);
  };

const resetDefaultRefundMethods = (state: IDefaultState) => {
  return reduce(
    get(state, 'api.user.paymentInfo'),
    (obj: any, m) => {
      obj[m.id] = { ...m, isDefault: false };
      return obj;
    },
    {}
  );
};
