import { constants } from '../../common';
import { header } from '../../components';
import { ReceiptCamera } from '../../containers/Receipt';
import ReceiptDetails from '../../containers/Receipt/ReceiptDetails';
import ReceiptImagePreview from '../../containers/Receipt/ReceiptImagePreview';
import {
  commonStackNavigatorConfig,
  cameraNavigationOptionsConfig
} from '../../navigation/config';
import {
  ReceiptCameraSceneName,
  ReceiptDetailsSceneName,
  ReceiptImagePreviewSceneName
} from '../../navigation/names';
import { INavigationScreenProps } from '../../navigation/types';
import { headerTrash } from '../../navigation/utils';

export default {
  [ReceiptCameraSceneName]: {
    screen: ReceiptCamera,
    navigationOptions: {
      title: constants.headers.scanReceipt,
      ...cameraNavigationOptionsConfig,
    },
  },
  [ReceiptImagePreviewSceneName]: {
    screen: ReceiptImagePreview,
    navigationOptions: {
      headerShown: false,
    },
  },
  [ReceiptDetailsSceneName]: {
    screen: ReceiptDetails,
    navigationOptions: (props: INavigationScreenProps) => ({
      ...header({
        ...props,
        ...headerTrash(props),
        title: constants.labels.receiptInfo,
      }),
      ...commonStackNavigatorConfig(props),
    }),
  }
};
