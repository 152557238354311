import { CommonActions } from '@react-navigation/routers';
import { filter, upperCase } from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import { isDesktop, isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import {
  addTrip,
  deleteTrip,
  getUser,
  IBeDetax,
  ICountry,
  ITransportNode,
  ITrip,
  updateTrip,
} from '../../api-store';
import { TIME_FORMAT } from '../../api-store/constants';
import {
  colors,
  constants,
  getArrivalDepartureDate,
  getCountryCode,
  getTransportNodeTitle,
  getTripById,
  inputConfigs,
  localStorage,
  waitForAsync
} from '../../common';
import { BE } from '../../common/constants/common';
import { isRequired } from '../../common/stringValidator';
import { AvForm, AvModal, SelectCountry } from '../../components';
import { avAlert, avConfirm } from '../../components/AvAlert/alertActions';
import { avLoader } from '../../components/AvAlert/loaderActions';
import inputStyles from '../../components/AvForm/styles';
import { AvFormInput } from '../../components/AvForm/types';
import { getIsFilledAddress } from '../../containers/AddDetails/documentsState';
import {
  getDefaultRefundMethod,
  getIsExistsUserPassport,
} from '../../containers/Home/state';
import { RNDatePicker } from '../../modules/RNDatePicker';
import {
  AddRefundMethodSceneName,
  AddressSceneName,
  HomeSceneName,
  PassportCameraPlaceholderSceneName,
} from '../../navigation/names';
import { IDefaultState } from '../../store/types';
import SelectTransportNode from './components/SelectTransportNode';
import { TripDates } from './components/TripDates';
import { IEditTripProps, IEditTripsState } from './types';

interface ITripWithState extends IEditTripsState, ITrip {
  arrivalDepartureDate?: string;
  outPunt?: string;
  outPuntSelect?: string;
  placeOfUnloading?: string;
}

const modalsInitState = {
  isDateModalVisible: false,
  tripSaveButtonEnabled: false,
  isTransportNodeModalVisible: false,
  isDepartureTimeModalVisible: false,
  isPlaceOfUnloadingModalVisible: false,
  isTransportReferenceModalVisible: false,
  isDestinationCountryModalVisible: false,
};

class EditTrip extends Component<IEditTripProps, IEditTripsState> {
  constructor(props: IEditTripProps) {
    super(props);
    this.state = {
      ...modalsInitState,
      currentTrip: props.currentTrip,
      tripSaveButtonEnabled: !!props.currentTrip?.id,
      isTripSaving: false,
      destinationCountry:
        props.currentTrip?.extraProps?.beDetax?.destinationCountry,
      placeOfUnloadingTransportNode:
        props.currentTrip?.extraProps?.beDetax?.placeOfUnloadingTransportNode,
      estimatedDateAndTimeOfDeparture: props.currentTrip?.extraProps?.beDetax
        ?.estimatedDateAndTimeOfDeparture
        ? moment(
            props.currentTrip?.extraProps?.beDetax
              ?.estimatedDateAndTimeOfDeparture
          ).toDate()
        : undefined,
      transportReference:
        props.currentTrip?.extraProps?.beDetax?.transportReference,
    };
  }

  handleResponseError = (e: any) => {
    this.props.avAlert('', e.message);
  };

  toggleTripSaving = () =>
    this.setState({ isTripSaving: !this.state.isTripSaving });

  saveTripDetails = async (data: ITrip) => {
    const {
      id,
      arrivalDepartureDate,
      estimatedDateAndTimeOfDeparture,
      outPuntSelect,
      placeOfUnloading,
      transportReference,
      transportNode,
      destinationCountry,
      ...tripForSave
    } = { ...this.state.currentTrip, ...data } as ITripWithState;
    const beDetax: IBeDetax = {
      placeOfUnloading: this.state.placeOfUnloadingTransportNode?.code,
      placeOfUnloadingTransportNode: this.state.placeOfUnloadingTransportNode,
      destinationCountry: this.state.destinationCountry,
      estimatedDateAndTimeOfDeparture: estimatedDateAndTimeOfDeparture
        ? moment(
            `${tripForSave.departureDate}T${estimatedDateAndTimeOfDeparture}:00`
          ).format()
        : undefined,
      outPunt: this.state.currentTrip?.transportNode?.code,
      transportReference: upperCase(transportReference),
    };

    tripForSave.extraProps = { beDetax };

    this.toggleTripSaving();
    try {
      if (this.props.tripId) {
        await this.props.updateTrip(this.props.tripId, tripForSave);
      } else {
        await this.props.addTrip(tripForSave);
      }
      await this.props.getUser();
      this.toggleTripSaving();

      let sceneName = HomeSceneName;
      if (!this.props.isExistsPassport) {
        sceneName = PassportCameraPlaceholderSceneName;
      } else if (!this.props.isFilledAddress) {
        sceneName = AddressSceneName;
      } else if (!this.props.isExistsDefaultRefundMethod) {
        sceneName = AddRefundMethodSceneName;
      }
      const routes = [{ name: sceneName }];
      this.props.navigation.dispatch(CommonActions.reset({ index: 0, routes }));
    } catch (e) {
      this.toggleTripSaving();
      this.handleResponseError(e);
    }
  };

  handleModalBackdropPress = () => this.setState({ ...modalsInitState });

  toggleModal = (key: keyof IEditTripsState) => {
    this.setState({
      ...this.state,
      [key]: !this.state[key],
    });
  };

  updateStateCurrentTrip = (currentTrip: ITrip, callback?: () => void) => {
    this.setState({ currentTrip, ...modalsInitState }, callback);
  };

  selectTransportNode = (transportNode: ITransportNode) => {
    this.updateStateCurrentTrip({
      ...this.state.currentTrip,
      transportNodeCode: transportNode?.code,
      departureTerminalCode: transportNode?.code,
      transportNode,
    } as ITrip);
  };

  selectPlaceOfUnloading = (placeOfUnloadingTransportNode: ITransportNode) => {
    this.setState({
      placeOfUnloadingTransportNode,
      ...modalsInitState,
    });
  };

  selectDestinationCountry = (destinationCountry: ICountry) => {
    this.setState({
      destinationCountry,
      placeOfUnloadingTransportNode: undefined,
      ...modalsInitState,
    });
  };

  selectDates = (arrivalDate: string, departureDate: string) => {
    this.updateStateCurrentTrip({
      ...this.state.currentTrip,
      arrivalDate,
      departureDate,
    } as ITrip);
  };

  submitTripDelete = async () => {
    const { id } = this.props.currentTrip as ITrip;
    this.props.avLoader('', true);
    try {
      await localStorage(`trip-receipts-agree-${id}`)?.remove?.();
      await localStorage(`trip-receipts-documents-${id}`)?.remove?.();
      await this.props.deleteTrip(id);
      await waitForAsync(600);
      this.props.avLoader('', false);
      this.props.navigation.dispatch(
        CommonActions.reset({ index: 0, routes: [{ name: HomeSceneName }] })
      );
    } catch (e) {
      await waitForAsync(600);
      this.props.avLoader('', false);
      await waitForAsync(600);
      this.handleResponseError(e);
    }
  };

  handleOnDeleteTrip = () => {
    this.props.avConfirm(
      '',
      constants.trip.deleteWarning,
      this.submitTripDelete
    );
  };

  handleChangeDepartureTime = (estimatedDateAndTimeOfDeparture: Date) => {
    this.setState({ estimatedDateAndTimeOfDeparture, ...modalsInitState });
  };

  isOther = () => {
    const placeOfUnloadingTransportNodeCode =
      this.state.placeOfUnloadingTransportNode?.code || '';
    return (
      placeOfUnloadingTransportNodeCode.startsWith('OTR_') ||
      this.isOtherTransportNode()
    );
  };

  isOtherTransportNode = () => {
    const transportNodeCode = this.state.currentTrip?.transportNodeCode || '';
    return transportNodeCode.startsWith('OTR_');
  };

  componentDidMount() {
    this.setState({
      ...modalsInitState,
      currentTrip: this.props.currentTrip,
      tripSaveButtonEnabled: !!this.props.currentTrip?.id,
      isTripSaving: false,
    });
    if (this.props.currentTrip?.id) {
      this.props.navigation.setParams({
        onDeleteMethod: this.handleOnDeleteTrip,
      });
    }
  }

  renderBeTimePicker = () => {
    const nowDate = new Date();
    return (
      <RNDatePicker
        modal
        mode="time"
        locale="en_GB"
        minuteInterval={30}
        is24hourSource="locale"
        androidVariant="nativeAndroid"
        open={this.state.isDepartureTimeModalVisible}
        date={this.state.estimatedDateAndTimeOfDeparture || nowDate}
        onConfirm={this.handleChangeDepartureTime}
        textColor={colors.darkGrey}
        onCancel={() => this.toggleModal('isDepartureTimeModalVisible')}
        title={constants.estimatedDateAndTimeOfDepartureLabel}
        confirmText={constants.labels.select}
        cancelText={constants.labels.cancel}
        theme="light"
      />
    );
  };

  render() {
    const isBe = getCountryCode(this.state.currentTrip?.country) === BE;

    const isValid = [isRequired];
    const formData = {
      ...this.state.currentTrip,
      ...(this.state.currentTrip?.extraProps?.beDetax || {}),
    } as ITripWithState;

    if (this.state.estimatedDateAndTimeOfDeparture) {
      (formData as any).estimatedDateAndTimeOfDeparture = moment(
        this.state.estimatedDateAndTimeOfDeparture
      ).format(TIME_FORMAT);
    }

    const arrivalDepartureDateInput = {
      ...inputConfigs.arrivalDepartureDate,
      onTouchStart: () => this.toggleModal('isDateModalVisible'),
      containerStyle: inputStyles.inputContainer,
      getInputValue: () => getArrivalDepartureDate(this.state.currentTrip),
      value: getArrivalDepartureDate(this.state.currentTrip),
    };

    const estimatedDateAndTimeOfDepartureInput = {
      ...inputConfigs.estimatedDateAndTimeOfDeparture,
      isValid,
      onTouchStart: () => {
        this.toggleModal('isDepartureTimeModalVisible');
      },
      containerStyle: inputStyles.inputContainer,
      getInputValue: () => (formData as any).estimatedDateAndTimeOfDeparture,
      value: (formData as any).estimatedDateAndTimeOfDeparture,
      renderWeb: isMobile ? this.renderBeTimePicker : undefined,
    };

    const placeOfUnloadingInput = {
      ...inputConfigs.placeOfUnloading,
      getInputValue: () =>
        getTransportNodeTitle(this.state.placeOfUnloadingTransportNode),
      value: getTransportNodeTitle(this.state.placeOfUnloadingTransportNode),
      onTouchStart: () => this.toggleModal('isPlaceOfUnloadingModalVisible'),
      isValid,
    };

    const transportReferenceInput = {
      ...inputConfigs.transportReference,
      containerStyle: inputStyles.inputContainer,
      isValid,
    };

    const transportNodeInput = {
      ...inputConfigs.transportNode,
      onTouchStart: () => this.toggleModal('isTransportNodeModalVisible'),
      getInputValue: () =>
        getTransportNodeTitle(this.state.currentTrip?.transportNode),
      value: getTransportNodeTitle(this.state.currentTrip?.transportNode),
      containerStyle: inputStyles.inputContainer,
    };

    const outPuntInputSelect = {
      ...inputConfigs.outPuntSelect,
      containerStyle: inputStyles.inputContainer,
      onTouchStart: () => this.toggleModal('isTransportNodeModalVisible'),
      getInputValue: () =>
        getTransportNodeTitle(this.state.currentTrip?.transportNode),
      value: getTransportNodeTitle(this.state.currentTrip?.transportNode),
      isValid,
    };

    const destinationCountrySelect = {
      ...inputConfigs.destinationCountry,
      onTouchStart: () => this.toggleModal('isDestinationCountryModalVisible'),
      getInputValue: () => this.state.destinationCountry?.name,
      value: this.state.destinationCountry?.name,
      isValid,
    };

    const countryCodeSelect = {
      ...inputConfigs.countryCode,
      isDisabled: true,
      isTripCountries: true,
      getInputValue: () => this.props.currentTrip?.country?.name,
      value: this.props.currentTrip?.country?.name,
    };

    const allInputs = filter([
      countryCodeSelect,
      isBe ? outPuntInputSelect : transportNodeInput,
      arrivalDepartureDateInput,
      isBe && !this.isOtherTransportNode()
        ? estimatedDateAndTimeOfDepartureInput
        : undefined,
      isBe && !this.isOtherTransportNode()
        ? destinationCountrySelect
        : undefined,
      isBe && !this.isOtherTransportNode() && this.state.destinationCountry
        ? placeOfUnloadingInput
        : undefined,
      isBe && !this.isOtherTransportNode()
        ? transportReferenceInput
        : undefined,
    ]) as AvFormInput[];

    console.log('allInputs', allInputs);

    return (
      <>
        <AvForm
          inputs={allInputs}
          data={formData}
          onSubmit={this.saveTripDetails}
          buttonText={constants.labels.save}
          isLoading={this.state.isTripSaving}
          isDefaultEnabled={this.props.route?.params?.isDefaultEnabled}
        />
        <AvModal
          title={constants.transportNodes.departureFrom}
          isVisible={this.state.isTransportNodeModalVisible}
          onBackdropPress={this.handleModalBackdropPress}
        >
          <SelectTransportNode
            onSelectItem={this.selectTransportNode}
            country={this.state.currentTrip?.country}
          />
        </AvModal>
        <AvModal
          navigation={this.props.navigation}
          title={constants.arrivalDepartureDate}
          isVisible={this.state.isDateModalVisible}
          onBackdropPress={this.handleModalBackdropPress}
        >
          <TripDates
            trip={this.state.currentTrip}
            onSave={this.selectDates}
            saveButtonLabel={constants.labels.select}
            isModal
          />
        </AvModal>
        <AvModal
          navigation={this.props.navigation}
          title={constants.placeOfUnloadingLabel}
          isVisible={this.state.isPlaceOfUnloadingModalVisible}
          onBackdropPress={this.handleModalBackdropPress}
        >
          <SelectTransportNode
            onSelectItem={this.selectPlaceOfUnloading}
            country={this.state.destinationCountry}
            withOutOther
          />
        </AvModal>
        <AvModal
          navigation={this.props.navigation}
          title={constants.countryOfUnloadingLabel}
          isVisible={this.state.isDestinationCountryModalVisible}
          onBackdropPress={this.handleModalBackdropPress}
        >
          <SelectCountry
            onItemPress={this.selectDestinationCountry}
            isEu={false}
          />
        </AvModal>
        {isDesktop ? this.renderBeTimePicker() : undefined}
      </>
    );
  }
}

const mapStateToProps = (state: IDefaultState, props: IEditTripProps) => {
  const tripId: number | undefined = props.route.params?.tripId;
  const newTrip: ITrip | undefined = props.route.params?.newTrip;
  const currentTrip = newTrip || (tripId && getTripById(tripId, state));
  const isExistsPassport = getIsExistsUserPassport(state);
  const isFilledAddress = getIsFilledAddress(state.api.user);
  const isExistsDefaultRefundMethod = getDefaultRefundMethod(state);
  return {
    tripId,
    currentTrip,
    isExistsPassport,
    isFilledAddress,
    isExistsDefaultRefundMethod,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      avAlert,
      avConfirm,
      updateTrip,
      deleteTrip,
      getUser,
      addTrip,
      avLoader,
    },
    dispatch
  );
};

export default compose<any>(connect(mapStateToProps, mapDispatchToProps))(
  EditTrip
);
