import { Label } from '../../components';
import React from 'react';
import { View } from 'react-native';
import { documentsStyles } from './documentsStyles';

export const documentsContentView = (
  title: string,
  message: string,
  isInvert?: boolean
) => {
  return (
    <View style={documentsStyles.contentContainer}>
      <Label
        text={title}
        type='H1LargeSemiBold'
        white={!isInvert}
        style={documentsStyles.title}
        centered
      />
      <Label text={message} type='H2Light' white={!isInvert} centered />
    </View>
  );
};
