import {
  createApiUploadFile,
  createExt,
  isWeb,
  waitForAsync,
} from '../../common';
import {
  CAMERA_OPTIONS,
  CameraRef,
  ITakePictureResponse,
} from '../../components/CameraContainer';
import {
  PassportDetailsSceneName,
  SelfiePreviewSceneName,
} from '../../navigation/names';
import { last } from 'lodash';
import { IDocumentCameraProps } from './types';
import { EDirname } from '../../api-store';
import { RNImageEditor } from '../../modules/RNImageEditor';

export const pictureCrop = (picture: ITakePictureResponse): Promise<any> => {
  const cropData = {
    offset: {
      x: 0,
      y: 0,
    },
    size: {
      width: picture.width,
      height: (picture.height / 3) * 2.2,
    },
  };

  return RNImageEditor.cropImage(picture.uri, cropData);
};

export const handleOnTakePicture =
  (props: IDocumentCameraProps) => (cameraRef: CameraRef) => {
    props.avLoader('', true);

    if (!cameraRef || !cameraRef.current) return;
    cameraRef.current
      .takePictureAsync(CAMERA_OPTIONS)
      .then((picture) => {
        props.avLoader('', false);
        props.navigation.navigate(SelfiePreviewSceneName, {
          ...(props.route.params || {}),
          selfie: {
            ...picture,
            uri: picture.uri,
            type: `image/png`,
            name: String(last(picture.uri?.split('/'))),
          },
        });
      })
      .catch(handleOnSavePictureError(props));
  };

export const handleOnSavePicture = (
  props: any,
  picture: ITakePictureResponse
): void => {
  props.avLoader('', true);
  if (isWeb) {
    const selfie = createApiUploadFile(picture, EDirname.SELFIES);
    props.avLoader('', false);
    return props.navigation.navigate(PassportDetailsSceneName, {
      ...(props.route.params || {}),
      selfie,
    });
  }
  pictureCrop(picture).then(async (selfiePath: string) => {
    try {
      const selfie = createApiUploadFile(
        { ...picture, uri: selfiePath },
        EDirname.SELFIES
      );
      props.avLoader('', false);
      props.navigation.navigate(PassportDetailsSceneName, {
        ...(props.route.params || {}),
        selfie,
      });
    } catch (e) {
      console.log(e);
      handleOnSavePictureError(props, picture)(e);
    }
  });
};

export const handleOnSavePictureError =
  (props: IDocumentCameraProps, picture?: ITakePictureResponse) =>
  async (error: Error) => {
    props.avLoader('', false);
    await waitForAsync(1000);
    if (!picture) {
      return props.avAlert('', error.message);
    }
    props.avConfirm('', error.message, async () => {
      await waitForAsync(1000);
      handleOnSavePicture(props, picture);
    });
  };
