import { every, filter, get, reduce, size, some } from 'lodash';
import React from 'react';
import {
  Keyboard,
  KeyboardAvoidingView,
  LayoutAnimation,
  View,
} from 'react-native';
import { ICountry } from '../../api-store';
import {
  constants,
  fadeLayout,
  globalStyles,
  isIOS,
  isSmallDevice,
  metrics,
  sketchSize,
} from '../../common';
import { isEnglish } from '../../common/stringValidator';
import { AvIcon } from '../../components/AvIcon';
import { Label } from '../../components/Labels';
import { AvNote } from '../AvNote';
import { AvTextInput } from '../AvTextInput';
import { BodyContainer } from '../BodyContainer';
import { CustomButton } from '../CustomButton';
import { List } from '../List';
import styles from './styles';
import { AvFormData, AvFormInput, AvFormProps, AvFormState } from './types';

export class AvForm extends React.Component<AvFormProps, AvFormState> {
  inputRefs: AvTextInput[] = [];
  avInputRefs: AvTextInput[];
  flatListRef: any;

  constructor(props: AvFormProps) {
    super(props);
    this.state = {
      isScrollEnabled: isSmallDevice,
      isForceShowErrors: false,
      isFormEdited: false,
      inputValues: this.getInputValues(),
      isHideSkipButton: false,
    };
    this.inputRefs = [];
    this.avInputRefs = [{ data: {} } as AvTextInput];
  }

  getInputValues = (isReset?: boolean) =>
    reduce(
      this.props.inputs,
      (obj: AvFormData, input, i) => {
        const value = isReset
          ? ''
          : input?.getInputValue
          ? input.getInputValue(this.props.data)
          : get(this.props, `data[${input.key}]`) ||
            get(this.avInputRefs, `[${i}]data.value`) ||
            input.value ||
            get(input, 'otherTextInputProps.defaulValue') ||
            '';
        obj[input.key] = {
          value,
          isValid: get(this.avInputRefs, `[${i}]data.isValid`, false),
          country: input.country,
          isEdited: get(this.avInputRefs, `[${i}]data.isEdited`, false),
        };
        return obj;
      },
      {}
    );

  isNotEnglishValue = (inputProps: AvFormInput): boolean =>
    !inputProps.isSkipEnglish &&
    !isEnglish(get(this.state, `inputValues[${inputProps.key}].value`));

  isValidFields = (): boolean => {
    const filtered = filter(
      this.props.inputs,
      (x) => !!x.key && x.key !== 'empty'
    );
    const isFormEdited = some(filtered, ({ key }) => {
      return get(this.state, `inputValues[${key}].isEdited`);
    });
    const isAllValid = every(filtered, ({ key }) => {
      return get(this.state, `inputValues[${key}].isValid`);
    });

    return (
      this.props.isDefaultEnabled ||
      (isFormEdited && isAllValid && this.state.isFormEdited)
    );
  };

  inputValidationChange =
    (key: string, i: number) =>
    (
      value: string,
      isValid: boolean,
      isEdited: boolean,
      country?: ICountry
    ) => {
      console.log({
        ...(this.props.inputs[i].isResetForm ? {} : this.state.inputValues),
        [key]: { value, isValid, isEdited, country },
      });

      this.setState({
        inputValues: {
          ...(this.props.inputs[i].isResetForm
            ? this.getInputValues(true)
            : this.state.inputValues),
          [key]: { value, isValid, isEdited, country },
        },
      });
    };

  getInputProps = (
    inputProps: AvFormInput,
    isNextExists: boolean,
    nextKey: number,
    i: number
  ): AvFormInput => {
    const key = inputProps.key;
    const isNotEnglishValue = this.isNotEnglishValue(inputProps);
    const inputValue = get(this.state, `inputValues[${key}].value`, '') || '';
    return {
      ...inputProps,
      value: inputValue,
      createRef: (ref) => (this.inputRefs[i] = ref),
      otherTextInputProps: {
        ...inputProps.otherTextInputProps,
        autoFocus: !!this.props.autoFocus && i === 0,
        returnKeyType:
          isNextExists &&
          inputProps.otherTextInputProps?.keyboardType !== 'numeric'
            ? 'next'
            : 'done',
        onSubmitEditing: () => {
          if (isNextExists) {
            if (i > 2 && this.flatListRef) {
              this.flatListRef.scrollToOffset({
                offset: sketchSize(200, true),
              });
            }
            if (this.props.inputs[nextKey].isCountry) {
              get(this.inputRefs, `[${i}].blur`, () => null)();
            } else {
              get(this.inputRefs, `[${nextKey}].focus`, () => null)();
            }
          } else {
            this.onSubmit();
          }
        },
        onBlur: () => this.handleInputFocus(false),
        onFocus: () => {
          this.handleInputFocus(true);
          this.setState({
            isForceShowErrors: false,
          });
        },
        editable:
          !this.props.isDisabled && inputProps.otherTextInputProps?.editable,
      },
      onValidationChange: this.inputValidationChange(key, i),
      errorText: !this.props.isDisabled
        ? isNotEnglishValue
          ? constants.validationErrors.english
          : inputProps.errorText
        : '',
      isForceShowErrorText:
        !this.props.isDisabled &&
        (isNotEnglishValue ||
          (this.state.isForceShowErrors &&
            !get(this.state, `inputValues[${key}].isValid`))),
    };
  };

  onSubmit = (): void => {
    Keyboard.dismiss();
    if (!this.isValidFields()) {
      this.setState({ isForceShowErrors: true });
      return;
    }
    const data = reduce(
      this.props.inputs,
      (obj: { [x: string]: string | number }, input) => {
        const countryValue = this.state.inputValues[input.key]?.country?.id;
        const inputValue = input.isCountry
          ? countryValue
          : this.state.inputValues[input.key]?.value;
        obj[input.key] = inputValue;
        return obj;
      },
      {}
    );
    this.props.onSubmit(data);
  };

  handleInputFocus = (isHideSkipButton: boolean) => {
    if (isIOS) {
      LayoutAnimation.configureNext(fadeLayout);
    }
    this.setState({ isHideSkipButton });
  };

  counterPlus = (key: string, value: number) => {
    const newValue = value + 1;
    this.setState({
      inputValues: {
        ...this.state.inputValues,
        [key]: { value: String(newValue), isValid: true, isEdited: true },
      },
    });
  };

  counterMinus = (key: string, value: number, min: number) => {
    const newValue = value - 1;
    if (newValue < min) {
      return;
    }
    this.setState({
      inputValues: {
        ...this.state.inputValues,
        [key]: { value: String(newValue), isValid: true, isEdited: true },
      },
    });
  };

  getNextInputIndex = (index: number) => {
    const nextKey = index + 1;
    return this.props.inputs[nextKey]?.filled ||
      !this.props.inputs[nextKey]?.key
      ? nextKey + 1
      : nextKey;
  };

  componentDidMount() {
    this.setState({
      inputValues: this.getInputValues(),
    });
  }

  componentDidUpdate(prevProps: AvFormProps, prevState: AvFormState) {
    const isFormEdited: boolean =
      prevState.inputValues &&
      Object.keys(prevState.inputValues).some(
        (key) =>
          get(this.state, `inputValues[${key}].value`) !==
          get(prevState, `inputValues[${key}].value`)
      ) &&
      !prevState.isFormEdited;

    if (isFormEdited) {
      this.setState({ isFormEdited });
    }

    const changedPropsValueInputs = filter(this.props.inputs, (input, i) => {
      return prevProps.inputs[i]?.value !== input.value;
    });

    // update passport date from modal
    if (size(changedPropsValueInputs)) {
      const inputValues = reduce(
        changedPropsValueInputs,
        (obj, input) => {
          return {
            ...obj,
            [input.key]: {
              isValid: every(input.isValid, (fn) => fn(input.value || '')),
              value: input.value,
              country: input.country,
              isEdited: true,
            },
          };
        },
        {}
      );
      this.setState({
        inputValues: { ...this.state.inputValues, ...inputValues },
      });
    }
  }

  render() {
    console.log('AvForm', this.state.inputValues);
    return (
      <KeyboardAvoidingView
        style={styles.container}
        keyboardVerticalOffset={
          this.props.isInModal ? 0 : metrics.keyboardOffset
        }
        {...(isIOS ? { behavior: 'padding' } : {})}
      >
        <BodyContainer useSafeArea containerStyle={styles.bodyContainer}>
          <List
            data={this.props.inputs}
            registerRef={(r) => (this.flatListRef = r)}
            renderItem={this.renderInput}
            numColumns={this.props.numColumns}
            style={styles.overflow}
            contentContainerStyle={styles.scrollContainer}
            ListFooterComponent={
              <>
                {this.props.footerComponent}
                {!!this.props.notes && this.props.notes.map(this.renderNote)}
              </>
            }
            keyExtractor={(item: AvFormInput, i: number) =>
              item.key ? item.key : `${i}`
            }
          />
          {this.renderButton()}
        </BodyContainer>
      </KeyboardAvoidingView>
    );
  }

  renderInput = ({ item, index }: { item: AvFormInput; index: number }) => {
    if (item.isCounter) {
      return this.renderCounter(item);
    }

    const nextKey = this.getNextInputIndex(index);
    const isNextExists = !!this.props.inputs[nextKey];

    const isRightMerged: boolean = !!(
      (this.props.numColumns || 0) > 0 &&
      isNextExists &&
      this.props.inputs[nextKey].key
    );

    const containerStyle = [
      styles.container,
      isRightMerged && styles.marginRight,
    ];
    if (item.filled) {
      return <View style={containerStyle} />;
    } else if (!item.key) {
      return null;
    }

    const params: AvFormInput = this.getInputProps(
      item,
      isNextExists,
      nextKey,
      index
    );

    return (
      <AvTextInput
        containerStyle={[
          this.props.numColumns
            ? isNextExists
              ? styles.input2Container
              : styles.inputLeftContainer
            : styles.inputContainer,
          isRightMerged && styles.marginRight,
        ]}
        ref={(ref) => ((this.avInputRefs as any)[index] = ref)}
        {...params}
      />
    );
  };

  renderNote = (note: string, i: number) => (
    <AvNote
      key={i}
      name={note.replace(/\s/, '') + this.props.name}
      text={note}
    />
  );

  renderCounter = (input: AvFormInput) => {
    const value = Number(
      get(this.state, `inputValues[${input.key}].value`) || input.min
    );

    return (
      <View style={styles.counterContainer}>
        <Label
          type="H2Light"
          text={constants.quantity}
          style={styles.detailsWithLabelGroup}
        />
        <View style={styles.counterWrap}>
          <CustomButton
            isRounded
            accent
            buttonStyle={styles.counterButton}
            iconComponent={<AvIcon width={40} name="Minus" />}
            onPress={() => {
              this.counterMinus(input.key, value, Number(input.min));
            }}
          />
          <Label
            text={String(value)}
            type="H1LargeSemiBold"
            xSize={0.9}
            numberOfLines={1}
          />
          <CustomButton
            isRounded
            accent
            buttonStyle={styles.counterButton}
            iconComponent={<AvIcon width={40} name="Plus" />}
            onPress={() => {
              this.counterPlus(input.key, value);
            }}
          />
        </View>
      </View>
    );
  };

  renderButton = () => {
    if (this.props.isDisabled) return null;
    const isShowSkip = this.props.useSkip;
    const isShowBefore = this.props.BeforeSaveButtonComponent;
    const buttonStyle =
      isShowSkip || isShowBefore
        ? styles.withSkip
        : globalStyles.absoluteBottomButton;
    return (
      <>
        {this.props.BeforeSaveButtonComponent}
        <CustomButton
          accent
          text={this.props.buttonText || constants.labels.send}
          isEnabled={this.isValidFields()}
          isLoading={this.props.isLoading}
          onPress={this.onSubmit}
          buttonStyle={buttonStyle}
          isUppercase
          testID="avFormSaveBtn"
        />
        {isShowSkip && !this.state.isHideSkipButton && (
          <CustomButton
            link
            text={constants.labels.addLater}
            testID="skipAvFormBtn"
            onPress={this.props.onSkip}
          />
        )}
      </>
    );
  };
}
