import { colors } from '../common';
import {
  getIsConfirmAddressDeclined
} from '../containers/AddDetails/documentsState';
import { IHeaderProps } from '../containers/Home/Header/types';
import { IDefaultState } from '../store/types';
import { get, map, sortBy } from 'lodash';
import React from 'react';
import { getIsDeclinedUserPassport } from './../containers/Home/state';
import {
  AddressDeclineSceneName,
  AuthSceneName,
  HomeSceneName,
  PassportDeclineSceneName
} from './names';
import { INavigationScreenProps } from './types';

export const renderNavigationScreens = (
  stack: any,
  screens: any,
  initSceneName?: string
) => {
  let data = map(screens, (s, name) => ({ ...s, name }));
  if (initSceneName) {
    data = sortBy(data, { name: initSceneName }).reverse();
  }

  return React.createElement(
    React.Fragment,
    undefined,
    map(data, ({ screen, navigationOptions, params, name }, i) =>
      React.createElement(stack.Screen, {
        options: navigationOptions,
        initialParams: { ...(params || {}) },
        name,
        component: screen,
        key: name,
      })
    )
  );
};

export const headerTrash = ({ route }: INavigationScreenProps) => {
  const deleteMethod = route.params?.onDeleteMethod;
  const iconParams = {
    rightIconName: 'Trash',
    rightIconPress: deleteMethod,
    rightIconColor: colors.red,
  };
  return (deleteMethod ? iconParams : {}) as IHeaderProps;
};

export const getRouteName = (navigation: any): string => {
  const routeIndex = get(navigation, 'state.index');
  const routeName = get(navigation, `state.routes[${routeIndex}].key`);
  return routeName;
};

export const getNavigationFirstScreenName = (
  state: IDefaultState,
  watchScreen: string
) => {
  if (!state.api?.user?.isAuth) {
    console.log('getNavigationFirstScreenName', watchScreen, AuthSceneName);
    return AuthSceneName;
  }

  const isDeclinedPassport = getIsDeclinedUserPassport(state);
  if (isDeclinedPassport) {
    
      console.log(
        'getNavigationFirstScreenName',
        watchScreen,
        PassportDeclineSceneName
      );
    return PassportDeclineSceneName;
  }
  const isAddressConfirmDeclined = getIsConfirmAddressDeclined(state.api.user);
  if (isAddressConfirmDeclined) {
    
      console.log(
        'getNavigationFirstScreenName',
        watchScreen,
        AddressDeclineSceneName
      );
    return AddressDeclineSceneName;
  }
  return HomeSceneName;
};
