import { constants, globalStyles, images, localStorage } from '../../common';
import { BodyContainer, Checkbox, CustomButton, Label } from '../../components';
import { AirvatInfoGoodsAboveSceneName } from '../../navigation/names';
import React from 'react';
import { View } from 'react-native';
import { RNFastImage } from '../../modules/RNFastImage';
import { getReceiptAgreeConditions } from './constants';
import styles from './styles';
import { IReceiptAgreeProps } from './types';

export default class ReceiptAgree extends React.Component<IReceiptAgreeProps> {
  handleOnAgreeConfirm = async () => {
    await localStorage(`trip-receipts-agree-${this.props.trip.id}`, true).set();
    this.props.onAgree();
  };

  render() {
    const receiptAgreeConditions = getReceiptAgreeConditions(
      this.props.trip.country
    );
    return (
      <BodyContainer useSafeArea>
        <RNFastImage
          resizeMode="contain"
          style={styles.placeholderAgreeIcon}
          source={images.receiptsIcon}
        />

        <View style={styles.conditionsContainer}>
          <View style={styles.agreeRow}>
            <Checkbox checked locked />
            <Label
              style={styles.description}
              text={
                <>
                  <Label
                    linkActions={{
                      goToAbove: () => {
                        this.props.onClose();
                        this.props.navigation.navigate(
                          AirvatInfoGoodsAboveSceneName
                        );
                      },
                    }}
                    text={receiptAgreeConditions[0].description}
                    type="H1Dark"
                    useFormatter
                  />
                  <Label
                    text={receiptAgreeConditions[1].description}
                    type="H1Dark"
                    bold
                  />
                </>
              }
            />
          </View>
        </View>
        <View style={[globalStyles.absoluteBottomButton, { width: '100%' }]}>
          <CustomButton
            accent
            isUppercase
            onPress={this.handleOnAgreeConfirm}
            text={constants.confirm}
            testID={'confirmReceiptCheckBoxesBtn'}
          />
        </View>
      </BodyContainer>
    );
  }
}
