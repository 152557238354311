import { colors, ENV, isWeb } from '../../common';
import React, { useEffect, useState } from 'react';
import WebView from 'react-native-webview';
import styles from './styles';
import { IBrowserProps } from './types';
import { ActivityIndicator, View } from 'react-native';

export const WebSiteBrowser: React.FC<IBrowserProps> = ({
  uri,
  onLoaded,
  onMount,
  ...props
}) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    onMount && onMount();
    setIsLoading(true);
  }, []);

  const handleLoadEnd = () => {
    setIsLoading(false);
    onLoaded && onLoaded(true);
  };

  return (
    <>
      {isWeb ? (
        <iframe
          src={uri}
          key={uri}
          style={styles.container}
          onLoad={handleLoadEnd}
        />
      ) : (
        <WebView
          style={styles.container}
          source={{ uri }}
          key={uri}
          contentMode="mobile"
          originWhitelist={['*']}
          showsVerticalScrollIndicator={false}
          showsHorizontalScrollIndicator={false}
          applicationNameForUserAgent={`Airvat/${ENV.APP_VERSION}`}
          directionalLockEnabled={true}
          allowsBackForwardNavigationGestures={false}
          setSupportMultipleWindows={false}
          textInteractionEnabled={false}
          autoManageStatusBarEnabled={false}
          forceDarkOn={false}
          cacheEnabled={true}
          onMessage={(e: any) => {
            if (e.nativeEvent.data === 'loaded') {
              handleLoadEnd();
            }
          }}
          {...props}
        />
      )}
      {isLoading && (
        <View style={styles.webView}>
          <ActivityIndicator size="large"  color={colors.dark}/>
        </View>
      )}
    </>
  );
};
