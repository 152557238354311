import { constants } from '../../../common';
import { isEmail, isPassword, isReferralCode } from '../../../common/stringValidator';
import { AvTextInput, CustomButton } from '../../../components';
import React from 'react';
import styles from '../styles';
import { ISignUpFormProps } from '../types';

export default class SignUpForm extends React.Component<ISignUpFormProps> {
  emailInputRef: any;
  passwordInputRef: any;

  state = {
    email: { value: '', isValid: true },
    password: { value: '', isValid: true },
    code: { value: '', isValid: true },
  };

  handleSubmit = () => {
    const { email, password, code } = this.state;
    if (isEmail(email.value) && isPassword(password.value) && isReferralCode(code.value)) {
      this.props.onSignUpPress(email.value, password.value, code.value);
    }
  };

  handleCloseModal = () => {
    this.setState({
      showRestoreWarningModal: false,
    });
  };

  isValidFields = (): boolean => {
    const { email, password, code } = this.state;
    return !!(
      email.isValid &&
      email.value &&
      password.isValid &&
      password.value &&
      code.isValid
    );
  };

  render() {
    const { isLoading, onLoginLinkPress } = this.props;

    return (
      <>
        <AvTextInput
          key='userEmail'
          createRef={ref => {
            this.emailInputRef = ref;
          }}
          label={constants.labels.email}
          otherTextInputProps={{
            placeholder: constants.labels.emailPlaceholder,
            onSubmitEditing: () => this.passwordInputRef.focus(),
            autoCapitalize: 'none',
            keyboardType: 'email-address',
            returnKeyType: 'next',
            onFocus:()=>this.props.onInputFocus(true),
            onBlur:()=>this.props.onInputFocus(false)
          }}
          isValid={[isEmail]}
          onValidationChange={(value, isValid) =>
            this.setState({ email: { value, isValid } })
          }
          errorText={constants.validationErrors.email.invalid}
          testID='regEmailInp'
        />
        <AvTextInput
          key='userPassword'
          createRef={ref => {
            this.passwordInputRef = ref;
          }}
          label={constants.labels.password}
          otherTextInputProps={{
            placeholder: constants.labels.passwordPlaceholder,
            secureTextEntry: true,
            autoCapitalize: 'none',
            returnKeyType: 'done',
            onFocus:()=>this.props.onInputFocus(true),
            onBlur:()=>this.props.onInputFocus(false)
          }}
          isValid={[isPassword]}
          onValidationChange={(value, isValid) =>
            this.setState({ password: { value, isValid } })
          }
          errorText={constants.validationErrors.password.invalid}
          testID='regPasswordInp'
        />
        <AvTextInput
          key='userReferralCode'
          label={constants.labels.referralCode}
          otherTextInputProps={{
            autoCapitalize: 'characters',
            placeholder: constants.labels.referralCodePlaceholder,
            returnKeyType: 'done',
            onFocus:()=>this.props.onInputFocus(true),
            onBlur:()=>this.props.onInputFocus(false)
          }}
          isValid={[isReferralCode]}
          onValidationChange={(value, isValid) => {
            this.setState({
              code: { value: (value || '').toUpperCase(), isValid },
            });
          }}
          value={this.state.code.value || ''}
          errorText={constants.validationErrors.referralCode.invalid}
          testID='referralCodeInp'
        />
        <CustomButton
          text={constants.labels.signUp}
          accent
          isUppercase
          isEnabled={!this.props.buttonDisabled && this.isValidFields()}
          isLoading={isLoading}
          onPress={this.handleSubmit}
          buttonStyle={styles.fillButton}
          testID='signUpBtn'
        />
        <CustomButton
          link
          text={constants.auth.alreadyHaveAnAccount}
          onPress={onLoginLinkPress}
          buttonStyle={styles.fillButton}
          testID='goBackToLoginBtn'
        />
        {this.props.renderThirdParty(this.state.code.value || '')}
      </>
    );
  }
}
