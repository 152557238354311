import { constants } from '../../../common/constants';
import { isEmail } from '../../../common/stringValidator';
import { AvTextInput, BodyContainer, CustomButton } from '../../../components';
import React from 'react';
import { Keyboard } from 'react-native';
import styles from '../styles';
import { IPasswordForgotFormProps, IPasswordForgotFormState } from '../types';
import { isWeb } from 'common';

export class PasswordForgotForm extends React.Component<
  IPasswordForgotFormProps,
  IPasswordForgotFormState
> {
  state = {
    email: { value: '', isValid: false },
  };

  isValidFields = (): boolean => {
    const { email } = this.state;
    return !!(email.isValid && email.value);
  };

  onSubmit = () => {
    this.state.email.value && this.props.passwordReset(this.state.email);
  };

  render() {
    return (
      <BodyContainer>
        <AvTextInput
          key="userEmail"
          isValid={[isEmail]}
          label={constants.labels.forgotPassEmail}
          otherTextInputProps={{
            placeholder: constants.labels.emailPlaceholder,
            returnKeyType: 'done',
            autoCapitalize: 'none',
            keyboardType: 'email-address',
            autoFocus: true,
            onEndEditing: this.onSubmit,
          }}
          onValidationChange={(value, isValid) =>
            this.setState({ email: { value, isValid } })
          }
          errorText={constants.validationErrors.email.invalid}
          testID="forgotEmailInp"
        />

        <CustomButton
          isUppercase
          accent
          text={constants.labels.send}
          isLoading={this.props.isLoading}
          isEnabled={this.isValidFields()}
          onPress={() => (isWeb ? this.onSubmit() : Keyboard.dismiss())}
          buttonStyle={styles.fillButton}
          testID="forgotBtn"
        />
      </BodyContainer>
    );
  }
}
