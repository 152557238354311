import { colors, sketchSize } from '../../common';
import { StyleSheet } from 'react-native';

export const documentsStyles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.darkTransparent,
  },
  contentContainer:{
    justifyContent: 'center',
    alignItems: 'center',
    padding: sketchSize(83)
  },
  maskContainer: {
    flex: 1,
    width: '100%'
  },
  title:{
    marginVertical: sketchSize(30)
  },
  documentImagePreview: {
    marginTop: sketchSize(120, true),
    width: sketchSize(1000),
    height: sketchSize(700),
    alignSelf: 'center',
    borderRadius: sketchSize(30)
  },
});
