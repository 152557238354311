import { constants } from '../../../common/constants';
import { avAlert } from '../../../components/AvAlert/alertActions';
import { IAvAlertActions } from '../../../components/AvAlert/types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { AddressConfirmation } from './AddressConfirmation';
import { waitForAsync } from '../../../common';

const AddressDeclineComponent: React.FC<IAvAlertActions> = props => {
  React.useEffect(() => {
    waitForAsync(600).then(() => {
      props.avAlert('', constants.invalidDocument);
    });
  }, []);
  return <AddressConfirmation {...props} />;
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      avAlert,
    },
    dispatch
  );
};

export const AddressDecline = compose<any>(connect(null, mapDispatchToProps))(
  AddressDeclineComponent
);
