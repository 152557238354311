import { applyMiddleware, legacy_createStore as createStore } from 'redux';
import thunk from 'redux-thunk';
import { defaultState } from './defaultState';
import rootReducer from './rootReducer';

export const store = createStore(
  rootReducer,
  defaultState,
  applyMiddleware(thunk)
);
