import { CommonActions } from '@react-navigation/native';
import { get } from 'lodash';
import React, { Component } from 'react';
import { ActivityIndicator, View } from 'react-native';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { deleteUser, logOut } from '../../api-store';
import { colors, getActiveTripId, openUrl, waitForAsync } from '../../common';
import { constants } from '../../common/constants';
import { globalStyles } from '../../common/globalStyles';
import {
  AppVersion,
  AvIcon,
  CustomButton,
  Label,
  List,
  SupportButton,
  TouchableView,
  avLoader,
} from '../../components';
import { avAlert, avConfirm } from '../../components/AvAlert/alertActions';
import {
  getIsConfirmAddressDeclined,
  getIsFilledAddress,
  getIsNeedConfirmAddress,
} from '../../containers/AddDetails/documentsState';
import {
  getDefaultRefundMethod,
  getIsExistsRefundMethods,
} from '../../containers/Home/state';
import TripCarousel from '../../containers/Trip/components/TripCarousel';
import * as SceneNames from '../../navigation/names';
import { getNavigationFirstScreenName } from '../../navigation/utils';
import { IDefaultState } from '../../store/types';
import styles from './styles';
import {
  ENotificationType,
  IMoreGroupItem,
  IMoreListItem,
  IMoreProps,
  IMoreState,
} from './types';

class MoreComponent extends Component<IMoreProps, IMoreState> {
  state = {
    isLoadingLogOut: false,
    isLoadingItem: undefined,
  };

  handleError = (e: Error) => {
    this.props.avAlert('', e.message);
  };

  handleOnLogout = async () => {
    this.setState({ isLoadingLogOut: true });
    await this.props.logOut().catch(this.handleError);
    this.setState({ isLoadingLogOut: true });
    this.props.navigation.dispatch(
      CommonActions.reset({
        index: 1,
        routes: [
          { name: SceneNames.AuthSceneName },
          {
            name: SceneNames.AuthLoginSceneName,
          },
        ],
      })
    );
  };

  onLogout = () => {
    this.props.avConfirm('', constants.account.logout, this.handleOnLogout);
  };

  handleOnDeleteAccount = async () => {
    this.props.avConfirm(
      '',
      constants.account.delete,
      this.handleOnDeleteAccountSubmit
    );
  };

  handleOnDeleteAccountSubmit = async () => {
    this.props.avLoader('', true);
    this.props
      .deleteUser()
      .then(async () => {
        await waitForAsync(600);
        this.props.avLoader('', false);
        this.props.navigation.dispatch(
          CommonActions.reset({
            index: 1,
            routes: [
              { name: SceneNames.AuthSceneName },
              {
                name: SceneNames.AuthLoginSceneName,
              },
            ],
          })
        );
      })
      .catch(async (e: any) => {
        await waitForAsync(600);
        this.props.avLoader('', false);
        await waitForAsync(600);
        this.handleError(e);
      });
  };

  render() {
    const referralDashboard: IMoreListItem = {
      key: ENotificationType.Referral,
      title: constants.headers.referralDashboard,
      testID: 'referralBtn',
      screenName: SceneNames.ReferralDashboardSceneName,
    };

    const refundSceneName = this.props.isExistsRefundMethods
      ? SceneNames.MyRefundMethodsSceneName
      : SceneNames.AddRefundMethodSceneName;

    const listData: IMoreGroupItem[] = [
      {
        title: constants.information,
        items: [
          {
            title: constants.tutorial,
            screenName: SceneNames.AirvatInfoHowItWorkSceneName,
            fill: colors.accent,
          },
          {
            title: constants.faqShort,
            onPress: () => {
              openUrl('https://intercom.help/airvat/en/collections/10643145-frequently-asked-questions')
            },
          },
          {
            title: constants.whatIsValidInvoice,
            screenName: SceneNames.AirvatInfoGoodsAboveSceneName,
          },
          {
            title: constants.invoiceRequestScreen,
            screenName: SceneNames.AirvatInfoCheckoutSceneName,
          },
        ],
      },
      {
        title: constants.myProfile,
        items: [
          {
            key: ENotificationType.Passport,
            title: constants.passport,
            testID: 'passportBtn',
            screenName: SceneNames.PassportInfoSceneName,
          },
          {
            key: ENotificationType.Address,
            title: constants.homeAddress,
            testID: 'addressBtn',
            screenName: SceneNames.AddressSceneName,
          },
          {
            key: ENotificationType.Trip,
            title: constants.tripDetails,
            testID: 'editTripBtn',
            onPress: () => {
              if (this.props.currentTrip) {
                return this.props.navigation.navigate(
                  SceneNames.EditTripSceneName,
                  {
                    tripId: this.props.currentTrip.id,
                  }
                );
              }
              this.props.navigation.navigate(
                SceneNames.SelectTripCountrySceneName
              );
            },
          },
          {
            key: ENotificationType.Refund,
            title: constants.account.refundMethodsTabName,
            testID: 'refundBtn',
            screenName: refundSceneName,
          },
          ...(this.props.isExistsReferralDashboard ? [referralDashboard] : []),
        ],
      },
      {
        title: constants.aboutUs,
        items: [
          // {
          //   title: constants.more.rateUs,
          //   id: 'rate',
          //   screenName: SceneNames.RateUsSceneName,
          //   onPress: () => {
          //     this.props.navigation.navigate(SceneNames.RateUsSceneName);
          //   },
          // },
          {
            title: constants.more.refundCalculator,
            screenName: SceneNames.RefundCalculatorSceneName,
          },
          {
            title: constants.termsAndConditions,
            screenName: SceneNames.TermsConditionsSceneName,
          },
          {
            title: constants.headers.privacyPolicy,
            screenName: SceneNames.PrivacyPolicySceneName,
          },
        ],
      },
      {
        title: constants.accountLabel,
        items: [
          {
            title: constants.headers.email,
            testID: 'goToChangeEmailBtn',
            screenName: SceneNames.EmailUpdateSceneName,
          },
          {
            title: constants.headers.password,
            testID: 'goToChangePasswordBtn',
            screenName: SceneNames.PasswordUpdateSceneName,
          },
          {
            title: constants.headers.deleteAccount,
            testID: 'deleteAccountBtn',
            onPress: this.handleOnDeleteAccount,
          },
        ],
      },
    ];

    return (
      <View style={styles.container}>
        <List
          data={listData}
          testID={'menuScroll'}
          renderItem={this.renderGroup}
          style={styles.list}
          initialNumToRender={3}
          contentContainerStyle={styles.listContainerStyle}
          keyExtractor={(item: IMoreListItem, index: number) => `${index}`}
          getItemLayout={(data, index) => ({
            length: 300,
            offset: 300 * index,
            index,
          })}
          ListHeaderComponent={
            <View style={styles.tripCarousel}>
              <TripCarousel navigation={this.props.navigation} />
            </View>
          }
          ListFooterComponent={
            <View style={styles.myAccountButtonContainer}>
              <CustomButton
                link
                onPress={this.onLogout}
                text={constants.labels.logout}
                isLoading={this.state.isLoadingLogOut}
                testID='logoutBtn'
              />
              <AppVersion style={styles.appVersion} />
              <SupportButton />
            </View>
          }
        />
      </View>
    );
  }

  renderGroup = ({ item, index }: { item: IMoreGroupItem; index: number }) => {
    return (
      <View style={styles.groupLabel} key={index}>
        <Label text={item.title} isUpperCase type='SubtitleSemibold' />
        <View style={styles.groupLabelItems}>
          {item.items.map(this.renderItem)}
        </View>
      </View>
    );
  };

  renderItem = (item: IMoreListItem, i: number) => {
    const itemPress = () => {
      if (item.onPress) {
        return item.onPress(item);
      }
      this.props.navigation.navigate(item.screenName, {
        tripId: this.props.currentTrip?.id,
        useGoBack: this.props.initSceneName === SceneNames.HomeSceneName,
      });
    };
    const testID = item.testID;
    const rowStyle = [styles.row, i > 0 && styles.borderRow];
    const isLoading = !!(item.id && this.state.isLoadingItem === item.id);
    return (
      <TouchableView
        key={`${testID}${i}`}
        onPress={itemPress}
        style={rowStyle}
        disabled={isLoading}
        testID={testID}
      >
        <Label text={item.title} type='SubtitleSemibold' style={styles.label} />
        <View style={styles.nextIconContainer}>
          {item.key && this.props.notifications[item.key] && (
            <AvIcon name='Notify' width={50} />
          )}
          {isLoading ? (
            <ActivityIndicator color={colors.dark} />
          ) : (
            <AvIcon
              name='Chevron'
              fill={colors.dark}
              containerStyle={globalStyles.chevronRight}
            />
          )}
        </View>
      </TouchableView>
    );
  };
}

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      avAlert,
      logOut,
      avConfirm,
      avLoader,
      deleteUser,
    },
    dispatch
  );
};

const mapStateToProps = (state: IDefaultState) => {
  const refCode = get(state, 'api.user.refCode');
  const isShowedPassport = !get(state, 'api.user.document.firstName');
  const isShowedAddress =
    !getIsFilledAddress(state.api.user) ||
    getIsConfirmAddressDeclined(state.api.user) ||
    getIsNeedConfirmAddress(state.api.user);

  const isShowedRefundMethod = !getDefaultRefundMethod(state);
  const isInfoShowedNotify = !get(state, 'api.user.isShowInfo');
  const isTripShowedNotify = !getActiveTripId(state);
  const notifications = {
    [ENotificationType.Passport]: isShowedPassport,
    [ENotificationType.Address]: isShowedAddress,
    [ENotificationType.Refund]: isShowedRefundMethod,
    [ENotificationType.Info]: isInfoShowedNotify,
    [ENotificationType.Trip]: isTripShowedNotify,
  };
  const tripId = getActiveTripId(state);
  const currentTrip = get(state, `api.user.trips.${tripId}`);
  const isExistsRefundMethods = getIsExistsRefundMethods(state);
  const initSceneName = getNavigationFirstScreenName(state, 'more');
  const isExistsReferralDashboard = !!state.api.referralDashboard?.id;
  return {
    refCode,
    notifications,
    currentTrip,
    isExistsRefundMethods,
    initSceneName,
    isExistsReferralDashboard,
  };
};

export const More = compose<any>(connect(mapStateToProps, mapDispatchToProps))(
  MoreComponent
);
