import { ICountry } from '../../api-store';
import { constants, getCountryCode, getFormattedAddress } from '../../common';
import { FR } from '../../common/constants/common';
import { SelectCountry } from '../../components';
import { avAlert, avConfirm } from '../../components/AvAlert/alertActions';
import {
  AddTripTransportNodeSceneName,
  PassportCameraPlaceholderSceneName
} from '../../navigation/names';
import { IDefaultState } from '../../store/types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { getIsNeedAskPassportAddressUpdate } from './tripState';
import { ISelectTripCountryProps } from './types';

const SelectTripCountryComponent: React.FC<ISelectTripCountryProps> = props => {
  const createRouteParams = (country: ICountry, isNeedAddressUpdate = false) => {
    const newTrip = props.route?.params?.newTrip || {};
    return {
      newTrip: {
        ...newTrip,
        country,
        isNeedAddressUpdate
      },
    };
  };

  const handleSuccessCreateTrip = (country: ICountry) => {
    props.navigation.navigate(
      AddTripTransportNodeSceneName,
      createRouteParams(country)
    );
  };

  const handleChangePassportAndAddress = (country: ICountry) => {
    props.navigation.navigate(
      PassportCameraPlaceholderSceneName,
      createRouteParams(country, true)
    );
  };

  const selectTripCountry = (country: ICountry) => {
    if (getCountryCode(country) === FR) {
      if (props.isForceNeedUpdatePassportAddress) {
        return props.avAlert('', constants.updatePassportAndAddress, () => {
          handleChangePassportAndAddress(country);
        });
      }
      if (props.isNeedAskPassportAddress) {
        const message = constants.haveYourPassportOrAddressChanged
          .replace(':passportNumber', props.passportNumber)
          .replace(':homeAddress', props.homeAddress)
          .trim();
        return props.avConfirm(
          '',
          message,
          () => {
            handleChangePassportAndAddress(country);
          },
          () => {
            handleSuccessCreateTrip(country);
          }
        );
      }
    }
    handleSuccessCreateTrip(country);
  };
  return <SelectCountry onItemPress={selectTripCountry} tripOnly />;
};

const mapStateToProps = (state: IDefaultState) => {
  const { isNeedAskPassportAddress, isForceNeedUpdatePassportAddress } =
    getIsNeedAskPassportAddressUpdate(state);
  const passportNumber = state.api.user?.document?.number || '';
  const homeAddress = getFormattedAddress(state.api.user?.address);
  return {
    isNeedAskPassportAddress,
    isForceNeedUpdatePassportAddress,
    passportNumber,
    homeAddress
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      avConfirm,
      avAlert,
    },
    dispatch
  );
};

export const SelectTripCountry = compose<any>(
  connect(mapStateToProps, mapDispatchToProps)
)(SelectTripCountryComponent);
