import { StyleSheet } from 'react-native';
import { sketchSize } from '../../common/metrics';
import { colors } from '../../common/colors';

export const MESSAGE_CONTAINER_HEIGHT = sketchSize(240);

export const styles = StyleSheet.create({
  container: {
    height: MESSAGE_CONTAINER_HEIGHT,
    width: '100%',
    top: 0,
    left: 0,
    paddingBottom: sketchSize(30),
    backgroundColor: colors.red,
    justifyContent: 'flex-end',
    alignItems: 'center',
    position: 'absolute'
  },
  fill: {
    flex: 1,
    justifyContent: 'flex-end'
  }
});
