import { RNCodePush } from '../../modules/RNCodePush';
import { ENV, isIOS, isWeb, openUrl } from '../../common';
import { get, padEnd } from 'lodash';

export const openStore = () => {
  if (isWeb) {
    window.location.reload();
  } else {
    const url = isIOS ? ENV.APPSTORE_URL : ENV.GOOGLEPLAY_URL;
    openUrl(String(url));
  }
};

export const getCodePushVersion = async () => {
  const metadata = await RNCodePush.getUpdateMetadata();
  return (metadata?.label || '1').replace(/[^0-9]/, '');
};

export const compareVersion = (v1?: string, v2?: string): boolean => {
  try {
    if (!v1 || !v2) return false;
    const vNum1 = parseInt(
      `${v1}`
        .split('.')
        .map((v) => padEnd(`${v}`, 2, '0'))
        .join('')
    );
    const vNum2 = parseInt(
      `${v2}`
        .split('.')
        .map((v) => padEnd(`${v}`, 2, '0'))
        .join('')
    );
    const vNum1Str = `${vNum1}`;
    const vNum2Str = `${vNum2}`;
    const isNotEqual = parseInt(vNum1Str) > parseInt(vNum2Str);
    console.log({ isNotEqual, vNum1Str, vNum2Str, vNum1, vNum2 });
    return isNotEqual;
  } catch (e) {
    return false;
  }
};
