import {
  EDocumentStatus,
  EReceiptStatus,
  IReceipt,
  IUserPassport,
} from '../../api-store';
import { getActiveTripId } from '../../common';
import {
  getIsConfirmAddressDeclined,
  getIsFilledAddress,
  getIsNeedConfirmAddress,
} from '../../containers/AddDetails/documentsState';
import { IDefaultState } from '../../store/types';
import { every, find, get, size, some } from 'lodash';

export const getIsExistsRefundMethods = (state: IDefaultState) => {
  const paymentInfo = get(state, 'api.user.paymentInfo');
  return size(paymentInfo) > 0;
};

export const getDefaultRefundMethod = (state: IDefaultState) => {
  const paymentInfo = get(state, 'api.user.paymentInfo');
  return find(paymentInfo, m => m.isDefault);
};

export const getIsExistsUserPassport = (state: IDefaultState) => {
  const document = get(state, 'api.user.document');
  return !!document?.id;
};

export const getIsFilledUserPassport = (state: IDefaultState) => {
  const passportKeys: (keyof IUserPassport)[] = [
    'id',
    'firstName',
    'lastName',
    'number',
    'country.id' as any,
    'expiryDate',
    'birthDate',
  ];
  const document = get(state, 'api.user.document');
  return !!document && every(passportKeys, k => !!get(document, k));
};

export const getIsDeclinedUserPassport = (state: IDefaultState) => {
  const user = get(state, 'api.user');
  return (
    !!user?.document?.id && user.document.status === EDocumentStatus.DECLINED
  );
};

export const getIsValidUserPassport = (state: IDefaultState) => {
  const user = get(state, 'api.user');
  return (
    getIsExistsUserPassport(state) &&
    getIsFilledUserPassport(state) &&
    user?.document?.status === EDocumentStatus.VERIFIED
  );
};

export const getIsAcceptedReceipt = (state: IDefaultState) => {
  const tripId = getActiveTripId(state);
  const receipts = get(state, `api.user.trips.${tripId}.receipts`);
  const isAcceptedSomeReceipt = some(receipts, (v: IReceipt) => {
    return v.statusCode === EReceiptStatus.Accepted;
  });
  return isAcceptedSomeReceipt;
};

export const getIsMenuNotify = (state: IDefaultState) => {
  const isFilledPassport = getIsFilledUserPassport(state);
  const isInvalidAddress =
    !getIsFilledAddress(state.api.user) ||
    getIsConfirmAddressDeclined(state.api.user) ||
    getIsNeedConfirmAddress(state.api.user);

  const isExistsDefaultRefundMethod = !!getDefaultRefundMethod(state);
  return !isFilledPassport || isInvalidAddress || !isExistsDefaultRefundMethod;
};
