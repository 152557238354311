import { constants } from '../../common';
import { Address, Passport } from '../../containers/AddDetails';
import { AddressConfirmation } from '../../containers/AddDetails/Address/AddressConfirmation';
import { AddressConfirmationCamera } from '../../containers/AddDetails/Address/AddressConfirmationCamera';
import AddressConfirmationImagePreview from '../../containers/AddDetails/Address/AddressConfirmationImagePreview';
import { AddressDecline } from '../../containers/AddDetails/Address/AddressDecline';
import { PassportDecline } from '../../containers/AddDetails/Passport';
import { EmailUpdate } from '../../containers/EmailUpdate';
import { PasswordUpdate } from '../../containers/PasswordUpdate';
import { ReferralDashboard } from '../../containers/ReferralDashboard';
import MyRefundMethods from '../../containers/Refund/MyRefundMethods';
import {
  commonStackNavigatorConfig,
  transparentHeader
} from '../../navigation/config';
import * as SceneNames from '../../navigation/names';
import { INavigationScreenProps } from '../../navigation/types';

export default {
  [SceneNames.PassportInfoSceneName]: {
    screen: Passport,
    navigationOptions: (props: INavigationScreenProps) => ({
      title: constants.headers.myPassport,
      ...commonStackNavigatorConfig(props),
    }),
  },
  [SceneNames.PassportDeclineSceneName]: {
    screen: PassportDecline,
    navigationOptions: (props: INavigationScreenProps) => ({
      title: constants.headers.myPassport,
      ...commonStackNavigatorConfig(props),
    }),
  },
  [SceneNames.EmailUpdateSceneName]: {
    screen: EmailUpdate,
    navigationOptions: (props: INavigationScreenProps) => ({
      title: constants.headers.email,
      ...commonStackNavigatorConfig(props),
    }),
  },
  [SceneNames.PasswordUpdateSceneName]: {
    screen: PasswordUpdate,
    navigationOptions: (props: INavigationScreenProps) => ({
      title: constants.headers.password,
      ...commonStackNavigatorConfig(props),
    }),
  },
  [SceneNames.MyRefundMethodsSceneName]: {
    screen: MyRefundMethods,
    navigationOptions: (props: INavigationScreenProps) => ({
      title: constants.headers.myRefundMethods,
      ...commonStackNavigatorConfig(props),
    }),
  },
  [SceneNames.AddressSceneName]: {
    screen: Address,
    navigationOptions: (props: INavigationScreenProps) => ({
      title: constants.headers.addPermanentAddress,
      ...commonStackNavigatorConfig(props),
    }),
  },
  [SceneNames.AddressDeclineSceneName]: {
    screen: AddressDecline,
    navigationOptions: (props: INavigationScreenProps) => ({
      title: constants.nonEuResidenceTitle,
      ...commonStackNavigatorConfig(props),
    }),
  },
  [SceneNames.AddressConfirmationSceneName]: {
    screen: AddressConfirmation,
    navigationOptions: (props: INavigationScreenProps) => ({
      title: constants.nonEuResidenceTitle,
      ...commonStackNavigatorConfig(props),
    }),
  },
  [SceneNames.AddressConfirmationCameraSceneName]: {
    screen: AddressConfirmationCamera,
    navigationOptions: (props: INavigationScreenProps) => ({
      title: constants.nonEuResidenceTitle,
      ...transparentHeader,
    }),
  },
  [SceneNames.AddressConfirmationImagePreviewSceneName]: {
    screen: AddressConfirmationImagePreview,
    navigationOptions: {
      headerShown: false,
    },
  },
  [SceneNames.ReferralDashboardSceneName]: {
    screen: ReferralDashboard,
    navigationOptions: (props: INavigationScreenProps) => ({
      title: props.route.params?.title || constants.headers.referralDashboard,
      ...commonStackNavigatorConfig(props),
    }),
  },
};
