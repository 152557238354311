import { userInit } from '../../api-store';
import React from 'react';
import { AppState } from 'react-native';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';

export interface IAppStateUpdaterProps {
  userInit(): Promise<void>;
}

const AppStateUpdaterComponent: React.FC<IAppStateUpdaterProps> = (props) => {
  const appState = React.useRef(AppState.currentState);
  React.useEffect(() => {
    const subscription = AppState.addEventListener('change', (nextAppState) => {
      if (
        appState.current.match(/inactive|background/) &&
        nextAppState === 'active'
      ) {
        try {
          props.userInit();
        } catch(e) {
          console.error(e);
        }
      }

      appState.current = nextAppState;
    });

    return () => {
      subscription?.remove?.();
    };
  }, []);
  return null;
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      userInit,
    },
    dispatch
  );
};

export const AppStateUpdater = compose<any>(connect(null, mapDispatchToProps))(
  AppStateUpdaterComponent
);
