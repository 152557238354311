import { infoMail } from './common';

// const lang =
//   Platform.OS === 'ios'
//     ? NativeModules.SettingsManager.settings.AppleLocale ||
//       NativeModules.SettingsManager.settings.AppleLanguages[0]
//     : NativeModules.I18nManager.localeIdentifier;

// todo: translate
export const locale = 'en';
// /ru/i.test(lang) ? 'ru' : /zh/i.test(lang) ? 'zh' : 'en';

const constants = {
  configs: {
    deepLink: 'airvat://',
  },
  currency: {
    gbp: '£',
    eur: '€',
  },
  airvatEnFullName: 'Airvat ltd',
  airvatVatNumber: '00311079990',
  regexps: {
    empty: /^\w{1,}/i,
    number: /^\d{1,}$/,
    numberWithDot: /^[\d\,\.]{1,}$/,
    password: /^.{6,}$/i,
    city: /^[a-zA-Z-. ]{2,}$/,
    english:
      /[^a-zA-Z0-9\-\s\!\@\#\$\%\^\&\*\(\)\_\+\:\;\{\}\[\]\?\<\>\,\.\`\`\"\"\'\'\~\/\|\\\§]/,
    zipcode: /^[a-zA-Z0-9- ]{3,}$/,
    address: /^[a-zA-Z0-9-.,\/ ]{4,}$/,
    countryMaxName: /(\w+\s\w+)\s.*/,
    countryMinName: /\s.*/,
    email: /^(.*?)+\@(.*?)\.\w+$/i,
    bicOrSwift: /^([a-z0-9]{6,})$/i, // only 8 or 11 letters/digits
    accountNumber: /^\d{4,}$/i,
    cardExpiry: /^\d{2}\/\d{2}$/i,
    boldRegexDelimiter: /\*/,
    linkRegexDelimiter: /\#/,
    singleUrlRegex: /^https?:\/\/[\w.\/\-_]+$/i,
    urlRegex: /https?:\/\/[\w.\/\-_]+/gi,
    weChatId: /^[a-z][a-z0-9_\-]{5,19}$/i,
    referralCode: /^[A-Z0-9]{3,20}$/,
    flightNumber: /^[A-Z]{1,}[0-9]{3,5}$/i,
  },
  dateFormat: 'YYYY-MM-DD',
  browser: {
    main: `https://airvat.com/?mobile=true`,
    legal: `https://airvat.com/legal?mobile=true`,
    terms: `https://airvat.com/terms-and-conditions?mobile=true`,
    blogInvoices: `https://airvat.com/blog/invoices-in-the-name-of-airvat?mobile=true`,
  },
  site: 'https://airvat.com',
  validationErrors: {
    invalidCountry: 'Invalid country',
    name: 'Invalid name',
    surname: 'Invalid surname',
    email: {
      invalid: 'Invalid email address',
    },
    password: {
      invalid: 'Minimum 6 characters',
    },
    confirmPassword: {
      invalid: 'Passwords do not match',
    },
    referralCode: {
      invalid: 'Referral code do not match',
    },
    bicOrSwift: 'Invalid BIC or SWIFT number',
    accountNumber: 'Invalid bank account number',
    english: 'Use English keyboard',
    cardNumber: 'Invalid card number',
    cardExpiry: 'Invalid expiry date',
    weChatId: {
      invalid: `6-20 letters, numbers, '_' or '-', must begin with a letter`,
    },
  },
  headers: {
    email: 'Change email',
    more: 'More',
    faq: 'Frequent questions',
    refundCalculator: 'Refund calculator',
    rateUs: 'Rate Us',
    kbis: 'Voir ici Extrait Kbis',
    referralDashboard: 'Referral Dashboard',
    checkout: 'Show at the check-out',
    addTrip: 'Add Trip',
    masterCardAccount: 'Add MasterCard account',
    visaCardAccount: 'Add Visa card account',
    bankAccount: 'Add bank account',
    password: 'Change password',
    emailAccount: 'Email',
    customsInspection: 'Customs inspection',
    paypalAccount: 'Add PayPal account',
    getRefund: 'Authorize',
    myPassport: 'My Passport',
    mySelfie: 'My Selfie',
    myDetails: 'My Details',
    addReceipts: 'Invoices',
    myNonEUHome: 'My Non-EU Home',
    yourReceipts: 'Your Invoices',
    scanReceipt: 'Scan Invoice',
    refundMethod: 'Refund Method',
    selectCountry: 'Select Country',
    selectArrivalDate: 'Add arrival date',
    selectDepartureDate: 'Add departure date',
    myRefundMethods: 'My refund methods',
    addRefundMethod: 'Add refund method',
    generateTaxForms: 'Tax Free Form',
    taxform: 'Tax-free form',
    getTaxBack: 'Let’s get your tax back!',
    hello: 'Hello',
    termsConditions: 'Terms & conditions',
    privacyPolicy: 'Privacy policy',
    deleteAccount: 'Delete account',
    addPermanentAddress: 'Add your non-EU address',
    receiptImage: 'Invoice image',
    eligibleReceipts: 'Valid invoices',
    invalidReceipts: 'Invalid invoices',
    goodsEligible: 'Eligible goods',
    goodsNotEligible: 'Not eligible',
    howItWorks: 'How the app works',
    weChatPayAccount: 'Add WeChat Pay',
    faqFR: 'Tax-free shopping in France',
    'faqGB-NIR': 'Tax-free shopping in Northern Ireland (UK)',
  },
  transportNodes: {
    flyFrom: 'I am flying home from',
    departureFrom: 'I am departing from',
    cannotChangeModal: {
      title:
        'Once your departure date has passed, you can’t make changes to your departure point.',
      body: 'Would you like to amend your trip dates if you have not yet left the EU?',
    },
  },
  auth: {
    canceledAuth: 'Cancelled request',
    idTokenErrorAuth: 'Authorization error',
    alreadySignedUp: 'Already signed up? #Log in{goToLogin}#',
    alreadyHaveAnAccount: 'Already have an account? #Log in here#',
    dontHaveAnAccount: `Don't have an account? #Sign up here#`,
    forgotYourPassword: '#Forgot your password?#',
    orLoginWith: 'Or login with',
    sendForgotEmailSuccess: 'Please check your email to reset your password',
    sendVerifyEmailSuccess:
      'Please check your email to verify your details.\n\n' +
      'If there is no message in your inbox, look in your junk or spam folder.',
  },
  home: {
    onlyUK: `Only upload $$$ #valid invoices{goToAbove}# in the name of Airvat.\n\nShow this $$$ #invoice request screen{goToCheckout}# to the shop assistant before making purchases.`,
    startPage: 'Tell us about your trip before invoice uploading',
  },
  account: {
    closeNotifications: 'Got it',
    myAccountTabName: 'My account',
    myInfo: 'My info',
    settings: 'Settings',
    addressTabName: 'My non-EU address',
    refundMethodsTabName: 'Refund method',
    passportInfoTabName: 'Name & passport',
    refundSavings: 'Your total savings since joining',
    noNotifications: 'There are no new notifications for you. ',
    passwordValidError: 'Enter valid password',
    passwordResetConfirm: 'Are you sure you want to change your password?',
    emailUpdateConfirm:
      'A confirmation message will be sent to your new email address.\n\nAre you sure you would like to change your email?',
    emailValidError: 'Enter valid email',
    newEmail: 'New email',
    logout: 'Are you sure you want\nto log out?',
    delete:
      'Are you sure you would like to delete your account and leave AIRVAT?',
  },
  more: {
    faq: 'Frequently asked questions',
    refundCalculator: 'Refund calculator',
    rateUs: 'Rate Us!',
    referralDashboard: 'Referral dashboard',
    goodsAbove: 'Valid invoice',
    invalidReceipts: 'Invalid invoices',
    validReceipts: 'Valid invoices',
    checkout: 'How to request VAT invoices',
  },
  refundCalculator: {
    title: 'See how much you can save with us!',
  },
  rateUs: {
    title: 'How do you rate our app?',
    secondTitle: 'Would you like to tell us a little more?',
    icons: {
      rate1: 'Awful',
      rate2: 'Poor',
      rate3: 'Average',
      rate4: 'Good',
      rate5: 'Great!',
    },
    placeholder: 'Write 2 or 3 lines',
    buttonText: 'Send',
  },
  placeholders: {
    email: 'Email',
    country: 'Country',
    password: 'Password',
    name: 'Name',
    surname: 'Surname',
    cardNumber: 'Enter card number',
    transportNodes: 'Type your departure airport',
    countries: 'Type country name',
    cardExpiry: 'MM / YY',
    faq: 'Search help articles',
  },
  labels: {
    ok: 'OK',
    no: 'No',
    iVeUnderstood: 'I’VE UNDERSTOOD',
    addLater: '#Skip and add later#',
    yes: 'Yes',
    next: 'Next',
    save: 'SAVE',
    change: 'CHANGE',
    select: 'Select',
    cancel: 'Cancel',
    delete: 'Delete',
    signUp: 'Sign Up',
    logout: '#Log out#',
    password: 'Password',
    oldPassword: 'Old password',
    newPassword: 'New password',
    confirmPassword: 'Confirm password',
    referralCode: 'Referral code (optional)',
    referralCodePlaceholder: 'Do you have a referral code?',
    confirmNewPassword: 'Confirm new password',
    enterPassword: 'Enter your password',
    retake: 'RETAKE',
    passportNumber: 'Passport number',
    receiptInfo: 'Invoice details',
    addRefundMethod: 'Add refund method',
    weChatId: 'WeChat ID',
    weChatIdPlaceholder: 'wxid_yr88yl9y8n2s22',
    myTrips: 'My trips',
    agree: 'Agree',
    tapAgreeToAccept:
      'By signing up you accept\n#Terms & Conditions{goToTermsConditions}# and #Privacy Policy{goToPrivacyPolicy}#\nfor Airvat Ltd.',
    and: 'and',
    signUpHere: 'Sign up here',
    login: 'Log In',
    passwordPlaceholder: 'Type password',
    newPasswordPlaceholder: 'Type new password',
    confirmPasswordPlaceholder: 'Type confirm password',
    name: 'Name',
    email: 'Email',
    forgotPassEmail: 'Enter your email to reset your password',
    emailPlaceholder: 'example@mail.com',
    appVersion: 'App version',
    permanentAddress: 'My non-EU address',
    send: 'SEND',
    resend: 'RESEND',
    rejectionReason: 'Rejection reason',
    update: 'UPDATE',
    softUpdate: 'Airvat software update',
    softUpdateMessage:
      'You are using an old version of the application. Please update now',
    haveQuestion: '#Do you still have a question?#',
    needHelpCheckout: '#Need help at the check-out?#',
  },
  myDetails: {
    cityLabel: 'City',
    countryLabel: 'Country',
    surnameLabel: 'Surname',
    zipCodeLabel: 'Zip Code',
    declinedLabel: 'Declined',
    verifiedLabel: 'Verified',
    issuedByLabel: 'Issued By',
    passportLabel: 'Passport',
    firstNameLabel: 'First Name',
    verifyingLabel: 'Verifying...',
    enterCityPlaceholder: 'Type your city',
    invalidCity: 'Invalid city',
    phoneLabel: 'Phone number',
    addressLineOneLabel: 'Address line 1',
    invalidAddress: 'Invalid address',
    invalidPhone: 'Invalid phone number',
    passportNumberLabel: 'Passport Number',
    birthDateLabel: 'Birth Date',
    expiryDateLabel: 'Expiry Date',
    enterAddressPlaceholder: 'Type your address',
    enterPhonePlaceholder: 'Type your phone number',
    enterZipcodePlaceholder: 'Type your zip code',
    invalidZipCode: 'Must contain min 3 characters',
    chooseCountryPlaceholder: 'Choose Your Country',
    addressLineTwoLabel: 'Address line 2',
    emptyPageLabel:
      'Have your Passport ready! We need to do a security check and get your address details!',
  },
  passportCameraPlaceholderTitle: 'Take a picture of your passport',
  passportCameraPlaceholderMessage:
    'Make sure the photo page is readable and well lit. No photocopies.',
  passportCameraPageTitle: 'Passport photo page',
  passportCameraPageMessage: 'Position the page with your photo in the frame',
  passportPreviewPageMessage:
    'Make sure your passport details are clear to read, with no blur or glare',
  selfieCameraPageTitle: 'Selfie photo page',
  selfieCameraPageMessage: 'Make sure your selfie clearly shows your face',
  selfieCameraPlaceholderTitle: 'Take a picture of your face',
  selfieCameraPlaceholderMessage:
    'Remove any obstructions such as glasses or hats and position your face inside the circle.\n\nWe will compare your face with the photo in the passport.',
  selfiePreviewPageMessage: 'Make sure your selfie clearly shows your face',
  receipt: {
    date: 'Date',
    uploading: 'Uploading...',
    declined: 'Declined',
    verified: 'Verified',
    verifying: 'Verifying...',
    pending: 'Pending...',
    underReview: 'Under review...',
    refundAmount: 'Refund Amount',
    receiptPhoto: 'Invoice image',
    receiptReference: 'Reference',
    processingReceipt: 'Processing invoice',
    checkout: '#Need help at the check-out?#',
    whatReceiptAbove250: '#What if my invoice is above £250?#',
    eligibleTopText:
      'Please take a full clear picture of the invoice, capturing all four corners, and enter the correct goods description for each purchase item. Each invoice must be below £250 and contain the following information:',
    eligibleText: [
      'VAT rate or amount',
      'Retailer’s name, address, and VAT number',
      'Invoice date and number',
      'Goods description and quantity',
    ],
    eligibleMiddleText:
      'If your purchase is above £250 or the invoice has missing information, request a #full VAT invoice{goToAbove}# from the store.',
    eligibleMiddleTitle: 'What purchases are NOT accepted?',
    within4hours: `Most invoices are processed within 4 hours, however, this can take longer during busy periods or outside of usual business hours.`,
    within24hours: `In the unlikely event that your invoices have not been processed within 24 hours, or you have an imminent departure, please contact us.`,
    withInfo: `Show this $$$ #invoice request screen{goToCheckout}# to the shop assistant before making purchases.`,
    onlyValidReceipts: `Only upload $$$ #valid invoices{goToAbove}# in the name of Airvat.`,
    tellUs: `Please tell us if you have any questions #${infoMail}{openChat}#`,
    cannotChangeModal: {
      title:
        'Once your departure date has passed, you can no longer add invoices.',
      body: 'Would you like to amend your trip dates if you have not yet left the EU?',
    },
    agreement: [
      'I have a #valid invoice{goToAbove}# issued in the name of ',
      'Airvat, 10 rue de Penthièvre, 75008, Paris',
    ],
    agreementBe: [
      'I have a #valid invoice{goToAbove}# issued in the name of ',
      'Airvat',
    ],
    agreementGbNir: [
      'I have a #valid invoice{goToAbove}# issued in the name of ',
      'Airvat ltd, 20-22 Wenlock Road, London, N1 7GU, England, VAT no. 312092250',
    ],
    eligibleItems: [
      'Any single item below £30',
      'Internet shopping',
      'Food and drinks',
      'Children’s clothes',
      'Services (e.g. hotel and restaurant bills, tickets)',
      'Invoices dated prior to registering your Airvat account',
    ],
  },

  refund: {
    placeholders: {
      accountHolderName: 'Type name',
      accountHolderSurname: 'Type surname',
      accountNumber: 'Type Account or IBAN number',
      swiftCode: 'Type BIC or SWIFT',
      countryName: 'Select country...',
    },
    month: 'Month',
    year: 'Year',
    cardNumber: 'Card number',
    swiftCode: 'BIC or SWIFT',
    accountNumber: 'Account or IBAN number',
    cardExpiry: 'Card expiry',
    accountHolderName: 'Name',
    accountHolderSurname: 'Surname',
    addFirstRefundMethod:
      'How would you prefer to get paid? Add your refund method',
    refundMethodDescription: `We do not offer cash refunds at the airport.\n\nYour refund payment will be paid directly to your selected refund method after your tax-free form has been validated by Customs.`,
    ensureBankAccountIsLinkedToAddress:
      'Ensure this bank account is linked to your home address',
    sureToDelete: {
      title: null,
      body: 'Are you sure you would like to delete this refund method?',
    },
    ensureCardIsLinkedToNameAndAddress:
      'Ensure this card is linked to your permanent non-EU address and Passport name. Card details are encrypted and stored securely.',
  },
  taxforms: {
    cannotChangeModal: {
      title: 'You cannot send or view tax free form after your departure date',
      body: 'Would you like to amend your trip dates if you have not yet left the EU?',
    },
    taxformInfoItems: [
      'A tax-free form, also known as a VAT refund form, is a simplified export declaration.',
      'The tax-free form contains some officially required information, such as your passport details, home address, and trip details, which you have to provide when registering your Airvat account.',
      'Airvat app will generate an electronic tax-free form in the app listing all of your verified purchases after you have finished shopping. There will also be a corresponding invoice attached to the tax-free form.',
      'To validate your refund simply scan the tax-free form’s barcode from your phone at self-service PABLO kiosks located in all the international transportNodes and train stations, or Calais Eurotunnel and ferry port.',
      `If France is not your final exit point from the EU, or if there is no PABLO kiosk at your departure point (such as some road border crossings), you will need to print your tax-free form and get a manual Customs stamp instead.`,
      'Ensure that the information listed in the tax-free form is accurate as it can be checked together with your purchases if you are selected for a Customs inspection.',
    ],
    taxformInfoItemsGbNir: [
      'A tax-free form or a VAT refund form (formally known as the VAT407 form in the UK), is a simplified export declaration.',
      'The tax-free form contains some officially required information, such as your passport details, home address, and trip details, which you have to provide when registering your Airvat account.',
      'Airvat app will generate an electronic tax-free form in the app listing all of your verified purchases after you have finished shopping.',
      'Ensure that the information listed in the tax-free form is accurate as it is likely to be checked by Customs.',
      'You will need to print, date and sign your Airvat tax free form before presenting it to Customs officers at your departure point to obtain a stamp.',
      'Attach the correct postage and send the stamped original form to our office before we can issue the refund payment.',
    ],
    generatingTaxForm:
      'Generating your\ntax-free form.\n\nCheck back in 1-5 min.\n\n#Contact support{openChat}#\nif your wait\nis much longer.',
    generatingTaxFormCustomsItSystem: `Generating your electronic tax-free form. This usually takes up to 30 min.\n\nOn the rare occasion that the Customs IT system is not responsive for over 30 min, we will automatically issue an emergency back-up tax-free for you to print out.\n\n#Contact support{openChat}# if your wait is much longer or you have questions.`,
    requestTaxFormDisabled:
      'Before being able to print your tax free form, have your invoices and passport verified, and chose a payment method',
    authoriseForm:
      'Plan ahead for your departure! Read our airport instructions on how to get your tax form stamped in your terminal before flying home.',
    sendFormTo: 'Send my tax free from to',
    formSentTo: 'Tax free form has been sent to',
    viewForm: '#View tax free form#',
    helpText:
      'Wondering where to print your tax free form?\n\nAsk at your hotel reception, or search online to find the nearest “print shop”, “internet cafe”, Ryman stationery shop, or Snappy Snaps printing specialist.',
  },
  trip: {
    deleteWarning: 'Are you sure you would like to delete this trip?',
    // Status to text
    incomplete: 'incomplete',
    stamp_the_form: 'validate your form',
    awaiting_form: 'awaiting stamped form',
    form_stamped: 'awaiting payment',
    form_rejected: 'tax form rejected',
    refunded: 'refunded',

    notSelectedTrip:
      'Your trip has been completed and cannot be edited any more',
    notAddedTrip:
      'You can only have one active trip at any one time.\n\nBefore a new trip can be started, please complete or delete your current trip',
    overDepartureDate: {
      title: 'You cannot edit your trip after your departure date',
      body: 'Would you like to amend your trip dates if you have not yet left the EU?',
    },
    helperStatusTextIncomplete: 'Your trip details are incomplete',
    helperStatusTextStamped: 'Get your tax free form stamped by Customs',
    helperStatusTextAwaiting: 'We’re are awaiting your stamped tax free form',
  },
  alerts: {
    passportData:
      'Before you can add your preferred refund method, you need to have your Passport verified and your non-EU address entered in ‘MY PROFILE’ section',
    notFilledPassport:
      'You need to add you Passport information before being able to uploading your first invoice',
    notFilledAddress:
      'You need to add your non-EU address information before generating the tax-free from',
    notFilledBelgiumFields:
      'You need to add your trip optional information before generating the tax-free from',
    notExistsRefundMethod:
      'You need to add a refund method before generating the tax-free from',
    appInitError: 'Application launch error',
    noInternet: 'No Internet Connection',
    deleteReceipt: {
      message: 'Are you sure you would like to delete this invoice?',
    },
    someError: {
      message: 'Oops! Something went wrong. Please try again.',
    },
    socProvider:
      'You have registered with your social media account. To make changes to your email or password go to your social media account',
  },
  accountTitles: {
    bankAccount: 'Bank account',
    card: 'Card',
    mastercard: 'MasterCard',
    paypal: 'PayPal',
    alipay: 'Alipay',
    unionPay: 'UnionPay',
    weChat: 'WeChat Pay',
    visa: 'Visa',
    visaElectron: 'Visa (Electron)',
  },
  agreement: {
    notEUResident:
      'I am a non-EU resident who is visiting the EU for less than 6 months',
    obtainVAT:
      'I will obtain a $$$ #valid invoices{goToAbove}# in the name of Airvat for each purchase',
    months:
      'I will return home by the end of the 3rd month following the month of purchase',
    unusedGoods:
      'My shopping will be still unused and available for a #Customs inspection{goToCustomsInspect}# before departure',
  },
  updating: 'Updating...',
  loading: 'Loading...',
  takePhotoPassport: 'Take a photo of your Passport',
  takeSelfieIdentity: 'Take a selfie to verify your identity',
  tellPermanentNonEuAddress: 'Tell us your permanent non-EU home address',
  airvatAddress: `Airvat ltd\n20-22 Wenlock Road,\nLondon, N1 7GU, UK\nUnited Kingdom`,
  airvatFullAddress: `Airvat ltd\n20-22 Wenlock Road,\nLondon, N1 7GU, UK\nUnited Kingdom\nVAT no. 312 0922 50`,
  shop: 'Shop',
  snap: 'Snap',
  signUp: 'Sign Up',
  print: 'Print',
  stamp: 'Stamp',
  orStamp: '…or Stamp',
  generate: 'Generate',
  validate: 'Validate',
  receive: 'Receive',
  shopMessages: [
    `Make purchases, in person or on-line, in any French store and make sure to obtain an invoice in the name of Airvat.\nShow this $$$ #invoice request screen{goToCheckout}# to the shop assistant before paying. Don’t Ask for the shop’s own VAT refund form.`,
  ],
  shopMessagesGbNir: [
    `Make purchases, in person or on-line, in any store in Northern Ireland and make sure to obtain a full VAT invoice in the name of Airvat. Show this $$$ #invoice request screen{goToCheckout}# to the shop assistant before paying. Don’t Ask for the shop’s own VAT refund form.`,
  ],
  snapMessages: [
    `Take pictures of all your $$$ #valid invoices{goToAbove}# as early as possible. Check that each invoice meets our requirements before uploading them into the app.`,
  ],
  passportMessages: [
    `Scan your passport! We need to collect some officially required information about you to check eligibility. Setting up your Airvat account is quick and easy.`,
  ],
  finishedShopping: 'Finished shopping?',
  printMessages: [
    `Finished shopping? Generate an electronic #tax-free form{goToTaxFreeFormInfo}# in the app. You will need to print, date and sign your Airvat tax free form before going to the airport.`,
  ],
  generateMessages: [
    `Finished shopping? Generate an electronic #tax-free form{goToTaxFreeFormInfo}# before departure, which will display a barcode in the app. If France is not your final exit point from the EU, you will need to print the tax-free form and get a manual Customs stamp instead.`,
  ],
  validateMessages: [
    `Scan the barcode from your phone at a self-service PABLO kiosk located in all the international transportNodes and train stations, or Calais Eurotunnel and ferry port. Occasionally you may be selected for a #Customs inspection.{goToCustomsInspect}#`,
  ],
  generateMessagesShort: [
    'Generate an electronic #tax-free form{goToTaxFreeFormInfo}# barcode as early as possible and ideally at least 12 hours before your departure. ',
  ],
  validateMessagesShort: [
    `Scan the barcode from your phone at a PABLO kiosk at any international airport or train station in France, and Calais Eurotunnel or ferry.`,
  ],
  validateMessagesShortBe: [
    `Show your passport at the customs office. The customs officer will validate your electronic tax-free forms from their terminal (self-service kiosks are not yet in service in Belgium).`,
  ],
  stampMessages: [
    `If there is no self-service PABLO kiosk at your departure point, head to the Customs desk to get a manual stamp on your printed tax-free form.`,
  ],
  stampMessagesBe: [
    `Print your Airvat tax free form and get it stamped by Customs at your departure point from the EU`,
  ],
  stampGbMessages: [
    `Ensure that you have all your unused shopping ready and proceed to get a customs stamp before your departure from Northern Ireland, or the EU, if Northern Ireland is not your last exit point from the EU. Occasionally you may be selected for a #Customs inspection{goToCustomsInspect}#.`,
  ],
  orStampMessages: [
    `If France is not your final exit point from the EU or there is no PABLO kiosk, print the tax-free form to get a manual stamp instead.`,
  ],
  receiveMessage: [
    `Get your refund paid directly into your bank account without any hidden charges or exchange rate mark-ups. Say goodbye to high fees charged by retailers!`,
  ],
  receiveMessageGbNir: [
    `Send us the original stamped form. Your VAT refund will be paid directly to your bank account without any hidden fees or exchange rate mark-up.`,
  ],
  airvatInfoCustomsInspection: [
    `You will need to have your tax-free form authorised by Customs before leaving the EU.`,
    `It can be done either digitally at a self-service PABLO kiosk, or by getting a manual stamp on your printed tax-free form if France is not your final departure point from the EU.`,
    'In either case, Customs officers may need to see your travel documents and goods before validating the refund. If you are asked to show the purchase invoice, it will be attached to the tax-free form in the app.',
    'If you hold an EU passport but permanently live outside the EU, you will need to show your proof of non-EU residency, such as residence permit, green card, consular registration, recent utility bill, etc.',
    'Arrive in good time at your departure point to complete the authorization before checking-in any luggage.',
    'Make sure to have all your shopping still unused and in its original packaging as listed on the tax-free form.',
    'Customs will invalidate the entire refund if you part consume or fail to show any of the items listed on your tax-free form.',
    'It is a serious offence to make fraudulent VAT refund claims, which can result in missed departures, goods confiscation, or penalties of up to €1,500. French Customs carry out over 220,000 spot checks each year to prevent VAT refund fraud. Don’t take chances!',
  ],
  airvatInfoCustomsInspectionGbNir: [
    `You will need to have your printed tax-free form stamped by Customs before leaving Northern Ireland, or the EU, if Northern Ireland (UK) is not your last exit point from the EU.`,
    `If you hold a UK or EU passport but permanently live outside of Northern Ireland or the EU, you may need to show proof of your non-EU residency, such as residence permit, green card, consular registration, etc.`,
    `Arrive in good time at your departure point to complete the authorization before checking-in any luggage.`,
    `Make sure to have all your shopping still unused and in its original packaging as listed on the tax-free form.`,
    `Customs will invalidate the entire refund if you part consume or fail to show any of the items listed on your tax-free form.`,
    `It is a serious offence to make fraudulent VAT refund claims, which can result in missed departures, goods confiscation, or penalties. Don’t take chances!`,
  ],
  airvatInfoCustomsInspectionImportantTitle: 'Important!',
  airvatInfoCustomsInspectionImportantMessages: [
    `Customs will invalidate your entire refund if you fail to show any of the items listed on your tax form.`,
    `Do not attempt to make invalid VAT refund claims as defrauding Customs is a serious offence and can result in penalties or your goods being confiscated.`,
  ],
  clothes: 'Clothes',
  jewellery: 'Jewellery',
  cosmetics: 'Cosmetics',
  electronics: 'Electronics',
  airvatInfoGoodsSlidesEligible: [
    `Goods purchased in physical retail stores only (i.e. not online shops);`,
    `Goods must be for personal use and taken back to your home country in your luggage within 3 months of purchase;`,
    `Your shopping must be unused and still in its original packing when leaving the EU.`,
  ],
  airvatInfoGoodsSlidesNotEligible: [
    `If an in-store tax refund form has already been issued;`,
    `Goods have been used or not available for Customs inspection at the airport;`,
    `Anything that you cannot actually take back home with you such as hotel stays, concert performances, transport journeys, restaurant meals, car rentals, gas, etc.`,
  ],
  onlineShopping: 'Online\nshopping',
  books: 'Books',
  childrenClothes: 'Children\nclothes',
  tickets: 'Tickets',
  airvatInfoInvalidReceipts: [
    {
      title: 'If an in-store tax refund form has already been issued',
      items: [
        `You will not be able to claim your refund via the Airvat app if the store has already issued their own tax refund form to you.`,
        `If you have been issued an in-store tax form but instead would like to receive a refund via Airvat, you will have to return the in-store tax form to the shop and exchange it for an ordinary till invoice. This till invoice can then be uploaded into the Airvat app.`,
        `Retailers use companies such as Global Blue and Planet Tax Free to issue tax forms at the till when requested by customers.`,
        `In-store VAT refund forms will usually be printed as till invoices but they will also state “Tax Free” or the name of the refund company, and may be stamped “Tax Free Form Issued” or “VAT Exports”.`,
      ],
    },
    {
      title: 'Incomplete details',
      items: [
        `Not all invoices contain the information required for a VAT refund, e.g. basic invoices from credit card transportNodes.`,
        `Remember to check that the invoice includes the shop name and address, goods description, and VAT rate before uploading it into the app.`,
      ],
    },
    {
      title: 'Supermarket and food invoices',
      items: [
        `Basic food items purchased from supermarkets usually do not have VAT and are generally intended for consumption after purchase, which is why they are not eligible for VAT refund. This includes items such as tea and coffee.`,
        `However, you are allowed to get VAT refund on chocolates and other sweets if you present a valid invoice showing the VAT rate.`,
      ],
    },
    {
      title: 'Goods without VAT',
      items: [`Books`, `Childrens clothes`, `Tea and coffee`],
    },
    {
      title: 'Services (i.e. anything that you can’t take back home!)',
      items: [
        `Tickets`,
        `Hotel bills`,
        `Restaurant bills`,
        `Car rentals`,
        `Gas`,
      ],
    },
    {
      title: 'Purchases not made in person in store',
      items: [`Online shopping`, `Click & Collect`, `Postal orders`],
    },
    {
      title: 'Out of date invoices',
      items: [
        `Invoices dated prior to opening your Airvat account or your arrival date in the EU.`,
        `Invoices uploaded into the app over 14 days after they are purchased.`,
      ],
    },
  ],
  emailPassword: 'Email my new Password',
  updatingData: 'Updating Data',
  cameraPermissions: {
    title: 'Camera Disabled',
    message:
      'Go to your phone settings to enable the camera use for Airvat application.',
    messageWeb:
      'Your device does not have a camera or access is denied.',
    reject: 'Cancel',
    resolve: 'Go To Settings',
  },
  permissions: {
    camera: {
      title: 'Permission to use camera',
      message: 'We need your permission to use your camera phone',
    },
  },
  refCodeInfo: `🥳👏🏽🎁🎉 Share your referral code and receive €:reward when your friend has completed their refund with Airvat!`,
  refCodeShare: `Hi\n\nCheck out this great tax-free shopping app that I’m using!\n\nDownload the Airvat tax refund app here :site and enter my referral code :code\n\nTax-free shopping is now easy!`,
  refCodeShareTitle: 'Referral code',
  important: 'Important!',
  receiptAgree: 'Is your invoice valid?',

  // todo: translate
  airline: 'Airline',
  arrivalDepartureDate: 'EU arrival & departure dates',
  flightNumber: 'Flight number',
  flightNumberPlaceholder: 'XX9999',
  flightNumberError: 'We are not able to locate your flight number',
  flightNumberErrorText: `Please try again to enter correct details or email your flight number to #${infoMail}{openChat}# for verification.`,
  selectShopName: 'Select shop name',
  selectShopNamePlaceholder: 'Enter invoice shop name',
  errorSearch: 'No results for your search',
  receiptAmount: 'Invoice Amount',
  sendForApproval: 'SEND FOR APPROVAL',
  edit: 'EDIT',
  addItemFromReceipt: 'ADD ITEM FROM THE RECEIPT',
  addReceiptItem: 'Add invoice item',
  receiptItemDescription: 'Goods description',
  selectReceiptItemDescription: 'Select goods description',
  invalidReceiptItemDescription: 'Invalid goods description',
  material: 'Material',
  selectMaterial: 'Select material',
  invalidMaterial: 'Invalid material',
  receiptItemPrice: 'Price per item including discounts',
  typeItemPrice: 'Type price',
  invalidPrice: 'Invalid price',
  quantity: 'Quantity',
  receiptDate: 'Invoice date',
  receiptIncomplete: 'Incomplete',
  other: 'other',
  addReceipts: 'ADD INVOICES',
  viewForm: 'VIEW YOUR TAX-FREE FORM',
  uploadForm: 'UPLOAD YOUR STAMPED FORM',
  taxform: 'Tax Form',
  shareTaxFormMessage: 'Share your tax form',
  yourTripDetails: 'Your trip details',
  refundBreakdown: 'Refund breakdown',
  refundBreakdownItems: {
    invoiceAmount: 'Your invoice value',
    vat: 'VAT',
    fee: 'Our fee',
    totalRefund: 'Your refund',
  },
  refundBreakdownInfo:
    'Our goal is to make tax refunds simple and stress-free, so you save money and time. To do this, we charge a single, transparent fee which is deducted from your refund amount.',
  enterPassportNumber: 'Type passport number',
  invalidPassportNumber: 'Invalid passport number',
  enterBirthDate: 'Type birth date',
  enterExpiryDate: 'Type expiry date',
  invalidBirthDate: 'Invalid birth date',
  invalidExpiryDate: 'Invalid expiry date',
  france: 'France',
  northernIreland: 'Northern Ireland',
  belgium: 'Belgium',
  airportInstructionsGbNir: [
    {
      title: 'What to do next',
      textItems: [
        'Print, date and sign your Airvat tax free form before heading to the airport.',
        'Get a Customs stamp before your departure from Northern Ireland (UK), or the EU, if Northern Ireland (UK) is not your last exit point from the EU.',
        'Make sure to have all your unused shopping as listed on your Airvat tax-free form.',
        'Arrive at your departure point in good time to complete Customs authorization before checking-in any luggage.',
      ],
    },
    {
      title: 'When do I receive my refund',
      textItems: [
        'Your refund will be paid as soon as Airvat receives your original stamped tax form in our office.',
        'Ensure to pay the correct postage and send the original stamped form to us:',
        [
          { bold: true, type: 'H1Bold', text: 'Airvat ltd' },
          { bold: true, type: 'H1Bold', text: '5 Campden House' },
          { bold: true, type: 'H1Bold', text: '29 Sheffield Terrace' },
          { bold: true, type: 'H1Bold', text: 'London, W8 7NE' },
          {
            bold: true,
            type: 'H1Bold',
            text: 'United Kingdom',
            style: { marginBottom: 20 },
          },
        ],
        'That’s it, we will notify you by email when your refund is on its way.',
        '#Upload here{uploadStampedForm}# your manually stamped tax-free form. Take a full clear image of each page of the stamped form or upload a pdf scan into the app before we can refund your tax.',
      ],
    },
    {
      title: 'Important',
      textItems: [
        'Customs will invalidate the entire refund if you part consume or fail to show any of the items listed on your tax form.',
        'It is a serious offence to make fraudulent VAT refund claims, which can result in missed departures, goods confiscation, or penalties. Don’t take chances!',
      ],
    },
  ],
  airportInstructionsBe: [
    {
      title: 'What to do next',
      textItems: [
        {
          title: `Show your passport at the customs office before your departure if you are leaving from one of the below:`,
          textItems: [
            `Antwerp International Airport (ANR)`,
            `Brussels (Zaventem) Airport (BRU)`,
            `Brussels South Charleroi Airport (Gosselies) (CRL)`,
            `Flanders International Airport (Kortrijk-Wevelgem) (KJK)`,
            `Internationale Luchthaven Oostende-Brugge (OST)`,
            `Liege-Bierset Airport (LGG)`,
          ],
        },
        'The customs officer will validate your electronic tax-free forms from their terminal (self-service kiosks are not yet in service in Belgium).',
        'Arrive in good time to complete the authorization before checking-in any luggage.',
        'Make sure to have all your shopping still unused and in its original packaging as listed on the tax-free form.',
        'That’s it, we will notify you by email when your refund is on its way.',
      ],
    },
    {
      title: 'Do I need to print the tax-free form',
      textItems: [
        'If Belgium is not your final exit point from the EU, or if your departure point is not listed above (such as some road or rail border crossings), you will need to print your tax-free form and get a manual Customs stamp instead.',
        '#Upload here{uploadStampedForm}# your manually stamped tax-free form. Take a full clear image of each page of the stamped form or upload a pdf scan into the app before we can refund your tax.',
      ],
    },
    {
      title: 'Important',
      textItems: [
        'Customs will invalidate the entire refund if you part consume or fail to show any of the items listed on your tax form.',
        'It is a serious offence to make fraudulent VAT refund claims, which can result in missed departures, goods confiscation, or penalties of up to €1,500. French Customs carry out over 220,000 spot checks each year to prevent VAT refund fraud. Don’t take chances!',
      ],
    },
  ],
  airportInstructionsFr: [
    {
      title: 'What to do next',
      textItems: [
        'Simply scan the barcode from your phone at a self-service PABLO kiosk before departure from France to validate your refund.',
        'PABLO kiosks are located in all the French international transportNodes and train stations, Calais Eurotunnel and ferry port.',
        'Follow signs for “tax refund / detax”. Arrive in good time to complete the authorization before checking-in any luggage.',
        'Make sure to have all your shopping still unused and in its original packaging as listed on the tax-free form.',
        'PABLO will either authorise your refund, or occasionally request to proceed to the Customs desk for inspection.',
        'That’s it, we will notify you by email when your refund is on its way.',
      ],
    },
    {
      title: 'Do I need to print the tax-free form',
      textItems: [
        'If France is not your final exit point from the EU, or if there is no PABLO kiosk at your departure point (such as some road border crossings), you will need to print your tax-free form and get a manual Customs stamp instead.',
        'Having a printed copy of your tax-free form can also be helpful in case there is a technical problem with PABLO barcode readers at your departure point in France so that Customs officers can stamp it manually instead.',
        '#Upload here{uploadStampedForm}# your manually stamped tax-free form. Take a full clear image of each page of the stamped form or upload a pdf scan into the app before we can refund your tax.',
      ],
    },
    {
      title: 'Important',
      textItems: [
        'Customs will invalidate the entire refund if you part consume or fail to show any of the items listed on your tax form.',
        'It is a serious offence to make fraudulent VAT refund claims, which can result in missed departures, goods confiscation, or penalties of up to €1,500. French Customs carry out over 220,000 spot checks each year to prevent VAT refund fraud. Don’t take chances!',
      ],
    },
  ],
  airportInstructionsOtherFR: [
    {
      title: 'What to do next',
      textItems: [
        'Print your Airvat tax-free form before going to your departure point.',
        'If France is not your final exit point from the EU, or if there is no PABLO kiosk at your departure point (such as some road border crossings), you will need to get a manual Customs stamp.',
        'Follow signs for “VAT refund / Customs”. Arrive at your departure point in good time to complete the authorization at the Customs desk before checking-in any luggage.',
        'Make sure to have all your shopping still unused and in its original packaging as listed on the tax-free form.',
        '#Upload here{uploadStampedForm}# your manually stamped tax-free form. Take a full clear image of each page of the stamped form or upload a pdf scan into the app before we can refund your tax.',
        'That’s it, we will notify you by email when your refund is on its way.',
      ],
    },
    {
      title: 'Important',
      textItems: [
        'Customs will invalidate the entire refund if you part consume or fail to show any of the items listed on your tax form.',
        'It is a serious offence to make fraudulent VAT refund claims, which can result in missed departures, goods confiscation, or penalties of up to €1,500. French Customs carry out over 220,000 spot checks each year to prevent VAT refund fraud. Don’t take chances!',
      ],
    },
  ],
  airportInstructionsNoPabloFR: [
    {
      title: 'What to do next',
      textItems: [
        'Due to a technical problem with the Customs electronic system, we have not been able to generate a PABLO barcode for you.',
        'You will need to print your Airvat tax-free form before going to your departure point in order to get a manual Customs stamp.',
        'Follow signs for “VAT refund / Customs”. Arrive at your departure point in good time to complete the authorization at the Customs desk before checking-in any luggage.',
        'Make sure to have all your shopping still unused and in its original packaging as listed on the tax-free form.',
        '#Upload here{uploadStampedForm}# your manually stamped tax-free form. Take a full clear image of each page of the stamped form or upload a pdf scan into the app before we can refund your tax.',
        'That’s it, we will notify you by email when your refund is on its way.',
      ],
    },
    {
      title: 'Important',
      textItems: [
        'Customs will invalidate the entire refund if you part consume or fail to show any of the items listed on your tax form.',
        'It is a serious offence to make fraudulent VAT refund claims, which can result in missed departures, goods confiscation, or penalties of up to €1,500. French Customs carry out over 220,000 spot checks each year to prevent VAT refund fraud. Don’t take chances!',
      ],
    },
  ],
  receiptItemRejectMessage: 'Item not eligible for VAT refund',
  warning: 'Warning!',
  invalidReceiptStoreText: `This store’s till invoices do not show full information required to qualify for a VAT refund.\n\nPlease only upload a #full VAT invoice{goToAbove}# addressed:`,
  invalidReceiptStore: 'VAT invoice required',

  flightNumberInfo:
    'Please ensure to state the correct flight number for your departure out of the UK so that Customs are able to validate your tax-free form at the airport',
  selectTripDepartureCountry: 'Select Shopping Destination',
  tripDepartureCountry: 'Shopping Destination',
  selectArrivalDepartureDates: 'Arrival & departure dates',
  income: 'Income',
  howCanHelpYou: 'How can we help you?',
  nonEuResidenceTitle: 'Non-EU residency',
  nonEuResidenceText: {
    text: 'Because you hold an EU passport, it is required to provide proof of residency outside the EU. Could you please provide one of the following:',
    list: [
      'a copy of a non-EU residence permit, green card, consular registration, etc.',
      'a utility bill dated within the last 3 months',
      'a bank statement dated within the last 3 months',
    ],
  },
  nonEuResidenceTextFr:
    'Because you hold a French passport, it is required to provide proof of residency outside the EU by uploading your consular registration document.',
  uploadFile: 'Upload pdf file',
  takePhoto: 'Take photo',
  tripDetails: 'Trip details',
  editTrip: 'Edit trip',
  selectMinOnePhoto: 'Please select at least 1 image',
  invalidDocument:
    'Your document has been rejected. Please read carefully instructions in the app and upload a valid document, or contact the support team if you have any questions.',
  chooseAction: 'Choose action',
  addDocument: 'Add document',
  addInvoice: 'Add invoice',
  showInShop: 'Show in shop',
  addNewTrip: 'Add new trip',
  generateTaxForms: 'Generate tax free form',
  deleteInvoice: 'Delete invoice',
  information: 'Information',
  myProfile: 'My profile',
  menu: 'Menu',
  tutorial: 'Tutorial',
  whatIsValidInvoice: 'What is a valid invoice',
  faqShort: 'FAQ',
  invoiceRequestScreen: 'Invoice request screen',
  homeAddress: 'Home address',
  aboutUs: 'About us',
  accountLabel: 'Account',
  privacySecurity: 'Privacy & security',
  passport: 'Passport',
  termsAndConditions: 'Terms and conditions',
  confirm: 'Confirm',
  addPassport: 'Add passport',
  takeSelfie: 'Take selfie',
  tryAgain: 'Try again',
  selectFromGallery: 'Select from gallery',
  barcodeNotScanning: 'Barcode not scanning? #Enlarge barcode#',
  barcodeScan: 'Barcode scan',
  scanStampedForm: 'Scan Stamped Form',
  pleaseScroll: 'Please scroll down to read the full article',
  confirmAddress: 'Confirm address',
  goToUploadDocument: 'Upload document?',
  nonEuResidenceTextAlert:
    'Because you hold an EU passport, the regulation requires you to provide additional proof of your non-EU residency e.g. foreign visa, residence permit, consular registration certificate, green card, or utility bill issued no older than 3 months.',
  nonEuResidenceTextAlertFr:
    'Because you hold a French passport, the regulation requires you to provide proof of consular registration outside the EU.',
  sendToEmailOrPrinter: 'Send to email or printer',
  updatePassportAndAddress: 'Please update your passport and address details',
  updatePassport: 'Update passport',
  haveYourPassportOrAddressChanged:
    'Has your passport number\n*:passportNumber*\nor home address\n*:homeAddress*\nchanged since your last trip?',
  invalidArrivalDepartureDate: 'Invalid EU arrival & departure dates',
  departureFrom: 'Departure From',
  selectDepartureFrom: 'Select Departure From...',
  invalidDepartureFrom: 'Invalid Departure From',
  estimatedDateAndTimeOfDepartureLabel: 'Estimated Time Of Departure',
  enterEstimatedDateAndTimeOfDeparture: 'Select Time...',
  invalidEstimatedDateAndTimeOfDeparture: 'Invalid Estimated Time',
  outPuntLabel: 'Place are you leaving the EU',
  enterOutPuntPlaceholder: 'Select Airport / Seaport...',
  invalidOutPunt: 'Invalid Airport / Seaport',
  countryOfUnloadingLabel: 'Destination Country',
  placeOfUnloadingLabel: 'Destination Airport / Seaport',
  enterPlaceOfUnloadingPlaceholder: 'Select Airport / Seaport...',
  selectCountry: 'Select Country...',
  invalidPlaceOfUnloading: 'Invalid Destination Country',
  transportReferenceLabel: 'Ticket number',
  enterTransportReferencePlaceholder: 'Type Ticket number',
  invalidTransportReference: 'Invalid Ticket number',
  optional: '(optional)',
  invoiceScannerAndroidPermissions:
    'You must grant camera permissions to use invoice scanner',
  airvatUsers160: 'Typical Airvat users gets €160 tax back! Start saving now!',
  invalidForBelgium:
    'Your total verified purchases must be at least €125 to qualify for a refund in Belgium',
  invalidForFrance:
    'To be eligible for VAT refund in France, you must have at least €100 worth at verified purchases',
};

// if (locale !== 'en') {
//   LocaleConfig.defaultLocale = locale;
//   LocaleConfig.locales['ru'] = {
//     monthNamesShort: constants.monthNamesShort,
//     dayNamesShort: constants.dayNamesShort,
//     monthNames: constants.monthNamesShort,
//     dayNames: constants.dayNamesShort,
//   };

//   LocaleConfig.locales['zh'] = {
//     monthNamesShort: constants.monthNamesShort,
//     dayNamesShort: constants.dayNamesShort,
//     monthNames: constants.monthNamesShort,
//     dayNames: constants.dayNamesShort,
//   };
// }

export { constants, infoMail };

export default constants;
