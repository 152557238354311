import { sketchSize } from "../../../common";
import { colors } from "../../../common/colors";
import { AvIcon, Label, TouchableView } from "../../../components";
import { RNLinearGradient } from "../../../modules/RNLinearGradient";
import React from "react";
import { View } from "react-native";
import styles from "./styles";
import {
  IStepIndicatorComponentProps,
  IStepIndicatorComponentState,
  IStepItem,
} from "./types";

export class StepIndicator extends React.PureComponent<
  IStepIndicatorComponentProps,
  IStepIndicatorComponentState
> {
  defaultCurrentPosition = 0;

  constructor(props: IStepIndicatorComponentProps) {
    super(props);
    this.state = {
      currentPosition: this.getCurrentPosition(this.props),
    };
  }

  getCurrentPosition = (props: IStepIndicatorComponentProps) => {
    return props.currentPosition || this.defaultCurrentPosition;
  };

  onStepPress = (stepNumber: number) => {
    this.setState({ currentPosition: stepNumber });
    this.props.onStepPress && this.props.onStepPress(stepNumber);
  };

  componentDidMount() {
    this.onStepPress(this.getCurrentPosition(this.props));
  }

  componentDidUpdate(
    prevProps: Readonly<IStepIndicatorComponentProps>,
    prevState: Readonly<IStepIndicatorComponentState>,
    snapshot?: any
  ): void {
    if (
      this.props.currentPosition !== prevProps.currentPosition &&
      this.props.currentPosition !== this.state.currentPosition
    ) {
      this.onStepPress(this.getCurrentPosition(this.props));
    }
  }

  render() {
    return (
      <View
        style={[
          styles.container,
          {
            backgroundColor: this.props.isHideNumber
              ? colors.white
              : colors.accent,
          },
        ]}
      >
        <RNLinearGradient
          pointerEvents="none"
          style={styles.itemTouchContainerGradient}
          colors={[colors.accent, colors.white]}
          locations={[0.5, 0.5]}
        />
        {this.props.data.map(this.renderStep)}
      </View>
    );
  }

  renderStep = (item: IStepItem, i: number) => {
    const key = `${item.label}_${i}`;
    const isActive = this.state.currentPosition === i;
    const inNextActive = this.state.currentPosition === i+1;
    const inPrevActive = this.state.currentPosition === i-1;
    const color = isActive ? colors.accent : colors.dark;
    const backgroundColor = isActive ? colors.white : colors.midGrey;
    return (
      <View
        key={key}
        style={[
          styles.itemTouchContainerWrap,
          { backgroundColor },
          {borderBottomRightRadius: inNextActive ? sketchSize(40) : 0},
          {borderBottomLeftRadius: inPrevActive ? sketchSize(40) : 0}
        ]}
      >
        <TouchableView
          style={styles.itemTouchContainer}
          onPress={() => this.onStepPress(i)}
          testID={item.testID}
          disabled={item.isDisabled}
        >
          {!this.props.isHideNumber && (
            <View style={[styles.itemContainer, { borderColor: color }]}>
              <Label
                style={[styles.itemLabelNum, { color }]}
                type="H3Bold"
                text={`${i+1}`}
                centered
              />
            </View>
          )}
          <Label
            style={[styles.itemLabel, { color }]}
            type="H3Bold"
            text={item.label}
            isUpperCase
            centered
          />
          {!!item.isNotify && (
            <AvIcon
              name="Notify"
              width={50}
              containerStyle={styles.itemNotify}
            />
          )}
        </TouchableView>
      </View>
    );
  };
}

export default StepIndicator;
