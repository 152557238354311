import React from 'react';
import { TouchableOpacity } from 'react-native';

export const RNGestureHandlerRootView = ({ children }: any) => {
  return <>{children}</>;
};

export const RNTouchableOpacity = ({ children }: any) => {
  return <TouchableOpacity>{children}</TouchableOpacity>;
};
