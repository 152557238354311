import { DATE_FORMAT } from 'api-store/constants';
import { deviceWidth, sketchSize } from 'common';
import { snakeCase } from 'lodash';
import moment from 'moment';
import React from 'react';
import Calendar from 'react-calendar';
import { isDesktop } from 'react-device-detect';
import { TouchableOpacity } from 'react-native';
import { AvModal, Label, List } from '../components';
import './Calendar.css';

const time = [
  '00:00',
  '00:30',
  '01:00',
  '01:30',
  '02:00',
  '02:30',
  '03:00',
  '03:30',
  '04:00',
  '04:30',
  '05:00',
  '05:30',
  '06:00',
  '06:30',
  '07:00',
  '07:30',
  '08:00',
  '08:30',
  '09:00',
  '09:30',
  '10:00',
  '10:30',
  '11:00',
  '11:30',
  '12:00',
  '12:30',
  '13:00',
  '13:30',
  '14:00',
  '14:30',
  '15:00',
  '15:30',
  '16:00',
  '16:30',
  '17:00',
  '17:30',
  '18:00',
  '18:30',
  '19:00',
  '19:30',
  '20:00',
  '20:30',
  '21:00',
  '21:30',
  '22:00',
  '22:30',
  '23:00',
  '23:30',
];

export const RNDatePicker = ({
  onConfirm,
  onCancel,
  minimumDate,
  maximumDate,
  open,
  date,
  mode,
  title,
  locale,
}: any) => {
  if (mode === 'time') {
    if (isDesktop) {
      return (
        <AvModal
          title={title}
          transparent={false}
          isVisible={open}
          onBackdropPress={onCancel}
        >
          <List
            data={time}
            renderItem={({ item, index }) => {
              return (
                <TouchableOpacity
                  key={item + index}
                  style={{
                    flex: 1,
                    paddingHorizontal: sketchSize(80),
                    paddingVertical: sketchSize(40),
                  }}
                  onPress={() => {
                    const value = `${moment().format(DATE_FORMAT)}T${item}`;
                    onConfirm?.(moment(value).toDate());
                  }}
                >
                  <Label type={'H1Dark'} text={item} />
                </TouchableOpacity>
              );
            }}
          />
        </AvModal>
      );
    }
    return (
      <input
        type={'time'}
        lang={locale}
        name={snakeCase(title)}
        min={minimumDate ? moment(minimumDate).format(DATE_FORMAT) : undefined}
        max={maximumDate ? moment(maximumDate).format(DATE_FORMAT) : undefined}
        onChange={(e) => {
          if (e.target.value) {
            const value = `${moment().format(DATE_FORMAT)}T${e.target.value}`;
            onConfirm?.(moment(value).toDate());
          }
        }}
        style={{
          position: 'absolute',
          width: deviceWidth - sketchSize(160),
          height: '50px',
          opacity: 0,
        }}
      />
    );
  }

  return (
    <AvModal
      title={title}
      transparent={false}
      isVisible={open}
      onBackdropPress={onCancel}
    >
      <Calendar
        selectRange={false}
        onChange={onConfirm}
        locale="en"
        minDate={minimumDate}
        maxDate={maximumDate}
        defaultValue={date}
      />
    </AvModal>
  );
};
export type RNDateData = '';
export interface RNTheme {}
