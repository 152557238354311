import { BlurViewProps } from '@react-native-community/blur';
import React from 'react';
import { View } from 'react-native';
import { colors } from '../../common';

export const Blur = ({ children, blurType, ...rest }: BlurViewProps) => {
  return (
    <View style={[rest.style, { backgroundColor: colors.darkTransparent }]}>
      {children}
    </View>
  );
};
