import React from 'react';
import { StyleProp, TouchableOpacity, View, ViewStyle } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  constants,
  globalStyles,
  images,
  isWeb,
  localStorage,
  metrics,
  sketchSize,
} from '../../common';
import { CustomButton, Label } from '../../components';
import { getCurrentTrip } from '../../containers/Trip/tripState';
import { RNFastImage } from '../../modules/RNFastImage';
import {
  AirvatInfoCheckoutSceneName,
  AuthLoginSceneName,
  AuthSignUpSceneName,
} from '../../navigation/names';
import { IDefaultState } from '../../store/types';
import styles from './styles';
import { IAirvatInfoSliderProps } from './types';

const AirvatInfoSliderComponent: React.FC<IAirvatInfoSliderProps> = ({
  navigation,
  route,
}) => {
  const handleOnNavigateToLogIn = () => {
    navigation.navigate(AuthLoginSceneName);
  };

  const handleOnNavigateToSignUp = () => {
    navigation.navigate(AuthSignUpSceneName);
  };

  const handleShowInShop = () => {
    localStorage('isShowInShop', { value: true }).set();
    navigation.navigate(AirvatInfoCheckoutSceneName);
  };

  const token = route.params?.token;

  React.useEffect(() => {
    if (token) {
      navigation.navigate(AuthLoginSceneName, {
        token,
      });
    }
  }, [token]);

  const containerStyle: StyleProp<ViewStyle> = isWeb
    ? { flex: 1, justifyContent: 'space-evenly', margin: 30 }
    : styles.container;
  const imageStyle: any = isWeb
    ? {}
    : {
        position: 'absolute',
        bottom: 0,
      };
  return (
    <SafeAreaView edges={['top', 'bottom']} style={styles.container}>
      <View style={containerStyle}>
        <Label text={constants.airvatUsers160} bold centered xSize={2.6} />
        <RNFastImage
          source={images.startScreenMoney}
          style={{
            flex: 1,
            width: isWeb ? '100%' : metrics.deviceWidth - 60,
            alignSelf: 'center',
            height: sketchSize(900, true),
            ...imageStyle,
          }}
          resizeMode="contain"
        />
      </View>
      <View style={styles.margin}>
        <CustomButton
          accent
          isUppercase
          buttonStyle={{ width: '100%', marginBottom: 10 }}
          text={constants.showInShop}
          onPress={handleShowInShop}
          testID="showInShopBtn"
        />
        <CustomButton
          accent
          isUppercase
          buttonStyle={[globalStyles.marginBottomButton, { width: '100%' }]}
          text={constants.labels.signUp}
          onPress={handleOnNavigateToSignUp}
          testID="goToSignUpBtn"
        />
        <TouchableOpacity
          testID={'goToLoginBtn'}
          onPress={handleOnNavigateToLogIn}
        >
          <Label
            text={constants.auth.alreadySignedUp}
            style={[globalStyles.marginBottomButton, { width: '100%' }]}
            useFormatter
            centered
            linkActions={{
              goToLogin: handleOnNavigateToLogIn,
            }}
          />
        </TouchableOpacity>
      </View>
    </SafeAreaView>
  );
};

const mapStateToProps = (state: IDefaultState) => {
  const currentTrip = getCurrentTrip(state);
  return {
    currentTrip,
  };
};

export const AirvatInfoSlider = compose<any>(connect(mapStateToProps))(
  AirvatInfoSliderComponent
);
