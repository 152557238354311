import { colors } from "../common/colors";
import { globalStyles } from "../common/globalStyles";
import { AvIcon, TouchableView } from "../components";
import { default as headerStyle } from "../components/Headers/styles";
import styles from "./styles";
import { INavigationScreenProps } from "./types";

const headerLeft = (props: any) => {
  return props.navigation.canGoBack()
    ? TouchableView({
        children: AvIcon({
          name: "Chevron",
          containerStyle: globalStyles.chevronLeft,
          fill: props.fill || colors.dark,
        }),
        style: {
          overflow: "hidden",
          maxHeight: 60,
        },
        testID: `${props?.route?.name || ""}GoBack`,
        onPress: () => props.navigation.goBack(),
      })
    : null;
};

export const commonStackNavigatorConfig: any = (
  props: INavigationScreenProps
) => ({
  headerTintColor: colors.light,
  headerTitleStyle: globalStyles.headerTitle,
  headerStyle: headerStyle.accentHeader,
  headerLeftContainerStyle: styles.headerLeftContainerStyle,
  headerLeft: () => headerLeft(props),
  headerTransparent: false,
});

export const commonErrorStackNavigatorConfig: any = (
  props: INavigationScreenProps
) => ({
  ...commonStackNavigatorConfig(props),
  headerTitleStyle: {
    ...globalStyles.headerTitle,
    ...globalStyles.headerErrorTitle,
  },
});

export const authStackNavigatorConfig: any = (
  props: INavigationScreenProps
) => ({
  headerTintColor: colors.light,
  headerTitleStyle: globalStyles.headerTitle,
  headerStyle: {
    ...headerStyle.accentHeader,
    ...headerStyle.transparentHeader,
  },
  title: "",
  headerLeftContainerStyle: styles.headerLeftContainerStyle,
  headerLeft: () => headerLeft(props),
  headerTransparent: true,
});

export const commonTabNavigatorConfig: any = {
  tabBarOptions: {
    activeBackgroundColor: colors.white,
    inactiveBackgroundColor: colors.white,
    activeTintColor: colors.accent,
    showLabel: false,
    style: styles.homeTabNavigator,
  },
};

export const transparentHeader = {
  headerTransparent: true,
  headerTintColor: colors.white,
  headerTitleStyle: {
    ...globalStyles.headerTitle,
    color: colors.white,
  },
};

export const cameraNavigationOptionsConfig = {
  headerTransparent: true,
  headerTintColor: colors.white,
  headerTitleStyle: {
    ...globalStyles.headerTitle,
    color: colors.white,
  },
};

export const baseNavigationConfig: any = {
  headerBackTitleVisible: false,
  headerTitleAlign: "center",
  headerStyle: {
    backgroundColor: null,
    borderBottomColor: null,
  },
  cardStyle: {
    backgroundColor: colors.white,
  },
};
