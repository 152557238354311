import { filter, map, size } from 'lodash';
import React from 'react';
import { View } from 'react-native';
import Svg, { Line } from 'react-native-svg';
import { colors } from '../../common';
import { Label } from '../../components';
import { RNFastImage } from '../../modules/RNFastImage';
import styles from './styles';
import { IAirvatInfoHowItWorksList } from './types';

export const AirvatInfoHowItWorksList: React.FC<IAirvatInfoHowItWorksList> = ({
  linkActions,
  withIsLast,
  data,
  style,
}) => {
  const filteredData = filter(data, item => !!item.title);
  const containerStyle = style
    ? style
    : [styles.airvatInfoHowItWorksWrap, styles.listGradientContainer];
  
  return (
    <View style={containerStyle}>
      {map(filteredData, ({ icon, title, message, footerComponent }, index) => {
        if (!title) return null;
        const isFirst = index === 0;
        const isLast = index === size(filteredData) - 1;
        const airvatInfoHowItWorksItemFooter = [
          styles.airvatInfoHowItWorksItemFooter,
          withIsLast && isLast ? { marginBottom: 0 } : undefined,
        ];

        return (
          <View key={index} style={styles.row}>
            <View style={styles.airvatInfoHowItWorksItemWrap}>
              <Svg width={10} style={styles.airvatInfoHowItWorksItem}>
                {!isFirst && (
                  <Line
                    stroke={colors.blueGrey}
                    strokeWidth='3'
                    strokeLinecap='round'
                    strokeDashoffset='10'
                    strokeDasharray='1, 20'
                    x1='5'
                    y1='0'
                    x2='5'
                    y2='50%'
                  />
                )}
                {!isLast && (
                  <Line
                    strokeDashoffset='10'
                    stroke={colors.blueGrey}
                    strokeWidth='3'
                    strokeLinecap='round'
                    strokeDasharray='1, 20'
                    x1='5'
                    y1='50%'
                    x2='5'
                    y2='100%'
                  />
                )}
              </Svg>

              {!!icon && (
                <RNFastImage
                  source={icon}
                  resizeMode='contain'
                  style={[styles.smallImage, { position: 'absolute' }]}
                />
              )}
            </View>
            <View style={airvatInfoHowItWorksItemFooter}>
              <Label
                text={title}
                type='CalculatorSemibold'
                style={styles.margin20}
              />
              {map(message, (text, i) => {
                return (
                  <Label
                    key={i}
                    text={text}
                    useFormatter
                    type='H1Dark'
                    linkActions={linkActions}
                    style={styles.margin20}
                  />
                );
              })}
              {footerComponent && footerComponent(true)}
            </View>
          </View>
        );
      })}
    </View>
  );
};
