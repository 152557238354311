import { ICountry, ITrip } from "../api-store";
import { cardExpiryDateMask } from "../containers/Refund/refundHelpers";
import { padStart } from "lodash";
import { constants } from "./constants";
import {
  isAddress,
  isBankNumber,
  isBicOrSwift,
  isCardExpiry,
  isCity,
  isEmail,
  isPassword,
  isPhone,
  isRequired,
  isWeChatId,
  isZipCode,
} from "./stringValidator";
import { getArrivalDepartureDate, getTransportNodeTitle } from "./utils";

export interface IInputConfig {
  key: string;
  isValid: any[];
  label: string;
  otherTextInputProps: any;
  errorText: string;
  testID: string;
}

export const inputConfigs = {
  empty: {
    key: 'empty',
    filled: true,
    otherTextInputProps: {}
  },
  weChatId: {
    key: "wechatId",
    isValid: [isWeChatId],
    label: constants.labels.weChatId,
    otherTextInputProps: {
      autoCorrect: false,
      maxLength: 20,
      placeholder: constants.labels.weChatIdPlaceholder,
    },
    errorText: constants.validationErrors.weChatId.invalid,
    testID: "wechatIdInp",
  },
  email: {
    key: "email",
    isValid: [isEmail],
    label: constants.labels.email,
    otherTextInputProps: {
      placeholder: constants.labels.emailPlaceholder,
      keyboardType: "email-address",
      autoCapitalize: "none",
    },
    errorText: constants.validationErrors.email.invalid,
    testID: "emailInp",
  },
  password: {
    key: "password",
    isValid: [isPassword],
    label: constants.labels.password,
    otherTextInputProps: {
      placeholder: constants.labels.passwordPlaceholder,
      secureTextEntry: true,
      autoCapitalize: "none",
    },
    errorText: constants.validationErrors.email.invalid,
    testID: "passwordInp",
  },
  name: {
    key: "name",
    isValid: [isRequired],
    label: constants.refund.accountHolderName,
    otherTextInputProps: {
      autoCorrect: false,
      textContentType: "name",
      placeholder: constants.refund.placeholders.accountHolderName,
    },
    errorText: constants.validationErrors.name,
    testID: "nameInp",
  },
  firstName: {
    key: "firstName",
    isValid: [isRequired],
    label: constants.refund.accountHolderName,
    otherTextInputProps: {
      autoCorrect: false,
      placeholder: constants.refund.placeholders.accountHolderName,
    },
    errorText: constants.validationErrors.name,
    testID: "firstNameInp",
  },
  lastName: {
    key: "lastName",
    isValid: [isRequired],
    label: constants.refund.accountHolderSurname,
    otherTextInputProps: {
      autoCorrect: false,
      placeholder: constants.refund.placeholders.accountHolderSurname,
    },
    errorText: constants.validationErrors.surname,
    testID: "lastNameInp",
  },
  passportNumber: {
    key: "number",
    isValid: [isRequired],
    label: constants.myDetails.passportNumberLabel,
    otherTextInputProps: {
      autoCorrect: false,
      placeholder: constants.enterPassportNumber,
    },
    errorText: constants.invalidPassportNumber,
    testID: "passportNumberInp",
  },
  birthDate: {
    key: "birthDate",
    isValid: [isRequired],
    chevroned: true,
    label: constants.myDetails.birthDateLabel,
    otherTextInputProps: {
      autoCorrect: false,
      placeholder: constants.enterBirthDate,
      editable: false,
    },
    errorText: constants.invalidBirthDate,
    testID: "birthDateInp",
    touchTestID: "birthDateBtn",
  },
  expiryDate: {
    key: "expiryDate",
    chevroned: true,
    isValid: [isRequired],
    label: constants.myDetails.expiryDateLabel,
    otherTextInputProps: {
      autoCorrect: false,
      placeholder: constants.enterExpiryDate,
      editable: false,
    },
    errorText: constants.invalidExpiryDate,
    testID: "expiryDateInp",
    touchTestID: "expiryDateBtn",
  },
  arrivalDepartureDate: {
    key: "arrivalDepartureDate",
    isValid: [isRequired],
    getInputValue: (trip: ITrip) => getArrivalDepartureDate(trip),
    label: constants.arrivalDepartureDate,
    otherTextInputProps: {
      autoCorrect: false,
      placeholder: constants.headers.selectArrivalDate,
      editable: false,
    },
    errorText: constants.invalidArrivalDepartureDate,
    testID: "arrivalDepartureDateInp",
    touchTestID: "arrivalDepartureDateBtn",
  },
  transportNode: {
    key: "transportNode",
    isValid: [isRequired],
    getInputValue: (trip: ITrip) => getTransportNodeTitle(trip?.transportNode),
    label: constants.departureFrom,
    chevroned: true,
    otherTextInputProps: {
      autoCorrect: false,
      placeholder: constants.selectDepartureFrom,
    },
    isSkipEnglish: true,
    errorText: constants.invalidDepartureFrom,
    testID: "transportNodeInp",
  },
  accountNumber: {
    key: "accountNumber",
    isValid: [isBicOrSwift],
    label: constants.refund.accountNumber,
    otherTextInputProps: {
      autoCorrect: false,
      placeholder: constants.refund.placeholders.accountNumber,
    },
    mask: (value: string) => `${value}`.replace(/[ ]/giu, ""),
    getFilteredValue: (value: string) => `${value}`.replace(/[ ]/giu, ""),
    errorText: constants.validationErrors.accountNumber,
    testID: "accountNumberInp",
  },
  bicOrSwift: {
    key: "bicOrSwift",
    isValid: [isBicOrSwift],
    label: constants.refund.swiftCode,
    otherTextInputProps: {
      autoCorrect: false,
      placeholder: constants.refund.placeholders.swiftCode,
    },
    mask: (value: string) => `${value}`.replace(/[ ]/giu, ""),
    getFilteredValue: (value: string) => `${value}`.replace(/[ ]/giu, ""),
    errorText: constants.validationErrors.bicOrSwift,
    testID: "bicOrSwiftInp",
  },
  issuedByCountryId: {
    key: "issuedByCountryId",
    chevroned: true,
    isCountry: true,
    isResetForm: true,
    isValid: [isRequired],
    getInputValue: (data?: { country: ICountry }) => data?.country?.name,
    label: constants.placeholders.country,
    otherTextInputProps: {
      editable: false,
      placeholder: constants.refund.placeholders.countryName,
    },
    errorText: constants.validationErrors.invalidCountry,
    touchTestID: "countryCodeInp",
  },
  countryCode: {
    key: "countryCode",
    chevroned: true,
    isCountry: true,
    getInputValue: (data?: { country: ICountry }) => data?.country?.name,
    isValid: [isRequired],
    label: constants.placeholders.country,
    otherTextInputProps: {
      editable: false,
      placeholder: constants.refund.placeholders.countryName,
    },
    errorText: constants.validationErrors.invalidCountry,
    touchTestID: "countryCodeInp",
  },
  residenceCountry: {
    key: "countryId",
    isValid: [isRequired],
    chevroned: true,
    isCountry: true,
    isResetForm: true,
    isAddressCountries: true,
    label: constants.myDetails.countryLabel,
    otherTextInputProps: {
      editable: false,
      placeholder: constants.myDetails.chooseCountryPlaceholder,
    },
    getInputValue: (data?: { country: ICountry }) => data?.country?.name,
    errorText: constants.validationErrors.invalidCountry,
    touchTestID: "countryCodeInp",
  },
  cardNumber: {
    key: "cardNumber",
    isValid: [isBankNumber],
    label: constants.refund.cardNumber,
    otherTextInputProps: {
      placeholder: constants.placeholders.cardNumber,
      maxLength: 19,
      keyboardType: "numeric",
    },
    errorText: constants.validationErrors.cardNumber,
    testID: "cardNumberInp",
  },
  cardLastDigits: {
    key: "lastDigits",
    label: constants.refund.cardNumber,
    mask: (value: string) => padStart(`${value}`, 19, "**** "),
    testID: "cardNumberInp",
    otherTextInputProps: {}
  },
  cardExpiryMonth: {
    key: "cardExpiryMonth",
    label: constants.refund.month,
    testID: "cardExpiryMonth",
    otherTextInputProps: {}
  },
  cardExpiryYear: {
    key: "cardExpiryYear",
    label: constants.refund.year,
    testID: "cardExpiryYear",
    otherTextInputProps: {}
  },
  cardExpiry: {
    key: "cardExpiry",
    isValid: [isCardExpiry],
    label: constants.refund.cardExpiry,
    otherTextInputProps: {
      placeholder: constants.placeholders.cardExpiry,
      maxLength: 5,
      keyboardType: "numeric",
    },
    mask: cardExpiryDateMask,
    errorText: constants.validationErrors.cardExpiry,
    testID: "cardExpiryInp",
  },
  phone: {
    key: "phone",
    isPhone: true,
    isValid: [isPhone],
    label: constants.myDetails.phoneLabel,
    otherTextInputProps: {
      placeholder: constants.myDetails.enterPhonePlaceholder,
      maxLength: 70,
      keyboardType: "phone-pad",
    },
    errorText: constants.myDetails.invalidPhone,
    testID: "phoneInp",
  },
  address: {
    key: "line",
    label: constants.myDetails.addressLineOneLabel,
    isValid: [isAddress],
    otherTextInputProps: {
      placeholder: constants.myDetails.enterAddressPlaceholder,
      maxLength: 70,
    },
    errorText: constants.myDetails.invalidAddress,
    testID: "lineInp",
  },
  addressAdditional: {
    key: "lineAdditional",
    label: constants.myDetails.addressLineTwoLabel,
    otherTextInputProps: {
      placeholder: constants.myDetails.enterAddressPlaceholder,
      maxLength: 70,
    },
    testID: "lineAdditionalInp",
  },
  postcode: {
    key: "postcode",
    label: constants.myDetails.zipCodeLabel,
    isValid: [isZipCode],
    otherTextInputProps: {
      placeholder: constants.myDetails.enterZipcodePlaceholder,
    },
    errorText: constants.myDetails.invalidZipCode,
    testID: "postcodeInp",
  },
  residenceCity: {
    key: "city",
    label: constants.myDetails.cityLabel,
    isValid: [isCity],
    otherTextInputProps: {
      placeholder: constants.myDetails.enterCityPlaceholder,
    },
    errorText: constants.myDetails.invalidCity,
    testID: "cityInp",
  },
  estimatedDateAndTimeOfDeparture: {
    key: "estimatedDateAndTimeOfDeparture",
    label: constants.estimatedDateAndTimeOfDepartureLabel,
    otherTextInputProps: {
      autoCorrect: false,
      placeholder: constants.enterEstimatedDateAndTimeOfDeparture,
      editable: false,
    },
    errorText: constants.invalidEstimatedDateAndTimeOfDeparture,
    testID: "estimatedDateAndTimeOfDepartureInp",
    touchTestID: "estimatedDateAndTimeOfDepartureBtn",
  },
  outPuntSelect: {
    key: "transportNode",
    label: constants.outPuntLabel,
    otherTextInputProps: {
      autoCorrect: false,
      editable: false,
      placeholder: constants.enterOutPuntPlaceholder,
    },
    errorText: constants.invalidOutPunt,
    testID: "outPuntSelectInp",
    chevroned: true,
    isSkipEnglish: true,
  },
  placeOfUnloading: {
    key: "placeOfUnloading",
    label: constants.placeOfUnloadingLabel,
    otherTextInputProps: {
      autoCorrect: false,
      editable: false,
      placeholder: constants.enterPlaceOfUnloadingPlaceholder,
    },
    errorText: constants.invalidPlaceOfUnloading,
    testID: "placeOfUnloadingInp",
    chevroned: true,
    isSkipEnglish: true,
  },
  transportReference: {
    key: "transportReference",
    label: constants.transportReferenceLabel,
    otherTextInputProps: {
      autoCapitalize: "characters",
      placeholder: constants.enterTransportReferencePlaceholder,
    },
    errorText: constants.invalidTransportReference,
    testID: "transportReferenceInp",
  },
  destinationCountry: {
    key: "destinationCountry",
    chevroned: true,
    label: constants.countryOfUnloadingLabel,
    otherTextInputProps: {
      editable: false,
      placeholder: constants.selectCountry,
    },
    errorText: constants.validationErrors.invalidCountry,
    touchTestID: "destinationCountryInp",
  },
};
