import { constants, getCountryCode, getCurrency, images } from '../../common';
import { AvNote, BodyContainer, Label, SupportButton } from '../../components';
import { IDefaultState } from '../../store/types';
import { get, map } from 'lodash';
import React, { Component } from 'react';
import { View } from 'react-native';
import { RNFastImage } from '../../modules/RNFastImage';
import { connect } from 'react-redux';
import { compose } from 'redux';
import styles from './styles';
import { ITripInfoProps } from './types';

class TripInfo extends Component<ITripInfoProps> {
  constructor(props: ITripInfoProps) {
    super(props);
    this.state = {
      isTripSaving: false,
    };
  }

  render() {
    return (
      <View style={styles.container}>
        <RNFastImage
          resizeMode='stretch'
          source={images.tripBackground}
          style={styles.backgroundImage}
        />
        <BodyContainer useSafeArea>
          <View style={styles.refundBreakdownItemsContainer}>
            {map(constants.refundBreakdownItems, (v, k) => {
              const amount = get(
                this.props,
                `currentTrip.paymentDetails.${k}`,
                0
              );
              const currency = getCurrency(
                getCountryCode(this.props.currentTrip?.country)
              );
              const value = currency + ' ' + amount;
              return (
                <View
                  key={k}
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <Label centered type='H1Dark' text={v} />
                  <Label centered type='H1Dark' text={value} />
                </View>
              );
            })}
          </View>
          <View style={styles.refundBreakdownInfo}>
            <AvNote
              isNotCross
              centered
              isNotHeader
              name='refundBreakdownInfo'
              text={constants.refundBreakdownInfo}
            />
          </View>
          <SupportButton />
        </BodyContainer>
      </View>
    );
  }
}

const mapStateToProps = (state: IDefaultState, props: ITripInfoProps) => {
  const tripId = props.route.params?.tripId;
  const currentTrip = get(state, `api.user.trips.${tripId}`);
  return {
    currentTrip,
  };
};

export default compose<any>(connect(mapStateToProps))(TripInfo);
