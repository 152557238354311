import { airvatClient } from '../../common';
import { keyBy } from 'lodash';
import { createSetAction } from '../helpers';
import { ICountry } from '../../api-store/types';

export const getSettings = () => async (dispatch: any) => {
  const data = await airvatClient.get<object[]>('settings');
  const storeData = keyBy(data, 'key');
  const action = createSetAction('service', storeData);
  dispatch(action);
};

export const getCountriesForTrip =
  () => async (dispatch: any, getState: any) => {
    const { results } = await airvatClient.get<{ results: ICountry[] }>(
      'i18n/countries',
      { params: { isAllowedForTrip: true, sort: 'name', direction: 'asc' } }
    );
    const action = createSetAction(`tripCountries`, results);
    dispatch(action);
  };
