import { colors, globalStyles, isSmallDevice, sketchSize } from '../../common';
import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  textInputContainer: {
    flex: 1,
    backgroundColor: colors.white,
    borderRadius: sketchSize(35),
    marginTop: sketchSize(isSmallDevice ? 10 : 30),
    marginBottom: sketchSize(isSmallDevice ? 20 : 60),
    minHeight: sketchSize(160),
    ...globalStyles.shadow
  },
  textInput: {
    margin: sketchSize(40)
  }
});
