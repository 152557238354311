import { isIOS, constants } from '../../common';
import { RNTakePictureOptions } from '../../modules/RNCamera';

export const CAMERA_OPTIONS: RNTakePictureOptions = {
  quality: 0.6,
  base64: false,
  exif: false,
  writeExif: false,
  pauseAfterCapture: false,
  orientation: 'portrait',
  ...(isIOS ? { forceUpOrientation: true } : { fixOrientation: true }),
};

export const ANDROID_CAMERA_PERMISSION = {
  title: constants.permissions.camera.title,
  message: constants.permissions.camera.message,
  buttonPositive: constants.labels.ok,
  buttonNegative: constants.labels.cancel,
};
