import { constants } from '../../../common/constants';
import { isEmail, isPassword } from '../../../common/stringValidator';
import { AvTextInput, CustomButton } from '../../../components';
import React, { Component } from 'react';
import styles from '../styles';
import { ILoginFormProps } from '../types';

export default class LoginForm extends Component<ILoginFormProps> {
  emailInputRef: AvTextInput | null;
  passwordInputRef: AvTextInput | null;

  constructor(props: ILoginFormProps) {
    super(props);
    this.emailInputRef = null;
    this.passwordInputRef = null;
  }

  state = {
    email: {
      value: this.props.email,
      isValid: isEmail(this.props.email),
    },
    password: {
      value: this.props.password,
      isValid: isPassword(this.props.password),
    },
  };

  onLoginPress = () => {
    const { email, password } = this.state;
    if (isEmail(email.value) && isPassword(password.value)) {
      this.props.onLoginPress(email.value, password.value);
    }
  };

  isValidFields = (): boolean => {
    const { email, password } = this.state;
    return !!(
      email.isValid &&
      email.value &&
      password.isValid &&
      password.value
    );
  };

  componentDidUpdate(prevProps: ILoginFormProps) {
    if (
      prevProps.email !== this.props.email ||
      prevProps.password !== this.props.password
    ) {
      this.setState({
        email: {
          value: this.props.email,
          isValid: isEmail(this.props.email),
        },
        password: {
          value: this.props.password,
          isValid: isPassword(this.props.password),
        },
      });
    }
  }

  render() {
    const {
      isLoading,
      onSignUpLinkPress,
      onForgotLinkPress,
      password,
      email,
      buttonDisabled,
    } = this.props;

    return (
      <>
        <AvTextInput
          key='userEmail'
          isValid={[isEmail]}
          createRef={ref => (this.emailInputRef = ref)}
          label={constants.labels.email}
          otherTextInputProps={{
            placeholder: constants.labels.emailPlaceholder,
            returnKeyType: 'next',
            autoCapitalize: 'none',
            keyboardType: 'email-address',
            onEndEditing: () =>
              this.passwordInputRef && this.passwordInputRef.focus(),
            defaultValue: email,
            onFocus:()=>this.props.onInputFocus(true),
            onBlur:()=>this.props.onInputFocus(false)
          }}
          onValidationChange={(value, isValid) =>
            this.setState({ email: { value, isValid } })
          }
          errorText={constants.validationErrors.email.invalid}
          testID='loginEmailInp'
        />
        <AvTextInput
          key='userPassword'
          isValid={[isPassword]}
          createRef={ref => (this.passwordInputRef = ref)}
          label={constants.labels.password}
          otherTextInputProps={{
            defaultValue: password,
            placeholder: constants.labels.passwordPlaceholder,
            autoCapitalize: 'none',
            returnKeyType: 'done',
            secureTextEntry: true,
            onFocus:()=>this.props.onInputFocus(true),
            onBlur:()=>this.props.onInputFocus(false)
          }}
          onValidationChange={(value, isValid) =>
            this.setState({ password: { value, isValid } })
          }
          errorText={constants.validationErrors.password.invalid}
          testID='loginPasswordInp'
        />
        <CustomButton
          isUppercase
          accent
          text={constants.labels.login}
          isEnabled={!buttonDisabled && this.isValidFields()}
          isLoading={isLoading}
          onPress={this.onLoginPress}
          buttonStyle={styles.fillButton}
          testID='loginBtn'
        />
        <CustomButton
          link
          text={constants.auth.dontHaveAnAccount}
          onPress={onSignUpLinkPress}
          buttonStyle={styles.fillButtonLink}
          testID='goBackToSignUpBtn'
        />
        <CustomButton
          link
          buttonStyle={styles.fillButtonLink}
          text={constants.auth.forgotYourPassword}
          onPress={onForgotLinkPress}
          testID='goToPasswordForgot'
        />
      </>
    );
  }
}
