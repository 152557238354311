import React from 'react';

export const RNCarousel = React.forwardRef(({ data, renderItem }: any) => {
  return renderItem({
    item: data?.[0],
    index: 0,
  });
});

export const RNPagination = React.forwardRef(() => {
  return null;
});
