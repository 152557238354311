import AsyncStorage from '@react-native-async-storage/async-storage';
import {
  endsWith,
  find,
  includes,
  last,
  some,
  startCase,
  startsWith
} from 'lodash';
import moment from 'moment';
import { Alert, Linking, StatusBar } from 'react-native';
import { DOTS_DATE_FORMAT } from '../api-store/constants';
import { ITakePictureResponse } from '../components/CameraContainer';
import {
  AirvatInfoCheckoutSceneName,
  AirvatInfoCustomsInspectionSceneName,
  AirvatInfoGoodsAboveSceneName,
  AirvatInfoTaxFormSceneName,
  PrivacyPolicySceneName,
  TermsConditionsSceneName,
} from '../navigation/names';
import { IDefaultState } from '../store/types';

import { isEmulatorSync } from 'react-native-device-info';
import {
  EDirname,
  EReceiptStatus,
  ETaxformStatus,
  ETransactionStatus,
  IApiFile,
  IApiUploadFile,
  ICountry,
  ITaxform,
  ITransportNode,
  ITrip,
  IUser,
  IUserAddress,
} from '../api-store';
import { RNCodePush } from '../modules/RNCodePush';
import { RNIntercom } from '../modules/RNIntercom';
import colors from './colors';
import constants from './constants';
import { GB, GB_NIR } from './constants/common';
import { fbAnalytics } from './fbAnalytics';
import { isAndroid, isWeb } from './metrics';

interface IStorageData {
  [x: string]: string;
}

export const createExt = (uri?: string, withDot?: boolean) => {
  const isBase64 = startsWith(uri, 'data:') || includes(uri, 'base64');
  if (!uri || isBase64) return '';
  const ext = last(uri?.split('.'));
  if (['jpg', 'jpeg', 'png'].some((v) => v === ext)) {
    return withDot ? `.${ext}` : ext;
  }
  return withDot ? `.pdf` : 'pdf';
};

export const createApiUploadFile = (
  picture: ITakePictureResponse | IApiUploadFile | IApiFile,
  dirname?: EDirname
): IApiUploadFile => {
  const name = dirname
    ? createFileName(String(picture.base64 || picture.uri), dirname)
    : (picture as any)?.name || '';
  const type = createMime(String(picture.base64 || picture.name || picture.uri));
  return {
    ...picture,
    type,
    name,
    dirname: dirname || EDirname.OTHER,
  };
};

export const createFileName = (uri: string, dirname: EDirname) => {
  return dirname + Date.now() + createExt(uri, true);
};

export const createMime = (uri: string, type?: string) => {
  const isBase64 = startsWith(uri, 'data:') || includes(uri, 'base64');
  const isPdf = isBase64
    ? false
    : endsWith(uri, 'pdf') || includes(type, 'pdf');
  return isPdf
    ? 'application/pdf'
    : isBase64
    ? 'base64'
    : `image/${createExt(uri)}`;
};

export const getStatusColor = (status: EReceiptStatus) => {
  switch (status) {
    case EReceiptStatus.Accepted:
      return colors.accent;
    case EReceiptStatus.Pending:
      return colors.lightGrey;
    default:
      return colors.red;
  }
};

export const stringSlice = (value: string, max: number) => {
  if (!value || value.length < max) return value;
  return value.slice(0, max - 3) + '...';
};

export const getCurrency = (countryCode?: string) => {
  switch (countryCode) {
    case GB_NIR:
    case GB:
      return constants.currency.gbp;
    default:
      return constants.currency.eur;
  }
};

export const waitForAsync = async (delay: number) => {
  return new Promise((resolve) => setTimeout(resolve, delay));
};

// todo: disable clear
// AsyncStorage.clear();
export const localStorage = <T>(key: string, value?: T) => {
  const set = async (): Promise<T | void> => {
    console.log('localStorage set =>', key, value);
    if (value) {
      const jsonValue = value && JSON.stringify(value);
      return AsyncStorage.setItem(key, jsonValue);
    }
  };

  const get = async (): Promise<T | undefined> => {
    const result = await AsyncStorage.getItem(key);
    console.log('localStorage get =>', key, result);
    if (result) {
      return JSON.parse(result) as T;
    }
  };

  const remove = (): Promise<void> => {
    console.log('localStorage remove =>', key);
    return AsyncStorage.removeItem(key);
  };

  return { set, get, remove };
};

export const codePushCheckForUpdate = async () => {
  if (isEmulatorSync() || isWeb) return;
  const update = await RNCodePush.checkForUpdate();
  if (update) {
    return new Promise((resolve, reject) => {
      RNCodePush.sync(
        { installMode: RNCodePush.InstallMode.IMMEDIATE },
        async (status) => {
          switch (status) {
            case RNCodePush.SyncStatus.INSTALLING_UPDATE:
              await RNCodePush.notifyAppReady();
              resolve(status);
              break;
          }
        },
        undefined,
        reject
      );
    });
  }
};

export const openChat = () => {
  RNIntercom.present();
  // const url = `mailto:${infoMail}`;
  // openUrl(url);
};

export const setStatusBar = (isLight?: boolean) => {
  if (isLight) {
    StatusBar.setBarStyle('light-content');
    isAndroid && StatusBar.setBackgroundColor(colors.accent);
  } else {
    StatusBar.setBarStyle('dark-content');
    isAndroid && StatusBar.setBackgroundColor(colors.white);
  }
};

export const linkActions = ({ navigate }: any) => ({
  goToAbove: () => {
    navigate(AirvatInfoGoodsAboveSceneName);
  },
  goToTaxFreeFormInfo: () => navigate(AirvatInfoTaxFormSceneName),
  goToCustomsInspect: () => navigate(AirvatInfoCustomsInspectionSceneName),
  goToCheckout: () => navigate(AirvatInfoCheckoutSceneName),
  openChat,
  goToPrivacyPolicy: () => {
    navigate(PrivacyPolicySceneName);
  },
  goToTermsConditions: () => {
    navigate(TermsConditionsSceneName);
  },
});

export const openUrl = (url: string) => {
  Linking.canOpenURL(url)
    .then((isSupported) => {
      if (isSupported) {
        Linking.openURL(url);
      } else {
        Alert.alert('Error', 'not supported');
      }
    })
    .catch((e: any) => {
      Alert.alert('Error', e?.message);
    });
};

export const getActiveTrip = (state: IDefaultState) => {
  const activeTrip = find(state.api.user?.trips, (v) => v.isActive);
  return activeTrip;
};

export const getTripById = (tripId: number, state: IDefaultState) => {
  const activeTrip = find(state.api.user?.trips, (v) => v.id === tripId);
  return activeTrip;
};

export const getActualTaxform = (state: IDefaultState) => {
  const trip = getActiveTrip(state);
  return getTripActualTaxform(trip);
};

export const getTripActualTaxform = (trip?: ITrip) => {
  return find(trip?.taxforms, 'isActual');
};

export const getActiveTripId = (state: IDefaultState) => {
  return getActiveTrip(state)?.id;
};

export const getCountryCode = (v?: ICountry) => {
  return v?.subdivision || v?.alpha2Code || '';
};

export const getRefundStatus = (taxform?: ITaxform) => {
  switch (taxform?.transaction?.status) {
    case ETransactionStatus.REGISTERED:
    case ETransactionStatus.PROCESSING:
      return 'Refund Processing';
    case ETransactionStatus.COMPLETED:
      return 'Refunded';
    case ETaxformStatus.ERROR:
      return 'Refund Error';
  }
};

export const getTripStatus = (trip?: ITrip) => {
  const taxform = getTripActualTaxform(trip);
  const refundStatus = getRefundStatus(taxform);
  if (refundStatus) return refundStatus;
  switch (taxform?.status) {
    case ETaxformStatus.STAMPED:
      return 'Awaiting Payment';
    case ETaxformStatus.NOT_STAMPED:
      return 'Stamp The Form';
    case ETaxformStatus.REJECTED:
      return 'Form Rejected';
  }
  return 'Incomplete';
};

export const isCompleteTaxform = (taxform?: ITaxform) => {
  if (taxform?.id) {
    return some(
      [ETaxformStatus.STAMPED, ETaxformStatus.NOT_STAMPED],
      (v) => v === taxform?.status
    );
  }
  return false;
};

export const getTransportNodeTitle = (transportNode?: ITransportNode) => {
  if (!transportNode) return '';
  let title = `${transportNode?.name}`;
  if (transportNode?.code && !transportNode?.code.startsWith('OTR_')) {
    title += ` (${transportNode?.code})`;
  }
  return title;
};

export const getArrivalDepartureDate = (trip?: Partial<ITrip>) => {
  const arrivalDate = trip?.arrivalDate
    ? moment(trip.arrivalDate).format(DOTS_DATE_FORMAT)
    : '';
  const departureDate = trip?.departureDate
    ? moment(trip.departureDate).format(DOTS_DATE_FORMAT)
    : '';
  return `${arrivalDate} / ${departureDate}`.trim();
};

export const getFormattedAddress = (address?: IUserAddress) => {
  if (!address) return '';
  const { line, lineAdditional, postcode, city, country } = address;
  const addressLine1 = line + (lineAdditional ? `, ${lineAdditional}` : '');
  const addressLine2 = `${postcode}, ${city}, ${country.name}`;
  return `${addressLine1}, ${addressLine2}`;
};

export const analyticsEvent = (event: string, data: any) => {
  console.log('analyticsEvent', event, data);
  return fbAnalytics().logEvent(event, data);
};

export const analyticsEventRegistration = (user: Partial<IUser>) => {
  const eventName = isAndroid
    ? 'user_registration_android'
    : 'user_registration_ios';
  const data = {
    provider: user.provider,
    refCode: user.refCode,
  };
  analyticsEvent(eventName, data);
  return analyticsEvent('user_registration', data);
};

export const intercomAuth = async (user: IUser) => {
  try {
    await RNIntercom.setUserHash(user.intercomHash);
    await RNIntercom.loginUserWithUserAttributes({
      userId: user.code,
      email: user.email,
    });
    if (user.document) {
      await RNIntercom.updateUser({
        name: startCase(user.document.firstName),
      });
    }
  } catch (e) {
    console.error(e);
  }
};
