import { constants, globalStyles, images } from '../../common';
import { EmptyPagePlaceholder } from '../../components';
import { EEmptyPageButtonTypes } from '../../components/EmptyPagePlaceholder/types';
import { SelfieCameraSceneName } from '../../navigation/names';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import { RNFastImage } from '../../modules/RNFastImage';
import { IDocumentCameraProps } from './types';

export const SelfieCameraPlaceholder: React.FC<IDocumentCameraProps> = props => {
  const handleOnTakePhoto = () => {
    props.navigation.replace(SelfieCameraSceneName, {
      ...(props.route.params || {}),
    });
  };

  if (!props.navigation.isFocused()) return null;
  return (
    <View style={StyleSheet.absoluteFill}>
      <EmptyPagePlaceholder
        onButtonPress={handleOnTakePhoto}
        title={constants.selfieCameraPlaceholderTitle}
        message={constants.selfieCameraPlaceholderMessage}
        buttonType={EEmptyPageButtonTypes.DEFAULT}
        buttonText={constants.takeSelfie}
        labelContainerStyle={{ flex: 0.4 }}
        iconComponent={
          <RNFastImage
            resizeMode='contain'
            style={globalStyles.defaultPlaceholderIcon}
            source={images.selfiePhotoIcon}
          />
        }
        buttonTestID={`selfieCameraPlaceholderBtn`}
      />
    </View>
  );
};
