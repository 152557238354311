import { isAndroid, locale } from '../../common';
import { filter, get, map } from 'lodash';
import { TextProps } from 'react-native';
import { formattedLabel } from './formattedLabel';
import styles from './styles';
import { ILabelProps } from './types';

export const labelCommonStyle = (props: ILabelProps) => {
  return [
    props.style,
    props.semibold && styles.Semibold,
    props.light && styles.Light,
    props.bold && styles.Bold,
    props.accent && styles.Accent,
    props.white && styles.White,
    props.dark && styles.Dark,
    props.centered && styles.Centered,
    props.right && styles.Right,
    props.isUpperCase && (locale as any) !== 'zh' && styles.UpperCase,
    props.link && styles.Link,
    props.error && styles.Error,
  ].filter(x => x !== undefined);
};

export const labelCommonProps = (props: ILabelProps): TextProps => {
  const { numberOfLines, onPress } = props;
  const labelType = props.type || 'H1Dark';
  const typeStyle: any = (styles as any)[labelType];
  const cropStyle = props.crop
    ? {
        lineHeight: isAndroid
          ? typeStyle.fontSize * 0.96
          : typeStyle.fontSize * 0.97,
        height: isAndroid
          ? typeStyle.fontSize * 0.74
          : typeStyle.fontSize * 0.73,
      }
    : {};
  return {
    style: [
      { overflow: 'visible' },
      typeStyle,
      props.xSize && { fontSize: typeStyle.fontSize * props.xSize },
      cropStyle,
      ...labelCommonStyle(props),
    ],
    numberOfLines,
    onPress: !isAndroid ? undefined : onPress,
  };
};

export const isDark = (text: string) =>
  /^\*/giu.test(text) && /\*$/giu.test(text);

export const isBold = (text: string) =>
  /^\*/giu.test(text) && /\*$/giu.test(text);

export const isLink = (text: string) =>
  /^#/giu.test(text) && /#$/giu.test(text);

export const isWarnIcon = (text: string) => text === '$$$';

export const isRedLink = (text: string) => />\*/giu.test(text);

export const isError = (text: string) =>
  /^\*#/giu.test(text) && /#*$/giu.test(text);

export const textItemFormatter = (props: any) => (text: string, i: number) => {
  const dark = isDark(text);
  const bold = isBold(text);
  const link = isLink(text);
  const warnIcon = isWarnIcon(text);
  const error = isError(text) || isRedLink(text);
  const matchedLinkAction =
    link &&
    get(filter(text.match(/\{(\w+)\}#$/giu)), 0, '').replace(
      /[^a-zA-Z]/giu,
      ''
    );
  const linkAction = get(props.linkActions, `${matchedLinkAction}`);
  const clearedText = text
    .replace(/\{\w+\}#$/giu, '')
    .replace(/(>\*)/giu, '')
    .replace(/[\*#]/giu, '')
    .replace(/[\$\$\$]/giu, '');

  const newProps = {
    dark,
    link,
    bold,
    error,
    warnIcon,
    ...props,
    text: clearedText,
    useFormatter: false,
  };
  return formattedLabel(newProps, i, linkAction);
};

export const textFormatter = (props: ILabelProps) => {
  const propsText: any = props.text || '';

  const dark = isDark(propsText);
  const link = isLink(propsText);
  const error = isError(propsText);
  if (dark || link || error) {
    return textItemFormatter(props)(propsText, 0);
  }
  const splitText = filter(
    String(propsText).split(/(\$\$\$)|(\*.*?\*)|(#.*?#)/gimu)
  );
  return map(splitText, textItemFormatter(props));
};
