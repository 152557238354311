import { colors, constants, isWeb } from '../../common';
import React from 'react';
import {
  NativeSyntheticEvent,
  StyleProp,
  TextInput,
  TextInputFocusEventData,
  TextInputProps,
  View,
  ViewStyle,
} from 'react-native';

import { ICountry } from '../../api-store';
import { AvModal } from '../../components/AvModal';
import { SelectCountry } from '../../components/SelectCountry';
import { get } from 'lodash';
import { AvIcon } from '../AvIcon';
import { TIconName } from '../AvIcon/icons';
import { Label } from '../Labels';
import TouchableView from '../TouchableView';
import styles from './styles';
import {
  IAvTextInputData,
  IAvTextInputProps,
  IAvTextInputState,
  IAvTextInputTouchProps,
} from './types';

const TouchInput: React.FC<IAvTextInputTouchProps> = (props) => {
  const style = [
    styles.textInputWrapper,
    props.isFocused && styles.activeBorder,
    !props.isValid && props.errorText && styles.errorBorder,
    props.inputWrapCustomStyle,
  ] as StyleProp<ViewStyle>;
  if (!props.isDisabled) {
    return (
      <TouchableView style={style} onPress={props.onTouchStart} {...props} />
    );
  }
  return <View style={style} {...props} />;
};

export class AvTextInput extends React.Component<
  IAvTextInputProps,
  IAvTextInputState
> {
  static defaultProps = {
    onValidationChange: () => null,
  };

  textInputRef: any;
  data: IAvTextInputData;
  defaultValue: string;

  constructor(props: IAvTextInputProps) {
    super(props);
    this.defaultValue = this.getDefaultValue(props);

    this.state = {
      isFocused: !!props.isFocused,
      hideSecureText: props.otherTextInputProps?.secureTextEntry,
      errorText: '',
      maskedValue: '',
      selectedCountry: this.props.country,
      isCountryModalVisible: false,
    };

    this.data = {
      isValid: !this.checkIsValid(this.defaultValue).errorText,
      value: this.defaultValue,
      isEdited: false,
    };
  }

  toggleCountryModal = () => {
    this.setState({ isCountryModalVisible: !this.state.isCountryModalVisible });
  };

  getDefaultValue = (props: IAvTextInputProps) =>
    props.value ||
    props.otherTextInputProps?.value ||
    props.otherTextInputProps?.defaultValue ||
    '';

  handleOnBlur = (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
    const errorText = this.data.isValid
      ? ''
      : !this.data.isEdited
      ? ''
      : this.props.errorText || '';
    this.setState(
      { isFocused: false, errorText },
      () =>
        this.props.otherTextInputProps?.onBlur &&
        this.props.otherTextInputProps?.onBlur(e)
    );
  };

  handleOnFocus = (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
    this.setState(
      { isFocused: true },
      () =>
        this.props.otherTextInputProps?.onFocus &&
        this.props.otherTextInputProps?.onFocus(e)
    );
  };

  checkIsValid = (v: string) => {
    const value = this.props.getFilteredValue
      ? this.props.getFilteredValue(v)
      : v;
    let isValid =
      !this.props.isValid ||
      this.props.isValid.every((validFunc) => !validFunc || validFunc(value));
    const errorText =
      isValid && this.state.errorText
        ? ''
        : this.defaultValue &&
          this.props.errorText &&
          !isValid &&
          !this.state.errorText
        ? this.props.errorText
        : this.state.errorText;
    const maskedValue = this.props.mask && this.props.mask(value);

    if (this.data) {
      if (this.defaultValue !== this.data.value) {
        this.data.isEdited = true;
      }
      this.data.value = value;
      this.data.isValid = isValid;
    }

    return { errorText, maskedValue };
  };

  onChangeText = (v: string, _selectedCountry?: ICountry) => {
    this.setState({ ...this.checkIsValid(v) });
    const value = this.props.getFilteredValue
      ? this.props.getFilteredValue(v)
      : v;
    const propsChangeText = get(this.props, 'otherTextInputProps.onChangeText');
    propsChangeText && propsChangeText(value);
    const { isValid, isEdited } = this.data;

    this.props.onValidationChange &&
      this.props.onValidationChange(
        value,
        isValid,
        isEdited,
        _selectedCountry || this.state.selectedCountry
      );
  };

  blur = () => {
    !isWeb && this.textInputRef.blur();
  };

  focus = () => {
    !isWeb && this.textInputRef.focus();
  };

  touchStart = () => {
    if (this.props.isCountry) {
      this.toggleCountryModal();
    } else {
      this.focus();
    }
  };

  componentDidMount() {
    this.setState({ ...this.checkIsValid(this.defaultValue) });
  }

  componentDidUpdate(
    prevProps: IAvTextInputProps,
    prevState: IAvTextInputState
  ) {
    if (get(this.data, 'value')) {
      const validData = this.checkIsValid(this.data.value);
      if (prevState.errorText !== validData.errorText) {
        this.setState({ ...validData }, () => {
          const { value, isValid, isEdited } = this.data;
          this.props.onValidationChange &&
            this.props.onValidationChange(value, isValid, isEdited);
        });
      }
    }
    if (this.props.isFocused !== prevProps.isFocused) {
      this.setState({ isFocused: this.props.isFocused }, () => {
        if (this.props.isFocused) {
          this.focus();
        } else {
          this.blur();
        }
      });
    }

    if (this.props.country?.alpha2Code !== prevProps.country?.alpha2Code) {
      this.setState({ selectedCountry: this.props.country });
    }
  }

  render() {
    const {
      value,
      label,
      chevroned,
      isDisabled,
      edited,
      onTouchStart,
      showSearchIcon,
      containerStyle,
      inputCustomStyles,
      otherTextInputProps,
      placeholderTextColor,
      leftComponent,
      testID,
      touchTestID,
      inputContainerStyle,
      rightComponent,
      isCountry,
      isPhone,
      renderWeb,
    } = this.props;
    const { isFocused, hideSecureText, maskedValue, selectedCountry } =
      this.state;
    const stringValue =
      maskedValue || value || (isCountry ? selectedCountry?.name : '');
    const valueProps = stringValue ? { value: `${stringValue}` } : {};
    const pointerEvents = onTouchStart || isCountry ? 'none' : 'auto';

    const textInputProps: TextInputProps = {
      testID: testID,
      autoCorrect: false,
      autoCapitalize: 'none',
      pointerEvents: pointerEvents,
      placeholderTextColor: placeholderTextColor || colors.lightGrey,
      selectionColor: colors.lightGrey,
      underlineColorAndroid: 'transparent',
      allowFontScaling: false,
      style: [
        styles.textInput,
        showSearchIcon && styles.textInputWithLeftIcon,
        inputCustomStyles,
      ],
      ...otherTextInputProps,
      onBlur: this.handleOnBlur,
      onFocus: this.handleOnFocus,
    };

    const allInputProps = {
      ref: (ref: TextInput) => {
        this.textInputRef = ref;
        this.props.createRef && this.props.createRef(this);
      },
      ...textInputProps,
      ...valueProps,
      secureTextEntry: hideSecureText,
      onChangeText: this.onChangeText,
    };

    return (
      <>
        <View
          style={[
            styles.container,
            !label && styles.containerWithNoLabel,
            containerStyle,
          ]}
        >
          {this.renderLabel(!!isFocused, label || '')}
          <View style={inputContainerStyle}>
            <TouchInput
              {...{ ...this.props, ...this.data, ...this.state }}
              isDisabled={isDisabled || isPhone}
              onTouchStart={() =>
                !isDisabled &&
                (onTouchStart ? onTouchStart() : this.touchStart())
              }
              testID={touchTestID}
            >
              {this.renderSearchIcon(!!isFocused, !!showSearchIcon)}
              {leftComponent}
              {isPhone && (
                <Label
                  numberOfLines={1}
                  type={isFocused ? 'H2Dark' : 'H2Light'}
                  style={styles.phonePlus}
                  text={'+'}
                />
              )}
                <TextInput {...allInputProps} />
                {renderWeb?.()}
              {this.renderRightIcon('Chevron', !!(chevroned && !isDisabled))}
              {this.renderEditButton(!!edited)}
              {this.renderEyeButton(!!otherTextInputProps?.secureTextEntry)}
              {rightComponent}
            </TouchInput>
            {this.renderErrorMessage()}
          </View>
        </View>
        {this.renderCountryModal()}
      </>
    );
  }

  renderErrorMessage = () => {
    const errorText = this.props.isForceShowErrorText
      ? this.props.errorText
      : this.state.errorText;
    return errorText ? (
      <Label type="H2Alert" numberOfLines={1} text={errorText} />
    ) : null;
  };

  renderLabel = (isFocused: boolean, text: string) => {
    return text ? (
      <Label
        numberOfLines={1}
        type={isFocused ? 'H2Dark' : 'H2Light'}
        text={text + (this.props.isValid ? '' : ' ' + constants.optional)}
      />
    ) : null;
  };

  renderRightIcon = (iconName: TIconName, isVisible: boolean) => {
    return isVisible ? (
      <AvIcon name={iconName} fill={colors.lightGrey} width={63} />
    ) : null;
  };

  renderEditButton = (isVisible: boolean) => {
    const { isFocused } = this.state;
    const testID = this.props.testID && `${this.props.testID}EditBtn`;
    return isVisible ? (
      <TouchableView
        onPress={() =>
          this.props.onTouchStart
            ? this.props.onTouchStart()
            : this.touchStart()
        }
        testID={testID}
      >
        <AvIcon
          name={'EditDetails'}
          fill={isFocused ? colors.dark : colors.lightGrey}
          width={63}
        />
      </TouchableView>
    ) : null;
  };

  renderEyeButton = (isVisible: boolean) => {
    const { hideSecureText } = this.state;
    const testID = this.props.testID && `${this.props.testID}EyeBtn`;
    return isVisible ? (
      <TouchableView
        onPress={() => this.setState({ hideSecureText: !hideSecureText })}
        testID={testID}
      >
        <AvIcon
          name={hideSecureText ? 'EyeOutline' : 'Eye'}
          fill={colors.lightGrey}
          width={63}
        />
      </TouchableView>
    ) : null;
  };

  renderSearchIcon = (isFocused: boolean, isVisible: boolean) => {
    return isVisible ? (
      <AvIcon
        name={'Search'}
        fill={isFocused ? colors.dark : colors.lightGrey}
        width={63}
      />
    ) : null;
  };

  renderCountryModal = () => {
    if (this.props.isCountry || this.props.isPhone) {
      return (
        <AvModal
          title={constants.headers.selectCountry}
          isVisible={this.state.isCountryModalVisible}
          onBackdropPress={this.toggleCountryModal}
        >
          <SelectCountry
            onItemPress={(selectedCountry) => {
              this.setState({ selectedCountry, isCountryModalVisible: false });
              this.props.isCountry &&
                this.onChangeText(selectedCountry.name, selectedCountry);
            }}
            isEu={this.props.isEu}
            isAddress={this.props.isAddressCountries}
            tripOnly={this.props.isTripCountries}
          />
        </AvModal>
      );
    }
  };
}
