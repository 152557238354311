import React from 'react';
import styles from './styles';
import { Animated, LayoutChangeEvent, View } from 'react-native';
import { AvIcon, TouchableView } from '../../components';
import { FormatedText } from './FormatedText';
import { colors, globalStyles } from '../../common';
import {
  IAccordionSearchTogglePanelProps,
  IAccordionSearchTogglePanelState,
} from './types';

export class TogglePanel extends React.Component<
  IAccordionSearchTogglePanelProps,
  IAccordionSearchTogglePanelState
> {
  state = {
    expandedHeight: 0,
    height: new Animated.Value(0),
  };

  isCalculated: boolean = false;

  componentDidUpdate(prevProps: IAccordionSearchTogglePanelProps): void {
    if (this.props.expanded !== prevProps.expanded) {
      this.animate(this.props.expanded);
    }
  }

  renderText = (
    {
      text,
      id,
      index,
      parentId,
      onTextPress,
      children,
      style,
    }: IAccordionSearchTogglePanelProps,
    i?: number
  ): any => {
    if (children) {
      return (
        <View style={styles.childrenWrap}>
          {children.map((item, i) => {
            return (
              <View key={i} style={styles.childrenItemWrap}>
                {this.renderText(
                  {
                    title: item.title,
                    text: item.body,
                    id: item.id,
                    parentId: item.parentId,
                    index,
                    expanded: true,
                    onToggle: () => {},
                    onTextPress,
                    style,
                  },
                  i
                )}
              </View>
            );
          })}
        </View>
      );
    }
    const textComponent = (
      <FormatedText
        key={`${i || index}_${id}`}
        text={text}
        style={[styles.bodyText, style]}
        type={this.props.type}
      />
    );
    return onTextPress ? (
      <TouchableView
        key={`${i || index}_${id}`}
        onPress={() => onTextPress(index, id, parentId)}
        testID={`accordionSearchTogglePanelTextItem${i || index}`}
      >
        {textComponent}
      </TouchableView>
    ) : (
      textComponent
    );
  };

  render() {
    const { title, expanded, onToggle, index } = this.props;
    const { height } = this.state;
    const chevronStyle = expanded ? globalStyles.chevronUp : {};
    if (title) {
      return (
        <>
          <TouchableView
            onPress={onToggle}
            style={[styles.row, index > 0 && styles.borderRow]}
            testID={`accordionSearchTogglePanelChevronItem${this.props.index}`}
          >
            <FormatedText text={title} style={styles.label} isTitle />
            <View style={styles.rightIconContainer}>
              <AvIcon
                name={'Chevron'}
                containerStyle={chevronStyle}
                fill={colors.dark}
              />
            </View>
          </TouchableView>
          <Animated.View
            onLayout={this.setHeight}
            style={[
              styles.textItemContainer,
              this.isCalculated ? { height } : { opacity: 0 },
            ]}
          >
            <View
              style={[
                this.isCalculated && { height: this.state.expandedHeight },
              ]}
            >
              {this.renderText(this.props)}
            </View>
          </Animated.View>
        </>
      );
    }
    return (
      <View style={styles.singleTextItemContainer}>
        {this.renderText(this.props)}
      </View>
    );
  }

  animate = (expanded: boolean) => {
    Animated.timing(this.state.height, {
      toValue: expanded ? this.state.expandedHeight : 0,
      duration: 250,
      useNativeDriver: false,
    }).start();
  };

  setHeight = (event: LayoutChangeEvent) => {
    if (!this.isCalculated) {
      this.isCalculated = true;
      const { height: expandedHeight } = event.nativeEvent.layout;
      this.setState({ expandedHeight }, () =>
        this.animate(this.props.expanded)
      );
    }
  };
}
