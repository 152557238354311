import { get, isArray, join, upperFirst } from 'lodash';
import React from 'react';
import {
  KeyboardAvoidingView,
  LayoutAnimation,
  ScrollView,
  View,
} from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import {
  EAuthProvider,
  checkReferrerCode,
  getUser,
  loginWithPassword,
  loginWithToken,
  passwordReset,
  providerAuth,
  signUpWithPassword,
} from '../../api-store';
import {
  constants,
  fadeLayout,
  isIOS,
  isWeb,
  linkActions
} from '../../common';
import { AvAlertComponent, BodyContainer, Label, Logo } from '../../components';
import {
  avAlert,
  avAlertToggle,
  avConfirm,
} from '../../components/AvAlert/alertActions';
import { usePrevious } from '../../hooks/usePrevious';
import {
  AuthForgotEmailSentSceneName,
  AuthLoginSceneName,
  AuthSignUpSceneName
} from '../../navigation/names';
import { IDefaultState } from '../../store/types';
import LoginForm from './LoginForm';
import { PasswordForgotForm } from './PasswordForgot';
import SignUpForm from './SignUpForm';
import { ThirdPartyAuth } from './ThirdPartyAuth/index';
import {
  appleOnLoginOrRegister,
  facebookOnLoginOrRegister,
  googleOnLoginOrRegister,
} from './actions';
import styles from './styles';
import { EFormType, IAuthProps, IAuthState } from './types';

export const AuthScreen: React.FC<IAuthProps> = (props) => {
  const prevProps = usePrevious<IAuthProps>(props);
  const [isHideAgree, setIsHideAgree] = React.useState(false);
  const [state, setState] = React.useState<IAuthState>({
    isLoading: false,
    isReady: false,
    isLoadingGoogleAuth: false,
    isLoadingFaceBookAuth: false,
    isLoadingAppleAuth: false,
    restoreEmail: '',
    restoreMessage: '',
  });

  React.useEffect(() => {
    const token = props.route.params?.token;
    const prevToken = prevProps?.route?.params?.token;
    if (prevToken && token && token !== prevToken) {
      handleLoginWithToken();
    }
  }, [prevProps]);

  React.useEffect(() => {
    handleLoginWithToken();
  }, []);

  const hideIsLoading = () => {
    setState({
      ...state,
      isLoading: false,
      isLoadingGoogleAuth: false,
      isLoadingFaceBookAuth: false,
      isLoadingAppleAuth: false,
    });
  };

  const handleError = (e: any) => {
    hideIsLoading();
    if (!e || (e.message && /cancelled|canceled/.test(e.message))) return;
    const message = e.response?.data?.message && (isArray(e.response?.data?.message)
        ? join(e.response?.data?.message, ', ')
        : e.response?.data?.message);
    props.avAlert("", upperFirst(message || e.message));
  };

  const restoreMessageHide = () => {
    setState({ ...state, restoreMessage: '', restoreEmail: '' });
  };

  const restoreMessageConfirm = () => {
    restoreMessageHide();
    props.passwordReset(state.restoreEmail);
  };

  const restoreMessageButtons = () => {
    return [{ text: constants.emailPassword, onPress: restoreMessageConfirm }];
  };

  const handleOnLogin = async (email: string, password: string) => {
    try {
      setState({ ...state, isLoading: true });
      await props.loginWithPassword({ email, password });
      hideIsLoading();
    } catch (e) {
      hideIsLoading();
      if (email && `../../{e.message}`.includes('have upgraded our security')) {
        return setState({
          ...state,
          restoreMessage: e.message,
          restoreEmail: email,
        });
      }
      handleError(e);
    }
  };

  const handleLoginWithToken = async () => {
    const token = props.route.params?.token;
    if (!token || state.isLoading) {
      return;
    }
    props.avAlertToggle(false);
    try {
      setState({ ...state, isLoading: true });
      await props.loginWithToken(token);
      hideIsLoading();
    } catch (e) {
      handleError(e);
    }
  };

  const handleOnSignUp = async (
    email: string,
    password: string,
    refCode?: string
  ) => {
    try {
      setState({ ...state, isLoading: true });
      if (refCode) {
        await props.checkReferrerCode(refCode);
      }
      await props.signUpWithPassword({
        email,
        password,
        refCode,
      });
      hideIsLoading();
      props.avAlert('', constants.auth.sendVerifyEmailSuccess, () => {
        props.navigation.navigate(AuthLoginSceneName, { email });
      });
    } catch (e) {
      handleError(e);
    }
  };

  const handleOnPasswordForgot = async (emailField: {
    value: string;
    isValid: boolean;
  }) => {
    try {
      if (!emailField.isValid) {
        throw new Error('invalid email');
      }
      const email = emailField.value;
      setState({ ...state, isLoading: true });
      await props.passwordReset(email);
      hideIsLoading();
      props.avAlert('', constants.auth.sendForgotEmailSuccess, () =>
        props.navigation.navigate(AuthLoginSceneName, { email })
      );
    } catch (e) {
      handleError(e);
    }
  };

  const handleGoogleOnLoginOrRegister = async (
    webToken?: string,
    refCode?: string
  ) => {
    try {
      setState({ ...state, isLoadingGoogleAuth: true });
      if (refCode) {
        await props.checkReferrerCode(refCode);
      }

      let code;
      if (isWeb) {
        code = webToken;
      } else {
        const res = await googleOnLoginOrRegister();
        code = res.code;
      }

      await props.providerAuth(EAuthProvider.GOOGLE, code, refCode);
      hideIsLoading();
    } catch (e) {
      handleError(e);
    }
  };

  const handleAppleOnLoginOrRegister = async (refCode?: string) => {
    try {
      setState({ ...state, isLoadingAppleAuth: true });
      if (refCode) {
        await props.checkReferrerCode(refCode);
      }
      const { code } = await appleOnLoginOrRegister();
      await props.providerAuth(EAuthProvider.APPLE, code, refCode);
      hideIsLoading();
    } catch (e) {
      handleError(e);
    }
  };

  const handleFacebookOnLoginOrRegister = async (
    webToken?: string,
    refCode?: string
  ) => {
    try {
      setState({ ...state, isLoadingFaceBookAuth: true });
      if (refCode) {
        await props.checkReferrerCode(refCode);
      }
      let code;
      if (isWeb) {
        code = webToken;
      } else {
        const res = await facebookOnLoginOrRegister();
        code = res.code;
      }
      await props.providerAuth(EAuthProvider.FACEBOOK, code, refCode);
      hideIsLoading();
    } catch (e) {
      handleError(e);
    }
  };

  const getFormType = () => {
    return props.route.params?.formType || EFormType.LOGIN;
  };

  const isButtonDisabled = () => {
    return (
      state.isLoadingAppleAuth ||
      state.isLoadingGoogleAuth ||
      state.isLoadingFaceBookAuth
    );
  };

  const referralLinkPress = () =>
    props.navigation.navigate(AuthSignUpSceneName);

  const signUpLinkPress = () => props.navigation.navigate(AuthSignUpSceneName);

  const forgotLinkPress = () =>
    props.navigation.navigate(AuthForgotEmailSentSceneName);

  const handleInputFocus = (isFocus: boolean) => {
    if (isIOS) {
      LayoutAnimation.configureNext(fadeLayout);
    }
    setIsHideAgree(isFocus);
  };

  const renderForms = () => {
    const formType = getFormType();
    switch (formType) {
      case EFormType.SIGNUP:
        return (
          <BodyContainer>
            <SignUpForm
              onLoginLinkPress={() =>
                props.navigation.navigate(AuthLoginSceneName)
              }
              onSignUpPress={handleOnSignUp}
              isLoading={state.isLoading}
              buttonDisabled={isButtonDisabled()}
              renderThirdParty={renderThirdParty}
              onInputFocus={handleInputFocus}
            />
          </BodyContainer>
        );

      case EFormType.FORGOT:
        return (
          <PasswordForgotForm
            isLoading={state.isLoading}
            passwordReset={handleOnPasswordForgot}
          />
        );

      default:
        return (
          <BodyContainer
            containerStyle={{
              justifyContent: 'space-between',
              flexDirection: 'column',
              flex: 1,
            }}
          >
            <LoginForm
              onSignUpLinkPress={signUpLinkPress}
              onForgotLinkPress={forgotLinkPress}
              onReferralLinkPress={referralLinkPress}
              onLoginPress={handleOnLogin}
              isLoading={state.isLoading}
              buttonDisabled={isButtonDisabled()}
              onInputFocus={handleInputFocus}
              email={props.route.params?.email}
              password={props.route.params?.password}
            />
            {renderThirdParty()}
          </BodyContainer>
        );
    }
  };

  const renderThirdParty = (refCode?: string) => (
    <ThirdPartyAuth
      googleOnLoginOrRegister={(token) =>
        handleGoogleOnLoginOrRegister(token, refCode)
      }
      facebookOnLoginOrRegister={(token) =>
        handleFacebookOnLoginOrRegister(token, refCode)
      }
      appleOnLoginOrRegister={() => handleAppleOnLoginOrRegister(refCode)}
      isLoadingGoogleAuth={state.isLoadingGoogleAuth}
      isLoadingFaceBookAuth={state.isLoadingFaceBookAuth}
      isLoadingAppleAuth={state.isLoadingAppleAuth}
      buttonDisabled={isButtonDisabled() || state.isLoading}
    />
  );

  const renderAgree = () => {
    const formType = getFormType();
    if (formType === EFormType.FORGOT || isHideAgree) return null;
    return (
      <Label
        centered
        useFormatter
        type="H1Dark"
        text={constants.labels.tapAgreeToAccept}
        linkActions={linkActions(props.navigation)}
        style={styles.agree}
      />
    );
  };

  const formType = getFormType();
  return (
    <>
      <KeyboardAvoidingView
        style={styles.container}
        {...(isIOS ? { behavior: 'padding' } : {})}
      >
        <SafeAreaView edges={['top', 'bottom']} style={styles.container}>
          <ScrollView
            keyboardShouldPersistTaps={'handled'}
            showsVerticalScrollIndicator={false}
            contentContainerStyle={{ height: 100 }}
            {...(isIOS ? { style: { overflow: 'visible' } } : {})}
          >
            <View>
              <Logo style={styles.logo} />
              {renderForms()}
            </View>
            {isWeb ? (
              <View
                style={{ height: 90, width: '100%' }}
              />
            ) : undefined}
          </ScrollView>
          {renderAgree()}
        </SafeAreaView>
      </KeyboardAvoidingView>
      <AvAlertComponent
        buttons={restoreMessageButtons()}
        onBackdropPress={restoreMessageHide}
        isVisible={!!state.restoreMessage}
        message={state.restoreMessage}
      />
    </>
  );
};

const mapStateToProps = (state: IDefaultState) => {
  const user = get(state, 'api.user', {});
  return { user };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      loginWithPassword,
      loginWithToken,
      signUpWithPassword,
      passwordReset,
      providerAuth,
      avAlert,
      avConfirm,
      avAlertToggle,
      checkReferrerCode,
      getUser
    },
    dispatch
  );
};

export default compose<any>(connect(mapStateToProps, mapDispatchToProps))(
  AuthScreen
);
