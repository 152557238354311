import { EDirname } from '../../api-store';
import { constants, createApiUploadFile } from '../../common';
import { avAlert, avConfirm } from '../../components/AvAlert/alertActions';
import { avLoader } from '../../components/AvAlert/loaderActions';
import { CameraPicturePreview } from '../../components/CameraContainer';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { documentsContentView } from './documentsContentView';
import { selfieCameraMask } from './selfieCameraMask';
import { handleOnSavePicture } from './selfieServices';
import { IDocumentPreviewProps } from './types';

export const SelfiePreviewView: React.FC<IDocumentPreviewProps> = props => {
  const picture = props.route.params?.selfie;

  const onSave = () => {
    handleOnSavePicture(props, picture);
  };

  const onRetake = () => {
    props.navigation.goBack();
  };

  return (
    <CameraPicturePreview
      {...props}
      mirror
      onSave={onSave}
      onRetake={onRetake}
      picture={createApiUploadFile(picture, EDirname.SELFIES)}
      imageMask={selfieCameraMask('selfie-preview', true)}
      testIdPrefix='selfie'
      imageContent={documentsContentView(
        '',
        constants.selfiePreviewPageMessage,
        true
      )}
    />
  );
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      avAlert,
      avLoader,
      avConfirm
    },
    dispatch
  );
};

export const SelfiePreview = compose(connect(null, mapDispatchToProps))(
  SelfiePreviewView
);
