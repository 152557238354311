import {
  ETaxformStatus,
  getTaxformPdf,
  getTrips,
  getUser,
  ITrip,
  setTrip,
  updateTrip,
} from '../../../../api-store';
import { colors, constants, getCountryCode, getCurrency, getTripActualTaxform, getTripStatus, isWeb } from '../../../../common';
import { amountFormat } from '../../../../common/amountHelpers';
import { AvIcon, CustomButton, Label } from '../../../../components';
import { avAlert, avConfirm } from '../../../../components/AvAlert/alertActions';
import { avLoader } from '../../../../components/AvAlert/loaderActions';
import { tripIsAfterDate } from '../../../../containers/Trip/tripHelpers';
import { ITripCarouselProps, ITripCarouselState } from '../../../../containers/Trip/types';
import {
  EditTripSceneName,
  HomeSceneName,
  SelectTripCountrySceneName
} from '../../../../navigation/names';
import { IDefaultState } from '../../../../store/types';
import { filter, map, orderBy, size } from 'lodash';
import moment from 'moment';
import React from 'react';
import { ActivityIndicator, FlatList, View } from 'react-native';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import styles from './styles';

class TripCarousel extends React.PureComponent<
  ITripCarouselProps,
  ITripCarouselState
> {
  state = {
    isDownloadingTripId: undefined,
  };
  handleOnAddNewTrip = () => {
    this.props.navigation.navigate(SelectTripCountrySceneName);
  };

  handleOnSelectTrip = (trip: ITrip) => async () => {
    const taxform = getTripActualTaxform(trip);
    if (taxform?.uploadId || taxform?.status === ETaxformStatus.STAMPED) {
      this.setState({ isDownloadingTripId: trip.id });
      await this.props.getTaxformPdf(taxform.id).catch((e: any) => {
        const errorMessage =
          e?.message || `Tax-free form ${taxform.invoiceCode} download error`;
        this.props.avAlert('', errorMessage);
      });
      this.setState({ isDownloadingTripId: undefined });
      return;
    }

    if (tripIsAfterDate(trip.departureDate)) {
      const { title, body } = constants.trip.overDepartureDate;
      return this.props.avConfirm(title, body, () =>
        this.props.navigation.navigate(EditTripSceneName, {
          tripId: trip.id,
        })
      );
    }
    if (trip.isActive) {
      return this.props.navigation.navigate(HomeSceneName);
    }
    const updatedTrip = { ...trip, isActive: true };
    this.props.setTrip(trip.id, updatedTrip);
    this.props.updateTrip(trip.id, updatedTrip);
    this.props.navigation.navigate(HomeSceneName);
  };

  componentDidMount() {
    this.props.getTrips();
  }

  componentDidUpdate(prevProps: ITripCarouselProps) {
    if (
      this.props.navigation.isFocused() &&
      this.props.navigation.isFocused() !== prevProps.navigation.isFocused()
    ) {
      this.props.getTrips();
    }
  }

  renderCardStackedDetails = (item: ITrip, isArrival: boolean) => {
    const date = moment(isArrival ? item.arrivalDate : item.departureDate);
    return (
      <View style={styles.cardStackedDetails}>
        <Label
          text={item.country.alpha2Code}
          type='TripCardCountryAlphaCodeBold'
          crop
        />
        <Label text={date.isValid() ? date.format('DD') : 'DD'} type='TripCardDateDaySemiBold' crop />
        <View style={styles.cardStackedDetailsDate}>
          <Label
            text={date.isValid() ? date.format('MMM').toUpperCase() : 'MMM'}
            type='TripCardDateMonthExtraBold'
            crop={!isWeb}
          />
          <Label text={date.isValid() ? date.format('YYYY') : 'YYYY'} type='TripCardDateYearBold' crop />
        </View>
      </View>
    );
  };

  renderAmount = (item: ITrip) => {
    const amount = amountFormat(item.paymentDetails?.totalRefund || 0);
    const isDownloading = this.state.isDownloadingTripId === item.id;
    return (
      <View style={styles.cardAmountContainer}>
        {isDownloading ? (
          <ActivityIndicator color='white' size='large' />
        ) : (
          <View>
            <Label
              white
              type='H3SemiBold'
              text={getCurrency(getCountryCode(item.country))}
              style={styles.cardRefundCurrency}
            />
            <Label crop={!isWeb} type='TripCardAmountLight' text={amount} />
          </View>
        )}
      </View>
    );
  };

  renderTripCard = (trip: ITrip, i: number) => (
    <>
      <View style={styles.cardContainer}>
        <View style={styles.cardDateContainer}>
          {this.renderCardStackedDetails(trip, true)}
          <AvIcon
            name={'Plane'}
            width={44}
            fill={colors.white}
            containerStyle={styles.cardPlaneIcon}
          />
          {this.renderCardStackedDetails(trip, false)}
        </View>

        {this.renderAmount(trip)}

        <View style={styles.cardCompletedLabel}>
          <Label
            type='H3SemiBoldAlert'
            text={getTripStatus(trip)}
            style={styles.cardStatusLabel}
            testID={`tripStatus${i}`}
          />
        </View>
      </View>
    </>
  );

  renderCardItem = ({ item, index }: any) => {
    if (index === 0) {
      return (
        <CustomButton
          accent
          buttonStyle={styles.addTripButton}
          iconComponent={<AvIcon name={'Plus'} width={102} />}
          onPress={this.handleOnAddNewTrip}
          testID='addNewTripBtn'
        />
      );
    }
    return (
      <CustomButton
        accent
        buttonStyle={styles.cardButtonContainer}
        iconComponent={this.renderTripCard(item, index)}
        onPress={this.handleOnSelectTrip(item)}
        testID={`carouselTrip${index}`}
      />
    );
  };

  render() {
    const data: any = [1, ...map(this.props.trips)];
    return (
      <View style={styles.container}>
        <Label
          dark
          type='HeroTitle'
          isUpperCase
          text={constants.labels.myTrips}
        />
        <FlatList
          inverted={this.props.isInverted}
          data={data}
          horizontal={true}
          keyExtractor={(item: ITrip, i: number) => `${item.id}${i}`}
          renderItem={this.renderCardItem}
          showsHorizontalScrollIndicator={false}
          contentContainerStyle={styles.tripListContainer}
          style={styles.tripList}
          getItemLayout={(_, index) => ({
            length: styles.cardContainer.width,
            offset: styles.cardContainer.width * index,
            index,
          })}
        />
      </View>
    );
  }
}

const mapStateToProps = (state: IDefaultState) => {
  const filtered = filter(state.api.user?.trips, v => !!v?.id);
  const isInverted = !!size(filtered);
  const trips = orderBy(filtered, 'createdAt', isInverted ? 'desc' : 'asc');
  return {
    trips,
    isInverted,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      avConfirm,
      avAlert,
      getTrips,
      getUser,
      setTrip,
      avLoader,
      updateTrip,
      getTaxformPdf,
    },
    dispatch
  );
};

export default compose<any>(connect(mapStateToProps, mapDispatchToProps))(
  TripCarousel
);
