import { StyleSheet } from 'react-native';
import { colors, sketchSize } from '../../../common';

export default StyleSheet.create({
  headerContainer: {
    backgroundColor: colors.accent,
  },
  headerButton: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    paddingBottom: sketchSize(24),
  },
  headerContentContainer: {
    marginTop: sketchSize(30),
    marginBottom: sketchSize(19),
  },
  headerContentTouch: { marginHorizontal: sketchSize(100) },
  editTripImage: {
    width: sketchSize(208),
    height: sketchSize(63),
  },
  refundCurrency: {
    position: 'absolute',
    left: sketchSize(-60),
    top: sketchSize(6),
  },
  refundInfo: {
    position: 'absolute',
    right: sketchSize(-60),
    top: sketchSize(6),
  },
  refundAmount: {
    marginBottom: sketchSize(23),
  },
  totalRefund: {
    marginBottom: sketchSize(20),
  },
  backgroundImage: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    right: 0,
    bottom: 0,
  },
  amountContainer: {
    maxWidth: '90%',
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dateContainer: {
    marginLeft: sketchSize(18),
  },
  headerWithStackedDetailsContainer: {
    flexDirection: 'row',
    marginHorizontal: sketchSize(20),
  },
  center: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  dot: {
    width: sketchSize(14),
    height: sketchSize(14),
    borderRadius: sketchSize(10),
    backgroundColor: colors.dark,
  },
  moreButtonWrap: {
    position: 'absolute',
    right: sketchSize(40),
    top: sketchSize(20),
  },
  moreButtonTouch: {
    height: sketchSize(180),
    width: sketchSize(180),
    alignItems: 'flex-end',
  },
  leftButtonWrap: {
    position: 'absolute',
    left: sketchSize(40),
    top: sketchSize(20),
  },
  leftButtonTouch: {
    height: sketchSize(180),
    width: sketchSize(180),
    alignItems: 'flex-start',
  },
});
