import { colors, globalStyles } from "../../common";
import { AvIcon } from "../AvIcon";
import { CustomButton } from "../CustomButton";
import { Label } from "../Labels";
import React, { Component } from "react";
import { View } from "react-native";
import styles from "./styles";
import { EEmptyPageButtonTypes, IEmptyPageProps } from "./types";
import { SafeAreaView } from "react-native-safe-area-context";

export class EmptyPagePlaceholder extends Component<IEmptyPageProps> {
  static defaultProps = {
    buttonType: EEmptyPageButtonTypes.DEFAULT,
  };

  render() {
    return (
      <SafeAreaView edges={["bottom"]} style={styles.container}>
        <View style={[styles.container, this.props.containerStyle]}>
          {this.props.contentComponent || (
            <>
              <View style={[styles.imageContainer, this.props.contentStyle]}>
                {this.props.iconComponent}
              </View>
              <View
                style={[styles.labelContainer, this.props.labelContainerStyle]}
              >
                {this.props.title && (
                  <Label
                    centered
                    useFormatter
                    text={this.props.title}
                    type={"H1Bold"}
                    style={globalStyles.placeholderTitle}
                  />
                )}
                {this.props.messageComponent || (
                  <Label
                    centered
                    useFormatter
                    text={this.props.message}
                    type={"H1Dark"}
                    style={globalStyles.placeholderLabel}
                  />
                )}
              </View>
            </>
          )}
          <View
            style={[styles.buttonContainer, this.props.buttonContainerStyle]}
          >
            {this.renderButton()}
            {this.renderButtons()}
          </View>
        </View>
      </SafeAreaView>
    );
  }

  renderButtons = () => {
    if (this.props.buttons) {
      return (
        <>
          {this.props.buttons.map((v, i) => (
            <CustomButton key={`btn${i}`} {...v} />
          ))}
        </>
      );
    }
    return null;
  };

  private renderButton = () => {
    const {
      buttonType,
      buttonText,
      isButtonTextUppercase,
      onButtonPress,
      buttonIcon,
      buttonDisabled,
      buttonStyle,
      isLoading,
      buttonTestID,
    } = this.props;

    switch (buttonType) {
      case EEmptyPageButtonTypes.ROUNDED:
        return (
          <CustomButton
            accent
            isRounded
            iconComponent={
              buttonIcon
                ? AvIcon({ name: buttonIcon, fill: colors.white })
                : null
            }
            isLoading={isLoading}
            isEnabled={!buttonDisabled}
            onPress={onButtonPress}
            buttonStyle={buttonStyle}
            testID={buttonTestID}
          />
        );
      case EEmptyPageButtonTypes.DEFAULT:
        return (
          buttonText && (
            <CustomButton
              accent
              onPress={onButtonPress}
              isUppercase={
                isButtonTextUppercase === void 0 ? true : isButtonTextUppercase
              }
              isEnabled={!buttonDisabled}
              text={buttonText}
              buttonStyle={buttonStyle}
              testID={buttonTestID}
            />
          )
        );
      default:
        return null;
    }
  };
}

export default EmptyPagePlaceholder;
