import { constants } from '../../common';
import { header } from '../../components';
import { AddRefundMethod, AddRefundMethodForm } from '../../containers/Refund';
import { INavigationScreenProps } from '../../navigation';
import { commonStackNavigatorConfig } from '../../navigation/config';
import { headerTrash } from '../../navigation/utils';
import * as SceneNames from '../names';

export default {
  [SceneNames.AddRefundMethodSceneName]: {
    screen: AddRefundMethod,
    navigationOptions: (props: INavigationScreenProps) => ({
      title: constants.headers.addRefundMethod,
      ...commonStackNavigatorConfig(props),
    }),
  },
  [SceneNames.AddBankAccountSceneName]: {
    screen: AddRefundMethodForm,
    navigationOptions: (props: INavigationScreenProps) => ({
      ...header({
        ...props,
        ...headerTrash(props),
        title: constants.headers.bankAccount,
      }),
      ...commonStackNavigatorConfig(props),
    }),
  },
  [SceneNames.AddPayPalAccountSceneName]: {
    screen: AddRefundMethodForm,
    navigationOptions: (props: INavigationScreenProps) => ({
      ...header({
        ...props,
        ...headerTrash(props),
        title: constants.headers.paypalAccount,
      }),
      ...commonStackNavigatorConfig(props),
    }),
  },
  [SceneNames.AddMasterCardSceneName]: {
    screen: AddRefundMethodForm,
    navigationOptions: (props: INavigationScreenProps) => ({
      ...header({
        ...props,
        ...headerTrash(props),
        title: constants.headers.masterCardAccount,
      }),
      ...commonStackNavigatorConfig(props),
    }),
  },
  [SceneNames.AddVisaCardSceneName]: {
    screen: AddRefundMethodForm,
    navigationOptions: (props: INavigationScreenProps) => ({
      ...header({
        ...props,
        ...headerTrash(props),
        title: constants.headers.visaCardAccount,
      }),
      ...commonStackNavigatorConfig(props),
    }),
  },
  [SceneNames.AddWeChatAccountSceneName]: {
    screen: AddRefundMethodForm,
    navigationOptions: (props: INavigationScreenProps) => ({
      ...header({
        ...props,
        ...headerTrash(props),
        title: constants.headers.weChatPayAccount,
      }),
      ...commonStackNavigatorConfig(props),
    }),
  },
};
