import { globalStyles } from '../../common';
import styles from './styles';
import { IHeaderProps } from './types';
import { renderButtonFromProps } from './renderButtonFromProps';

export const header = (props: IHeaderProps) => {
  const headerStyle = props.transparent
    ? {
        headerStyle: [styles.accentHeader, styles.transparentHeader],
        headerTransparent: true,
      }
    : {
        headerStyle: styles.accentHeader,
      };

  const renderLeftButtonFromProps = renderButtonFromProps(props, 'left') || {};
  return {
    title: props.route?.params?.title || props.title || '',
    ...renderLeftButtonFromProps,
    headerRight: () => renderButtonFromProps(props, 'right'),
    headerTitleStyle: [globalStyles.headerTitle, props.headerTitleStyle],
    ...headerStyle,
  };
};
