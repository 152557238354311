import {
  ERefundMethodProvider,
  IBankAccountOption,
  ICreditCardOption,
  IPayPalOption,
  IRefundMethod,
  IWeChatPayOption,
  ECreditCard,
} from '../../api-store';
import { colors, constants, globalStyles } from '../../common';
import { AvIcon, Checkbox, TouchableView, Label } from '../../components';
import * as ScenesNames from '../../navigation/names';
import { get, padStart } from 'lodash';
import React from 'react';
import { View } from 'react-native';
import { IRefundMethodRowProps } from './types';
import { TIconName } from '../../components/AvIcon/icons';

export const createRouteNameByProvider = ({
  provider,
  data,
}: IRefundMethod) => {
  switch (provider) {
    case ERefundMethodProvider.Paypal:
      return ScenesNames.AddPayPalAccountSceneName;
    case ERefundMethodProvider.Bank:
      return ScenesNames.AddBankAccountSceneName;
    case ERefundMethodProvider.Card:
      const paymentInfoCardType = (data as ICreditCardOption).card?.cardType;
      switch (paymentInfoCardType) {
        case ECreditCard.MC:
          return ScenesNames.AddMasterCardSceneName;
        default:
          return ScenesNames.AddVisaCardSceneName;
      }
    case ERefundMethodProvider.Wechat:
      return ScenesNames.AddWeChatAccountSceneName;
  }
  return '';
};

export const renderRefundMethodRow =
  (onPress: any, onCheck?: any, isDefault?: boolean) =>
  ({ item, index }: { item: IRefundMethod; index: number }) => {
    return (
      <RefundMethodRow
        onPress={onPress}
        onCheck={onCheck}
        isDefault={isDefault}
        item={item}
        index={index}
        key={index}
      />
    );
  };

export class RefundMethodRow extends React.PureComponent<IRefundMethodRowProps> {
  render() {
    const { item, onPress } = this.props;
    const icon = getIconFromByRefundMethod(item);
    const title = getRefundMethodTitle(item);
    const subtitle = getRefundMethodSubtitle(item);
    const handleOnPress = () => onPress(item);

    return (
      <View style={globalStyles.refundMethodRowContainer}>
        <TouchableView
          onPress={handleOnPress}
          style={globalStyles.refundMethodRowContent}
          testID={`refundMethodItemTouch${this.props.index}`}>
          <AvIcon
            name={icon}
            width={100}
            containerStyle={globalStyles.refundMethodRowIcon}
          />
          <View style={globalStyles.refundMethodTitleContainer}>
            <Label type='H2Dark' text={title} />
            {!!subtitle && (
              <Label
                type='H1Dark'
                numberOfLines={1}
                text={subtitle}
                style={globalStyles.refundMethodTitle}
              />
            )}
          </View>
          {!this.props.onCheck && (
            <AvIcon
              name={'Chevron'}
              fill={colors.dark}
              containerStyle={globalStyles.chevronRight}
            />
          )}
        </TouchableView>
        {!!this.props.onCheck && (
          <Checkbox
            testID={`refundMethodCheckBox${this.props.index}`}
            onPress={this.props.onCheck}
            checked={this.props.isDefault}
          />
        )}
      </View>
    );
  }
}

export const getIconFromByRefundMethod = (method: IRefundMethod): TIconName => {
  switch (method.provider) {
    case ERefundMethodProvider.Paypal:
      return 'PayPal';
    case ERefundMethodProvider.Bank:
      return 'Bank';
    case ERefundMethodProvider.Card:
      const cardType = (method.data as ICreditCardOption).card?.cardType;
      switch (cardType) {
        case ECreditCard.MC:
          return 'MasterCard';
        case ECreditCard.VE:
        case ECreditCard.VI:
          return 'Visa';
      }
      return 'Card';
    case ERefundMethodProvider.Wechat:
      return 'WechatPay';
  }
  return 'Checkbox';
};

export const getRefundMethodSubtitle = (method: IRefundMethod) => {
  switch (method.provider) {
    case ERefundMethodProvider.Paypal:
      const { email } = method.data as IPayPalOption;
      return email;
    case ERefundMethodProvider.Card:
      const lastDigits = (method.data as ICreditCardOption).card?.lastDigits;
      return !!lastDigits && padStart(`${lastDigits}`, 19, '**** ');
    case ERefundMethodProvider.Bank:
      const { accountNumber } = method.data as IBankAccountOption;
      return accountNumber;
    case ERefundMethodProvider.Wechat:
      const { wechatId } = method.data as IWeChatPayOption;
      return wechatId;
  }
  return '';
};

export const getRefundMethodTitle = (method: IRefundMethod) => {
  switch (method.provider) {
    case ERefundMethodProvider.Paypal:
      return constants.accountTitles.paypal;
    case ERefundMethodProvider.Card:
      const cardType = (method.data as ICreditCardOption).card?.cardType;
      switch (cardType) {
        case ECreditCard.MC:
          return constants.accountTitles.mastercard;
        case ECreditCard.VI:
          return constants.accountTitles.visa;
        case ECreditCard.VE:
          return constants.accountTitles.visaElectron;
      }
      return constants.accountTitles.card;
    case ERefundMethodProvider.Bank:
      return constants.accountTitles.bankAccount;
    case ERefundMethodProvider.Wechat:
      return constants.accountTitles.weChat;
  }
  return method.provider;
};
