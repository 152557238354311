import { isDesktop } from 'react-device-detect';
import { Dimensions, Platform } from 'react-native';
// @ts-ignore
import ExtraDimensions from 'react-native-extra-dimensions-android';

export const isIOS = Platform.OS === 'ios';
export const isAndroid = Platform.OS === 'android';
export const isWeb = Platform.OS === 'web';
let { height } = Dimensions.get('window');
const dimW = Dimensions.get('window').width;
export const maxDesktopWidth = isWeb && isDesktop ? height * 0.56 : dimW;
export const deviceWidth =
  isWeb && isDesktop && dimW > maxDesktopWidth ? maxDesktopWidth : dimW;

export const isIphoneXorXS = isIOS && (deviceWidth === 812 || height === 812);
export const isIphoneXRorXSMax =
  isIOS && (deviceWidth === 896 || height === 896);
export const isIphoneSmall = isIOS && (deviceWidth === 568 || height === 568); // 5, 5S, SE
export const isIphoneMedium = isIOS && (deviceWidth === 667 || height === 667); // 6, 6S, 7, 8
export const isIphonePlus = isIOS && (deviceWidth === 736 || height === 736); // 6+, 6S+, 7+, 8+
export const isIphoneLargest = isIphoneXorXS || isIphoneXRorXSMax;

if (isAndroid) {
  height = ExtraDimensions.get('REAL_WINDOW_HEIGHT');
}

export const deviceHeight = height;

const isRippleEffectSupported = isAndroid && Number(Platform.Version) >= 21;

const designWidth = 375;
const designHeight = 812;

export const scale = deviceWidth / designWidth;
export const isSmallDevice = isWeb || deviceHeight < designHeight;

export const sketchSize = (value: number, isHeight?: boolean): number => {
  const scaleValue = isHeight
    ? deviceHeight / designHeight
    : deviceWidth / designWidth;
  return Math.round((value / 3) * scaleValue);
};

const keyboardOffset = deviceHeight / 10;
const scrollViewContentInset = { bottom: sketchSize(80) };

export const headerHeight = isIOS ? 64 : 56;

export const metrics = {
  isIOS,
  isAndroid,
  deviceWidth,
  deviceHeight,
  isIphonePlus,
  isIphoneSmall,
  isIphoneXorXS,
  isIphoneMedium,
  isIphoneLargest,
  isIphoneXRorXSMax,
  isRippleEffectSupported,
  sketchSize,
  keyboardOffset,
  scrollViewContentInset,
  scale,
};

export default metrics;
