import { ITrip } from '../../api-store';
import { IAvAlertActions } from '../../components/AvAlert/types';
import { INavigationScreenProps } from '../../navigation';

export interface IMoreProps extends INavigationScreenProps, IAvAlertActions {
  refCode?: string;
  notifications: { [key: string]: boolean };
  currentTrip?: ITrip;
  isExistsRefundMethods: boolean;
  isExistsReferralDashboard: boolean;
  initSceneName: string;
  logOut(): Promise<void>;
  deleteUser(): Promise<void>;
}

export interface IMoreState {
  isLoadingLogOut: boolean;
  isLoadingItem?: string;
}

export interface IMoreGroupItem {
  title: string;
  items: IMoreListItem[];
}

export interface IMoreListItem {
  title: string;
  isLoading?: boolean;
  onPress?(item: IMoreListItem): void;
  screenName?: string;
  fill?: string;
  key?: ENotificationType;
  testID?: string;
  id?: string;
}

export enum ENotificationType {
  Common = 'common',
  Info = 'info',
  Account = 'account',
  Address = 'address',
  Passport = 'document',
  Refund = 'refund',
  Trip = 'trip',
  Referral = 'referral',
}
