import Web from '../../containers/Web';
import { constants } from '../../common/constants';
import { commonStackNavigatorConfig } from '../../navigation/config';
import {
  PrivacyPolicySceneName,
  TermsConditionsSceneName,
} from '../../navigation/names';

export default {
  [PrivacyPolicySceneName]: {
    screen: Web,
    params: {
      url: constants.browser.legal,
    },
    navigationOptions: (props: any) => ({
      title: constants.headers.privacyPolicy,
      headerTransparent: false,
      ...commonStackNavigatorConfig(props),
    }),
  },
  [TermsConditionsSceneName]: {
    screen: Web,
    params: {
      url: constants.browser.terms,
    },
    navigationOptions: (props: any) => ({
      title: constants.headers.termsConditions,
      headerTransparent: false,
      ...commonStackNavigatorConfig(props),
    }),
  },
};
