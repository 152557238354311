import { constants, globalStyles, setStatusBar } from '../../common';
import PicturePreview from '../../components/PicturePreview';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { CustomButton } from '../CustomButton';
import { cameraContainerStyles } from './styles';
import { IPicturePreviewProps } from './types';

export const CameraPicturePreview: React.FC<IPicturePreviewProps> = props => {
  const {
    onSave,
    onRetake,
    imageMask,
    imageContent,
    picture,
    mirror,
    isLoading,
    testIdPrefix,
    retakeLabel
  } = props;

  React.useEffect(() => {
    setStatusBar(false);
  }, []);

  return (
    <View style={cameraContainerStyles.container}>
      <PicturePreview
        picture={picture}
        style={[
          StyleSheet.absoluteFill,
          mirror && cameraContainerStyles.mirror,
        ]}
      />
      <View style={StyleSheet.absoluteFill}>{imageMask}</View>
      <View style={cameraContainerStyles.container} />
      <SafeAreaView edges={['bottom']}>
        {imageContent}
        <CustomButton
          accent
          inverted
          isUppercase
          text={retakeLabel || constants.labels.retake}
          onPress={onRetake}
          buttonStyle={globalStyles.buttonsBottomSpace}
          testID={`${testIdPrefix}CameraReTakePictureBtn`}
        />
        <CustomButton
          accent
          text={constants.labels.next}
          onPress={onSave}
          isUppercase
          buttonStyle={globalStyles.marginBottomButton}
          testID={`${testIdPrefix}CameraSavePictureBtn`}
          isLoading={isLoading}
        />
      </SafeAreaView>
    </View>
  );
};
