import { constants } from '../../../common';
import { AvForm } from '../../../components';
import React from 'react';
import { IRefundViewProps } from '../types';
import { AddRefundHOC } from './AddRefundHOC';
import { getFormConfigs } from './formConfigs';
import { parseCardExpiryDate } from '../refundHelpers';

const AddRefundMethodFormComponent: React.FunctionComponent<
  IRefundViewProps
> = props => {
  const formProps: any = getFormConfigs(props)[props.method.provider];

  let data: any = { ...props.method.data };
  if (data.card) {
    const year = `${data.card.cardExpiry?.year}`.substring(2);
    const cardExpiry = `${data.card.cardExpiry?.month}/${year}`;
    data = { ...data.card, ...parseCardExpiryDate(cardExpiry) };
  }

  return (
    <AvForm
      {...formProps}
      autoFocus={true}
      data={data}
      isDisabled={props.isFormDisabled}
      isLoading={props.isLoading}
      buttonText={constants.labels.save}
      name={props.method.provider}
    />
  );
};

export const AddRefundMethodForm = AddRefundHOC(AddRefundMethodFormComponent);
