import React from 'react';
import { ActivityIndicator, View } from 'react-native';
import { RNFastImage } from '../../modules/RNFastImage';
import { colors, createExt, globalStyles, isWeb } from '../../common';
import styles from './styles';
import { IPicturePreviewProps, IPicturePreviewState } from './types';
import { RNPdf } from '../../modules/RNPdf';

class PicturePreview extends React.Component<
  IPicturePreviewProps,
  IPicturePreviewState
> {
  state: IPicturePreviewState = {
    isPhotoLoading: true,
    isMounted: true,
  };

  photoOnLoad = () => {
    this.props.onLoadEnd && this.props.onLoadEnd();
    this.setState({ isPhotoLoading: false });
  };

  handlePhotoOnError = () => {
    if (this.state.isMounted) {
      this.props.onError && this.props.onError();
      this.setState({ isPhotoLoading: false });
    }
  };

  componentWillUnmount() {
    this.setState({ isMounted: false });
  }

  componentDidMount() {
    this.props.onLoadStart && this.props.onLoadStart();
    this.setState({ isMounted: true, isPhotoLoading: true });
    if (this.props.picture?.uri || this.props.picture?.base64) {
      this.setState({ picture: this.props.picture });
    }
  }

  componentDidUpdate(prevProps: IPicturePreviewProps) {
    const isUrl =
      this.props.picture?.uri &&
      this.props.picture?.uri !== prevProps.picture?.uri;
    const isBase64 =
      this.props.picture?.base64 &&
      this.props.picture?.base64 !== prevProps.picture?.base64;
    if (this.state.isMounted && (isUrl || isBase64)) {
      this.setState({ picture: this.props.picture });
    }
  }

  renderView = () => {
    console.log('this.state.picture', this.state.picture);
    if (!this.state.picture) {
      return null;
    }
    const ext = createExt(this.state.picture?.base64  || this.state.picture?.name || this.state.picture?.uri);

    if (ext !== 'pdf') {
      return (
        <RNFastImage
          source={{ uri: this.state.picture?.base64 || this.state.picture?.uri }}
          resizeMode={this.props.resizeMode || 'cover'}
          style={[
            styles.container,
            this.props.style,
            isWeb && { maxWidth: '100%', height: 'auto', minHeight: '100%' },
          ]}
          onLoadEnd={this.photoOnLoad}
          onError={this.handlePhotoOnError}
        />
      );
    }
    return (
      <RNPdf
        source={{ uri: this.state.picture?.uri, cache: true }}
        onLoadComplete={this.photoOnLoad}
        onError={this.handlePhotoOnError}
        style={[
          styles.container,
          this.props.style,
          isWeb && { maxWidth: '100%', height: 'auto', minHeight: '100%' },
        ]}
        trustAllCerts={false}
      />
    );
  };

  render() {
    return (
      <View style={[styles.container, this.props.style]}>
        {this.renderView()}
        {this.state.isPhotoLoading ? (
          <ActivityIndicator
            color={colors.light}
            style={globalStyles.photoFullScreenPreloader}
          />
        ) : null}
      </View>
    );
  }
}

export default PicturePreview;
