export const images = {
  iconAvatarPlaceholder: require('../assets/img/icon_avatar_placeholder.png'),
  tripBackground: require('../assets/img/trip_background_crop_optimized.png'),
  logo: require('../assets/img/airvat-logo.png'),
  homeHeaderBackgroundGb: require('../assets/img/home-header-background-gb.png'),
  homeHeaderBackgroundFr: require('../assets/img/home-header-background-fr.png'),
  homeHeaderBackgroundBe: require('../assets/img/home-header-background-be.png'),
  homeHeaderBackgroundIt: require('../assets/img/home-header-background-it.png'),

  //icons
  airportPlaceholder: require('../assets/img/airoport-placeholder.png'),
  shopIcon: require('../assets/img/shop-icon.png'),
  receiptPhotoIcon: require('../assets/img/receipt-photo-icon.png'),
  selfiePhotoIcon: require('../assets/img/selfie-icon.png'),
  passportPhotoIcon: require('../assets/img/passport-photo-icon.png'),
  passportIcon: require('../assets/img/passport-icon.png'),
  printIcon: require('../assets/img/print-icon.png'),
  stampedTaxFormIcon: require('../assets/img/stamped-tax-form-icon.png'),
  cardsIcon: require('../assets/img/cards-icon.png'),
  receiptsIcon: require('../assets/img/receipts-icon.png'),
  securityIcon: require('../assets/img/security-icon.png'),
  receiptEligible: require('../assets/img/eligible-receipt.png'),
  invalidReceipt: require('../assets/img/invalid-receipt.png'),
  stampedTaxFormExampleFr: require('../assets/img/stamped-tax-form-example-fr.png'),
  stampedTaxFormExampleGbNir: require('../assets/img/stamped-tax-form-example-gb-nir.png'),
  customsIcon: require('../assets/img/customs-icon.png'),
  warnIcon: require('../assets/img/warning-icon.png'),
  passportRadialIcon: require('../assets/img/passport-photo-radial-icon.png'),
  selfieRadialIcon: require('../assets/img/selfie-radial-icon.png'),
  addressRadialIcon: require('../assets/img/address-radial-icon.png'),
  warning: require('../assets/img/warning.png'),
  phoneBarcode: require('../assets/img/phone_barcode.png'),
  terminalBarcodeScan: require('../assets/img/terminal_barcode_scan.png'),
  startScreenMoney: require('../assets/img/start-screen-money.png'),
};
