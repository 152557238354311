import { avAlert } from '../../components/AvAlert/alertActions';
import {
  CAMERA_OPTIONS,
  CameraContainer,
  CameraRef,
  ITakePictureResponse,
} from '../../components/CameraContainer';
import { ReceiptImagePreviewSceneName } from '../../navigation/names';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { IReceiptCameraProps } from './types';

export const ReceiptCameraView: React.FC<IReceiptCameraProps> = (props) => {
  const handleOnSavePicture = (cameraRef: CameraRef) => {
    if (!cameraRef || !cameraRef.current) return;
    cameraRef.current
      .takePictureAsync(CAMERA_OPTIONS)
      .then((picture: ITakePictureResponse) => {
        props.navigation.navigate(ReceiptImagePreviewSceneName, {
          pictures: [picture],
        });
      })
      .catch(handleOnSavePictureError);
  };

  const handleOnSavePictureError = (e: Error) => {
    props.avAlert('', e.message);
  };

  return !props.navigation.isFocused() ? null : (
    <CameraContainer
      testIdPrefix="receipt"
      onTakePicture={handleOnSavePicture}
    />
  );
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      avAlert,
    },
    dispatch
  );
};

export const ReceiptCamera = compose(connect(null, mapDispatchToProps))(
  ReceiptCameraView
);
