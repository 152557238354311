import { constants, getCountryCode, images } from '../../../common';
import { FR } from '../../../common/constants/common';
import {
  BodyContainer,
  CustomButton,
  DotList,
  Label,
  SupportButton,
  UploadActonSheet,
} from '../../../components';
import { getDefaultRefundMethod } from '../../../containers/Home/state';
import {
  AddRefundMethodSceneName,
  AddressConfirmationCameraSceneName,
  AddressConfirmationImagePreviewSceneName,
  HomeSceneName,
} from '../../../navigation/names';
import { IDefaultState } from '../../../store/types';
import { CommonActions } from '@react-navigation/native';
import { get } from 'lodash';
import React from 'react';
import { View } from 'react-native';
import { RNFastImage } from '../../../modules/RNFastImage';
import { connect } from 'react-redux';
import { compose } from 'redux';
import styles from './styles';
import { IAddressConfirmationProps } from './types';

const AddressConfirmationComponent: React.FC<
  IAddressConfirmationProps
> = props => {
  const actionSheetRef = React.useRef<any>(null);
  const handleOnTakePhoto = () => {
    props.navigation.navigate(AddressConfirmationCameraSceneName);
  };

  const handleOnUploadPhoto = async (picture: any) => {
    if (picture?.uri) {
      props.navigation.navigate(AddressConfirmationImagePreviewSceneName, {
        ...(props.route.params || {}),
        retakeLabel: constants.tryAgain,
        picture,
      });
    }
  };

  const skipAddress = () => {
    const name = props.isExistsDefaultRefundMethod
      ? HomeSceneName
      : AddRefundMethodSceneName;
    const params = {
      index: 0,
      routes: [{ name, params: props.route.params }],
    };
    props.navigation.dispatch(CommonActions.reset(params));
  };

  const showActionSheet = () => {
    actionSheetRef.current?.show();
  };

  const isFr = getCountryCode(props.document?.country) === FR;
  const residenceText = isFr
    ? constants.nonEuResidenceTextFr
    : constants.nonEuResidenceText.text;
  const isHideSkip = props.route?.params?.hideSkip;

  return (
    <>
      <BodyContainer useSafeArea>
        <RNFastImage
          resizeMode='contain'
          style={styles.placeholderIcon}
          source={images.addressRadialIcon}
        />
        <View style={styles.conditionsContainer}>
          <View style={styles.dotListItemMargin}>
            <Label
              text={residenceText}
              type='CalculatorSemibold'
              style={styles.dotListItemLabel}
            />
            {!isFr && <DotList items={constants.nonEuResidenceText.list} />}
          </View>
        </View>
        <View style={styles.agreeButtonContainer}>
          <SupportButton />
          <CustomButton
            accent
            isUppercase
            onPress={showActionSheet}
            buttonStyle={styles.nextButton}
            text={constants.addDocument}
            testID={'addressUploadDocumentBtn'}
          />
          {!isHideSkip && (
            <CustomButton
              link
              text={constants.labels.addLater}
              testID='skipAddressUploadDocumentBtn'
              onPress={skipAddress}
            />
          )}
        </View>
      </BodyContainer>
      <UploadActonSheet
        ref={actionSheetRef}
        onTakePhoto={handleOnTakePhoto}
        onUploadFile={handleOnUploadPhoto}
      />
    </>
  );
};

const mapStateToProps = (state: IDefaultState) => {
  const document = get(state, 'api.user.document');
  const isExistsDefaultRefundMethod = !!getDefaultRefundMethod(state);
  return {
    document,
    isExistsDefaultRefundMethod,
  };
};

export const AddressConfirmation = compose<any>(connect(mapStateToProps))(
  AddressConfirmationComponent
);
