import { images } from '../../common/images';
import React from 'react';
import { Image } from 'react-native';
import styles from './styles';
import { ILogoProps } from './types';
import { isWeb } from '../../common';

export const Logo: React.FC<ILogoProps> = ({ style }) => (
  <Image source={images.logo} style={[styles.logo, style, isWeb ? { minHeight: 70 } : {}]} />
);
