import React from 'react';
import { renderButton } from './renderButton';

export const renderButtonFromProps: React.FC = (props: any, key: string) => {
  if(!props[`${key}IconPress`]){
    return null;
  }
  return renderButton({
    onPress: props[`${key}IconPress`],
    icon: props[`${key}ButtonIconComponent`],
    iconName: props[`${key}IconName`],
    iconColor: props[`${key}IconColor`],
    iconSize: props[`${key}IconSize`],
    buttonComponent: props[`${key}ButtonComponent`],
    testID: props[`${key}ButtonTestID`] || `${props?.route?.name || ''}${key}Btn`
  });
};
