import { ICountry, ITrip } from '../../api-store';
import {
  colors,
  constants,
  getActiveTripId,
  getCountryCode,
  getCurrency,
  isIOS,
  metrics,
} from '../../common';
import { amountFormat, calculateTotalRefund } from '../../common/amountHelpers';
import {
  AvIcon,
  AvModal,
  AvTextInput,
  Label,
  SelectCountry,
  TouchableView,
} from '../../components';
import labelStyles from '../../components/Labels/styles';
import { IDefaultState } from '../../store/types';
import { get } from 'lodash';
import React from 'react';
import { KeyboardAvoidingView, View } from 'react-native';
import { RNFastImage } from '../../modules/RNFastImage';
import { connect } from 'react-redux';
import { compose } from 'redux';
import styles from './styles';
import { INavigationScreenProps } from '../../navigation';

interface IRefundCalculatorProps extends INavigationScreenProps {
  currentTrip?: ITrip;
}

interface IRefundCalculatorState {
  text: string;
  refund: string;
  country?: ICountry;
  isCountryModalVisible: boolean;
  pref: string;
  defaultValue: string;
}

class RefundCalculatorComponent extends React.Component<
  IRefundCalculatorProps,
  IRefundCalculatorState
> {
  state = {
    text: '',
    refund: '',
    country: this.props.currentTrip?.country,
    isCountryModalVisible: false,
    pref: '',
    defaultValue: '',
  };

  selectCountry = (country: ICountry) => {
    const pref = getCurrency(getCountryCode(country));
    const defaultValue = pref + '0';
    this.setState({
      country,
      isCountryModalVisible: false,
      pref,
      defaultValue,
      text: defaultValue,
      refund: defaultValue,
    });
  };

  toggleCountryModal = () => {
    this.setState({ isCountryModalVisible: !this.state.isCountryModalVisible });
  };

  updateCountryCurrency = () => {
    const pref = getCurrency(getCountryCode(this.state.country));
    const defaultValue = pref + '0';
    this.setState({
      pref,
      defaultValue,
      text: defaultValue,
      refund: defaultValue,
      country: this.props.currentTrip?.country,
    });
  };

  componentDidMount() {
    this.updateCountryCurrency();
  }

  componentDidUpdate(prevProps: IRefundCalculatorProps) {
    if (
      prevProps.currentTrip?.country?.id &&
      this.props.currentTrip?.country?.id &&
      getCountryCode(prevProps.currentTrip.country) !==
      getCountryCode(this.props.currentTrip.country)
    ) {
      this.updateCountryCurrency();
    }
  }

  render() {
    const country = this.state?.country;

    return (
      <>
        <KeyboardAvoidingView
          {...(isIOS ? { behavior: 'padding' } : {})}
          style={styles.container}
          keyboardVerticalOffset={metrics.keyboardOffset}>
          <View style={styles.titleStyle}>
            <Label
              type='CalculatorBold'
              text={constants.refundCalculator.title}
            />
          </View>

          {!!country && <View style={styles.fill}>
            <TouchableView
              style={styles.locationContainer}
              onPress={this.toggleCountryModal}>
              <Label type='CalculatorSemibold' text={country?.name} />
              <RNFastImage
                resizeMode='cover'
                style={styles.locationImage}
                source={{ uri: country?.flagUri }}
              />
            </TouchableView>
          </View>}

          <View style={styles.fill}>
            <AvTextInput
              key='in'
              otherTextInputProps={{
                autoFocus: true,
                value: this.state.text,
                onChangeText: this.onRefundTextChange,
                keyboardType: 'numbers-and-punctuation',
                maxLength: 9,
              }}
              inputCustomStyles={[
                labelStyles.CalculatorLight,
                styles.inputStyle,
              ]}
              inputWrapCustomStyle={styles.inputWrapStyle}
              leftComponent={
                <View style={styles.inputExtraWrapper}>
                  <AvIcon name={'Bag'} width={105} />
                </View>
              }
            />

            <AvTextInput
              key='out'
              isFocused
              placeholderTextColor={colors.darkGrey}
              otherTextInputProps={{
                editable: false,
                value: this.state.refund,
              }}
              containerStyle={styles.inputStylePadding}
              inputCustomStyles={[
                labelStyles.CalculatorLightDark,
                styles.inputStyle,
              ]}
              inputWrapCustomStyle={styles.inputWrapStyle}
              leftComponent={
                <View style={styles.inputExtraWrapper}>
                  <AvIcon name={'RefundPig'} width={125} />
                </View>
              }
            />
          </View>
        </KeyboardAvoidingView>
        {this.renderCountryModal()}
      </>
    );
  }

  renderCountryModal = () => {
    return (
      <AvModal
        navigation={this.props.navigation}
        title={constants.selectTripDepartureCountry}
        isVisible={this.state.isCountryModalVisible}
        onBackdropPress={this.toggleCountryModal}>
        <SelectCountry onItemPress={this.selectCountry} tripOnly />
      </AvModal>
    );
  };

  textFormat = (n: number): string => {
    const truncatedNumber = Math.round(n * 100) / 100;
    const [integer, fraction] = truncatedNumber.toFixed(2).split('.');
    const parseFraction = parseInt(fraction.replace(/(\d)0$/g, '$1'), 10);
    return integer
      .split('')
      .reverse()
      .reduce(
        (res, cur, i, origin) => {
          const isThirdNumber = (i + 1) % 3 === 0;
          const isLast = i === origin.length - 1;
          return !isLast && isThirdNumber ? `,${cur}${res}` : `${cur}${res}`;
        },
        parseFraction > 0 ? '.' + parseFraction : ''
      );
  };

  onRefundTextChange = (text: string) => {
    if (text === this.state.pref) {
      this.setState({
        text: this.state.defaultValue,
        refund: this.state.defaultValue,
      });
      return;
    }
    if (text.endsWith('.')) {
      this.setState({ text });
      return;
    }
    const regExp = new RegExp(
      `[,${constants.currency.eur}|,${constants.currency.gbp}]`,
      'g'
    );
    const cleanText = text.replace(regExp, '').trim();
    const parsedText = parseFloat(cleanText);
    const totalRefund = calculateTotalRefund(
      parsedText,
      getCountryCode(this.state.country)
    );
    if (isNaN(parsedText)) {
      return;
    }
    this.setState({
      text:
        parsedText > 0
          ? this.state.pref + this.textFormat(parsedText)
          : this.state.defaultValue,
      refund:
        totalRefund > 0
          ? this.state.pref +
          amountFormat(totalRefund)
            .replace(/(\.\d)0$/g, '$1')
            .trim()
          : this.state.defaultValue,
    });
  };
}

const mapStateToProps = (state: IDefaultState) => {
  const tripId = getActiveTripId(state);
  const currentTrip = get(state, `api.user.trips.${tripId}`, {});
  return {
    currentTrip,
  };
};

export const RefundCalculator = compose<any>(connect(mapStateToProps))(
  RefundCalculatorComponent
);
