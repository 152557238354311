import { getTrips, uploadStampedForm } from '../../api-store';
import {
  constants,
  getActiveTripId,
  globalStyles,
  isAndroid
} from '../../common';
import { CustomButton } from '../../components';
import { avAlert } from '../../components/AvAlert/alertActions';
import PicturePreview from '../../components/PicturePreview';
import { IDefaultState } from '../../store/types';
import { StackActions } from '@react-navigation/native';
import { get } from 'lodash';
import React from 'react';
import { View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import styles from './styles';
import {
  IStampedFormImagePreviewProps,
  IStampedFormImagePreviewState,
} from './types';

class StampedFormImagePreview extends React.Component<
  IStampedFormImagePreviewProps,
  IStampedFormImagePreviewState
> {
  state = { isFileUploading: false };

  complete = async () => {
    this.setState({ isFileUploading: true });
    try {
      const picture = this.props.route?.params?.picture;
      const taxformId = this.props.route?.params?.taxformId;
      await this.props.uploadStampedForm(taxformId, [picture]);
      await this.props.getTrips();
      this.props.navigation.dispatch(StackActions.popToTop());
    } catch (e) {
      this.setState({ isFileUploading: false });
      this.handleError(e);
    }
  };

  handleError = (e: any) => {
    this.props.avAlert('', e.message);
  };

  handleOnRetake = () => {
    this.props.navigation.goBack();
  };

  render() {
    return (
      <SafeAreaView edges={['top']} style={styles.container}>
        <View style={styles.container}>
          <PicturePreview
            style={styles.imagePreview}
            picture={this.props.route.params?.picture}
          />
          <View style={styles.buttonsContainer}>
            <CustomButton
              accent
              inverted
              isUppercase
              text={
                this.props.route.params?.retakeLabel || constants.labels.retake
              }
              onPress={this.handleOnRetake}
              buttonStyle={globalStyles.buttonsBottomSpace}
              testID='stampedCameraReTakePictureBtn'
              isEnabled={!this.state.isFileUploading}
            />
            <CustomButton
              accent
              isUppercase
              text={constants.labels.send}
              isLoading={this.state.isFileUploading}
              onPress={this.complete}
              buttonStyle={isAndroid && globalStyles.buttonsBottomSpace}
              testID='stampedCameraSavePictureBtn'
            />
          </View>
        </View>
      </SafeAreaView>
    );
  }
}

const mapStateToProps = (state: IDefaultState) => {
  const tripId = getActiveTripId(state);
  const currentTrip = get(state, `api.user.trips.${tripId}`, {});
  return {
    currentTrip,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      avAlert,
      uploadStampedForm,
      getTrips,
    },
    dispatch
  );
};

export default compose<any>(connect(mapStateToProps, mapDispatchToProps))(
  StampedFormImagePreview
);
