import { IAlertSetAction, IAlertToggleAction } from './types';

export const alertSetAction = ({
  title,
  message,
  buttons,
}: IAlertSetAction) => ({
  type: 'ALERT_SET',
  payload: {
    isVisible: true,
    title,
    message,
    buttons,
  },
});

export const alertToggleAction = ({ isVisible }: IAlertToggleAction) => ({
  type: 'ALERT_TOGGLE',
  payload: {
    isVisible,
  },
});

export const alertResetAction = () => ({
  type: 'ALERT_RESET',
});
