import { constants } from "../../common";
import { WebSiteBrowser } from "../../components/WebSiteBrowser";
import React from "react";
import { IAirvatInfoHowItWorksProps } from "./types";

export const AirvatInfoHowItWorks: React.FC<
  IAirvatInfoHowItWorksProps
> = () => {
  return (
    <WebSiteBrowser
      showsVerticalScrollIndicator
      uri={constants.browser.main}
      onShouldStartLoadWithRequest={(request) => {
        const validUrls = [
          constants.browser.main,
          "https://www.youtube.com/embed/ngm-S5PTZZ0?si=ulpBLYO_pha3jqIY",
        ];
        const result = validUrls.some((v) => v === request.url);
        return result;
      }}
    />
  );
};
