import { colors, constants, globalStyles, images, sketchSize } from '../../common';
import { AvIcon, CustomButton, Label } from '../../components';
import { filter, map, size } from 'lodash';
import React from 'react';
import { View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { RNFastImage } from '../../modules/RNFastImage';
import Svg, { Circle, Line } from 'react-native-svg';
import styles from './styles';

export const AIRVAT_INFO_MANDATORY = [
  {
    icon: images.passportRadialIcon,
    message: constants.takePhotoPassport,
    key: 'document',
  },
  {
    icon: images.selfieRadialIcon,
    message: constants.takeSelfieIdentity,
    key: 'selfie',
  },
  {
    icon: images.addressRadialIcon,
    message: constants.tellPermanentNonEuAddress,
    key: 'address',
  },
];

export const AirvatInfoMandatory: React.FC<any> = ({
  onStart,
  withOutAddress,
}) => {
  const filteredData = withOutAddress
    ? filter(AIRVAT_INFO_MANDATORY, item => item.key !== 'address')
    : AIRVAT_INFO_MANDATORY;
  return (
    <SafeAreaView edges={['bottom']} style={styles.container}>
      <View style={styles.centredVertContainer}>
        {map(filteredData, ({ key, icon, message }, index) => {
          const isFirst = index === 0;
          const isLast = index === size(filteredData) - 1;
          return (
            <View
              key={key}
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                marginHorizontal: sketchSize(100),
              }}
            >
              <RNFastImage
                source={icon}
                resizeMode='contain'
                style={[
                  styles.smallImage,
                  { marginHorizontal: 0, marginVertical: sketchSize(100) },
                ]}
              />
              <View
                style={{
                  marginRight: sketchSize(30),
                  marginLeft: sketchSize(50),
                }}
              >
                <Svg width={30} style={{ alignSelf: 'center', flex: 1 }}>
                  {!isFirst && (
                    <Line
                      stroke={colors.blueGrey}
                      strokeWidth='2'
                      strokeLinecap='round'
                      strokeDasharray='1, 16'
                      x1='5'
                      y1='0'
                      x2='5'
                      y2='50%'
                    />
                  )}
                  {!isLast && (
                    <Line
                      stroke={colors.blueGrey}
                      strokeWidth='2'
                      strokeLinecap='round'
                      strokeDasharray='1, 16'
                      x1='5'
                      y1='50%'
                      x2='5'
                      y2='100%'
                    />
                  )}
                  <Circle cx='5' cy='50%' r='3' fill={colors.dark} />
                </Svg>
              </View>
              <Label
                text={message}
                useFormatter
                type='H1Dark'
                style={styles.container}
              />
            </View>
          );
        })}
      </View>
      <CustomButton
        accent
        isUppercase
        text={constants.addPassport}
        onPress={onStart}
        buttonStyle={globalStyles.marginBottomButton}
        testID='addDocumentsBtn'
      />
    </SafeAreaView>
  );
};
