import { constants, isAndroid, isWeb } from '../../common';
import { findLastIndex } from 'lodash';
import React from 'react';
import {
  RNActionSheet,
  RNActionSheetCustom,
} from '../../modules/RNActionSheet';
import { IUploadActonSheetProps } from './types';
import {
  RNDocumentPicker,
  RNDocumentPickerTypes,
} from '../../modules/RNDocumentPicker';

const Sheet: any = isAndroid && !isWeb ? RNActionSheetCustom : RNActionSheet;
const styles = isAndroid
  ? {
      buttonText: {
        width: '100%',
        textAlign: 'center',
      },
      titleText: {
        width: '100%',
        textAlign: 'center',
      },
    }
  : undefined;

const OPTIONS = [
  constants.takePhoto,
  constants.uploadFile,
  constants.labels.cancel,
];

export const UploadActonSheet: React.FC<IUploadActonSheetProps> =
  React.forwardRef((props, ref: React.ForwardedRef<RNActionSheet>) => {
    return (
      <Sheet
        ref={ref}
        useNativeDriver={true}
        title={constants.chooseAction}
        options={OPTIONS}
        styles={styles}
        cancelButtonIndex={findLastIndex(OPTIONS)}
        onPress={async (index: number) => {
          switch (index) {
            case 0:
              return props.onTakePhoto();
            case 1:
              const document = await RNDocumentPicker.pickSingle({
                type: [RNDocumentPickerTypes.pdf],
                allowMultiSelection: false,
                copyTo: 'cachesDirectory',
              })
              if(!document) return;
              return props.onUploadFile({
                uri: String(document.fileCopyUri || document.uri),
                type: String(document.type),
                name: String(document.name),
              });
          }
        }}
      />
    );
  });
