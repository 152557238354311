import { deleteReceipt, EReceiptStatus, getReceipt } from '../../api-store';
import { DOTS_DATE_FORMAT } from '../../api-store/constants';
import {
  constants,
  getActiveTripId,
  getCountryCode,
  getCurrency,
  getStatusColor,
  globalStyles,
  isWeb,
} from '../../common';
import { amountFormat } from '../../common/amountHelpers';
import {
  AvModal,
  BodyContainer,
  CustomButton,
  Label,
  TouchableView,
} from '../../components';
import { avAlert, avConfirm } from '../../components/AvAlert/alertActions';
import { TLabelType } from '../../components/Labels/types';
import { IDefaultState } from '../../store/types';
import { get } from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import { ScrollView, View, ViewStyle } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import ReceiptImageView from './ReceiptImageView';
import { getReceiptStatusIcon, receiptStatusToString } from './receiptUtils';
import styles from './styles';
import { IReceiptDetailsProps } from './types';
import ReceiptImagesView from './ReceiptImagesView';

class ReceiptDetails extends Component<IReceiptDetailsProps> {
  state = {
    isShowImageModal: false,
    isLoading: false,
  };

  submitOnDeleteReceipt = async () => {
    try {
      this.setState({ isLoading: true });
      await this.props.deleteReceipt(this.props.receipt.id);
      this.setState({ isLoading: false });
      this.props.navigation.goBack(null);
    } catch (e) {
      this.setState({ isLoading: false });
      this.props.avAlert('', e.message);
    }
  };

  handleOnDeleteReceipt = () => {
    this.props.avConfirm(
      '',
      constants.alerts.deleteReceipt.message,
      this.submitOnDeleteReceipt
    );
  };

  toggleOnShowImageModal = () => {
    this.setState({ isShowImageModal: !this.state.isShowImageModal });
  };

  componentDidMount() {
    if (this.props.receipt.id) {
      this.props.getReceipt(this.props.receipt.id);
    }
  }

  render() {
    return (
      <>
        <View style={styles.container}>{this.renderReceiptDetails()}</View>
        {this.renderImageModal()}
      </>
    );
  }

  renderAmount = (amount: number) => {
    const formattedAmount = amountFormat(amount);
    return this.renderDetailWithLabelGroup({
      label: constants.receipt.refundAmount,
      component: (
        <Label
          crop
          type="ReceiptAmountLargeRegular"
          text={
            getCurrency(getCountryCode(this.props.currentTrip?.country)) +
            `${formattedAmount}`
          }
        />
      ),
    });
  };

  renderReceiptDetails = () => {
    if (!this.props.receipt.id) return null;
    const isAccepted =
      this.props.receipt.statusCode === EReceiptStatus.Accepted;
    const rejectReason =
      this.props.receipt.statusCode === EReceiptStatus.Rejected &&
      this.props.receipt.statusMessage;
    const storeName = isAccepted && this.props.receipt.storeName;
    const status = receiptStatusToString(this.props.receipt.statusCode);

    const statusColor = getStatusColor(this.props.receipt.statusCode);
    const labelType = storeName
      ? 'ReceiptAmountLargeRegular'
      : 'H1LargeSemiBold';
    const isLocked = !!this.props.receipt?.resourceLock?.id;
    const textTitle =
      storeName ||
      receiptStatusToString(this.props.receipt.statusCode, true, isLocked);
    const totalRefund = this.props.receipt.paymentDetails?.totalRefund;
    const amount = this.renderAmount(
      isAccepted && totalRefund ? totalRefund : 0
    );

    const createdAt = moment(this.props.receipt.createdAt).format(
      DOTS_DATE_FORMAT
    );

    return (
      <SafeAreaView edges={['bottom']} style={styles.container}>
        <ScrollView contentContainerStyle={isWeb ? styles.receiptsList : undefined} showsVerticalScrollIndicator={false}>
          <BodyContainer>
            <Label
              type={labelType}
              text={textTitle}
              style={styles.titleStyle}
              numberOfLines={1}
            />

            <View style={styles.receiptTopSection}>
              {getReceiptStatusIcon(
                this.props.receipt.statusCode,
                64,
                statusColor
              )}
              <Label
                type="H2Light"
                text={status}
                style={[
                  styles.receiptTouchContent,
                  styles.receiptLabelRegular,
                  { color: statusColor },
                ]}
              />
            </View>
            {this.renderDetailWithLabelGroup({
              label: constants.receipt.receiptPhoto,
            })}
            <View style={styles.receiptMiddleSection}>
              <View style={styles.container}>
                <View style={[styles.imageContainer, globalStyles.shadow]}>
                  <TouchableView
                    onPress={this.toggleOnShowImageModal}
                    style={styles.imgPlaceholder}
                  >
                    <ReceiptImageView {...this.props} />
                  </TouchableView>
                </View>
              </View>
            </View>
            <View style={styles.receiptContent}>
              <View style={styles.receiptContent}>{amount}</View>
              <View style={styles.receiptDateData}>
                {this.renderDetailWithLabelGroup({
                  label: constants.receipt.date,
                  text: createdAt,
                  numberOfLines: 1,
                })}
              </View>
            </View>

            <View style={styles.container}>
              {!!rejectReason ? (
                <View style={styles.receiptContentRejectReason}>
                  {this.renderDetailWithLabelGroup({
                    label: constants.labels.rejectionReason,
                    text: String(rejectReason),
                    textClassType: 'H1Alert',
                    style: { flex: 1 }
                  })}
                </View>
              ) : null}
            </View>
            {isWeb ? <View style={{height: 90, width: '100%'}}/> : undefined}
          </BodyContainer>
        </ScrollView>
        <CustomButton
          accent
          isUppercase
          text={constants.deleteInvoice}
          onPress={this.handleOnDeleteReceipt}
          isLoading={this.state.isLoading}
          buttonStyle={globalStyles.absoluteBottomButton}
          testID="receiptDeleteBtn"
          isEnabled={!isLocked}
        />
      </SafeAreaView>
    );
  };

  renderDetailWithLabelGroup = (props: {
    text?: string;
    component?: React.ReactNode;
    label?: string;
    textClassType?: TLabelType;
    numberOfLines?: number;
    style?: ViewStyle;
  }) => {
    return (
      <View style={props.style}>
        <Label
          type="H2Light"
          text={props.label}
          style={styles.detailsWithLabelGroup}
        />
        {props.component ? (
          props.component
        ) : (
          <Label
            type={props.textClassType || 'H1Dark'}
            text={props.text}
            numberOfLines={props.numberOfLines}
          />
        )}
      </View>
    );
  };

  renderImageModal = () => {
    return (
      <AvModal
        title={constants.headers.receiptImage}
        onBackdropPress={this.toggleOnShowImageModal}
        isVisible={this.state.isShowImageModal}
      >
        <ReceiptImagesView {...this.props} resizeMode="contain" />
      </AvModal>
    );
  };
}

const mapStateToProps = (state: IDefaultState, props: IReceiptDetailsProps) => {
  const receiptId = props.route.params?.receiptId;
  const tripId = getActiveTripId(state);
  const receiptPath = `api.user.trips.${tripId}.receipts.${receiptId}`;
  const receipt = get(state, receiptPath, {});
  const currentTrip = get(state, `api.user.trips.${tripId}`, {});
  return {
    receipt,
    currentTrip,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      avAlert,
      avConfirm,
      getReceipt,
      deleteReceipt,
    },
    dispatch
  );
};

export default compose<any>(connect(mapStateToProps, mapDispatchToProps))(
  ReceiptDetails
);
