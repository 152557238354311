import { DATE_FORMAT } from '../../api-store/constants';
import { selectedDateStyle } from '../../common';
import { indexOf, keys, size } from 'lodash';
import moment from 'moment';

export const tripIsAfterDate = (date: string): boolean => {
  return moment().isAfter(date, 'day');
};

export const getArrivalDepartureValidRange = (
  interval: number,
  unit: moment.unitOfTime.Base
) => {
  return {
    start: moment().subtract(interval, unit),
    end: moment().add(interval, unit),
  };
};

export const createPeriodDates = <T>(
  startDate: string,
  endDate: string,
  callback: (
    startingDay: boolean,
    endingDay: boolean,
    dateKey: string,
    i: number
  ) => T
) => {
  const dates: { [x: string]: T } = {};
  const startMoment = moment(startDate, DATE_FORMAT);
  const endMoment = moment(endDate, DATE_FORMAT);
  const range = endMoment
    .startOf('day')
    .diff(startMoment.startOf('day'), 'days');
  const startDateKey = startMoment.format(DATE_FORMAT);
  const endDateKey = endMoment.format(DATE_FORMAT);
  if (!range) {
    dates[startDateKey] = callback(true, true, startDate, 0);
    return dates;
  }
  dates[startDateKey] = callback(true, false, startDate, 0);
  for (let i = 1; i <= range; i++) {
    const tempDate = startMoment.clone().add(i, 'day');
    const tempDateKey = tempDate.format(DATE_FORMAT);
    dates[tempDateKey] = callback(false, false, tempDateKey, i);
  }
  dates[endDateKey] = callback(false, true, endDate, range - 1);
  return dates;
};

export const changeDateInterval = (calendarDates: any, dateString: string) => {
  const dates: any = { ...calendarDates };
  dates[dateString] = { ...selectedDateStyle };
  const startDatesRange = keys(dates).sort();
  const startDatesRangeSize = size(startDatesRange);
  const selectedDateIndex = indexOf(startDatesRange, dateString);

  const middleDateIndex = Math.round(startDatesRangeSize / 2);

  const lastIndex = startDatesRangeSize - 1;
  const lastDateIndex =
    selectedDateIndex >= middleDateIndex ||
    (selectedDateIndex === 0 && calendarDates[dateString])
      ? selectedDateIndex
      : lastIndex;
  const firstDateIndex =
    selectedDateIndex < middleDateIndex ||
    (selectedDateIndex === lastIndex && calendarDates[dateString])
      ? selectedDateIndex
      : 0;

  const firstDate = startDatesRange[firstDateIndex];
  const lastDate = startDatesRange[lastDateIndex];
  return [firstDate, lastDate];
};
