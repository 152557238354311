import { Dictionary } from "lodash";

export enum ECreditCard {
  AM = "AM",
  DC = "DC",
  DI = "DI",
  MC = "MC",
  VI = "VI",
  VE = "VE",
}
export type TLocale = "en" | "ru" | "zh";

export enum ERefundMethodProvider {
  Bank = "bank-account",
  Card = "credit-card",
  Paypal = "paypal",
  Revolut = "revolut",
  Wechat = "wechat",
}

export enum ETransportNode {
  AIRPORT = "AIRPORT",
  SEAPORT = "SEAPORT",
  RAILWAY = "RAILWAY",
  OTHER = "OTHER",
}

export type TBankAccountValidationTypes =
  | "account_no"
  | "iban"
  | "sort_code"
  | "bic";

export interface IBankAccountValidation {
  type: TBankAccountValidationTypes;
  regexp: string;
}

export interface IApiState {
  user: IUser;
  transportNodes: ITransportNode[];
  service: {
    MIN_CLIENTS_VERSIONS: { value: { mobileApp: string } };
    REFERRALS: { value: { reward: number } };
  };
  tripCountries: ICountry[],
  bankAccountValidation?: IBankAccountValidation[];
  referralDashboard: IReferralDashboard;
  deviceToken?: string;
  isIntercomNotify?: boolean;
}
export interface IRequiredId {
  id: number;
}

export interface IBase extends IRequiredId {
  createdAt: string;
  updatedAt?: string;
  deleted?: boolean | null;
}

export interface IFileUploads extends IBase {
  contentType: string;
  uri: string;
}

export interface IFeedBack extends IBase {
  userEmail: string;
  rate: number;
  message: string;
}

export enum EAuthProvider {
  PASSWORD = "password",
  GOOGLE = "google",
  FACEBOOK = "facebook",
  APPLE = "apple",
}

export interface IUser extends IBase {
  code: string;
  email: string;
  isAuth: boolean;
  locale: TLocale;
  password?: string;
  newPassword?: string;
  token?: string;
  document?: IUserPassport;
  address?: IUserAddress;
  accessToken?: string;
  refreshToken?: string;
  isAgreeAccepted?: boolean;
  trips?: Dictionary<ITrip>;
  receipts?: Dictionary<IReceipt>;
  paymentInfo?: Dictionary<IRefundMethod>;
  feedback?: IFeedBack;
  isShowInfo?: boolean;
  provider?: EAuthProvider;
  refCode?: string;
  platform?: string;
  pushToken?: string;
  intercomHash: string;
}

export interface IUserPassport {
  birthDate: string;
  country: ICountry;
  createdAt: string;
  deleted?: boolean | null;
  expiryDate: string;
  firstName: string;
  id: number;
  issuedByCountryId: number;
  lastName: string;
  number: string;
  selfieUploads: IApiFile;
  selfieUploadsId: number;
  status: EDocumentStatus;
  updatedAt: string;
  uploads: IApiFile;
  uploadsId: number;
  userId: number;
}

export enum EDocumentStatus {
  NONE,
  IN_PROGRESS,
  VERIFIED,
  DECLINED,
}

export interface IUserAddress {
  id: number;

  city: string;
  country: ICountry;
  phone?: string;
  line: string;
  lineAdditional?: string | null;
  postcode: string;

  createdAt: string;
  updatedAt: string;
  deleted?: boolean | null;
  userId: number;

  uploads: IApiFile;
  statusId: EDocumentStatus;
  uploadsId: number;
}

export interface IBeDetax {
  estimatedDateAndTimeOfDeparture?: string;
  outPunt?: string;
  placeOfUnloading?: string;
  transportReference?: string;
  placeOfUnloadingTransportNode?: ITransportNode;
  destinationCountry?: ICountry;
}

export interface ITripExtraProps {
  beDetax?: Partial<IBeDetax>;
}

export interface ITrip extends IBase {
  arrivalDate: string;
  country: ICountry;
  departureDate: string;
  transportNode?: ITransportNode;
  transportNodeCode?: string;
  departureTerminalCode?: string;
  receipts?: Dictionary<IReceipt>;
  taxforms?: ITaxform[];
  isActive: boolean;
  paymentDetails?: IRefundDetails;
  countryId?: number;
  extraProps?: ITripExtraProps;
}

export enum ETaxformStatus {
  REGISTERED,
  PROCESSING,
  NOT_STAMPED,
  STAMPED,
  REJECTED,
  ERROR,
}

export interface ITransaction extends IBase {
  amount: number;
  currency: string;
  paymentInfo: IRefundMethod;
  status: number;
  taxformId: number;
}

export enum ETransactionStatus {
  REGISTERED,
  PROCESSING,
  COMPLETED,
  ERROR,
}

export enum ETaxformType {
  CUSTOM = "CUSTOM",
  TAXFORM = "TAXFORM",
}

export interface ITaxform extends IBase {
  invoiceCode: string | null;
  providerMessage: string | null;
  reference: string | null;
  status: ETaxformStatus;
  tripId: number;
  type: ETaxformType | null;
  uploadId: string | null;
  userId: number;
  transaction?: ITransaction;
}

export interface IApiUploadFile {
  name: string;
  dirname: EDirname;
  uri?: string;
  type: string;
  base64?: string;
}

export interface IApiFile {
  contentType: string;
  createdAt: string;
  deleted?: boolean | null;
  dirname: EDirname;
  id: number;
  updatedAt: string;
  uri: string;
  userId: number;
  base64?: string;
  name?:string;
}

export enum EReceiptStatus {
  Pending,
  Accepted,
  Rejected,
}

export interface IPassportDto extends IUserPassport {
  files: IApiUploadFile[];
}

export interface IReceiptDto {
  files: IApiUploadFile[];
  storeName?: string;
}

export interface IReceiptItemUploads extends IFileUploads {
  description: string;
}

export interface IReceiptItem extends IBase {
  description: string;
  amount: number;
  quantity: number;
  additionalInfo: {
    serialNumber?: string;
    classification?: string;
  };
}

export interface IResourceLock {
  createdAt: string;
  id: number;
  lockedUntil: string;
  resource: string;
  resourceId: number;
  updatedAt: string;
}

export interface IReceipt extends IBase {
  country: ICountry;
  countryId: number;
  receiptDate: null;
  statusCode: number;
  statusMessage: EReceiptStatus;
  storeName: string | null;
  tripId: number;
  uploads: IApiFile[];
  userId: number;
  paymentDetails: IRefundDetails;
  resourceLock: IResourceLock;
}

export interface IPayPalOption {
  email: string;
}

export interface ICreditCardOption {
  card: {
    lastDigits?: string;
    cardExpiryYear: string;
    cardExpiryMonth: string;
    cardType?: ECreditCard;
    cardNumber?: string;
  };
}

export interface IBankAccountOption {
  accountNumber: string;
  bicOrSwift: string;
}

export interface IRevolutOption {
  revolutAccountId: string;
}

export interface IWeChatPayOption {
  wechatId: string;
}

export interface IRefundMethod extends IBase {
  validate?(): boolean;
  provider: ERefundMethodProvider;
  isDefault: boolean;
  data:
    | IRevolutOption
    | IBankAccountOption
    | ICreditCardOption
    | IPayPalOption
    | IWeChatPayOption;
}
export interface ICountry {
  alpha2Code: string;
  createdAt: string;
  flagUri: string;
  id: number;
  isAllowedForTrip: false;
  isEu: false;
  source?: number;
  name: string;
  subdivision: string | null;
  updatedAt: string;
}

export interface ITransportNode {
  createdAt: string;
  id: number;
  code: string;
  name: string;
  updatedAt: string;
  type: ETransportNode;
}

export interface IRefundDetails {
  vat?: number;
  fee?: number;
  totalRefund?: number;
  invoiceAmount?: number;
}

export interface IReferralDashboard extends IBase {
  referrerId?: number;
  refCode: string;
  balance: number;
  userId: number;
  referralsCount?: number;
  awaitingReferralTripsCount: number;
  awaitingEndLatestTripDate?: string;
}

export enum EDirname {
  DOCUMENTS = "documents",
  SELFIES = "selfies",
  RECEIPTS = "receipts",
  ADDRESSES = "addresses",
  TAXFORMS = "taxforms",
  OTHER = "other",
}
