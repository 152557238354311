import {
  deleteRefundMethod,
  getUser,
  IRefundMethod,
  setDefaultRefundMethod,
} from '../../../api-store';
import { linkActions } from '../../../common';
import { constants } from '../../../common/constants';
import { globalStyles } from '../../../common/globalStyles';
import { images } from '../../../common/images';
import {
  AvNote,
  CustomButton,
  EmptyPagePlaceholder,
  Label,
  List,
  TouchableView,
} from '../../../components';
import { avAlert, avConfirm } from '../../../components/AvAlert/alertActions';
import { EEmptyPageButtonTypes } from '../../../components/EmptyPagePlaceholder/types';
import { getDefaultRefundMethod } from '../../../containers/Home/state';
import {
  createRouteNameByProvider,
  renderRefundMethodRow,
} from '../../../containers/Refund/refundMethodsCommon';
import * as ScenesNames from '../../../navigation/names';
import { IDefaultState } from '../../../store/types';
import { filter, get, reverse, size } from 'lodash';
import React, { Component } from 'react';
import { View } from 'react-native';
import { RNFastImage } from '../../../modules/RNFastImage';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import styles from '../styles';
import { IMyRefundMethodsProps } from '../types';
import { SafeAreaView } from 'react-native-safe-area-context';
import { RNSwipeable } from '../../../modules/RNSwipeable';

class MyRefundMethodsComponent extends Component<IMyRefundMethodsProps> {
  handleOnDeleteMethodSubmit = (id: IRefundMethod['id']) => () => {
    this.props.deleteRefundMethod(id);
  };

  handleOnDeleteFromRefundDetails = (id: IRefundMethod['id']) => () => {
    this.handleOnDeleteMethodSubmit(id)();
    this.props.navigation.goBack();
  };

  handleOnDeleteMethod = (id: IRefundMethod['id']) => () => {
    const { title, body }: any = constants.refund.sureToDelete;
    this.props.avConfirm(title, body, this.handleOnDeleteMethodSubmit(id));
  };

  onAddButtonPress = () => {
    this.props.navigation.navigate(ScenesNames.AddRefundMethodSceneName);
  };

  render() {
    const data = reverse(filter(this.props.paymentInfo, item => !!item.id));

    if (!size(data)) {
      return this.renderDefaultPage();
    }

    return (
      <SafeAreaView
        edges={['bottom']}
        testID='goBackContainer'
        style={styles.fill}
      >
        <List
          useMask
          data={data}
          renderItem={this.renderRow}
          contentContainerStyle={styles.refundMethodsList}
          ListFooterComponent={this.renderNote()}
          keyExtractor={(item: IRefundMethod, i: number) => `${item.id}${i}`}
        />
        <CustomButton
          accent
          isUppercase
          text={constants.labels.addRefundMethod}
          buttonStyle={globalStyles.absoluteBottomButton}
          onPress={this.onAddButtonPress}
          testID='goToAddRefundMethodList'
        />
      </SafeAreaView>
    );
  }

  renderDefaultPage = () => {
    return (
      <EmptyPagePlaceholder
        iconComponent={
          <RNFastImage
            resizeMode='contain'
            style={globalStyles.defaultPlaceholderIcon}
            source={images.cardsIcon}
          />
        }
        message={constants.refund.addFirstRefundMethod}
        onButtonPress={this.onAddButtonPress}
        buttonText={constants.labels.addRefundMethod}
        buttonType={EEmptyPageButtonTypes.DEFAULT}
        buttonTestID='goToAddRefundMethodList'
      />
    );
  };

  rightButtons = (onPress: any, index: number) => [
    <View style={styles.swipeButtonWrap}>
      <TouchableView
        onPress={onPress}
        testID={`refundMethodItemDeleteTouch${index}`}
        style={styles.swipeButton}
      >
        <Label white text={constants.labels.delete} />
      </TouchableView>
    </View>,
  ];

  renderRow = ({ item, index }: { item: IRefundMethod; index: number }) => {
    const handleOnPress = () => {
      const routeName = createRouteNameByProvider(item);
      this.props.navigation.navigate(routeName, {
        method: item,
        isFormDisabled: true,
        onDeleteMethod: this.handleOnDeleteFromRefundDetails(item.id),
      });
    };

    const handleOnCheck = async () => {
      await this.props.setDefaultRefundMethod(item);
    };

    return (
      <RNSwipeable
        key={index}
        style={styles.refundMethodContainer}
        rightButtons={this.rightButtons(
          this.handleOnDeleteMethod(item.id),
          index
        )}
      >
        {renderRefundMethodRow(
          handleOnPress,
          handleOnCheck,
          item.isDefault
        )({
          item,
          index,
        })}
      </RNSwipeable>
    );
  };

  renderNote = () => (
    <AvNote
      name='dependingOnYourDeparture'
      text={constants.refund.refundMethodDescription}
      linkActions={linkActions(this.props.navigation)}
    />
  );
}

const mapStateToProps = (state: IDefaultState) => {
  const defaultRefundMethod = getDefaultRefundMethod(state);
  const paymentInfo = get(state, `api.user.paymentInfo`);

  return {
    paymentInfo,
    defaultRefundMethod,
    state,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      avAlert,
      avConfirm,
      deleteRefundMethod,
      setDefaultRefundMethod,
      getUser,
    },
    dispatch
  );
};

export const MyRefundMethods = compose<any>(
  connect(mapStateToProps, mapDispatchToProps)
)(MyRefundMethodsComponent);

export default MyRefundMethods;
