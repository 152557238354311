import { findIndex, get } from 'lodash';
import moment from 'moment';
import React from 'react';
import { LayoutAnimation, ScrollView, View } from 'react-native';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { ICountry, ITrip, IUser } from '../../api-store';
import {
  API_URL,
  colors,
  fadeLayout,
  getActiveTripId,
  getCountryCode,
  globalStyles,
  isIOS,
  isWeb,
  setStatusBar,
} from '../../common';
import { BE, FR, GB, GB_NIR } from '../../common/constants/common';
import { Label } from '../../components';
import StepIndicator from '../../containers/Home/StepIndicator';
import { IStepItem } from '../../containers/Home/StepIndicator/types';
import { RNFastImage } from '../../modules/RNFastImage';
import { INavigationScreenProps } from '../../navigation';
import {
  AirvatFranceKbisSceneName,
  HomeSceneName,
} from '../../navigation/names';
import { IDefaultState } from '../../store/types';
import styles from './styles';

interface IAirvatInfoCheckoutComponentProps extends INavigationScreenProps {
  currentTrip?: ITrip;
  tripCountries: ICountry[];
  user: IUser;
}

const GB_AND_NIR = {
  title: 'Invoice request',
  flag: API_URL + '/storage/static/flags/gb.jpg',
  text1:
    'Hi, could you please issue a full VAT invoice in the name of Airvat for my purchase:',
  nameLabel: { label: 'Company name', value: 'Airvat ltd' },
  addressLabel: {
    label: 'Address',
    value: `20-22 Wenlock Road,\nLondon, N1 7GU,\nEngland`,
  },
  vatLabel: { label: 'VAT number', value: '312092250' },
  emailLabel: 'Email',
  email: 'info@airvat.com',
};

const FR_AND_BE = {
  title: 'Demande de facturation',
  flag: API_URL + '/storage/static/flags/fr.jpg',
  text1: `Bonjour, j'effectue ces achats pour le compte de la société Airvat EURL. Merci d'émettre une facture commerciale avec les détails suivants:`,
  nameLabel: { label: 'nom de la société', value: 'Airvat EURL' },
  addressLabel: {
    label: 'adresse',
    value: '10 rue de Penthièvre, 75008, Paris',
  },
  vatLabel: { label: 'SIRET', value: '900 847 096 00014' },
  dateLabel: { label: `date d'achat`, value: moment().format('DD/MM/YYYY') },
  emailLabel: 'email',
  email: 'info@airvat.com',
  phone: '+33 1 85 53 38 24',
};

const CHECKOUT_TEXTS: { [x: string]: any } = {
  [FR]: {
    ...FR_AND_BE,
    link: {
      text: 'voir ici Extrait Kbis',
      onPress: (props: IAirvatInfoCheckoutComponentProps) =>
        props.navigation.navigate(AirvatFranceKbisSceneName),
    },
  },
  [BE]: {
    ...FR_AND_BE,
    flag: API_URL + '/storage/static/flags/be.jpg',
    vatLabel: { label: 'TVA', value: 'BE 1013 708 792' },
  },
  [GB]: GB_AND_NIR,
  [GB_NIR]: GB_AND_NIR,
};

export const AirvatInfoCheckoutComponent: React.FC<
  IAirvatInfoCheckoutComponentProps
> = (props) => {
  React.useEffect(() => {
    setStatusBar(true);
    return () => {
      const { index, routes } = props.navigation.getState();
      setStatusBar(routes[index]?.name === HomeSceneName);
    };
  }, []);

  const countryCode = getCountryCode(props.currentTrip?.country);
  const initIndex = findIndex(
    props.tripCountries,
    (v) => getCountryCode(v) === countryCode
  );
  const [activeIndex, setActiveIndex] = React.useState<number>(
    initIndex > -1 ? initIndex : 1
  );

  const code = getCountryCode(props.tripCountries[activeIndex]) || countryCode;

  const texts = code
    ? CHECKOUT_TEXTS[code] || CHECKOUT_TEXTS[FR]
    : CHECKOUT_TEXTS[FR];

  React.useEffect(() => {
    props.navigation.setOptions({
      headerTitle: () => {
        return (
          <View style={styles.infoCheckoutContainer}>
            <Label
              text={texts.title}
              style={[globalStyles.headerTitle, { color: colors.white }]}
            />
            <RNFastImage
              resizeMode="cover"
              style={styles.infoCheckoutNavigationButton}
              source={{ uri: texts.flag }}
            />
          </View>
        );
      },
    });
  }, [activeIndex]);

  const handleOnStepPress = (stepNumber: number) => {
    if (isIOS) {
      LayoutAnimation.configureNext(fadeLayout);
    }
    setActiveIndex(stepNumber);
  };

  const indicatorData: IStepItem[] = props.tripCountries.map((v) => ({
    label: v.name,
    testID: `checkout${v.name}`,
  }));

  const vatLabelStyles = [styles.vatLabel, { fontSize: 30, lineHeight: 34 }];

  return (
    <>
      <StepIndicator
        onStepPress={handleOnStepPress}
        data={indicatorData}
        currentPosition={activeIndex}
        isHideNumber={true}
      />
      <ScrollView
        style={styles.container}
        contentContainerStyle={styles.scrollContainer}
      >
        <View style={[{flex: 1}, styles.dotListItemMargin]}>
          <Label
            text={texts.text1}
            centered
            type="H1Dark"
            style={[styles.dotListItemLabel, { fontSize: 22 }]}
          />
          <Label text={texts.nameLabel.label} centered type="H1Light" />
          <Label
            text={texts.nameLabel.value}
            type="CalculatorSemibold"
            style={vatLabelStyles}
            xSize={1.2}
            centered
          />
          <Label text={texts.addressLabel.label} centered type="H1Light" />
          <Label
            text={texts.addressLabel.value}
            type="CalculatorSemibold"
            style={vatLabelStyles}
            xSize={1.2}
            centered
          />
          <Label text={texts.vatLabel.label} centered type="H1Light" />
          <Label
            text={texts.vatLabel.value}
            type="CalculatorSemibold"
            style={vatLabelStyles}
            xSize={1.2}
            centered
          />
          {!!texts.emailLabel && (
            <Label text={texts.emailLabel} centered type="H1Light" />
          )}
          <Label
            text={props.user?.email || texts.email}
            type="CalculatorSemibold"
            style={vatLabelStyles}
            xSize={1.2}
            centered
          />
          {!!texts.link && (
            <Label
              text={texts.link.text}
              style={vatLabelStyles}
              type="CalculatorSemibold"
              link
              centered
              onPress={() => texts.link.onPress(props)}
            />
          )}
          {!!texts.phone && (
            <Label
              text={texts.phone}
              style={styles.vatLabel}
              type="H1Light"
              centered
              xSize={1.2}
            />
          )}
          {isWeb ? <View style={{height: 90, width: '100%'}}/> : undefined}
        </View>
      </ScrollView>
    </>
  );
};

const mapStateToProps = (state: IDefaultState) => {
  const tripId = getActiveTripId(state);
  const currentTrip = get(state, `api.user.trips.${tripId}`, {});
  const user = state.api.user;
  const tripCountries = state.api.tripCountries;
  return {
    currentTrip,
    tripCountries,
    user,
  };
};

export const AirvatInfoCheckout = compose<any>(connect(mapStateToProps))(
  AirvatInfoCheckoutComponent
);
