import { constants, getCountryCode, images, isWeb, linkActions } from '../../common';
import { GB_NIR } from '../../common/constants/common';
import { DotList } from '../../components';
import { getCurrentTrip } from '../../containers/Trip/tripState';
import { IDefaultState } from '../../store/types';
import React from 'react';
import { ScrollView, View } from 'react-native';
import { RNFastImage } from '../../modules/RNFastImage';
import { connect } from 'react-redux';
import { compose } from 'redux';
import styles from './styles';
import { IAirvatInfoTaxFormProps } from './types';

const AirvatInfoTaxFormComponent: React.FC<IAirvatInfoTaxFormProps> = ({
  navigation,
  currentTrip,
}) => {
  let taxformInfoItems = constants.taxforms.taxformInfoItems;
  let stampedTaxFormExample = images.stampedTaxFormExampleFr;
  const country = currentTrip?.country;
  const code = getCountryCode(country);
  if (code === GB_NIR) {
    taxformInfoItems = constants.taxforms.taxformInfoItemsGbNir;
    stampedTaxFormExample = images.stampedTaxFormExampleGbNir;
  }
  return (
    <ScrollView contentContainerStyle={{ height: 100 }}>
      <View style={styles.dotListWrap}>
        <DotList
          items={constants.taxforms.taxformInfoItems}
          style={styles.dotListMargin}
          linkActions={linkActions(navigation)}
        />
      </View>
      <View style={styles.listGradientContainer}>
        <RNFastImage
          style={styles.bottomTaxFormImage}
          resizeMode='contain'
          source={stampedTaxFormExample}
        />
      </View>
      {isWeb ? <View style={{height: 90, width: '100%'}}/> : undefined}
    </ScrollView>
  );
};

const mapStateToProps = (state: IDefaultState) => {
  const currentTrip = getCurrentTrip(state);
  return {
    currentTrip,
  };
};

export const AirvatInfoTaxForm = compose<any>(connect(mapStateToProps))(
  AirvatInfoTaxFormComponent
);
