import Intercom, {
  show,
  shutdown,
  update
} from '@intercom/messenger-js-sdk';

export const RNIntercom = {
  hash: '',
  logout: () => {
    shutdown();
  },
  present: () => {
    show();
  },
  setUserHash: (hash: string) => {
    RNIntercom.hash = hash;
  },
  loginUserWithUserAttributes: ({ userId, email }: any) => {
    Intercom({
      app_id: 'bihg0hgc',
      user_id: userId,
      email,
      user_hash: RNIntercom.hash,
      hide_default_launcher: true,
    });
  },
  updateUser: ({ name }: any) => {
    update({ name: name });
  },
  sendTokenToIntercom: () => {},
  addEventListener: () => {
    return { remove: () => {} };
  },
  setBottomPadding: () => {},
  presentContent: (id: string) => {},
};
export const RNIntercomEvents = { IntercomUnreadCountDidChange: {} };
export const RNIntercomContent = {
  helpCenterCollectionsWithIds: (id: string) => id,
};
