import { StyleSheet } from 'react-native';
import { colors } from './colors';
import { isAndroid, isSmallDevice, sketchSize } from './metrics';

const averta = 'Averta';
const gillSans = 'Gill Sans';

export const fonts = {
  averta: {
    bold: `${averta}-Bold`,
    light: `${averta}-Light`,
    regular: `${averta}-Regular`,
    semibold: `${averta}-Semibold`,
    extrabold: `${averta}-Extrabold`,
  },
  gillSans: {
    only: `${gillSans}`,
  },
};

export const globalStyles = StyleSheet.create({
  row: {
    flexDirection: 'row',
  },
  center: {
    justifyContent: 'center',
  },
  shadow: {
    shadowColor: colors.dark,
    shadowOffset: {
      width: 0,
      height: sketchSize(15),
    },
    shadowOpacity: 0.15,
    shadowRadius: sketchSize(10),
    elevation: 5,
  },
  buttonRound: {
    width: sketchSize(150),
    borderRadius: sketchSize(75),
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    margin: sketchSize(30),
  },
  buttonMain: {
    height: sketchSize(150),
    backgroundColor: colors.accent,
    borderRadius: sketchSize(24),
    marginHorizontal: sketchSize(120),
  },
  refundMethodRowContainer: {
    padding: sketchSize(20),
    paddingHorizontal: sketchSize(70),
    alignItems: 'center',
    flexDirection: 'row',
  },
  refundMethodRowContent: {
    flex: 1,
    flexDirection: 'row',
  },
  refundMethodRowIcon: {
    marginVertical: sketchSize(20),
  },
  refundMethodTitle: {
    paddingRight: sketchSize(180),
  },
  refundMethodTitleContainer: {
    flex: 1,
    paddingLeft: sketchSize(70),
    flexDirection: 'column',
    justifyContent: 'center',
  },
  headerTitle: {
    borderWidth: 0,
    fontWeight: 'bold',
    color: colors.accent,
    textAlign: 'center',
    fontSize: sketchSize(52),
    fontFamily: fonts.averta.semibold,
  },
  headerErrorTitle: {
    color: colors.red,
  },
  marginBottomButton: {
    marginBottom: sketchSize(83, true),
    alignSelf: 'center',
  },
  marginVerticalBottomButton: {
    marginVertical: isAndroid ? sketchSize(83, true) : 0,
    alignSelf: 'center',
  },
  absoluteBottomButton: {
    bottom: sketchSize(83, true),
    position: 'absolute',
    alignSelf: 'center',
  },
  absoluteBottomButtonSafe: {
    bottom: isAndroid ? sketchSize(83, true) : 0,
    position: 'absolute',
    alignSelf: 'center',
  },
  absoluteBottomRightButton: {
    alignSelf: 'flex-end',
    right: sketchSize(70),
  },
  chevronLeft: {
    transform: [{ rotateZ: '90deg' }],
  },
  chevronRight: {
    transform: [{ rotateZ: '-90deg' }, { translateY: sketchSize(30) }],
  },
  chevronRightCentred: {
    transform: [{ rotateZ: '-90deg' }],
  },
  chevronUp: {
    transform: [{ rotateZ: '180deg' }],
  },
  defaultPlaceholderIcon: {
    height: sketchSize(isSmallDevice ? 700 : 810, isSmallDevice),
    width: sketchSize(isSmallDevice ? 700 : 810, isSmallDevice),
  },
  listContainerStyle: {
    marginTop: 0,
    overflow: 'hidden',
    alignSelf: 'stretch',
  },
  buttonsBottomSpace: {
    marginBottom: sketchSize(40, true),
  },
  photoPreloader: {
    top: sketchSize(30),
    left: sketchSize(30),
    position: 'absolute',
  },
  photoFullScreenPreloader: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    position: 'absolute',
  },
  placeholderLabel: {
    marginHorizontal: sketchSize(80),
    alignSelf: 'center',
  },
  placeholderTitle: {
    marginHorizontal: sketchSize(80),
    alignSelf: 'center',
    marginBottom: sketchSize(32),
  },
  dotContainer: {
    width: sketchSize(20),
    height: sketchSize(20),
    marginTop: sketchSize(20, true),
    marginRight: sketchSize(30, true),
  },
  dotWarnContainer: {
    backgroundColor: colors.red,
  },
  dot: {
    borderRadius: sketchSize(20),
    backgroundColor: colors.black,
  },
  dotMarginBottomStyle: {
    flex: 1,
    marginBottom: sketchSize(50, true),
  },
  dotMarginLeftStyle: {
    marginLeft: sketchSize(50),
  },
});

export default globalStyles;

export const calendarTheme: any = {
  textMonthFontWeight: 'bold',
  textMonthFontSize: 32,
  'stylesheet.calendar.main': {
    container: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  'stylesheet.calendar-list.main': {
    calendar: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  'stylesheet.day.basic': {
    todayText: {
      fontWeight: 'bold',
      color: colors.darkGrey,
    },
  },
};

export const selectedDateStyle = {
  selected: true,
  color: colors.accent,
  selectedColor: colors.accent,
  textColor: colors.white,
};
