import colors from '../../common/colors';
import { headerHeight, sketchSize } from '../../common/metrics';
import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  container: {
    flex: 1
  },
  contentContainer: {
    flex: 2,
    justifyContent: 'space-around'
  },
  calendarListContainer: {
    backgroundColor: colors.white,
    flex: 1
  },

  dateSelectorMainTextStyle: {
    color: colors.darkGrey
  },
  whiteText: {
    color: colors.white
  },
  darkText: {
    color: colors.darkGrey
  },
  dateSelectorContainerStyle: {
    justifyContent: 'center'
  },
  calendarButtonWrapper: {
    paddingHorizontal: sketchSize(100),
    paddingBottom: sketchSize(83)
  },
  backgroundImage: {
    maxHeight: '30%',
    width: '100%',
    backgroundColor: colors.white,
    overflow: 'hidden',
    alignSelf: 'center'
  },
  dateInput: {
    margin: 0,
    padding: 0,
    height: sketchSize(28),
    borderWidth: 0,
    alignItems: 'flex-start'
  },
  questionLabel: {
    color: colors.lightGrey,
    marginBottom: sketchSize(20),
    marginTop: sketchSize(30)
  },
  textInput: {
    flex: 1,
    justifyContent: 'flex-start'
  },
  selectButton: {
    margin: sketchSize(20)
  },
  defaultPlaceholder: {
    width: sketchSize(50),
    height: sketchSize(50),
    borderRadius: sketchSize(50),
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.accent
  },
  defaultPlaceholderIcon: {
    width: sketchSize(24)
  },
  countryNameContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row'
  },
  countryUkContainer: {
    marginTop: sketchSize(40),
    marginBottom: sketchSize(80)
  },
  countryFlagImage: {
    width: sketchSize(122),
    height: sketchSize(80),
    borderRadius: sketchSize(15)
  },
  fromToSelector: {
    marginTop: sketchSize(39),
    marginBottom: sketchSize(15),
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  fromToSelectorSpacedAround: {
    justifyContent: 'space-around'
  },
  fromToSelectorCentered: {
    justifyContent: 'center'
  },
  selectorFromButton: {
    width: '35%',
    alignItems: 'center',
    justifyContent: 'center'
  },
  separatorImage: {
    flex: 1,
    width: sketchSize(208),
    height: sketchSize(65),
    alignItems: 'center',
    justifyContent: 'center'
  },
  selectorToButton: {
    width: '35%',
    borderWidth: 0,
    alignItems: 'flex-end',
    justifyContent: 'center'
  },
  permanentlyLiveContainer: {
    flexDirection: 'row',
    marginTop: sketchSize(39),
    marginBottom: sketchSize(40),
    justifyContent: 'space-around'
  },
  saveButton: {
    margin: sketchSize(20)
  },
  fillImageContainer: {
    flex: 1,
    justifyContent: 'flex-end'
  },
  fillImage: {
    width: '100%',
    flex: 1
  },
  emptyLabelCentred: {
    justifyContent: 'center'
  },
  dateContainer: {
    marginLeft: sketchSize(18)
  },
  headerWithStackedDetailsContainer: {
    flexDirection: 'row'
  },
  spacerInDateContainer: {
    height: sketchSize(15)
  },
  buttonContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  emptyContainer: {
    marginTop: headerHeight
  },
  labelContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    flex: 0.4
  }
});
