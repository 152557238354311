import moment from 'moment';
import React from 'react';
import Calendar from 'react-calendar';
import './Calendar.css';
import { isArray, map } from 'lodash';

type ValuePiece = Date | null;
type Value = ValuePiece | [ValuePiece, ValuePiece];
// Only for TripDates
export const RNTripDatesCalendarList = ({
  markingType,
  current,
  onDayPress,
  minDate,
  maxDate,
  monthFormat,
  markedDates,
}: any) => {
  const [isReady, setIsReady] = React.useState(false);
  React.useEffect(() => {
    setTimeout(() => {
      setIsReady(true);
    }, 400);
    return () => {
      setIsReady(false);
    };
  }, []);

  if (!isReady) return;

  return (
    <Calendar
      selectRange={markingType === 'period'}
      onChange={(data) => {
        isArray(data) && onDayPress(map(data, (v) => moment(v).format('YYYY-MM-DD')));
      }}
      locale="en"
      minDate={moment(minDate).toDate()}
      maxDate={moment(maxDate).toDate()}
      // defaultValue={[
      //   moment(findKey(markedDates, (v) => v.startingDay)).toDate(),
      //   moment(findKey(markedDates, (v) => v.endingDay))
      //     .add(1, 'day')
      //     .toDate(),
      // ]}
    />
  );
};
export type RNDateData = '';
export interface RNTheme {}
