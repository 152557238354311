import { StyleSheet } from 'react-native';
import { colors } from '../../common/colors';
import { sketchSize } from '../../common/metrics';

export default StyleSheet.create({
  modalContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.lightTransparent
  },
  wrapper: {
    flex: 1,
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  alertContainer: {
    width: sketchSize(900),
    backgroundColor: colors.white,
    borderRadius: sketchSize(58),
    padding: sketchSize(58)
  },
  loaderContainer: {
    marginHorizontal: sketchSize(100),
    backgroundColor: colors.white,
    borderRadius: sketchSize(58),
    padding: sketchSize(58)
  },
  label: {
    marginBottom: sketchSize(58)
  },
  bottomLabel: {
    marginTop: sketchSize(20)
  },
  button: {
    width: undefined,
    minWidth: sketchSize(276),
    height: sketchSize(103),
    borderRadius: sketchSize(58),
    marginHorizontal: sketchSize(29),
    paddingHorizontal: sketchSize(29)
  },
  buttonGray: {
    backgroundColor: colors.lightGrey
  },
  buttonRed: {
    backgroundColor: colors.red
  },
  buttonSuccess: {
    backgroundColor: colors.accent
  },
  buttonsContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  activityContainer: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  absolute: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0
  }
});
