import { ECreditCard, ERefundMethodProvider, IRefundMethod } from '../../../api-store';
import { constants, globalStyles } from '../../../common';
import { CustomButton, List, SupportButton } from '../../../components';
import { avAlert, avConfirm } from '../../../components/AvAlert/alertActions';
import { getIsFilledAddress } from '../../../containers/AddDetails/documentsState';
import { getIsFilledUserPassport } from '../../../containers/Home/state';
import {
  createRouteNameByProvider,
  renderRefundMethodRow,
} from '../../../containers/Refund/refundMethodsCommon';
import { HomeSceneName } from '../../../navigation/names';
import { IDefaultState } from '../../../store/types';
import { CommonActions } from '@react-navigation/native';
import { get } from 'lodash';
import React, { Component } from 'react';
import { SafeAreaView } from 'react-native-safe-area-context';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import styles from '../styles';
import { IAddRefundMethodProps } from '../types';

export class AddRefundMethodComponent extends Component<IAddRefundMethodProps> {
  checkPassportAddressExists = () => {
    const isFilled = this.props.isFilledAddress && this.props.isFilledPassport;
    if (!isFilled) {
      this.props.avAlert('', constants.alerts.passportData);
      return false;
    }
    return true;
  };

  paymentInfo = [
    {
      provider: ERefundMethodProvider.Bank,
      id: 'bankAccount',
      data: { accountNumber: '' },
      validate: this.checkPassportAddressExists,
    },
    {
      provider: ERefundMethodProvider.Card,
      id: 'creditCardMc',
      data: { card: { cardType: ECreditCard.MC } },
      validate: this.checkPassportAddressExists,
    },
    {
      provider: ERefundMethodProvider.Card,
      id: 'creditCardVi',
      data: { card: { cardType: ECreditCard.VI } },
      validate: this.checkPassportAddressExists,
    },
    {
      provider: ERefundMethodProvider.Paypal,
      id: 'payPal',
      data: { email: '' },
    },
    // {
    //   provider: ERefundMethodProvider.Wechat,
    //   id: 'wechatPay',
    //   data: { wechatId: '' },
    //   validate: this.checkPassportAddressExists,
    // },
  ];

  navigateToMethodForm = (method: IRefundMethod) => {
    if (method.validate && !method.validate()) return;
    const routeName = createRouteNameByProvider(method);
    this.props.navigation.navigate(routeName, {
      method,
      useGoBack: this.props.navigation.canGoBack(),
    });
  };

  navigationReset = () => {
    const routes = [
      {
        name: HomeSceneName,
      },
    ];
    this.props.navigation.dispatch(
      CommonActions.reset({
        index: 0,
        routes,
      })
    );
  };

  render() {
    return (
      <SafeAreaView edges={['bottom']} style={styles.fill}>
        <List
          data={this.paymentInfo}
          renderItem={renderRefundMethodRow(this.navigateToMethodForm)}
          ListFooterComponent={<SupportButton />}
        />
        {!this.props.navigation.canGoBack() && (
          <CustomButton
            link
            text={constants.labels.addLater}
            buttonStyle={globalStyles.marginBottomButton}
            testID='skipAddRefundMethodBtn'
            onPress={this.navigationReset}
          />
        )}
      </SafeAreaView>
    );
  }
}

const mapStateToProps = (state: IDefaultState) => {
  const isFilledAddress = getIsFilledAddress(state.api.user);
  const isFilledPassport = getIsFilledUserPassport(state);
  const user = get(state, 'api.user', {});
  return {
    ...user,
    isFilledAddress,
    isFilledPassport,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      avAlert,
      avConfirm,
    },
    dispatch
  );
};

export const AddRefundMethod = compose<any>(
  connect(mapStateToProps, mapDispatchToProps)
)(AddRefundMethodComponent);
