import { ETransportNode, ICountry, ITransportNode } from '../../../../api-store';
import { airvatClient, colors, constants, getCountryCode, getTransportNodeTitle, isIOS, isWeb } from '../../../../common';
import { isEnglish } from '../../../../common/stringValidator';
import { AvIcon, AvTextInput, Label, List, TouchableView } from '../../../../components';
import { avAlert } from '../../../../components/AvAlert/alertActions';
import { IAvAlertActions } from '../../../../components/AvAlert/types';
import { TIconName } from '../../../../components/AvIcon/icons';
import { debounce, filter, find, size, sortBy, uniqBy } from 'lodash';
import React, { Component } from 'react';
import { ActivityIndicator, KeyboardAvoidingView, View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import styles from './styles';

const limit = isWeb ? 9999 : 50;

export interface ISelectTransportNodeProps extends IAvAlertActions {
  country?: ICountry;
  onSelectItem(item: ITransportNode): void;
  withOutOther?: boolean;
}

export interface ISelectTransportNodeState {
  search: string;
  errorText: string;
  isLoading: boolean;
  isMoreLoading: boolean;
  hasMoreLoad: boolean;
  isMounted: boolean;
  transportNodes?: ITransportNode[];
}

class SelectTransportNode extends Component<
  ISelectTransportNodeProps,
  ISelectTransportNodeState
> {
  search = '';
  state: ISelectTransportNodeState = {
    search: '',
    errorText: '',
    isLoading: false,
    isMoreLoading: false,
    hasMoreLoad: true,
    isMounted: true,
    transportNodes: undefined,
  };

  handleError = (e: any) => {
    this.setState({
      errorText: e.message,
      isMoreLoading: false,
      isLoading: false,
    });
  };

  loadTransportNodes = async (offset: number = 0, search: string = '') => {
    const params: any = {
      size: limit,
      page: Math.ceil(offset / limit),
      sort: 'name',
      direction: 'asc',
      countryCode: this.props.country?.alpha2Code,
      countrySubdivision: this.props.country?.subdivision,
      search,
    };
    try {
      const { results } = await airvatClient.get<{
        results: ITransportNode[];
      }>('transport-nodes', { params });
      const hasMoreLoad = size(results || []) === limit;
      this.setState({ hasMoreLoad });
      return results;
    } catch (e) {
      this.handleError(e);
    }
  };

  getTransportNodes = async (search?: string) => {
    this.setState({ isLoading: true, transportNodes: undefined });
    const transportNodes = await this.loadTransportNodes(0, search);
    this.setTransportNodes(transportNodes);
  };

  setTransportNodes = (transportNodes?: ITransportNode[]) => {
    const errorText =
      transportNodes && size(transportNodes) ? '' : constants.errorSearch;
    this.state.isMounted &&
      this.setState({
        transportNodes: size(transportNodes) ? transportNodes : undefined,
        isLoading: false,
        isMoreLoading: false,
        errorText,
      });
  };

  getMoreTransportNodes = async () => {
    const offset = size(this.state.transportNodes);
    if (!this.state.hasMoreLoad || this.state.isLoading || offset < limit)
      return;
    this.setState({ isMoreLoading: true });
    const transportNodes = await this.loadTransportNodes(offset, this.search);
    const resultCountries = [
      ...(this.state.transportNodes || []),
      ...(transportNodes || []),
    ];
    this.setTransportNodes(resultCountries);
  };

  debounceSearch = debounce(this.getTransportNodes, 400);

  handleOnSearch = (search: string) => {
    this.search = search;
    this.debounceSearch(search);
  };

  getErrorText = (): string => {
    const { search } = this.state;
    if (!search) return '';
    if (!isEnglish(search)) {
      return constants.validationErrors.english;
    }
    return '';
  };

  getOtherCode = () => {
    const countryCode = getCountryCode(this.props.country);
    const otherCode = 'OTR_' + countryCode;
    return otherCode;
  };

  componentDidMount() {
    this.setState({
      isMounted: true,
    });
    this.getTransportNodes();
  }

  componentWillUnmount() {
    this.setState({ isMounted: false });
  }

  render() {
    const data = filter(
      this.state.transportNodes,
      (v) => v?.code !== this.getOtherCode()
    );
    const other = find(
      this.state.transportNodes,
      (v) => v?.code === this.getOtherCode()
    );

    const filteredData = uniqBy(
      filter([...data, this.props.withOutOther ? undefined : other]),
      'code'
    ) as ITransportNode[];

    return (
      <KeyboardAvoidingView
        {...(isIOS ? { behavior: 'padding' } : {})}
        style={styles.containerStyle}
      >
        <SafeAreaView edges={['bottom']} style={{ flex: 1 }}>
          <AvTextInput
            key="search"
            errorText={this.getErrorText()}
            isForceShowErrorText={true}
            showSearchIcon
            otherTextInputProps={{
              placeholder: constants.placeholders.transportNodes,
              autoFocus: true,
              onChangeText: this.handleOnSearch,
            }}
            testID={`transportNodeSearchInp`}
          />
          <List
            useMask
            data={filteredData}
            renderItem={this.renderItem}
            testID="selectTransportNodeScroll"
            keyExtractor={(item: ITransportNode, i: number) =>
              `${item?.id}_${i}`
            }
            contentContainerStyle={{ flexGrow: 0 }}
            contentInset={{ bottom: 60 }}
            onEndReached={this.getMoreTransportNodes}
            onEndReachedThreshold={0.2}
            ListFooterComponent={
              <View style={styles.listFooterSpacer}>
                <ActivityIndicator
                  animating={this.state.isMoreLoading}
                  color={colors.dark}
                />
              </View>
            }
            ListEmptyComponent={
              <ActivityIndicator
                animating={this.state.isLoading}
                color={colors.dark}
              />
            }
          />
        </SafeAreaView>
      </KeyboardAvoidingView>
    );
  }

  renderItem = ({ item, index }: { item: ITransportNode; index: number }) => {
    if (!item) return null;
    const iconName: TIconName =
      item.type === ETransportNode.AIRPORT
        ? 'Plane'
        : item.type === ETransportNode.SEAPORT
        ? 'Ship'
        : item.type === ETransportNode.RAILWAY
        ? 'Railway'
        : 'Pin';
    return (
      <TouchableView
        style={styles.stepWrap}
        onPress={() => this.props.onSelectItem(item)}
        testID={`transportNodeTouch${index}`}
        key={index}
      >
        <AvIcon
          name={iconName}
          width={60}
          fill={colors.grey}
          containerStyle={styles.stepIcon}
        />
        <Label
          type="H1Dark"
          text={getTransportNodeTitle(item)}
          numberOfLines={2}
        />
      </TouchableView>
    );
  };
}

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      avAlert,
    },
    dispatch
  );
};

export default compose<any>(connect(null, mapDispatchToProps))(
  SelectTransportNode
);
