import { IUser } from '../../api-store';
import { IFieldState } from '../../common/types';
import { IAvAlertActions } from '../../components/AvAlert/types';
import { INavigationScreenProps } from '../../navigation';

export enum EFormType {
  LOGIN,
  SIGNUP,
  OPENING,
  AGREEMENT,
  FORGOT
}

export interface IThridPartyAuthProps {
  isLoadingGoogleAuth?: boolean;
  isLoadingFaceBookAuth?: boolean;
  isLoadingAppleAuth?: boolean;
  buttonDisabled?: boolean;
  googleOnLoginOrRegister(token?:string): Promise<any>;
  appleOnLoginOrRegister(): Promise<any>;
  facebookOnLoginOrRegister(token?:string): Promise<any>;
}

export interface ILoginFormProps {
  isLoading: boolean;
  buttonDisabled: boolean;
  email: string;
  password: string;
  onSignUpLinkPress(): void;
  onLoginPress(email: string, password: string): void;
  onForgotLinkPress(): void;
  onReferralLinkPress(): void;
  onInputFocus(isFocus: boolean): void;
}

export interface ISignUpFormProps {
  isLoading: boolean;
  buttonDisabled: boolean;
  onLoginLinkPress(): void;
  onSignUpPress(email: string, password: string, refCode?: string): Promise<any>;
  renderThirdParty(refCode?: string): any;
  onInputFocus(isFocus: boolean): void;
}

export interface IAuthState {
  isLoading: boolean;
  isReady: boolean;
  restoreEmail: string;
  isLoadingGoogleAuth: boolean;
  isLoadingFaceBookAuth: boolean;
  isLoadingAppleAuth: boolean;
  restoreMessage: string;
}

export interface IAuthProps extends IAvAlertActions, INavigationScreenProps {
  user: IUser;
  loginWithPassword(user: Partial<IUser>): Promise<IUser>;
  loginWithToken(refreshToken: string): Promise<any>;
  signUpWithPassword(user: Partial<IUser>): Promise<any>;
  passwordReset(email: string): Promise<any>;
  providerAuth(
    provider: string,
    code?: string,
    refCode?: string
  ): Promise<string>;
  checkReferrerCode(
    refCode: string
  ): Promise<string>;
  agreeAccepted(user: Partial<IUser>): Promise<any>;
  facebookOnLoginOrRegister(): Promise<any>;
  googleOnLoginOrRegister(): Promise<any>;
  userInit(): Promise<void>;
  getUser(): Promise<IUser>;
}

export interface IPasswordForgotFormProps {
  isLoading: boolean;
  passwordReset(email: { value: string; isValid: boolean }): void;
}

export interface IPasswordForgotFormState {
  email: IFieldState;
}

export interface IReferralFormProps {
  code?: string;
  isLoading: boolean;
  onChangeCode(code: string): void;
}

export interface IReferralFormState {
  code: IFieldState;
}
