import { createSetAction } from './../helpers';
import { airvatClient } from '../../common/api';
import { IReferralDashboard } from '../../api-store/types';

export const checkReferrerCode =
  (refCode: string) => async (dispatch: any, getState: any) => {
    await airvatClient.get(`referrals/${refCode}`);
    const action = createSetAction('user', { referrerCode: refCode });
    dispatch(action);
  };

export const getReferralDashboard =
  () => async (dispatch: any, getState: any) => {
    const data = await airvatClient.get<IReferralDashboard>(`referrals`);
    const action = createSetAction('referralDashboard', data);
    dispatch(action);
  };
