import { ITrip } from '../../api-store';
import { constants } from '../../common';
import { INavigationScreenProps } from '../../navigation';
import {
  EditTripSceneName, HomeSceneName
} from '../../navigation/names';
import { CommonActions } from '@react-navigation/native';
import React from 'react';
import { TripDates } from './components/TripDates';

const AddTripDates: React.FC<INavigationScreenProps> = ({
  navigation,
  route
}) => {
  const newTrip: ITrip | undefined = route.params?.newTrip;

  const onSaveDates = async (arrivalDate: string, departureDate: string) => {
    const params = {
      ...(route.params || {}),
      newTrip: {
        ...(newTrip || {}),
        arrivalDate,
        departureDate,
      }
    };
    navigation.dispatch(
      CommonActions.reset({
        index: 1,
        routes: [
          { name: HomeSceneName },
          {
            name: EditTripSceneName,
            params,
          },
        ],
      })
    )
  };

  return (
    <TripDates
      trip={newTrip}
      onSave={onSaveDates}
      saveButtonLabel={constants.labels.next}
    />
  );
};

export default AddTripDates;
