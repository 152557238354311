import { colors, constants, images } from '../../common';
import { EmptyModalScreen, Label } from '../../components';
import { Header } from '../../components/Headers/Header';
import { get } from 'lodash';
import React from 'react';
import { ActivityIndicator, View } from 'react-native';
import { RNFastImage } from '../../modules/RNFastImage';
import styles from './styles';
import { IPreloaderProps } from './types';

export default class Preloader extends React.Component<IPreloaderProps> {
  render() {
    const message = get(this.props, 'error.message', '');
    return (
      <Header title={constants.updatingData}>
        <View style={styles.fillImageContainer}>
          <RNFastImage
            style={styles.fillImage}
            resizeMode='cover'
            source={images.airportPlaceholder}
          />
        </View>
        <View style={styles.labelContainer}>
          <ActivityIndicator size='large' color={colors.dark} />
          <Label
            text={this.props.progressMessage}
            style={styles.progressMessage}
          />
        </View>
        <EmptyModalScreen
          title={constants.alerts.appInitError}
          isVisible={this.props.isShowError}
          message={message}
          isLoading={this.props.isLoading}
          onUpdate={this.props.onUpdate}
        />
      </Header>
    );
  }
}
