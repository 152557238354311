import { EDirname } from '../../api-store';
import { createApiUploadFile } from '../../common';
import {
  CAMERA_OPTIONS,
  CameraRef,
  ITakePictureResponse,
} from '../../components/CameraContainer';
import {
  PassportPreviewSceneName,
  SelfieCameraPlaceholderSceneName,
} from '../../navigation/names';
import { RNImageEditor } from '../../modules/RNImageEditor';

import { IDocumentCameraProps, IDocumentPreviewProps } from './types';

export const pictureCrop = (picture: ITakePictureResponse): Promise<any> => {
  const cropData = {
    offset: {
      x: 0,
      y: 0,
    },
    size: {
      width: picture.width,
      height: (picture.height / 3) * 1.9,
    },
  };

  return RNImageEditor.cropImage(picture.uri, cropData);
};

export const handleOnTakePicture =
  (props: IDocumentCameraProps) => (cameraRef: CameraRef) => {
    props.avLoader('', true);

    if (!cameraRef || !cameraRef.current) return;
    cameraRef.current
      .takePictureAsync(CAMERA_OPTIONS)
      .then(picture => {
        props.avLoader('', false);
        props.navigation.navigate(PassportPreviewSceneName, {
          ...(props.route.params || {}),
          document: createApiUploadFile(picture, EDirname.DOCUMENTS),
        });
      })
      .catch(handleOnSavePictureError(props));
  };

export const handleOnSavePictureError =
  (props: IDocumentCameraProps | IDocumentPreviewProps) => (error: Error) => {
    props.avLoader('', false);
    props.avAlert('', error.message);
  };

export const handleOnSavePicture = async (
  props: IDocumentPreviewProps,
  picture: any
) => {
  props.avLoader('', true);
  try {
    const document = createApiUploadFile(picture, EDirname.DOCUMENTS);
    if (picture.width) {
      document.uri = await pictureCrop(picture);
    }
    props.avLoader('', false);
    props.navigation.navigate(SelfieCameraPlaceholderSceneName, {
      ...(props.route.params || {}),
      document,
    });
  } catch (e) {
     console.log(e);
    handleOnSavePictureError(props)(e);
  }
};
