import { StyleSheet } from 'react-native';
import { sketchSize } from '../../common/metrics';

export default StyleSheet.create({
  logo: {
    maxHeight: sketchSize(220),
    resizeMode: 'contain',
    width: '80%',
    alignSelf: 'center'
  }
});
