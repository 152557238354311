import { get } from 'lodash';
import { appleAuthHelpers } from 'react-apple-signin-auth';
import { constants } from '../../common/constants';
import { ENV } from '../../common/env';
import { RNAccessToken, RNLoginManager } from '../../modules/rnfbsdk';

const handleResponse = (code?: string | null) => {
  if (!code) {
    throw new Error(constants.auth.idTokenErrorAuth);
  }
  return { code };
};

export const googleOnLoginOrRegister = async () => {};

export const appleOnLoginOrRegister = async (): Promise<
  { code: string } | never
> => {
    const env = ENV.ENV === 'development' ? '.dev' : '';
    const response = await appleAuthHelpers.signIn({
        authOptions: {
          clientId: `airvat.auth.web${env}`,
          scope: 'email',
          redirectURI: `https://app${env}.airvat.com`,
          usePopup: true,
          nonce: 'nonce',
          state: 'origin:web',
        },
        onError: console.error,
        onSuccess: console.log,
      });
  if (response?.authorization?.id_token) {
    return handleResponse(response?.authorization?.id_token);
  }
  throw { message: constants.auth.canceledAuth };
};

export const facebookOnLoginOrRegister = (): Promise<
  { code: string } | never
> => {
  return RNLoginManager.logInWithPermissions(['public_profile', 'email'])
    .then(result => {
      if (result.isCancelled) {
        throw { message: constants.auth.canceledAuth };
      } else {
        return RNAccessToken.getCurrentAccessToken();
      }
    })
    .then((data) => {
      const code = get(data, 'accessToken');
      return handleResponse(code);
    }).catch(e=>{
      console.error('react-native-fbsdk-next', JSON.stringify(e));
      throw e;
    });
};
