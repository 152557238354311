import { StyleSheet } from 'react-native';

import colors from '../../common/colors';
import { sketchSize, isSmallDevice } from '../../common/metrics';

export default StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
  },
  icon: {
    color: colors.light,
  },
  imageContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    flex: 0.5,
  },
  labelContainer: {
    flex: isSmallDevice ? 0.55 : 0.45,
  },
  buttonContainer: {
    marginBottom: sketchSize(83),
    justifyContent: 'flex-end',
    flex: 0.1,
  },
});
