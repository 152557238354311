import { constants } from '../../common';
import { header } from '../../components';
import { Home } from '../../containers/Home';
import { StampedFormCamera } from '../../containers/TaxForms/StampedFormCamera';
import StampedFormImagePreview from '../../containers/TaxForms/StampedFormImagePreview';
import AddTripDates from '../../containers/Trip/AddTripDates';
import AddTripTerminal from '../../containers/Trip/AddTripTransportNode';
import EditTrip from '../../containers/Trip/EditTrip';
import { SelectTripCountry } from '../../containers/Trip/SelectTripCountry';
import TripInfo from '../../containers/Trip/TripInfo';
import {
  cameraNavigationOptionsConfig,
  commonStackNavigatorConfig,
} from '../../navigation/config';
import {
  AddTripDatesSceneName,
  AddTripTransportNodeSceneName,
  EditTripSceneName,
  HomeSceneName,
  SelectTripCountrySceneName,
  StampedFormCameraSceneName,
  StampedFormImagePreviewSceneName,
  TripInfoSceneName
} from '../../navigation/names';
import { INavigationScreenProps } from '../../navigation/types';
import { headerTrash } from '../../navigation/utils';

export default {
  [HomeSceneName]: {
    screen: Home,
    navigationOptions: {
      headerShown: false,
    },
  },
  [EditTripSceneName]: {
    screen: EditTrip,
    navigationOptions: (props: INavigationScreenProps) => ({
      ...header({
        ...props,
        ...headerTrash(props),
        title: constants.yourTripDetails,
      }),
      ...commonStackNavigatorConfig(props),
    }),
  },
  [TripInfoSceneName]: {
    screen: TripInfo,
    navigationOptions: (props: INavigationScreenProps) => ({
      title: constants.refundBreakdown,
      ...commonStackNavigatorConfig(props),
    }),
  },
  [SelectTripCountrySceneName]: {
    screen: SelectTripCountry,
    navigationOptions: (props: INavigationScreenProps) => ({
      title: constants.selectTripDepartureCountry,
      ...commonStackNavigatorConfig(props),
    }),
  },
  [AddTripTransportNodeSceneName]: {
    screen: AddTripTerminal,
    navigationOptions: (props: INavigationScreenProps) => ({
      ...commonStackNavigatorConfig(props),
    }),
  },
  [AddTripDatesSceneName]: {
    screen: AddTripDates,
    navigationOptions: (props: INavigationScreenProps) => ({
      title: constants.selectArrivalDepartureDates,
      ...commonStackNavigatorConfig(props),
    }),
  },
  [StampedFormCameraSceneName]: {
    screen: StampedFormCamera,
    navigationOptions: {
      title: constants.scanStampedForm,
      ...cameraNavigationOptionsConfig,
    },
  },
  [StampedFormImagePreviewSceneName]: {
    screen: StampedFormImagePreview,
    navigationOptions: {
      headerShown: false,
    },
  },
};
