import { constants, isAndroid, metrics } from '../../common';
import { CustomButton, avAlert, avAlertToggle } from '../../components';
import { WebSiteBrowser } from '../../components/WebSiteBrowser';
import React from 'react';
import { NativeSyntheticEvent, View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import styles from './styles';
import { IAirvatInfoGoodsAboveProps } from './types';

export const AirvatInfoGoodsAboveComponent: React.FC<
  IAirvatInfoGoodsAboveProps
> = props => {
  const [isDisableButton, setIsDisableButton] = React.useState(true);
  const params = props.route.params || {};
  const useLockButton = params.nextScreenName;

  const handlePress = () => {
    props.avAlertToggle(false);
    props.navigation.navigate(params.nextScreenName, {
      ...params,
    });
  };

  const handleScroll = (syntheticEvent: NativeSyntheticEvent<any>) => {
    if (!useLockButton) return;
    const { contentOffset, layoutMeasurement, contentSize } =
      syntheticEvent.nativeEvent;
    const offset = Math.round(contentOffset.y + layoutMeasurement.height);
    const deviceOffset = isAndroid ? metrics.deviceHeight / 3 : 0;
    const contentHeight = Math.round(contentSize.height - deviceOffset);
    if (offset > contentHeight) {
      setIsDisableButton(false);
    }
  };

  const handleMount = () => {
    if (!useLockButton) return;
    props.avAlert(constants.important, constants.pleaseScroll);
  };

  return (
    <View style={styles.flexContainer}>
      <WebSiteBrowser
        showsVerticalScrollIndicator
        uri={constants.browser.blogInvoices}
        onShouldStartLoadWithRequest={(request) => {
          const validUrls = [constants.browser.blogInvoices, "https://www.youtube.com/embed/tAdTKoifUrQ?si=bIGKPFLcbBfWaaXe"];
          const result = validUrls.some(v => v === request.url);
          return result;
        }}
        onScroll={handleScroll}
        onMount={handleMount}
      />
      {useLockButton && (
        <SafeAreaView edges={['bottom']} style={styles.bottomButtonContainer}>
          <CustomButton
            accent
            isUppercase
            text={constants.labels.iVeUnderstood}
            onPress={handlePress}
            isEnabled={!isDisableButton}
            testID='iVeUnderstoodBtn'
          />
        </SafeAreaView>
      )}
    </View>
  );
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      avAlert,
      avAlertToggle,
    },
    dispatch
  );
};

export const AirvatInfoGoodsAbove = compose<any>(
  connect(null, mapDispatchToProps)
)(AirvatInfoGoodsAboveComponent);
