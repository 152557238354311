import { isUndefined } from "lodash";
import { constants } from "./constants";
import parsePhoneNumber from "libphonenumber-js";

enum StringValidTypes {
  REQUIRED,
  EMAIL,
  PASSWORD,
  NUMBER,
  ZIPCODE,
  BICSWIFT,
  ADDRESS,
  CITY,
  ENGLISH,
  BANK,
  EXPIRY,
  WECHAT_ID,
  REFERRAL_CODE,
  FLIGHT_NUMBER,
  NUMBER_WITH_DOT,
}

const StringValidRegexp = {
  [StringValidTypes.REQUIRED]: constants.regexps.empty,
  [StringValidTypes.PASSWORD]: constants.regexps.password,
  [StringValidTypes.EMAIL]: constants.regexps.email,
  [StringValidTypes.NUMBER]: constants.regexps.number,
  [StringValidTypes.ZIPCODE]: constants.regexps.zipcode,
  [StringValidTypes.BICSWIFT]: constants.regexps.bicOrSwift,
  [StringValidTypes.ADDRESS]: constants.regexps.address,
  [StringValidTypes.CITY]: constants.regexps.city,
  [StringValidTypes.ENGLISH]: constants.regexps.english,
  [StringValidTypes.BANK]: constants.regexps.accountNumber,
  [StringValidTypes.EXPIRY]: constants.regexps.cardExpiry,
  [StringValidTypes.WECHAT_ID]: constants.regexps.weChatId,
  [StringValidTypes.REFERRAL_CODE]: constants.regexps.referralCode,
  [StringValidTypes.FLIGHT_NUMBER]: constants.regexps.flightNumber,
  [StringValidTypes.NUMBER_WITH_DOT]: constants.regexps.numberWithDot,
};

const stringValidatorBase = (value: string, type: StringValidTypes): boolean =>
  !isUndefined(value) &&
  !!(`${value}` || "").trim() &&
  StringValidRegexp[type].test(value);

export const isRequired = (value: string) =>
  stringValidatorBase(value, StringValidTypes.REQUIRED);
export const isEmail = (value: string) =>
  stringValidatorBase(value, StringValidTypes.EMAIL);
export const isPassword = (value: string) =>
  stringValidatorBase(value, StringValidTypes.PASSWORD);
export const isNumber = (value: string) =>
  stringValidatorBase(value, StringValidTypes.NUMBER);
export const isZipCode = (value: string) =>
  stringValidatorBase(value, StringValidTypes.ZIPCODE);
export const isBicOrSwift = (value: string) =>
  stringValidatorBase(value, StringValidTypes.BICSWIFT);
export const isConfirm = (confirmValue: string) => (value: string) =>
  confirmValue === value;
export const isAddress = (value: string) =>
  stringValidatorBase(value, StringValidTypes.ADDRESS);
export const isCity = (value: string) =>
  stringValidatorBase(value, StringValidTypes.CITY);
export const isEnglish = (value: string) =>
  isUndefined(value) || !stringValidatorBase(value, StringValidTypes.ENGLISH);
export const isBankNumber = (value: string) =>
  stringValidatorBase(value, StringValidTypes.BANK);
export const isCardExpiry = (value: string) =>
  stringValidatorBase(value, StringValidTypes.EXPIRY);
export const isWeChatId = (value: string) =>
  stringValidatorBase(value, StringValidTypes.WECHAT_ID);
export const isReferralCode = (value: string) =>
  !value || stringValidatorBase(value, StringValidTypes.REFERRAL_CODE);
export const isFlightNumber = (value: string) =>
  isUndefined(value) ||
  stringValidatorBase(value, StringValidTypes.FLIGHT_NUMBER);
export const isNumberWithDot = (value: string) => {
  return (
    isUndefined(value) ||
    stringValidatorBase(value, StringValidTypes.NUMBER_WITH_DOT)
  );
};
export const isPrice = (min: number) => (value: string) => {
  return isUndefined(value) || (min <= Number(value) && isNumberWithDot(value));
};
export const isPhone = (value: string) => {
  if (!(value || '').trim()) return true;
  const phoneNumber = parsePhoneNumber(`+${value}`);
  return !!phoneNumber?.isValid();
};
