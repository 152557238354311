import { FunctionComponent } from 'react';
import { textFormatter } from './helpers';
import { renderText } from './renderText';
import { ILabelProps } from './types';

export const Label: FunctionComponent<ILabelProps> = props => {
  const text: any =
    props.useFormatter && props.text ? textFormatter(props) : props.text;
  return renderText({ ...props, text });
};
