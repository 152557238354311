import { BE, FR } from './constants/common';

export const getWithPrecision = (n: number, signs: number = 2): number => {
  const precision = Math.pow(10, signs);
  return Math.round(n * precision) / precision;
};

export const calculateTotalRefund = (
  amount: number,
  countryCode?: string
) => {
  let isVatIncluded = true;
  let vatPercent = 20;
  if (countryCode === BE) {
    vatPercent = 21;
  }
  if (!amount) {
    return 0;
  }
  const invoiceAmount = getWithPrecision(amount);
  const vat = isVatIncluded
    ? invoiceAmount * (1 - 1 / (1 + vatPercent / 100))
    : (invoiceAmount * vatPercent) / 100;
  const refund = vat * 0.8;
  return getWithPrecision(refund);
};

export const amountFormat = (n: number): string => {
  const truncatedNumber = Math.round(n * 100) / 100;
  const [integer, fraction] = truncatedNumber.toFixed(2).split('.');
  const result = integer
    .split('')
    .reverse()
    .reduce((res, cur, i, origin) => {
      const isThirdNumber = (i + 1) % 3 === 0;
      const isLast = i === origin.length - 1;
      return !isLast && isThirdNumber ? `,${cur}${res}` : `${cur}${res}`;
    }, '.' + fraction);
  return result.replace(/ /g, '');
};
