import { globalStyles } from '../../common';
import { isArray, isObject, map } from 'lodash';
import React from 'react';
import { View } from 'react-native';
import { Label } from '../Labels';
import { IDotListProps } from './types';

const alpha = Array.from(Array(26)).map((e, i) => i + 65);
const alphabet = alpha.map((x) => String.fromCharCode(x).toLowerCase());

export const DotList: React.FC<IDotListProps> = (props) => {
  const { items, style, linkActions, isNoDot, isWarn, isChar } = props;
  const dotStyle = isNoDot
    ? globalStyles.dotContainer
    : [
        globalStyles.dot,
        globalStyles.dotContainer,
        isWarn && globalStyles.dotWarnContainer,
      ];

  return (
    <View style={style}>
      {map(items, (text, i: number) => {
        if (isArray(text)) {
          return (
            <DotList
              {...props}
              items={text}
              isChar={true}
              key={'dotList' + i}
              style={globalStyles.dotMarginLeftStyle}
            />
          );
        }
        if (isObject(text)) {
          const item = text as any;
          if (item.textItems) {
            return (
              <View key={'dotList-with-title' + i}>
                <View style={{ flexDirection: 'row' }}>
                  <View style={dotStyle} />
                  <Label
                    useFormatter
                    text={item.title}
                    type="H1Dark"
                    style={globalStyles.dotMarginBottomStyle}
                    linkActions={linkActions}
                    error={isWarn}
                  />
                </View>
                <DotList
                  isNoDot
                  style={globalStyles.dotMarginLeftStyle}
                  items={item.textItems}
                />
              </View>
            );
          }
          return <Label key={'label' + i} {...(text as any)} />;
        }
        return (
          <View key={'view' + i} style={{ flexDirection: 'row' }}>
            {isChar ? (
              <Label text={alphabet[i] + '. '} type="H1Dark" />
            ) : (
              <View style={dotStyle} />
            )}
            <Label
              useFormatter
              text={text}
              type="H1Dark"
              style={globalStyles.dotMarginBottomStyle}
              linkActions={linkActions}
              error={isWarn}
            />
          </View>
        );
      })}
    </View>
  );
};
