import { constants, ENV } from '../../common';
import { EmptyModalScreen } from '../../components';
import React from 'react';
import { compareVersion, openStore } from './actions';
import { IAppVersionProps } from './types';

export const AppVersionView: React.FC<IAppVersionProps> = props => {
  const isVisible = compareVersion(props.appVersion, ENV.APP_VERSION);
  return (
    <EmptyModalScreen
      title={constants.labels.softUpdate}
      isVisible={isVisible}
      message={constants.labels.softUpdateMessage}
      onUpdate={openStore}
    />
  );
};
