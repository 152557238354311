import { useWebSocket } from '../../hooks/useWebSocket';
import { get, map } from 'lodash';
import React from 'react';
import { ScrollView, View } from 'react-native';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import {
  checkIsShowTaxformLoader,
  ETaxformType,
  generateTaxForm,
  getDocument,
  getTaxformPdf,
  getTrip,
  ITaxform,
  setTrip,
} from '../../api-store';
import {
  colors,
  constants,
  deviceHeight,
  deviceWidth,
  getActualTaxform,
  getCountryCode,
  images,
  isCompleteTaxform,
  isWeb,
  linkActions,
  sketchSize,
} from '../../common';
import { BE, FR, GB_NIR } from '../../common/constants/common';
import {
  AirvatInfoHowItWorksList,
  AvIcon,
  AvModal,
  CustomButton,
  DotList,
  EmptyPagePlaceholder,
  GradientWrapper,
  Label,
  SupportButton,
  TouchableView,
  UploadActonSheet,
} from '../../components';
import { avAlert, avConfirm } from '../../components/AvAlert/alertActions';
import { EEmptyPageButtonTypes } from '../../components/EmptyPagePlaceholder/types';
import {
  getIsConfirmAddressDeclined,
  getIsFilledAddress,
  getIsNeedConfirmAddress,
} from '../../containers/AddDetails/documentsState';
import {
  getDefaultRefundMethod,
  getIsAcceptedReceipt,
} from '../../containers/Home/state';
import { tripIsAfterDate } from '../../containers/Trip/tripHelpers';
import { getCurrentTrip } from '../../containers/Trip/tripState';
import { RNActionSheet } from '../../modules/RNActionSheet';
import { RNBarcode } from '../../modules/RNBarcode';
import { RNScreenBrightness } from '../../modules/RNScreenBrightness';
import * as ScenesNames from '../../navigation/names';
import { IDefaultState } from '../../store/types';
import styles from './styles';
import { IGenerateTaxFormProps } from './types';
import { RNLinearGradient } from '../../modules/RNLinearGradient';

const GenerateTaxFormsComponent: React.FC<IGenerateTaxFormProps> = (props) => {
  const ws = useWebSocket<ITaxform>(
    `${props.user.code}/taxform-generation-complete`
  );
  let brightnessRef = React.useRef<number>(1.0);
  let actionSheetRef = React.useRef<RNActionSheet>();

  const [isShowBarcodeModal, setIsShowBarcodeModal] = React.useState(false);
  const [isDownloading, setIsDownloading] = React.useState(false);

  const navigateToEditTrip = () => {
    props.navigation.navigate(ScenesNames.EditTripSceneName, {
      tripId: props.currentTrip?.id,
    });
  };

  const isAfterBeforeDeparture = () => {
    const { currentTrip } = props;
    if (tripIsAfterDate(currentTrip.departureDate)) {
      const { title, body } = constants.taxforms.cannotChangeModal;
      props.avConfirm(title, body, navigateToEditTrip);
      return true;
    }
  };

  const isNotRefundMethod = () => {
    if (!props.isDefaultRefundMethod) {
      props.avAlert('', constants.alerts.notExistsRefundMethod, () => {
        props.navigation.navigate(ScenesNames.AddRefundMethodSceneName);
      });
      return true;
    }
  };

  const isNotValidAddress = () => {
    if (!props.isFilledAddress) {
      props.avAlert('', constants.alerts.notFilledAddress, () => {
        props.navigation.navigate(ScenesNames.AddressSceneName);
      });
      return true;
    }
    const isTripFr = getCountryCode(props.currentTrip?.country) === FR;
    if (isTripFr && props.isNeedAddressConfirm) {
      const isFr = getCountryCode(props.user?.document?.country) === FR;
      const residenceText = isFr
        ? constants.nonEuResidenceTextAlertFr
        : constants.nonEuResidenceTextAlert;
      const message = `${residenceText}\n${constants.goToUploadDocument}`;
      props.avConfirm('', message, goToAddressConfirm, submitGenerate);
      return true;
    }
  };

  const isBelgiumOptionalFields = () => {
    if (getCountryCode(props.currentTrip?.country) === BE) {
      const belgiumDetaxFields = [
        props.currentTrip?.extraProps?.beDetax?.estimatedDateAndTimeOfDeparture,
        props.currentTrip?.extraProps?.beDetax?.outPunt,
        props.currentTrip?.extraProps?.beDetax?.placeOfUnloading,
        props.currentTrip?.extraProps?.beDetax?.transportReference,
      ];
      if (!isOther() && belgiumDetaxFields.some((v) => !v)) {
        props.avAlert('', constants.alerts.notFilledBelgiumFields, () => {
          props.navigation.navigate(ScenesNames.EditTripSceneName, {
            tripId: props.currentTrip?.id,
          });
        });
        return true;
      }
    }
    return false;
  };

  const goToAddressConfirm = () => {
    props.navigation.navigate(ScenesNames.AddressConfirmationSceneName, {
      hideSkip: true,
    });
  };

  const onGenerate = async () => {
    if (props.isInvalidForFrance) {
      return props.avAlert('', constants.invalidForFrance);
    }
    if (props.isInvalidForBelgium) {
      return props.avAlert('', constants.invalidForBelgium);
    }
    if (isAfterBeforeDeparture()) return;
    if (isNotRefundMethod()) return;
    if (isBelgiumOptionalFields()) return;
    //every last, in call submitGenerate();
    if (isNotValidAddress()) return;
    submitGenerate();
  };

  const submitGenerate = () => {
    props.generateTaxForm();
    ws.on();
  };

  const handleOpenPdf = async () => {
    if (!props.taxform?.id) return;
    setIsDownloading(true);
    await props.getTaxformPdf(props.taxform?.id).catch((e: any) => {
      const errorMessage =
        e?.message || `Tax-free form ${props.taxform?.id} download error`;
      props.avAlert('', errorMessage);
    });
    setIsDownloading(false);
  };

  const toggleIsShowBarcodeModal = () => {
    const v = !isShowBarcodeModal;
    setIsShowBarcodeModal(v);
    const newBrightness = v ? 1.0 : brightnessRef.current;
    RNScreenBrightness.setBrightness(newBrightness);
  };

  const isOther = () => {
    const nodeCode =
      props.currentTrip?.transportNodeCode ||
      props.currentTrip?.departureTerminalCode ||
      '';
    const placeOfUnloadingTransportNodeCode =
      props.currentTrip?.extraProps?.beDetax?.placeOfUnloadingTransportNode
        ?.code || '';
    return (
      placeOfUnloadingTransportNodeCode.startsWith('OTR_') ||
      nodeCode.startsWith('OTR_')
    );
  };

  React.useEffect(() => {
    RNScreenBrightness.getBrightness().then((v: number) => {
      brightnessRef.current = v;
    });
    if (props.currentTrip?.id) {
      props.getDocument().then(() => props.getTrip(props.currentTrip.id));
    }
  }, []);

  React.useEffect(() => {
    props.getTrip(ws.message?.tripId || props.currentTrip.id);
  }, [ws.message, props.currentTrip.id]);

  React.useEffect(() => {
    const IsShowTaxformLoader = props.checkIsShowTaxformLoader(props.taxform);
    if (props.taxform && !IsShowTaxformLoader) {
      ws.off();
    }
  }, [props.taxform]);

  const renderDefaultPlaceholder = () => {
    const gbNirOtherStepInstructions = [
      {
        title: constants.print,
        icon: images.printIcon,
        message: constants.printMessages,
      },
      {
        title: constants.stamp,
        icon: images.stampedTaxFormIcon,
        message: constants.stampGbMessages,
      },
    ];
    const frOtherStepInstructions = [
      {
        title: constants.print,
        icon: images.printIcon,
        message: constants.printMessages,
      },
      {
        title: constants.stamp,
        icon: images.stampedTaxFormIcon,
        message: constants.stampMessages,
      },
    ];
    const frInstructions = [
      {
        title: constants.generate,
        icon: images.phoneBarcode,
        message: constants.generateMessagesShort,
      },
      {
        title: constants.validate,
        icon: images.terminalBarcodeScan,
        message: constants.validateMessagesShort,
      },
    ];

    const beOtherStepInstructions = [
      frOtherStepInstructions[0],
      {
        title: constants.stamp,
        icon: images.stampedTaxFormIcon,
        message: constants.stampMessagesBe,
      },
    ];
    const beInstructions = [
      frInstructions[0],
      {
        title: constants.validate,
        icon: images.terminalBarcodeScan,
        message: constants.validateMessagesShortBe,
      },
    ];

    const code = getCountryCode(props.currentTrip.country);
    const isGbNir = code === GB_NIR;
    let data = gbNirOtherStepInstructions;
    if (code === FR) {
      data = isOther() ? frOtherStepInstructions : frInstructions;
    } else if (code === BE) {
      data = isOther() ? beOtherStepInstructions : beInstructions;
    }

    return (
      <EmptyPagePlaceholder
        onButtonPress={onGenerate}
        buttonText={constants.generateTaxForms}
        buttonType={EEmptyPageButtonTypes.DEFAULT}
        buttonDisabled={!props.isAllowed}
        contentComponent={
          <>
            <ScrollView
              showsVerticalScrollIndicator={false}
              contentInset={{ bottom: 90 }}
              contentContainerStyle={{ height: 100 }}
            >
              <View style={[styles.infoLabel]}>
                <Label
                  centered
                  type="CalculatorSemibold"
                  text={constants.finishedShopping}
                />
              </View>
              <View>
                <AirvatInfoHowItWorksList
                  {...props}
                  withIsLast
                  style={styles.infoContainer}
                  linkActions={linkActions(props.navigation)}
                  data={data}
                />
              </View>
              {isWeb ? (
                <View
                  style={{ height: 90, width: '100%' }}
                />
              ) : undefined}
            </ScrollView>
            <RNLinearGradient
              pointerEvents="none"
              style={[
                {
                  position: 'absolute',
                  bottom: 0,
                  height: sketchSize(300, true),
                  width: '100%',
                },
              ]}
              colors={[colors.transparent, colors.white]}
            />
          </>
        }
        buttonTestID="generateTaxformBtn"
      />
    );
  };

  const renderBarcode = () => {
    const { taxform, currentTrip } = props;
    const barcode =
      getCountryCode(currentTrip.country) === FR && taxform?.reference;
    const isCustom = taxform?.type === ETaxformType.CUSTOM;
    if (isCustom || !barcode) {
      return null;
    }
    return (
      <TouchableView
        style={styles.barcodeContainer}
        onPress={toggleIsShowBarcodeModal}
        testID="barcodeBtn"
      >
        <RNBarcode
          format="CODE128"
          width={deviceWidth / 160}
          height={deviceHeight / 5}
          value={barcode}
        />
        <Label text={barcode} type="H1Dark" />
        <Label
          dark
          useFormatter
          text={constants.barcodeNotScanning}
          style={styles.barcodeNotScanning}
        />
        <View style={styles.barcodeFullScreenIcon}>
          <AvIcon width={60} name="FullScreen" />
        </View>
      </TouchableView>
    );
  };

  const renderBarcodeModal = () => {
    const { taxform } = props;
    const isCustom = taxform?.type === ETaxformType.CUSTOM;
    if (isCustom || !taxform?.reference) {
      return null;
    }
    return (
      <AvModal
        navigation={props.navigation}
        title={constants.barcodeScan}
        isVisible={isShowBarcodeModal}
        onBackdropPress={toggleIsShowBarcodeModal}
      >
        <View style={styles.barcodeFullScreenContainer}>
          <View style={styles.barcodeFullScreenRotate}>
            <RNBarcode
              format="CODE128"
              width={deviceHeight / 186}
              height={200}
              value={taxform.reference}
            />
            <Label
              text={taxform.reference}
              type="H1Dark"
              style={{ fontSize: 28 }}
            />
          </View>
        </View>
      </AvModal>
    );
  };

  const getInstructions = () => {
    const {
      taxform,
      currentTrip: { country, departureTerminalCode, transportNode },
    } = props;
    const code = getCountryCode(country);
    const isCustom = taxform?.type === ETaxformType.CUSTOM;
    let instructions: any = [...constants.airportInstructionsGbNir];
    if (code === FR) {
      instructions = [...constants.airportInstructionsFr];
      const code = transportNode?.code || departureTerminalCode || '';
      if (code.startsWith('OTR_')) {
        instructions = [...constants.airportInstructionsOtherFR];
      }
      if (isCustom) {
        instructions = [...constants.airportInstructionsNoPabloFR];
      }
    } else if (code === BE) {
      instructions = [...constants.airportInstructionsBe];
    }

    return instructions;
  };

  const handleNavigateToCamera = () => {
    props.navigation.navigate(ScenesNames.StampedFormCameraSceneName, {
      taxformId: props.taxform?.id,
    });
  };

  const handleOnUploadPhoto = async (picture: any) => {
    if (picture?.uri) {
      props.navigation.navigate(ScenesNames.StampedFormImagePreviewSceneName, {
        taxformId: props.taxform?.id,
        picture,
        retakeLabel: constants.tryAgain,
      });
    }
  };

  const renderTaxForm = () => {
    const instructions = getInstructions();
    return (
      <>
        <ScrollView
          contentContainerStyle={styles.scrollWrap}
          testID="taxformInstructionsScroll"
        >
          <View style={styles.container}>
            {renderBarcode()}
            <CustomButton
              accent
              isUppercase
              text={constants.viewForm}
              onPress={handleOpenPdf}
              isLoading={isDownloading}
              disabled={!!props.taxform?.uploadId}
              buttonStyle={styles.taxformButton}
              testID="goToTaxformBtn"
            />
            <View style={styles.blockContainer}>
              {map(instructions, (item, i) => {
                return (
                  <View key={i}>
                    <Label
                      text={item.title}
                      type="CalculatorSemibold"
                      style={styles.dotListItemLabel}
                    />
                    <DotList
                      items={item.textItems as any}
                      linkActions={{
                        uploadStampedForm: () => {
                          actionSheetRef.current?.show?.();
                        },
                      }}
                    />
                  </View>
                );
              })}
            </View>
            <SupportButton />
          </View>
          {isWeb ? <View style={{ height: 90, width: '100%' }} /> : undefined}
        </ScrollView>
        <UploadActonSheet
          ref={actionSheetRef}
          onTakePhoto={handleNavigateToCamera}
          onUploadFile={handleOnUploadPhoto}
        />
      </>
    );
  };

  return (
    <>
      {isCompleteTaxform(props.taxform)
        ? renderTaxForm()
        : renderDefaultPlaceholder()}
      {renderBarcodeModal()}
    </>
  );
};

const mapStateToProps = (state: IDefaultState) => {
  const currentTrip = getCurrentTrip(state);
  const taxform = getActualTaxform(state);
  const isAcceptedReceipt = getIsAcceptedReceipt(state);
  const invoiceAmount = Number(currentTrip?.paymentDetails?.invoiceAmount);
  const isFr = getCountryCode(currentTrip?.country) === FR;
  const isBe = getCountryCode(currentTrip?.country) === BE;
  const isInvalidForFrance = isFr && invoiceAmount <= 100;
  const isInvalidForBelgium = isBe && invoiceAmount <= 125;
  const isAllowed = isAcceptedReceipt && !!invoiceAmount && !taxform;
  const user = get(state, 'api.user');
  const isFilledAddress = getIsFilledAddress(state.api.user);
  const isDefaultRefundMethod = getDefaultRefundMethod(state);
  const isNeedAddressConfirm =
    getIsConfirmAddressDeclined(user) || getIsNeedConfirmAddress(user);
  return {
    currentTrip,
    isAllowed,
    user,
    isInvalidForFrance,
    isInvalidForBelgium,
    departureDate: currentTrip?.departureDate,
    tripId: currentTrip?.id,
    isFilledAddress,
    isDefaultRefundMethod,
    isNeedAddressConfirm,
    taxform,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      avAlert,
      avConfirm,
      getTrip,
      generateTaxForm,
      getDocument,
      getTaxformPdf,
      setTrip,
      checkIsShowTaxformLoader,
    },
    dispatch
  );
};

export const GenerateTaxForms = compose<any>(
  connect(mapStateToProps, mapDispatchToProps)
)(GenerateTaxFormsComponent);

export default GenerateTaxForms;
