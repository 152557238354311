import {
  colors,
  getCountryCode,
  getCurrency,
  openChat
} from "../../../common";
import { amountFormat } from "../../../common/amountHelpers";
import { BE, FR } from "../../../common/constants/common";
import { globalStyles } from "../../../common/globalStyles";
import { images } from "../../../common/images";
import { AvIcon } from "../../../components/AvIcon";
import { Label } from "../../../components/Labels";
import { TouchableView } from "../../../components/TouchableView";
import moment from "moment";
import React, { PureComponent } from "react";
import { View } from "react-native";
import { RNFastImage } from '../../../modules/RNFastImage';
import { SafeAreaView } from "react-native-safe-area-context";
import styles from "./styles";
import { IHeaderProps, IRefundAmountProps } from "./types";

export const RefundAmount: React.FC<IRefundAmountProps> = (props) => {
  const amountValue = amountFormat(parseFloat(props.amount || "") || 0);
  const amountClassType =
    props.amount || props.label ? "HeaderAmountLight" : "HeaderAmount";

  return (
    <View style={[styles.amountContainer, props.containerStyle]}>
      <View style={[globalStyles.row, styles.center]}>
        <Label
          accent={props.accent}
          crop
          type="HeaderCcySemiBold"
          text={props.ccy}
          style={styles.refundCurrency}
        />
        <Label
          accent={props.accent}
          crop
          centered
          type={amountClassType}
          text={amountValue}
          style={styles.refundAmount}
          testID="amountLbl"
        />
        <AvIcon
          name="InfoBold"
          fill={colors.white}
          width={50}
          containerStyle={styles.refundInfo}
        />
      </View>
      {!props.amount || props.label ? (
        <Label
          crop={props.crop}
          accent={props.accent}
          type="H3Bold"
          text={props.label || ""}
          style={styles.totalRefund}
        />
      ) : null}
    </View>
  );
};

export class Header extends PureComponent<IHeaderProps> {
  getBackgroundImage() {
    switch (getCountryCode(this.props.currentTrip?.country)) {
      case FR:
        return images.homeHeaderBackgroundFr;
      case BE:
        return images.homeHeaderBackgroundBe;
      default:
        return images.homeHeaderBackgroundGb;
    }
  }

  renderDate = (date?: string) => {
    const momentDate = moment(date);
    const day = momentDate && momentDate.format("DD");
    const year = momentDate && momentDate.format("YYYY");
    const month = momentDate && momentDate.format("MMM").toUpperCase();
    return (
      <View style={styles.headerWithStackedDetailsContainer}>
        <Label text={day} type="HeaderCountryAlphaCode" crop />
        <View style={styles.dateContainer}>
          <Label text={month} type="CalendarDateMonthYear" centered crop />
          <Label
            text={year}
            type="CalendarDateMonthYear"
            xSize={0.88}
            centered
            crop
          />
        </View>
      </View>
    );
  };

  render() {
    const { currentTrip } = this.props;
    return (
      <View style={styles.headerContainer}>
        <RNFastImage
          source={this.getBackgroundImage()}
          style={styles.backgroundImage}
          resizeMode="cover"
        />
        <SafeAreaView edges={["top"]}>
          <View style={styles.headerContentContainer}>
            <TouchableView
              onPress={this.props.onPress}
              testID="tripInfoBtn"
              style={styles.headerContentTouch}
            >
              <RefundAmount
                amount={this.props.amount?.toString()}
                ccy={getCurrency(getCountryCode(currentTrip?.country))}
                crop
              />
            </TouchableView>
          </View>
        </SafeAreaView>
        <SafeAreaView edges={["top"]} style={styles.leftButtonWrap}>
          <TouchableView
            testID="howCanHelpYouBtn"
            onPress={openChat}
            style={styles.leftButtonTouch}
          >
            <AvIcon
              name="Chat"
              fill={colors.white}
              withNotify={this.props.withNotifyLeft}
            />
          </TouchableView>
        </SafeAreaView>
        <SafeAreaView edges={["top"]} style={styles.moreButtonWrap}>
          <TouchableView
            testID="goToMoreBtn"
            onPress={this.props.onMore}
            style={styles.moreButtonTouch}
          >
            <AvIcon
              name="More"
              fill={colors.white}
              withNotify={this.props.withNotifyRight}
            />
          </TouchableView>
        </SafeAreaView>
      </View>
    );
  }
}

export default Header;
