import { getReferralDashboard } from '../../api-store';
import { colors, globalStyles } from '../../common';
import { constants } from '../../common/constants';
import {
  AvIcon,
  AvNote,
  CustomButton,
  Label,
  List,
  avAlert,
} from '../../components';
import { RefundAmount } from '../../containers/Home/Header';
import { IDefaultState } from '../../store/types';
import { filter, get } from 'lodash';
import React from 'react';
import { Share, View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import styles from './styles';
import { IReferralDashboardProps } from './types';

const ReferralDashboardComponent: React.FC<IReferralDashboardProps> = props => {
  React.useEffect(() => {
    props
      .getReferralDashboard()
      .catch(e => props.avAlert('', e.message));
  });

  const refCodeInfo = constants.refCodeInfo.replace(':reward', String(props.rewardAmount));

  const data = [
    { label: 'Number of invitees', value: String(props.referralsCount || 0) },
    {
      label: 'Awaiting referral trips',
      value: String(props.awaitingReferralTripsCount || 0),
    },
  ];

  const share = async () => {
    try {
      await Share.share(
        {
          title: constants.refCodeShareTitle,
          message: constants.refCodeShare
            .replace(':site', constants.site)
            .replace(':code', props.refCode || ''),
        },
        {
          subject: constants.refCodeShareTitle,
        }
      );
    } catch (e: any) {
      props.avAlert('', e.message);
    }
  };

  return (
    <View style={styles.container}>
      <RefundAmount
        accent
        amount={String(props.balance || 0)}
        ccy={constants.currency.eur}
        label={constants.income}
        containerStyle={styles.amountContainer}
      />
      <List
        data={filter(data)}
        style={styles.container}
        contentContainerStyle={styles.paddingContainer}
        keyExtractor={(item, i) => `${item.label}_${i}`}
        renderItem={({ item, index }) => {
          return (
            <View style={styles.rowContainerItem} key={index}>
              <Label style={styles.container} type='H2Dark' text={item.label} />
              <Label text={item.value} type='H1Dark' />
            </View>
          );
        }}
        ListFooterComponent={
          <AvNote
            name='refCode'
            containerStyle={styles.noteContainer}
            component={<Label text={refCodeInfo} type='H1Dark' />}
          />
        }
      />
      <SafeAreaView edges={['bottom']}>
        <CustomButton
          accent
          buttonStyle={globalStyles.marginBottomButton}
          onPress={share}
          iconComponent={
            <View style={styles.copyRefButton}>
              <Label
                text={props.refCode}
                type='H1Bold'
                numberOfLines={1}
                style={styles.copyRefLabel}
              />
              <AvIcon name='Share' fill={colors.white} />
            </View>
          }
        />
      </SafeAreaView>
    </View>
  );
};

const mapStateToProps = (state: IDefaultState) => {
  const referralDashboard = get(state, 'api.referralDashboard', {});
  const userId = get(state, 'api.user.id');
  const rewardAmount = state.api.service.REFERRALS.value.reward || 5;
  return { ...referralDashboard, userId, rewardAmount };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      getReferralDashboard,
      avAlert,
    },
    dispatch
  );
};

export const ReferralDashboard = compose<any>(
  connect(mapStateToProps, mapDispatchToProps)
)(ReferralDashboardComponent);
