import { logOut, passwordChange } from '../../api-store';
import { constants, isIOS, metrics } from '../../common';
import { isConfirm, isPassword } from '../../common/stringValidator';
import { AvTextInput, BodyContainer, CustomButton } from '../../components';
import { avAlert, avConfirm } from '../../components/AvAlert/alertActions';
import { AuthLoginSceneName, AuthSceneName } from '../../navigation/names';
import { IDefaultState } from '../../store/types';
import { CommonActions } from '@react-navigation/native';
import { get } from 'lodash';
import React, { Component } from 'react';
import { Keyboard, KeyboardAvoidingView, View } from 'react-native';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import styles from './styles';
import { IPasswordFormProps, IPasswordFormState } from './types';

class PasswordUpdateComponent extends Component<
  IPasswordFormProps,
  IPasswordFormState
> {
  oldPasswordRef: any;
  newPasswordRef: any;
  confirmPasswordRef: any;

  state = {
    newPassword: { value: '', isValid: true },
    confirmPassword: { value: '', isValid: true },
    isLoading: false,
  };

  isValidFields = (): boolean => {
    const { newPassword, confirmPassword } = this.state;
    return !!(
      newPassword.isValid &&
      newPassword.value &&
      confirmPassword.isValid &&
      confirmPassword.value
    );
  };

  toggleIsLoading = () => this.setState({ isLoading: !this.state.isLoading });

  handleOnResetPassword = async () => {
    if (this.isValidFields()) {
      Keyboard.dismiss();
      this.props.avConfirm(
        '',
        constants.account.passwordResetConfirm,
        this.handleOnResetPasswordSubmit
      );
    }
  };

  handleOnResetPasswordSubmit = () => {
    this.toggleIsLoading();
    const { newPassword } = this.state;
    const { email } = this.props;

    this.props
      .passwordChange(newPassword.value)
      .then(async () => {
        await this.props.logOut();
        this.toggleIsLoading();
        this.props.navigation.dispatch(
          CommonActions.reset({
            index: 1,
            routes: [
              { name: AuthSceneName },
              {
                name: AuthLoginSceneName,
                params: { email },
              },
            ],
          })
        );
      })
      .catch((e: any) => {
        this.toggleIsLoading();
        this.props.avAlert('', e.message);
      });
  };

  render() {
    return (
      <KeyboardAvoidingView
        {...(isIOS ? { behavior: 'padding' } : {})}
        style={styles.container}
        keyboardVerticalOffset={metrics.keyboardOffset}>
        <BodyContainer useSafeArea>
          <View style={styles.container}>
            <AvTextInput
              key='newPass'
              createRef={ref => {
                this.newPasswordRef = ref;
              }}
              label={constants.labels.newPassword}
              otherTextInputProps={{
                placeholder: constants.labels.newPasswordPlaceholder,
                onSubmitEditing: () => this.confirmPasswordRef.focus(),
                secureTextEntry: true,
                returnKeyType: 'next',
              }}
              isValid={[isPassword]}
              onValidationChange={(value, isValid) =>
                this.setState({ newPassword: { value, isValid } })
              }
              errorText={constants.validationErrors.password.invalid}
              testID='newPasswordInp'
            />
            <AvTextInput
              key='confirmPass'
              createRef={ref => {
                this.confirmPasswordRef = ref;
              }}
              label={constants.labels.confirmNewPassword}
              otherTextInputProps={{
                placeholder: constants.labels.newPasswordPlaceholder,
                secureTextEntry: true,
                returnKeyType: 'done',
              }}
              isValid={[isConfirm(this.state.newPassword.value)]}
              onValidationChange={(value, isValid) =>
                this.setState({ confirmPassword: { value, isValid } })
              }
              errorText={constants.validationErrors.confirmPassword.invalid}
              testID='confirmPasswordInp'
            />
          </View>
          <CustomButton
            text={constants.labels.change}
            isUppercase
            accent
            isEnabled={this.isValidFields()}
            isLoading={this.state.isLoading}
            onPress={this.handleOnResetPassword}
            testID='onChangePasswordBtn'
          />
        </BodyContainer>
      </KeyboardAvoidingView>
    );
  }
}

const mapStateToProps = (state: IDefaultState) => {
  const email = get(state, 'api.user.email', '');
  return { email };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      avAlert,
      avConfirm,
      passwordChange,
      logOut,
    },
    dispatch
  );
};

export const PasswordUpdate = compose<any>(
  connect(mapStateToProps, mapDispatchToProps)
)(PasswordUpdateComponent);
