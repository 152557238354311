import React from 'react';
import { StyleProp, Text, TextStyle } from 'react-native';
import colors from '../../common/colors';
import { IFormatedTextProps } from './types';
import { Label } from '../../components/Labels/index';

export class FormatedText extends React.Component<IFormatedTextProps> {
  makeTextArray = (): { format: string; text: string }[] => {
    const { text } = this.props;
    const reg = new RegExp('<\\w>', 'g');

    const x = [];
    const stack = [''];
    let result = reg.exec(text);
    let lastIndex = 0;

    while (result) {
      const format = result[0].replace(/[<>]/g, '');
      const content = text.slice(lastIndex, result.index);
      const lastFormat = stack.length && stack[stack.length - 1];
      const curFormat = stack.reduce((prev, cur) => `${prev}${cur}`, '');
      if (lastFormat === format) {
        stack.pop();
      } else {
        stack.push(format);
      }
      x.push({ format: curFormat, text: content });

      lastIndex = reg.lastIndex;
      result = reg.exec(text);
    }
    x.push({ format: '', text: text.slice(lastIndex) });

    return x.filter(item => item.text.length);
  };

  getStylesByFormat = (format: string) => {
    const result: StyleProp<TextStyle> = {};
    if (format.includes('b')) {
      // bold
      result.fontWeight = 'bold';
    }
    if (format.includes('c')) {
      // colored
      result.color = colors.accent;
    }
    if (format.includes('s')) {
      // searched
      result.color = colors.accent;
    }
    return result;
  };

  render() {
    return (
      <Text
        style={this.props.style}
        allowFontScaling={false}
        adjustsFontSizeToFit={false}
        minimumFontScale={1}
      >
        {this.makeTextArray().map((item, i) => {
          return (
            <Label
              type={this.props.type || 'H1Dark'}
              key={`${i}`}
              style={[
                this.props.style as any,
                this.getStylesByFormat(item.format),
              ]}
              text={item.text}
            />
          );
        })}
      </Text>
    );
  }
}
