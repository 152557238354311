import { ERefundMethodProvider } from '../../../api-store';
import { constants, inputConfigs, IInputConfig } from '../../../common';
import { IRefundViewProps } from './../types';
import { map, pick, get } from 'lodash';

const BANK_ACCOUNT_KEYS = ['accountNumber', 'bicOrSwift'];

const PAY_PAL_KEYS = ['email'];

const stringBankAccountValidator = (regexpString: string) => (
  value: string
): boolean => {
  const regexp = new RegExp(regexpString);
  return !!value && !!(value || '').trim() && regexp.test(value);
};

const createBankAccountForm = (props: IRefundViewProps) => {
  const inputs: IInputConfig[] = map(
    pick(inputConfigs, BANK_ACCOUNT_KEYS)
  ) as IInputConfig[];
  const v0 = get(props, 'bankAccountValidation[0]');
  const v1 = get(props, 'bankAccountValidation[1]');
  inputs[0].isValid = v0
    ? [stringBankAccountValidator(v0.regexp)]
    : inputs[0].isValid;
  inputs[1].isValid = v1
    ? [stringBankAccountValidator(v1.regexp)]
    : inputs[1].isValid;
  return inputs;
};

export const getFormConfigs: any = (props: IRefundViewProps) => ({
  [ERefundMethodProvider.Paypal]: {
    inputs: map(pick(inputConfigs, PAY_PAL_KEYS)),
    onSubmit: props.createPayPalMethod,
  },
  [ERefundMethodProvider.Wechat]: {
    inputs: [inputConfigs.weChatId],
    onSubmit: props.createWeChatMethod,
  },
  [ERefundMethodProvider.Bank]: {
    inputs: createBankAccountForm(props),
    onSubmit: props.createBankAccountMethod,
    notes: [constants.refund.ensureBankAccountIsLinkedToAddress],
  },
  [ERefundMethodProvider.Card]: {
    inputs: [
      props.isFormDisabled
        ? inputConfigs.cardLastDigits
        : inputConfigs.cardNumber,
      {},
      props.isFormDisabled
        ? inputConfigs.cardExpiryMonth
        : inputConfigs.cardExpiry,
      props.isFormDisabled && inputConfigs.cardExpiryYear,
    ].filter(v => !!v),
    onSubmit: props.createCardMethod,
    notes: [constants.refund.ensureCardIsLinkedToNameAndAddress],
    numColumns: 2,
  },
});
