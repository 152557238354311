import { ViewStyle } from 'react-native';
import { TIconName } from '../AvIcon/icons';
import { ICustomButtonProps } from '../../components/CustomButton/types';

export enum EEmptyPageButtonTypes {
  DEFAULT,
  ROUNDED
}

export interface IEmptyPageProps {
  message?: string;
  messageComponent?: any;
  title?: string;
  iconId?: string;
  buttonTestID?: string;
  iconComponent?: any;
  contentComponent?: any;
  buttonComponent?: any;
  buttonText?: string;
  buttonType?: EEmptyPageButtonTypes;
  buttonIcon?: TIconName;
  isButtonTextUppercase?: boolean;
  containerStyle?: ViewStyle;
  buttonContainerStyle?: ViewStyle;
  buttonStyle?: {};
  contentStyle?: ViewStyle;
  labelContainerStyle?: ViewStyle;
  isLoading?: boolean;
  buttonDisabled?: boolean;
  onButtonPress?: () => void;
  buttons?: ICustomButtonProps[]
}
