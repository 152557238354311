import { last } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { avAlert } from '../../components/AvAlert/alertActions';
import {
  CAMERA_OPTIONS,
  CameraContainer,
  CameraRef,
  ITakePictureResponse,
} from '../../components/CameraContainer';
import { StampedFormImagePreviewSceneName } from '../../navigation/names';
import { IStampedFormCameraProps } from './types';

export const StampedFormCameraView: React.FC<
  IStampedFormCameraProps
> = props => {
  const handleOnSavePicture = (cameraRef: CameraRef) => {
    if (!cameraRef || !cameraRef.current) return;
    cameraRef.current
      .takePictureAsync(CAMERA_OPTIONS)
      .then((picture: ITakePictureResponse) => {
        props.navigation.navigate(StampedFormImagePreviewSceneName, {
          ...props.route?.params,
          picture: {
            ...picture,
            uri: picture.uri,
            type: `image/png`,
            name: String(last(picture.uri?.split('/'))),
          },
        });
      })
      .catch(handleOnSavePictureError);
  };

  const handleOnSavePictureError = (e: Error) => {
    props.avAlert('', e.message);
  };

  return !props.navigation.isFocused() ? null : (
    <CameraContainer
      testIdPrefix='stamped'
      onTakePicture={handleOnSavePicture}
    />
  );
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      avAlert,
    },
    dispatch
  );
};

export const StampedFormCamera = compose(connect(null, mapDispatchToProps))(
  StampedFormCameraView
);
