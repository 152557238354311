import { globalStyles } from '../../common/globalStyles';
import { StyleSheet } from 'react-native';

export const cameraContainerStyles = StyleSheet.create({
  container: { flex: 1 },
  centred: { justifyContent: 'center', alignItems: 'stretch' },
  toBottom: { justifyContent: 'flex-end', alignItems: 'center' },
  button: globalStyles.marginBottomButton,
  transparent: { backgroundColor:'transparent'},
  mirror:{
    transform: [{ scaleX: -1 }]
  }
});
