import { defaultState } from '../../store/defaultState';
import { AlertActionType, IAvAlertActions } from './types';

export const alertReducer = (
  state = { ...defaultState.alert },
  actions: IAvAlertActions
) => {
  const type: AlertActionType = actions.type;
  if (!/^ALERT/.test(type)) return state;
  switch (type) {
    case 'ALERT_SET':
      return { ...state, ...actions.payload };
    case 'ALERT_TOGGLE':
      return { ...state, ...actions.payload };
    case 'ALERT_RESET':
      return { ...defaultState.alert };
    default:
      return state;
  }
};
