import { sketchSize } from '../../common';
import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  howCanHelpYou: {
    paddingVertical: sketchSize(60, true),
    marginHorizontal: sketchSize(40),
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  howCanHelpYouLabel: {
    marginRight: sketchSize(40),
  },
});
