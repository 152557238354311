import { get } from 'lodash';
import { airvatClient } from '../../common/api';
import { createSetAction } from '../helpers';
import { IUser } from '../types';
import { handleAuthError } from './auth';
import { IDefaultState } from '../../store/types';

export const sendFeedBack = (feedBackData: {
  message: string;
  rate: number;
}) => async (dispatch: any, getState: any) => {
  const state = getState();
  const user = get(state, 'api.user', {} as IUser);
  const requestData = {
    user_email: user.email,
    ...feedBackData,
  };
  const data = await airvatClient
    .post('feedback', requestData)
    .catch((e: any) => handleAuthError(e)(dispatch, getState));
  const action = createSetAction(`user.feedback`, data);
  dispatch(action);
};

export const setIntercomNotify =
  (isIntercomNotify: boolean) => async (dispatch: any, getState: any) => {
    const state: IDefaultState = getState();
    if (state.api?.isIntercomNotify !== isIntercomNotify) {
      const action = createSetAction("isIntercomNotify", isIntercomNotify);
      dispatch(action);
    }
  };