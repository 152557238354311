import { localStorage } from '../../common';
import React from 'react';
import { IAvNoteProps, IAvNoteState } from './types';

export const AvNoteHOC = (Comp: React.FunctionComponent<IAvNoteProps>) =>
  class AvNoteComponent extends React.Component<IAvNoteProps, IAvNoteState> {
    state = {
      isVisible: !!this.props.isVisible,
    };

    toggleIsVisible = () => {
      const isVisible = !this.state.isVisible;
      this.setState({ isVisible });
      !isVisible && localStorage(this.props.name, { isVisible: false }).set();
    };

    componentDidMount() {
      localStorage(this.props.name)
        .get()
        .then(data => {
          !data && this.toggleIsVisible();
        });
    }

    public render() {
      const props = {
        ...this.props,
        isVisible: this.state.isVisible,
        onSetIsVisible: this.toggleIsVisible,
      } as IAvNoteProps;
      return <Comp {...props} />;
    }
  };
