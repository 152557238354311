import { StyleSheet } from 'react-native';
import colors from '../../../common/colors';
import { sketchSize } from '../../../common/metrics';

export default StyleSheet.create({
  container: {
    flex: 1,
  },
  safeAreaContainer: { flex: 1, justifyContent: 'space-between' },
  calendarButtonWrapper: {
    marginHorizontal: sketchSize(50),
    marginVertical: sketchSize(50),
    marginTop: sketchSize(40),
  },
  lockIconContainer: {
    position: 'absolute',
    right: sketchSize(15),
    bottom: sketchSize(20),
  },
  lockIcon: {
    marginTop: 8,
    marginLeft: 8,
    marginRight: 0,
    width: sketchSize(42),
    height: sketchSize(42),
  },
  fieldsContainer: {
    flexDirection: 'row',
    paddingVertical: sketchSize(20),
    paddingHorizontal: sketchSize(30),
    marginVertical: sketchSize(40),
    marginHorizontal: sketchSize(30),
    flex: 1
  },
  immutableFieldsContainer: {
    backgroundColor: colors.whiteGrey,
    borderRadius: sketchSize(20),
  },
  nextButton: {
    marginBottom: sketchSize(40, true),
  },
});
