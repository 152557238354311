import { addReceipt, IApiUploadFile } from '../../api-store';
import {
  constants,
  createApiUploadFile,
  deviceWidth,
  globalStyles,
  isAndroid,
} from '../../common';
import { CustomButton } from '../../components';
import { avAlert } from '../../components/AvAlert/alertActions';
import PicturePreview from '../../components/PicturePreview';
import { StackActions } from '@react-navigation/native';
import { size } from 'lodash';
import React from 'react';
import { View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { RNCarousel, RNPagination } from '../../modules/RNCarousel';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import styles from './styles';
import { IReceiptImagePreviewProps } from './types';

const ReceiptImagePreview: React.FC<IReceiptImagePreviewProps> = (props) => {
  const [activeSlide, setActiveSlide] = React.useState(0);
  const [isFileUploading, setIsFileUploading] = React.useState(false);
  const carouselRef: any = React.useRef();

  const pictures: IApiUploadFile[] = props.route?.params?.pictures;

  const complete = async () => {
    setIsFileUploading(true);
    try {
      await props.addReceipt(pictures);
      props.navigation.dispatch(StackActions.popToTop());
    } catch (e) {
      handleError(e);
    }
  };

  const handleError = (e: any) => {
    setIsFileUploading(false);
    props.avAlert('', e.message);
  };

  const handleOnRetake = () => {
    props.navigation.goBack();
  };

  return (
    <SafeAreaView edges={['top', 'bottom']} style={styles.container}>
      <View style={styles.container}>
        <View style={styles.container}>
          <RNCarousel
            data={pictures}
            sliderWidth={deviceWidth}
            itemWidth={deviceWidth}
            renderItem={({ item, index }) => (
              <PicturePreview
                style={styles.imagePreview}
                picture={createApiUploadFile(item)}
                key={index}
              />
            )}
            onSnapToItem={(index: number) => setActiveSlide(index)}
            ref={carouselRef}
          />
          <RNPagination
            tappableDots={!!carouselRef.current}
            carouselRef={carouselRef.current}
            dotsLength={size(pictures)}
            activeDotIndex={activeSlide}
            dotStyle={styles.dotStyle}
            inactiveDotOpacity={0.4}
            inactiveDotScale={0.6}
          />
        </View>
        <View style={styles.buttonsContainer}>
          <CustomButton
            accent
            inverted
            isUppercase
            text={props.route.params?.retakeLabel || constants.labels.retake}
            onPress={handleOnRetake}
            buttonStyle={globalStyles.buttonsBottomSpace}
            testID="receiptCameraReTakePictureBtn"
            isEnabled={!isFileUploading}
          />
          <CustomButton
            accent
            isUppercase
            text={constants.labels.save}
            isLoading={isFileUploading}
            onPress={complete}
            buttonStyle={isAndroid && globalStyles.buttonsBottomSpace}
            testID="receiptCameraSavePictureBtn"
          />
        </View>
      </View>
    </SafeAreaView>
  );
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      avAlert,
      addReceipt,
    },
    dispatch
  );
};

export default compose<any>(connect(null, mapDispatchToProps))(
  ReceiptImagePreview
);
