import { EDocumentStatus } from '../../../../api-store';
import { colors, constants, globalStyles, isWeb } from '../../../../common';
import { AvIcon, Label } from '../../../../components';
import { TIconName } from '../../../../components/AvIcon/icons';
import PicturePreview from '../../../../components/PicturePreview';
import { size } from 'lodash';
import React from 'react';
import { ActivityIndicator, View } from 'react-native';
import styles from './styles';
import { IPassportDetailFieldProps } from './types';

export class PassportDetailField extends React.PureComponent<IPassportDetailFieldProps> {
  getFieldName = () => {
    const { documentStatus, fieldName, showPhoto } = this.props;
    const isPassportField = fieldName === constants.myDetails.passportLabel;
    const isDeclined = documentStatus === EDocumentStatus.DECLINED;

    return (
      <View
        style={[
          isPassportField
            ? styles.fieldNameContainerSpaced
            : styles.fieldNameContainer,
          showPhoto && styles.fieldNameContainerWithIncreasedMarginBottom,
        ]}
      >
        <Label
          text={fieldName}
          style={[
            styles.fieldName,
            isPassportField && styles.fieldNamePassport,
          ]}
          type={'H2Light'}
        />
        {!isPassportField &&
          fieldName &&
          isDeclined &&
          this.getErrorSign('Notify')}
        {isPassportField && this.getDocumentStatus()}
      </View>
    );
  };

  getErrorSign = (iconName: TIconName) => <AvIcon name={iconName} width={42} />;

  getDocumentStatus = () => {
    switch (this.props.documentStatus) {
      case EDocumentStatus.VERIFIED:
        return this.renderStatus(constants.myDetails.verifiedLabel);
      case EDocumentStatus.DECLINED:
        return this.renderStatus(constants.myDetails.declinedLabel);
      default:
        return null;
    }
  };

  renderStatus = (status: string) => {
    const { declinedLabel, verifiedLabel } = constants.myDetails;
    const iconName =
      status === declinedLabel
        ? 'Notify'
        : status === verifiedLabel
        ? 'CheckboxChecked'
        : 'Clock';

    return (
      <View style={styles.verificationStatusContainer}>
        {this.getErrorSign(iconName)}
        <Label
          accent={status === verifiedLabel}
          error={status === declinedLabel}
          type="H2Light"
          text={status}
          style={styles.verifiedText}
        />
      </View>
    );
  };

  sliceString = (value?: string) => {
    if (value && size(value) > 13) {
      return value.slice(0, 13) + '...';
    } else {
      return value || '';
    }
  };

  getFieldValue = () => {
    const { documentStatus, fieldName, fieldValue } = this.props;
    const isPassportField = fieldName === constants.myDetails.passportLabel;

    const isVerifying = documentStatus === EDocumentStatus.IN_PROGRESS;
    const text = fieldName && this.sliceString(fieldValue);
    if (isVerifying && isPassportField) {
      return this.renderStatus(constants.myDetails.verifyingLabel);
    }
    return (
      <Label
        text={(text || '').toUpperCase()}
        type="H1Dark"
        style={styles.fieldText}
        numberOfLines={1}
      />
    );
  };

  getPhotoView = () => {
    const { picture, useFullSizePhoto, documentStatus, isPhotoLoading } =
      this.props;
    return (
      <View
        style={[
          styles.photoPlaceholder,
          useFullSizePhoto ? styles.photoPlaceholderFullSize : {},
          isWeb ? { width: '100%' } : {},
        ]}
      >
        {!!picture && (
          <View style={styles.photoDocumentContainer}>
            <PicturePreview
              picture={picture}
              style={styles.photoDocumentImage}
              onLoadStart={this.props.onLoadStart}
              onLoadEnd={this.props.photoOnLoad}
              onError={this.props.photoOnError}
            />
          </View>
        )}
        {isPhotoLoading && !!documentStatus && !!picture && (
          <ActivityIndicator
            color={colors.accent}
            style={globalStyles.photoPreloader}
          />
        )}
      </View>
    );
  };

  render() {
    const { showPhoto, noMargin, useFullSizePhoto } = this.props;
    return (
      <View
        style={[
          styles.container,
          showPhoto && useFullSizePhoto ? styles.photoContainer : {},
          noMargin ? styles.containerWithNoMarginRight : {},
        ]}
      >
        {this.getFieldName()}
        {showPhoto ? this.getPhotoView() : this.getFieldValue()}
      </View>
    );
  }
}

export default PassportDetailField;
