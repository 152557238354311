import { globalStyles } from '../../common';
import React from 'react';
import { SectionList as RnSectionList } from 'react-native';
import { ISectionList } from './types';

export const SectionList: React.FC<ISectionList<any>> = ({
  sections,
  renderItem,
  renderSectionHeader,
  ...options
}) => {
  const {
    style,
    contentContainerStyle,
    keyExtractor,
    registerRef,
    ListFooterComponent,
    ...otherOptions
  } =
    options ||
    ({
      scrollEnabled: true,
      useMask: false,
    } as any);

  const flatListStyle = [globalStyles.listContainerStyle, style || {}];

  return (
    <RnSectionList
      ref={ref => {
        registerRef && registerRef(ref);
      }}
      stickySectionHeadersEnabled={false}
      renderItem={renderItem}
      renderSectionHeader={renderSectionHeader}
      sections={sections}
      keyboardShouldPersistTaps={'handled'}
      style={flatListStyle}
      ListFooterComponent={ListFooterComponent}
      contentContainerStyle={contentContainerStyle}
      showsVerticalScrollIndicator={false}
      {...((keyExtractor && { keyExtractor: keyExtractor }) || {})}
      {...otherOptions}
    />
  );
};
