import { colors, constants, images, isSmallDevice, sketchSize } from '../../../common';
import { AvIcon, Header } from '../../../components';
import { IHomeProps } from '../../../containers/Home/types';
import styles from '../../../containers/Trip/styles';
import { MoreSceneName, SelectTripCountrySceneName } from '../../../navigation/names';
import React from 'react';
import { View } from 'react-native';
import {
  SafeAreaInsetsContext,
  SafeAreaView,
} from 'react-native-safe-area-context';
import TripCarousel from './TripCarousel';
import { RNFastImage } from '../../../modules/RNFastImage';

class AllTrips extends React.Component<IHomeProps> {
  onAddNewTrip = () => {
    this.props.navigation.navigate(SelectTripCountrySceneName);
  };

  renderBackgroundImage = () => (
    <View style={styles.fillImageContainer}>
      <RNFastImage
        style={styles.fillImage}
        resizeMode='cover'
        source={images.airportPlaceholder}
      />
    </View>
  );

  render() {
    return (
      <Header
        title={
          this.props.userFirstName
            ? `Hello, ${this.props.userFirstName}!`
            : constants.headers.getTaxBack
        }
        rightIconPress={() => {
          this.props.navigation.navigate(MoreSceneName);
        }}
        rightButtonIconComponent={
          <AvIcon
            name='More'
            withNotify={this.props.isMenuNotify}
            fill={colors.light}
          />
        }
        rightButtonTestID='goToMoreBtn'
      >
        <SafeAreaInsetsContext.Consumer>
          {(insets) => (
            <SafeAreaView
              edges={['bottom']}
              style={[
                styles.container,
                {
                  marginBottom: insets?.bottom
                    ? 0
                    : sketchSize(isSmallDevice ? 40 : 70),
                },
              ]}
            >
              {this.renderBackgroundImage()}
              <TripCarousel navigation={this.props.navigation} />
            </SafeAreaView>
          )}
        </SafeAreaInsetsContext.Consumer>
      </Header>
    );
  }
}

export default AllTrips;
