import { find } from 'lodash';
import moment from 'moment';
import { ITrip } from '../../api-store';
import { getActiveTripId, isCompleteTaxform } from '../../common';
import { IDefaultState } from '../../store/types';

export function getCurrentTrip(state: IDefaultState): ITrip | undefined {
  const tripId = getActiveTripId(state);
  return state.api.user?.trips && state.api.user?.trips[`${tripId}`];
}

export const getIsNeedAskPassportAddressUpdate = (state: IDefaultState) => {
  const document = state.api.user?.document;
  const isExistsCompleteTrips = !!find(
    state.api.user?.trips,
    (v) => !!find(v.taxforms, isCompleteTaxform)
  );
  const isNeedAskPassportAddress = !!document?.id && isExistsCompleteTrips;
  const isForceNeedUpdatePassportAddress =
    !!document?.id && moment(document.createdAt).add(1, 'year').isBefore();
  return {
    isNeedAskPassportAddress,
    isForceNeedUpdatePassportAddress,
  };
};
