import { last } from 'lodash';
import { airvatClient } from '../../common/api';
import { createSetAction } from '../helpers';
import { IUser, IUserAddress } from '../types';
import { handleAuthError } from './auth';
import { IDefaultState } from '../../store/types';

export const addAddress =
  (addressRequest: IUserAddress) => async (dispatch: any, getState: any) => {
    const state: IDefaultState = getState();
    const addressResponse = await airvatClient
      .post<IUserAddress>('addresses', addressRequest)
      .catch((e: any) => handleAuthError(e)(dispatch, getState));

    const storeUser = state.api?.user;
    const userData = {
      ...storeUser,
      address: { ...(storeUser.address || {}), ...addressResponse },
    } as IUser;

    const action = createSetAction('user', userData);
    dispatch(action);
  };

export const updateAddress =
  (id: number, addressRequest: IUserAddress) => async (dispatch: any, getState: any) => {
    const state: IDefaultState = getState();
    const addressResponse = await airvatClient
      .put<IUserAddress>(
        `addresses/${id}`,
        addressRequest
      )
      .catch((e: any) => handleAuthError(e)(dispatch, getState));

    const storeUser = state.api?.user;
    const userData = {
      ...storeUser,
      address: { ...(storeUser.address || {}), ...addressResponse },
    } as IUser;
    const action = createSetAction('user', userData);
    dispatch(action);
  };

export const getAddress = () => async (dispatch: any, getState: any) => {
  const state: IDefaultState = getState();
  const addressResponse = await airvatClient
    .get<IUserAddress[]>('addresses')
    .catch((e: any) => handleAuthError(e)(dispatch, getState));
  const address = last(addressResponse || []);
  if (!address) return;
  const storeUser = state.api?.user;
  const userData = {
    ...storeUser,
    address: { ...(storeUser.address || {}), ...address },
  } as IUser;

  const action = createSetAction('user', userData);
  dispatch(action);
};
