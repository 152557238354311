import { constants, ENV } from '../../common';
import { Label } from '../../components';
import { ILabelProps } from '../../components/Labels/types';
import { getCodePushVersion } from '../../containers/AppVersion/actions';
import React from 'react';

export const AppVersion = (props: Partial<ILabelProps>) => {
  const isMountedRef = React.useRef(false);
  const [appVersion, setAppVersion] = React.useState(ENV.APP_VERSION);
  React.useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  React.useEffect(() => {
    getCodePushVersion().then(m => {
      let appVersion = `${ENV.APP_VERSION} r${m}`;
      if(isMountedRef.current){
        setAppVersion(appVersion);
      }
    });
  }, []);

  return (
    <Label
      centered
      text={`${constants.labels.appVersion} ${appVersion}`}
      type='H2Light'
      {...props}
    />
  );
};
