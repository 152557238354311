import { apiState } from '../api-store';

export const defaultState: any = {
  alert: {
    title: '',
    message: '',
    isVisible: false,
  },
  loader: {
    message: '',
    isVisible: false,
  },
  api: apiState,
};
