import { isWeb } from '../../common';
import { AirvatInfoSlider } from '../../containers/AirvatInfo';
import AuthScreen from '../../containers/Auth';
import { EFormType } from '../../containers/Auth/types';
import { authStackNavigatorConfig } from '../../navigation/config';
import {
  AuthForgotEmailSentSceneName,
  AuthLoginSceneName,
  AuthSceneName,
  AuthSignUpSceneName,
} from '../../navigation/names';
import { INavigationScreenProps } from '../../navigation/types';

const navigationOptions = (props: INavigationScreenProps) => {
  return {
    ...authStackNavigatorConfig(props),
  };
};

export default {
  [AuthSceneName]: {
    screen: AirvatInfoSlider,
    path: isWeb ? undefined : 'auth/:token',
    navigationOptions,
  },
  [AuthLoginSceneName]: {
    screen: AuthScreen,
    path: isWeb ? undefined : 'login/:email',
    navigationOptions,
    params: {
      formType: EFormType.LOGIN,
    },
  },
  [AuthSignUpSceneName]: {
    screen: AuthScreen,
    navigationOptions,
    params: {
      formType: EFormType.SIGNUP,
    },
  },
  [AuthForgotEmailSentSceneName]: {
    screen: AuthScreen,
    navigationOptions,
    params: {
      formType: EFormType.FORGOT,
    },
  },
};
