import { map } from 'lodash';
import React from 'react';
import { ScrollView, View } from 'react-native';
import { colors, deviceHeight, isWeb } from '../../common';
import { RNLinearGradient } from '../../modules/RNLinearGradient';

export const List: React.FC<any> = ({ data, renderItem, ...options }) => {
  const {
    scrollEnabled,
    style,
    contentContainerStyle,
    gradientStyle,
    keyExtractor,
    useMask,
    numColumns,
    ListFooterComponent,
    registerRef,
    contentInset,
    ListEmptyComponent,
    ListHeaderComponent,
    ...otherProps
  } =
    options ||
    ({
      scrollEnabled: true,
      useMask: false,
    } as any);

  const flatList = (
    <ScrollView
      ref={(ref: any) => {
        registerRef && registerRef(ref);
      }}
      style={style}
      numColumns={numColumns}
      renderItem={renderItem}
      scrollEnabled={scrollEnabled}
      keyboardShouldPersistTaps={'handled'}
      showsVerticalScrollIndicator={false}
      contentContainerStyle={[
        { height: deviceHeight - 200 },
        contentContainerStyle,
      ]}
      contentInset={contentInset}
      renderToHardwareTextureAndroid
      shouldRasterizeIOS
      onScrollToIndexFailed={(e: any) => {}}
      {...otherProps}
    >
      {ListHeaderComponent}
      {map(data, (item, index) => (
        <React.Fragment key={keyExtractor ? keyExtractor(item, index) : index}>
          {renderItem({ item, index })}
        </React.Fragment>
      ))}
      {ListEmptyComponent}
      {ListFooterComponent}
      {isWeb ? <View style={{ height: 90, width: '100%' }} /> : undefined}
    </ScrollView>
  );

  return useMask ? (
    <View style={{ flex: 1 }}>
      {flatList}
      <RNLinearGradient
        pointerEvents="none"
        style={[
          {
            position: 'absolute',
            bottom: 0,
            height: '30%',
            width: '100%',
          },
          gradientStyle,
        ]}
        colors={[colors.transparent, colors.white]}
      />
    </View>
  ) : (
    flatList
  );
};
