import { ENV } from 'common';

export const RNCodePush = {
  getUpdateMetadata: async () => {
    return {
      label: ENV.WEB_REVISION,
    };
  },
  checkForUpdate: () => {},
  sync: () => {},
  notifyAppReady: () => {},
  InstallMode: { IMMEDIATE: '' },
  SyncStatus: { INSTALLING_UPDATE: '' },
};
