import { StyleSheet } from 'react-native';
import { sketchSize, colors } from '../../common';

export const selfieStyles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.darkTransparent,
  },
  maskContainer: {
    flex: 1,
    width: '100%'
  },
  imagePreview: {
    marginTop: sketchSize(90, true),
    width: sketchSize(430, true),
    height: sketchSize(530, true),
    alignSelf: 'center',
  }
});
