import { useGoogleLogin } from '@react-oauth/google';
import React from 'react';
import { appleAuthHelpers, useScript } from 'react-apple-signin-auth';
import FacebookAuth from 'react-facebook-auth';
import { View } from 'react-native';
import { constants, ENV } from '../../../common';
import { AvIcon, CustomButton, Label } from '../../../components';
import { IThridPartyAuthProps } from '../types';
import styles from './styles';

export const ThirdPartyAuth: React.FC<IThridPartyAuthProps> = (props) => {
  const appId =
    ENV.ENV === 'development' ? '1538194809935119' : '1745733565800021';
  const env = ENV.ENV === 'development' ? '.dev' : '';

  useScript(appleAuthHelpers.APPLE_SCRIPT_SRC);

  const facebookButton = ({ onClick }: any) => (
    <CustomButton
      buttonStyle={styles.touchable}
      onPress={onClick}
      isLoading={props.isLoadingFaceBookAuth}
      isEnabled={!props.buttonDisabled}
      inverted={props.isLoadingFaceBookAuth}
      iconComponent={<AvIcon name={'Facebook'} width={70} />}
    />
  );

  const googleAuth = useGoogleLogin({
    flow: 'auth-code',
    onSuccess: (tokenResponse) => {
      props.googleOnLoginOrRegister(tokenResponse?.code);
    },
    onError: () => {
      console.error('Google auth error');
    },
  });

  const facebookAuth = (response: any) => {
    props.facebookOnLoginOrRegister(response.accessToken);
  };

  return (
    <View style={styles.container}>
      <Label
        text={constants.auth.orLoginWith}
        type="H2Light"
        style={styles.textContainer}
      />
      <View style={styles.buttonsContainer}>
        <CustomButton
          buttonStyle={[styles.touchable, styles.touchableLeft]}
          onPress={props.appleOnLoginOrRegister}
          isLoading={props.isLoadingAppleAuth}
          isEnabled={!props.buttonDisabled}
          inverted={props.isLoadingAppleAuth}
          iconComponent={<AvIcon name={'Apple'} width={65} />}
        />
        <CustomButton
          buttonStyle={styles.touchable}
          onPress={googleAuth}
          isLoading={props.isLoadingGoogleAuth}
          isEnabled={!props.buttonDisabled}
          inverted={props.isLoadingGoogleAuth}
          iconComponent={<AvIcon name={'Google'} width={70} />}
        />
        <FacebookAuth
          appId={appId}
          callback={facebookAuth}
          component={facebookButton}
          redirectUri={`https://app${env}.airvat.com`}
          disableRedirect={true}
          onFailure={(e: any) => {
            console.error('Facebook auth error', e);
          }}
        />
      </View>
    </View>
  );
};
