import React from 'react';
import { CustomButton } from '../../components';
import { AvAlertButtonProps } from './types';
import styles from './styles';
export const renderButtons = (buttons: AvAlertButtonProps[]) =>
  buttons.map((b, i) => (
    <CustomButton
      key={i}
      buttonStyle={[
        styles.button,
        i === 0
          ? b.accent
            ? styles.buttonSuccess
            : styles.buttonRed
          : styles.buttonGray,
      ]}
      testID={`avAlertBtn${i}`}
      onPress={b.onPress}
      text={b.text}
    />
  ));
