import { constants } from '../../common/constants';
import {
  PassportCamera,
  PassportPreview,
  SelfieCamera
} from '../../containers/Documents';
import { PassportCameraPlaceholder } from '../../containers/Documents/PassportCameraPlaceholder';
import { SelfieCameraPlaceholder } from '../../containers/Documents/SelfieCameraPlaceholder';
import { SelfiePreview } from '../../containers/Documents/SelfiePreviewView';
import {
  commonStackNavigatorConfig,
  transparentHeader
} from '../../navigation/config';
import * as SceneNames from '../../navigation/names';
import { INavigationScreenProps } from '../../navigation/types';
import { PassportDetails } from './../../containers/AddDetails/Passport/PassportDetails';

export default {
  [SceneNames.PassportCameraPlaceholderSceneName]: {
    screen: PassportCameraPlaceholder,
    navigationOptions: (props: INavigationScreenProps) => ({
      title: constants.headers.myPassport,
      ...commonStackNavigatorConfig(props)
    }),
  },
  [SceneNames.PassportCameraSceneName]: {
    screen: PassportCamera,
    navigationOptions: {
      title: constants.headers.myPassport,
      ...transparentHeader,
    },
  },
  [SceneNames.PassportPreviewSceneName]: {
    screen: PassportPreview,
    navigationOptions: (props: INavigationScreenProps) => ({
      title: constants.headers.myPassport,
      ...commonStackNavigatorConfig(props),
      headerTransparent: true,
    }),
  },
  [SceneNames.SelfieCameraPlaceholderSceneName]: {
    screen: SelfieCameraPlaceholder,
    navigationOptions: (props: any) => ({
      title: constants.headers.mySelfie,
      ...commonStackNavigatorConfig(props),
    }),
  },
  [SceneNames.SelfieCameraSceneName]: {
    screen: SelfieCamera,
    navigationOptions: {
      title: constants.headers.mySelfie,
      ...transparentHeader,
    },
  },
  [SceneNames.SelfiePreviewSceneName]: {
    screen: SelfiePreview,
    navigationOptions: (props: INavigationScreenProps) => ({
      title: constants.headers.mySelfie,
      ...commonStackNavigatorConfig(props),
      headerTransparent: true,
    }),
  },
  [SceneNames.PassportDetailsSceneName]: {
    screen: PassportDetails,
    navigationOptions: (props: INavigationScreenProps) => ({
      title: constants.headers.myPassport,
      ...commonStackNavigatorConfig(props),
    }),
  },
};
