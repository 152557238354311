import { StyleSheet } from 'react-native';
import { colors, sketchSize } from '../../common';

export default StyleSheet.create({
  containerStyle: {
    padding: sketchSize(39),
    flex: 1
  },
  step: {
    flexDirection: 'row',
    backgroundColor: colors.white,
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingHorizontal: sketchSize(5)
  },
  stepTitle:{
    flex: 1,
    justifyContent: 'center',
    marginLeft: sketchSize(39),
  },
  stepWrap: {
    height: sketchSize(200)
  },
  listFooterSpacer: {
    height: sketchSize(90)
  },
  listItemText: {
    marginLeft: sketchSize(39),
    flex: 1
  },
  listItemImage: {
    width: sketchSize(204),
    height: sketchSize(134),
    borderRadius: sketchSize(30),
    overflow: 'hidden'
  },
  gradient: {
    flex: 1
  },
  listContent:{
    paddingBottom: sketchSize(160)
  }
});
