import { colors, ENV, isIOS, waitForAsync } from '../../common';
import constants from '../../common/constants';
import { isEnglish } from '../../common/stringValidator';
import { AvTextInput, List, SectionList, TouchableView } from '../../components';
import React, { Component } from 'react';
import { KeyboardAvoidingView, View } from 'react-native';
import { FormatedText } from './FormatedText';
import styles from './styles';
import { TogglePanel } from './TogglePanel';
import {
  IAccordionSearchItem,
  IAccordionSearchMatches,
  IAccordionSearchProps,
  IAccordionSearchResult,
  IAccordionSearchState,
  IAccordionSearchText,
} from './types';

export class AccordionSearch extends Component<
  IAccordionSearchProps,
  IAccordionSearchState
> {
  state = {
    openedIndex: null,
    matches: [],
    isValidTextInput: true,
    query: '',
  };

  private textInput!: any;
  private list!: any;
  private data = this.props.data.map(
    ({ title, body, children, id, style }: IAccordionSearchItem) => {
      const result: IAccordionSearchResult = {
        title,
        body: (body || '').replace('*INFO_EMAIL*', `<c>${ENV.INFO_EMAIL}<c>`),
        children,
        style,
        id,
      };
      return result;
    }
  );

  render() {
    return (
      <KeyboardAvoidingView
        {...(isIOS ? { behavior: 'padding' } : {})}
        style={styles.container}
      >
        <View style={styles.textInput}>
          <AvTextInput
            createRef={ref => {
              this.textInput = ref;
            }}
            key='search'
            testID='accordionSearchInp'
            isForceShowErrorText={!this.state.isValidTextInput}
            showSearchIcon
            placeholderTextColor={colors.santasGrey}
            onValidationChange={this.onSearchTextChange}
            isValid={[isEnglish]}
            errorText={constants.validationErrors.english}
            otherTextInputProps={{
              placeholder: this.props.searchPlaceholder || '',
              autoFocus: !!this.props.autoFocus,
            }}
          />
        </View>
        <View style={styles.fill}>
          {this.state.query
            ? this.renderMatches()
            : this.renderAccordionSearch()}
        </View>
      </KeyboardAvoidingView>
    );
  }

  private blurInput = () => {
    this.textInput && this.textInput.blur();
  };

  private onRowPress = (index: number) => {
    this.blurInput();
    this.setState({
      openedIndex: this.state.openedIndex === index ? null : index,
    });
  };

  private buildSearchPreString = (text: string, index: number) => {
    const wordsArray = text.slice(0, index).split(' ');
    const initialIndex = Math.max(wordsArray.length - 3, 0);
    const preString = wordsArray
      .slice(initialIndex, wordsArray.length)
      .join(' ')
      .trim();
    return initialIndex === 0 ? preString : preString ? '...' + preString : '';
  };

  private buildSearchPostString = (text: string, lastIndex: number) => {
    const wordsArray = text.slice(lastIndex, text.length).split(' ');
    const postString = wordsArray.slice(0, 3).join(' ');
    return postString ? postString + '...' : '';
  };

  private onSearchTextChange = (str: string, isValidTextInput: boolean) => {
    const matches: IAccordionSearchText[] = [];
    if (str && str.length) {
      this.data.forEach(
        (
          { title, body, children, id }: IAccordionSearchResult,
          index: number
        ) => {
          const data = this.makeSearch(body, str);
          const topic = (title || '').replace(
            new RegExp(str, 'gi'),
            `<s>$&<s>`
          );
          const topicData: IAccordionSearchMatches = {
            index,
            topic,
            data,
            id,
            children,
          };
          const dataChildren: IAccordionSearchMatches[] = [];
          if (children) {
            children.forEach(child => {
              const childData = this.makeSearch(child.body, str);
              const childTopic = (child.title || '').replace(
                new RegExp(str, 'gi'),
                `<s>$&<s>`
              );
              if (childData.length) {
                dataChildren.push({
                  index,
                  id: child.id,
                  parentId: id,
                  topic: childTopic,
                  data: childData,
                });
              }
            });
            if (dataChildren.length) {
              topicData.data = dataChildren;
            }
          }
          if (data.length || topicData.data.length) {
            matches.push(topicData);
          }
        }
      );
    }

    this.setState({ matches, isValidTextInput, query: str });
  };

  private makeSearch = (text: string, searchString: string) => {
    const matches = [];
    if (searchString.length < 1 || searchString === undefined) {
      return [];
    }
    const regexp = new RegExp(`(\\s?\\w*)(${searchString})(\\w*\\s?)`, 'gi');
    let result = regexp.exec(text);

    while (result) {
      const preString = this.buildSearchPreString(text, result.index);
      const postString = this.buildSearchPostString(text, regexp.lastIndex);
      const matching = `${preString} ${result[1]}<s>${result[2]}<s>${result[3]} ${postString}`;
      matches.push(matching);
      result = regexp.exec(text);
    }
    return matches;
  };

  private onMatchingPressComplete = () => {
    this,
      this.setState({ query: '' }, async () => {
        await waitForAsync(100);
        this.list.scrollToIndex({ index: this.state.openedIndex });
        this.textInput?.clear && this.textInput.clear();
      });
  };

  private onMatchingPress = (
    sectionIndex: number,
    item: IAccordionSearchMatches
  ) => {
    this.blurInput();
    if (this.props.onChange && !item.children) {
      this.props.onChange(sectionIndex, item.id, item.parentId);
    } else {
      this.setState(
        { matches: [], openedIndex: sectionIndex },
        this.onMatchingPressComplete
      );
    }
  };

  renderRow = ({
    item,
    index,
  }: {
    item: IAccordionSearchResult;
    index: number;
  }) => {
    const isOpened = this.state.openedIndex === index;
    return (
      <TogglePanel
        index={index}
        id={item.id}
        key={`${item.id}_${index}`}
        parentId={item.parentId}
        title={item.title}
        text={item.body}
        expanded={isOpened}
        children={item.children}
        onToggle={() => this.onRowPress(index)}
        onTextPress={this.props.onChange}
        style={item.style}
        type={this.props.type}
      />
    );
  };

  private renderMatchListItem = ({ item, section }: any) => {
    return (
      <TouchableView
        style={styles.row}
        onPress={() => this.onMatchingPress(section.index, item)}
        testID={`selectAccordionMatchItem${section.index}`}
      >
        <FormatedText
          text={item.topic || item}
          style={[styles.label, styles.matchingText, item.style]}
        />
      </TouchableView>
    );
  };

  private renderSectionHeader = ({ section }: any) => {
    if (!section.topic) return null;
    return (
      <TouchableView
        onPress={() => this.onMatchingPress(section.index, section)}
        testID={`selectAccordionItemHeader${section.index}`}
      >
        <FormatedText
          text={section.topic}
          style={[styles.label, styles.row, section.style]}
          isTitle
        />
      </TouchableView>
    );
  };

  private renderMatches = () => {
    return (
      <SectionList
        sections={this.state.matches}
        renderItem={this.renderMatchListItem}
        renderSectionHeader={this.renderSectionHeader}
        keyExtractor={(item: string, index: number) => index.toString()}
        onScroll={this.blurInput}
        showsVerticalScrollIndicator={true}
        contentContainerStyle={styles.listContainer}
        ListEmptyComponent={this.props.emptyComponent}
      />
    );
  };

  private renderAccordionSearch = () => {
    return (
      <List
        keyExtractor={(item: string, index: number) => index.toString()}
        registerRef={(ref: any) => {
          this.list = ref;
        }}
        data={this.data}
        renderItem={this.renderRow}
        onScroll={this.blurInput}
        showsVerticalScrollIndicator={true}
        contentContainerStyle={styles.listContainer}
        ListEmptyComponent={this.props.emptyComponent}
      />
    );
  };
}
