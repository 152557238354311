import { sketchSize, colors } from '../../common';
import { get, isArray, map } from 'lodash';
import React from 'react';
import { View } from 'react-native';
import Svg, { Path } from 'react-native-svg';
import icons, { IconConfig } from './icons';
import styles from './styles';
import { IAvIconProps } from './types';

const renderSvg: React.FC<IAvIconProps> = ({ name, width, height, fill }) => {
  const size = width || 75;
  const icon: IconConfig = icons[name] as IconConfig;
  const pathFill: string = fill || icon.fill || colors.dark;

  return (
    <Svg
      width={sketchSize(size)}
      height={sketchSize(height || size)}
      viewBox={icon.viewBox}
      style={{ justifyContent: 'center', alignItems: 'center' }}
    >
      {icon.fillRange ? (
        icon.fillRange.map((color: string, i: number) => (
          <Path
            key={`${color}${i}`}
            fill={color}
            d={`${get(icon.d, `[${i}]`)}`}
          />
        ))
      ) : isArray(icon.d) ? (
        <>
          {map(icon.d, (d, i) => {
            return <Path key={i} fill={pathFill} d={`${d}`} />;
          })}
        </>
      ) : (
        <Path fill={pathFill} d={`${icon.d}`} />
      )}
    </Svg>
  );
};

export const AvIcon: React.FC<IAvIconProps> = ({
  name,
  width,
  height,
  fill,
  withNotify,
  containerStyle,
}) => {
  return (
    <View style={[styles.notifyContainerStyle, containerStyle]}>
      {renderSvg({ name, width, height, fill })}
      <View style={styles.innerContainer}>
        {withNotify && renderSvg({ name: 'Notify', width: 50, height })}
      </View>
    </View>
  );
};
