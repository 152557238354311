import { DATE_FORMAT } from '../../../api-store/constants';
import { calendarTheme, constants, isWeb, selectedDateStyle } from '../../../common';
import { CustomButton } from '../../../components';
import moment from 'moment';
import React from 'react';
import { View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import styles from '../styles';
import {
  changeDateInterval,
  createPeriodDates,
  getArrivalDepartureValidRange,
} from '../tripHelpers';
import { ITripDatesProps } from '../types';
import {
  RNTripDatesCalendarList,
  RNDateData,
} from '../../../modules/RNTripDatesCalendarList';

export const TripDates: React.FC<ITripDatesProps> = ({
  trip,
  onSave,
  saveButtonLabel,
  isLoading,
  isModal,
}) => {
  const nowDate = moment().format(DATE_FORMAT);
  const [arrivalDate, setArrivalDate] = React.useState(
    trip?.arrivalDate || nowDate
  );
  const [departureDate, setDepartureDate] = React.useState(
    trip?.departureDate || nowDate
  );

  const handleOnDayPress = ({ dateString }: RNDateData) => {
    const currentDates = createPeriodDates(
      arrivalDate,
      departureDate,
      (startingDay, endingDay) => ({
        ...selectedDateStyle,
        startingDay,
        endingDay,
      })
    );
    const [arrivalDateValue, departureDateValue] = changeDateInterval(
      currentDates,
      dateString
    );

    setArrivalDate(arrivalDateValue);
    setDepartureDate(departureDateValue);
  };

  const handleOnChange = ([arrivalDateValue, departureDateValue]: string[]) => {
    setArrivalDate(arrivalDateValue);
    setDepartureDate(departureDateValue);
  };

  const datesIntervalMoment = getArrivalDepartureValidRange(12, 'month');

  const currentDates = createPeriodDates(
    arrivalDate,
    departureDate,
    (startingDay, endingDay) => ({
      ...selectedDateStyle,
      startingDay,
      endingDay,
    })
  );

  const isSaveButtonEnabled =
    trip?.arrivalDate !== arrivalDate || trip?.departureDate !== departureDate;

  const content = (
    <>
      <View style={styles.container}>
        <RNTripDatesCalendarList
          current={nowDate}
          monthFormat="MMM yyyy"
          hideExtraDays={true}
          //@ts-ignore
          onDayPress={isWeb ? handleOnChange : handleOnDayPress}
          markedDates={currentDates}
          theme={calendarTheme}
          disableMonthChange={true}
          firstDay={1}
          showWeekNumbers={false}
          minDate={datesIntervalMoment.start.format(DATE_FORMAT)}
          maxDate={datesIntervalMoment.end.format(DATE_FORMAT)}
          markingType={'period'}
          pastScrollRange={12}
          futureScrollRange={12}
        />
      </View>
      <View style={styles.calendarButtonWrapper}>
        <CustomButton
          accent
          isEnabled={isSaveButtonEnabled}
          text={saveButtonLabel || constants.labels.save}
          onPress={() => {
            onSave(arrivalDate, departureDate);
          }}
          isLoading={isLoading}
          isUppercase
          testID="saveTripDatesBtn"
        />
      </View>
    </>
  );

  if (isModal) {
    return content;
  }

  return (
    <SafeAreaView edges={['bottom']} style={styles.container}>
      {content}
    </SafeAreaView>
  );
};
