import {
  getReferralDashboard,
  getRefundMethods,
  getSettings,
} from "../../api-store";
import { getActiveTripId, localStorage } from "../../common";
import { IDefaultState } from "../../store/types";
import { keyBy } from "lodash";
import { airvatClient } from "../../common/api";
import { createSetAction } from "../helpers";
import { IUser } from "../types";
import { getAddress } from "./address";
import { agreeAccepted, handleAuthError, logOut } from "./auth";
import { getDocument } from "./documents";
import { getTrip, getTrips } from "./trips";

export const deleteUser = () => async (dispatch: any, getState: any) => {
  await airvatClient
    .delete('accounts')
    .catch((e: any) => handleAuthError(e)(dispatch, getState));
  await logOut()(dispatch, getState);
};

export const userInit =
  (user?: IUser) =>
  async (dispatch: any, getState: any): Promise<IUser | void> => {
    if (user) {
      dispatch(createSetAction('user', user));
    }
    const state: IDefaultState = getState();
    if (!state.api.user?.accessToken) {
      throw new Error('Auth error');
    }
    if (!state.api.user?.isAuth && !user?.id) {
      return;
    }
    await agreeAccepted()(dispatch, getState);
    await getRefundMethods()(dispatch, getState);
    await getAddress()(dispatch, getState);
    await getDocument()(dispatch, getState);
    await getTrips()(dispatch, getState);
    const tripId = getActiveTripId(getState());
    if (tripId) {
      await getTrip(tripId)(dispatch, getState);
    }
    await getSettings()(dispatch);
    try {
      await getReferralDashboard()(dispatch, getState);
    } catch {}
    const res = await getUser()(dispatch, getState);
    dispatch(createSetAction('user.isAuth', !!res?.id));
    return res;
  };

export const updateUser =
  (user: Partial<IUser>) => async (dispatch: any, getState: any) => {
    const state: IDefaultState = getState();
    if (user.email) {
      user.email = user.email.toLowerCase();
    }
    const users = await airvatClient
      .put<IUser[]>(`users/${user.id || state.api?.user?.id}`, user)
      .catch((e: any) => handleAuthError(e)(dispatch, getState));
    if (!users) return;
    const storeData = users[0] || {};
    const storeUser = state.api?.user;
    const userData = {
      ...storeUser,
      ...storeData,
    } as IUser;
    userData.trips = keyBy(userData.trips, 'id');
    const action = createSetAction('user', userData);
    dispatch(action);
  };

export const getUser =
  () =>
  async (dispatch: any, getState: any): Promise<IUser | void> => {
    const state: IDefaultState = getState();
    const users = await airvatClient
      .get<IUser[]>('users')
      .catch((e: any) => handleAuthError(e)(dispatch, getState));
    const user = users && users[0];
    if (!user) return logOut()(dispatch, getState);
    const storageUserInfo = await localStorage(`user-info-${user.id}`).get();
    const isShowInfo = storageUserInfo || {};
    const userUpdatedData = {
      ...state.api.user,
      ...user,
      ...isShowInfo,
    };
    userUpdatedData.trips = keyBy(userUpdatedData.trips, 'id');
    const action = createSetAction('user', userUpdatedData);
    dispatch(action);
    return userUpdatedData;
  };
