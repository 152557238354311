import { colors, constants } from '../../common';
import { avAlert } from '../../components/AvAlert/alertActions';
import { avLoader } from '../../components/AvAlert/loaderActions';
import { CameraContainer } from '../../components/CameraContainer';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { documentsContentView } from './documentsContentView';
import { selfieCameraMask } from './selfieCameraMask';
import { handleOnTakePicture } from './selfieServices';
import { IDocumentCameraProps } from './types';

export const SelfieCameraView: React.FC<IDocumentCameraProps> = props => {
  if (!props.navigation.isFocused()) return null;
  return (
    <CameraContainer
      onTakePicture={handleOnTakePicture(props)}
      cameraMask={selfieCameraMask('selfie-camera')}
      cameraContent={documentsContentView(
        constants.selfieCameraPageTitle,
        constants.selfieCameraPageMessage,
      )}
      testIdPrefix='selfie'
      cameraType='front'
      backgroundColor={colors.darkTransparent}
      cameraFlashMode='off'
    />
  );
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators({
    avAlert,
    avLoader,
  }, dispatch);
};

export const SelfieCamera = compose(
  connect(
    null,
    mapDispatchToProps
  )
)(SelfieCameraView);
