import { locale } from '../../common';
import { colors } from '../../common/colors';
import { globalStyles } from '../../common/globalStyles';
import { images } from '../../common/images';
import React from 'react';
import { ActivityIndicator, View } from 'react-native';
import { RNFastImage } from '../../modules/RNFastImage';
import { AvIcon } from '../AvIcon';
import { Label } from '../Labels';
import TouchableView from '../TouchableView';
import styles from './styles';
import { ICustomButtonProps } from './types';


const renderButtonContent: React.FC<ICustomButtonProps> = props => {
  const {
    isLoading,
    image,
    imageStyle,
    text,
    isUppercase,
    iconComponent,
    inverted,
    link,
    textStyle
  } = props;

  if (isLoading) {
    return (
      <ActivityIndicator
        style={styles.spinner}
        color={inverted ? colors.dark : colors.white}
      />
    );
  }

  if (iconComponent) {
    return iconComponent;
  }

  if (image) {
    return (
      <RNFastImage style={imageStyle} resizeMode='contain' source={images[image]} />
    );
  }
  if (!text) {
    return AvIcon({ name: 'Plus' });
  }
  const classType = link ? 'H1Dark' : 'Button';
  const isUpper = isUppercase && locale === 'en'
  return Label({
    text: isUpper ? (text || '').toUpperCase() : text,
    type: classType,
    crop: isUpper,
    dark: inverted,
    bold: isUpper,
    centered: true,
    useFormatter: true,
    style: textStyle
  });
};

export const CustomButton: React.FunctionComponent<ICustomButtonProps> = props => {
  const {
    isRounded,
    buttonStyle,
    isEnabled,
    isLoading,
    otherProps,
    onPress,
    shadow,
    accent,
    isUppercase,
    inverted,
    testID,
  } = props;
  const disabled = !isEnabled && isEnabled === undefined ? false : !isEnabled;

  const roundStyle = isRounded ? styles.buttonRound : null;
  return (
    <View {...otherProps}>
      <TouchableView
        testID={testID}
        disabled={isLoading || disabled}
        onPress={onPress}
        style={[
          styles.buttonMain,
          roundStyle,
          accent ? styles.accentButton : null,
          inverted ? styles.invertedButton : null,
          accent || shadow ? globalStyles.shadow : null,
          buttonStyle,
          disabled ? styles.disabledButton : null,
          isUppercase ? styles.uppercase : null,
        ]}
      >
        {renderButtonContent(props)}
      </TouchableView>
    </View>
  );
};
