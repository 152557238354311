import React from 'react';
import { Modal } from 'react-native';
import {
  SafeAreaProvider,
  initialWindowMetrics,
} from 'react-native-safe-area-context';
import { Header } from '../Headers';
import styles from './styles';
import { IAvModalProps } from './types';
import { RNTouchableWithoutFeedback } from '../../modules/RNTouchableWithoutFeedback';
import { isUndefined } from 'lodash';

export const AvModal: React.FC<IAvModalProps> = (props) => {
  const content = props.title ? (
    <Header
      title={props.title}
      rightIconName="Cross"
      rightIconPress={props.onBackdropPress}
      style={[
        styles.container,
        styles.containerWithBackground,
        props.modalStyle || {},
      ]}
    >
      {props.children}
    </Header>
  ) : (
    <RNTouchableWithoutFeedback
      style={[styles.container, props.modalStyle]}
      onPress={props.onBackdropPress}
    >
      {props.children}
    </RNTouchableWithoutFeedback>
  );
  return (
    <Modal
      transparent={isUndefined(props.transparent) ? true : props.transparent}
      hardwareAccelerated
      animationType="fade"
      visible={props.isVisible}
      presentationStyle="overFullScreen"
      onRequestClose={() => {
        // console.log('close');
      }}
    >
      <SafeAreaProvider initialMetrics={initialWindowMetrics}>
        {content}
      </SafeAreaProvider>
    </Modal>
  );
};
