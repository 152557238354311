import { colors, fonts, sketchSize } from '../../common';
import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  fill: { flex: 1 },
  container: {
    flex: 1,
    backgroundColor: colors.white,
    justifyContent: 'space-between',
  },
  textInput: {
    paddingHorizontal: sketchSize(60),
  },
  row: {
    marginHorizontal: sketchSize(60),
    alignItems: 'center',
    flexDirection: 'row',
  },
  label: {
    marginVertical: sketchSize(40),
    width: '80%',
    fontFamily: fonts.averta.semibold
  },
  bodyText: {
    marginHorizontal: sketchSize(60),
    color: colors.black,
  },
  childrenWrap: {
    flex: 1,
  },
  childrenItemWrap: {
    paddingVertical: sketchSize(30),
  },
  rightIconContainer: {
    flex: 1,
    alignItems: 'flex-end',
  },
  matchContainer: {
    paddingBottom: sketchSize(60),
  },
  matchesContainer: {
    padding: sketchSize(60),
  },
  listContainer: {
    paddingBottom: sketchSize(120),
    flexGrow: 1
  },
  matchingText: {
    color: colors.darkGrey,
  },
  textItemContainer: { overflow: 'hidden', paddingRight: sketchSize(50) },
  singleTextItemContainer: {
    overflow: 'hidden',
    paddingRight: sketchSize(50),
    height: sketchSize(120),
  },
  borderRow: {
    borderTopWidth: StyleSheet.hairlineWidth,
    borderColor: colors.light
  },
});
