import {
  EDocumentStatus,
  getDocument,
  IApiUploadFile,
} from '../../../api-store';
import { createApiUploadFile, globalStyles } from '../../../common';
import { constants } from '../../../common/constants';
import { CustomButton, List } from '../../../components';
import { avAlert, avConfirm } from '../../../components/AvAlert/alertActions';
import { checkCameraPermissions } from '../../../components/CameraContainer';
import { AirvatInfoMandatory } from '../../../containers/AirvatInfo';
import { PassportCameraPlaceholderSceneName } from '../../../navigation/names';
import { IDefaultState } from '../../../store/types';
import { get } from 'lodash';
import React from 'react';
import { View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { getIsFilledAddress } from '../documentsState';
import { PassportDetailField } from './PassportDetailField/index';
import styles from './styles';
import { IPassportPreviewProps, IPassportPreviewState } from './types';

class PassportComponent extends React.PureComponent<
  IPassportPreviewProps,
  IPassportPreviewState
> {
  state: IPassportPreviewState = {
    isPhotoLoading: true,
  };

  immutableFields = [
    {
      showPhoto: true,
      fieldName: constants.myDetails.passportLabel,
    },
    {
      //  empty view for creating space
    },
    {
      propsKey: 'firstName',
      fieldName: constants.myDetails.firstNameLabel,
    },
    {
      propsKey: 'lastName',
      fieldName: constants.myDetails.surnameLabel,
    },
    {
      propsKey: 'number',
      fieldName: constants.myDetails.passportNumberLabel,
    },
    {
      propsKey: 'country.name',
      fieldName: constants.myDetails.issuedByLabel,
    },
  ];

  startPassportScan = () => {
    checkCameraPermissions().then(() => {
      this.props.navigation.replace(
        PassportCameraPlaceholderSceneName,
        this.props.route.params
      );
    });
  };

  goTopPlaceholder = () => {
    checkCameraPermissions().then(() => {
      this.props.navigation.navigate(
        PassportCameraPlaceholderSceneName,
        this.props.route.params
      );
    });
  };

  createPhotoUrl = async () => {
    const picture = this.getFirstDocumentUploads();
    this.setState({ picture });
  };

  getFirstDocumentUploads = (): IApiUploadFile | undefined => {
    const { uploads } = this.props.document || {};
    return uploads && createApiUploadFile(uploads);
  };

  photoOnLoad = () => {
    this.setState({ isPhotoLoading: false });
  };

  handlePhotoOnError = async () => {
    const picture = this.getFirstDocumentUploads();
    this.setState({ picture, isPhotoLoading: false });
  };

  onLoadStart = () => {
    this.setState({ isPhotoLoading: true });
  };

  photoOnError = () => {
    this.setState({ isPhotoLoading: true });
    this.handlePhotoOnError();
  };

  handleOnError = (e: Error) => {
    this.props.avAlert('', e.message);
  };

  async componentDidMount() {
    this.createPhotoUrl();
    await this.props.getDocument();
    this.props.createRef && this.props.createRef(this);
  }

  async componentDidUpdate(prevProps: IPassportPreviewProps) {
    const propsIsFocused = this.props.navigation.isFocused();
    const prevPropsIsFocused = prevProps.navigation.isFocused();
    if (propsIsFocused && propsIsFocused !== prevPropsIsFocused) {
      await this.props.getDocument();
      this.createPhotoUrl();
    }
    if (this.props.document?.status !== prevProps.document?.status) {
      await this.props.getDocument();
      this.createPhotoUrl();
    }
    this.props.onUpdate && this.props.onUpdate(this.props);
  }

  renderImmutableUserDetailField = ({
    item: { fieldName, showPhoto, propsKey },
    index,
  }: any) => {
    if (!fieldName) {
      return null;
    }

    const { document } = this.props;
    const { picture = this.getFirstDocumentUploads(), isPhotoLoading } =
      this.state;

    return (
      <PassportDetailField
        isPhotoLoading={isPhotoLoading}
        key={String(fieldName) + index}
        picture={picture}
        onLoadStart={this.onLoadStart}
        photoOnLoad={this.photoOnLoad}
        photoOnError={this.photoOnError}
        showPhoto={showPhoto}
        useFullSizePhoto
        fieldName={fieldName}
        noMargin={index % 2 === 1}
        fieldValue={get(document, propsKey) || ''}
        documentStatus={
          this.props.isProcessing && !document?.status
            ? EDocumentStatus.IN_PROGRESS
            : document?.status
        }
      />
    );
  };

  render() {
    const { document, isFilledAddress } = this.props;
    const isShowPhotoButton =
      document?.status == EDocumentStatus.VERIFIED ||
      document?.status === EDocumentStatus.DECLINED;
    if (!document?.status) {
      return (
        <AirvatInfoMandatory
          withOutAddress={isFilledAddress}
          onStart={this.startPassportScan}
        />
      );
    }
    return (
      <SafeAreaView edges={['bottom']} style={styles.safeAreaContainer}>
        <View style={[styles.fieldsContainer, styles.immutableFieldsContainer]}>
          <List
            scrollEnabled={false}
            data={this.immutableFields}
            keyExtractor={(item: any, i: number) => item.fieldName + i}
            renderItem={this.renderImmutableUserDetailField}
            numColumns={2}
          />
        </View>
        {isShowPhotoButton && (
          <CustomButton
            accent
            isUppercase
            onPress={this.goTopPlaceholder}
            text={constants.updatePassport}
            buttonStyle={globalStyles.marginBottomButton}
            testID="addDocument"
          />
        )}
      </SafeAreaView>
    );
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      avAlert,
      avConfirm,
      getDocument,
    },
    dispatch
  );
};

const mapStateToProps = (state: IDefaultState) => {
  const user = state.api.user;
  const isFilledAddress = getIsFilledAddress(user);
  return {
    isFilledAddress,
    ...user,
  };
};

export const Passport = compose<any>(
  connect(mapStateToProps, mapDispatchToProps)
)(PassportComponent);
