import { DATE_FORMAT, DOTS_DATE_FORMAT } from '../../api-store/constants';
import { airvatClient } from '../../common/api';
import { orderBy } from 'lodash';
import moment from 'moment';
import { createSetAction } from '../helpers';
import { IApiUploadFile } from '../types';
import { IUserPassport } from './../types';
import { handleAuthError } from './auth';
import { uploadFile } from './uploads';

const createDocumentData =
  (
    data: IUserPassport,
    documentFile: IApiUploadFile,
    selfieFile: IApiUploadFile
  ) =>
  async (dispatch: any, getState: any) => {
     console.log('addDocument', data, documentFile, selfieFile);
    data.birthDate = moment(String(data.birthDate), DOTS_DATE_FORMAT).format(
      DATE_FORMAT
    );
    data.expiryDate = moment(String(data.expiryDate), DOTS_DATE_FORMAT).format(
      DATE_FORMAT
    );
    const uploadsId = await uploadFile(documentFile)(dispatch, getState);
    if (uploadsId) {
      data.uploadsId = uploadsId;
    }
    const selfieUploadsId = await uploadFile(selfieFile)(dispatch, getState);
    if (selfieUploadsId) {
      data.selfieUploadsId = selfieUploadsId;
    }
    return data;
  };

export const addDocument =
  (
    data: IUserPassport,
    documentFile: IApiUploadFile,
    selfieFile: IApiUploadFile
  ) =>
  async (dispatch: any, getState: any) => {
    const document = await createDocumentData(
      data,
      documentFile,
      selfieFile
    )(dispatch, getState);
    await airvatClient
      .post<IUserPassport>('documents', document)
      .catch((e: any) => handleAuthError(e)(dispatch, getState));
    await getDocument()(dispatch, getState);
  };

export const getDocument = () => async (dispatch: any, getState: any) => {
  const data = await airvatClient
    .get<IUserPassport[]>('documents')
    .catch((e: any) => handleAuthError(e)(dispatch, getState));
  const lastDocument = data && orderBy(data, 'createdAt', 'desc')[0];
  if (lastDocument) {
    const action = createSetAction('user.document', lastDocument);
    dispatch(action);
    return lastDocument;
  }
};
