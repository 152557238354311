import { clearObject } from '../../api-store';
import { isAndroid, isWeb, localStorage } from '../../common';
import constants, { locale } from '../../common/constants';
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { camelizeKeys } from 'humps';
import { get, isArray, join, omit, upperFirst } from 'lodash';
import RNFetchBlob from 'react-native-blob-util';

export class AirvatApiClient {
  baseURL: string;
  accessToken = '';
  refreshToken = '';
  tokenType = '';
  client: AxiosInstance;

  constructor(baseURL: string) {
    // console.log('baseURL', baseURL);
    this.baseURL = baseURL;
    this.client = axios.create({
      baseURL,
    });
    this.client.interceptors.request.use((r) => {
      r.baseURL = this.baseURL;
      console.log('request', r.url, r);
      return r;
    });
    this.client.interceptors.response.use(
      this.responseResolve,
      this.responseReject
    );
  }

  setBaseUrl = (baseURL: string) => {
    this.baseURL = baseURL;
  };

  getBaseUrl = () => {
    return this.baseURL;
  };

  setTokens = (
    accessToken: string,
    refreshToken: string,
    tokenType: string
  ) => {
    this.accessToken = accessToken;
    this.refreshToken = refreshToken;
    this.tokenType = tokenType;
    this.client.defaults.headers[
      'Authorization'
    ] = `${this.tokenType} ${accessToken}`;
    return this;
  };

  resetTokens = () => {
    this.accessToken = '';
    this.refreshToken = '';
    this.tokenType = '';
    delete this.client.defaults.headers['Authorization'];
    return this;
  };

  get = <T>(query: string, params?: AxiosRequestConfig): Promise<T | never> => {
    return this.client.get<T>(query, params).then(({ data }) => data);
  };

  post = <T>(
    query: string,
    data: any,
    config?: AxiosRequestConfig
  ): Promise<T | never> => {
    return this.client
      .post<T>(query, clearObject(data), config)
      .then(({ data }) => data);
  };

  put = <T>(query: string, data?: any): Promise<T | never> => {
    return this.client
      .put<T>(query, clearObject(data))
      .then(({ data }) => data);
  };

  delete = (query: string, data?: any): Promise<never> => {
    return this.client.delete(query, {
      data: clearObject(data),
    });
  };

  uploadWrap = (uri: string, name: string) => {
    const path = uri.replace('file://', '');
    console.log('uploadWrap path', path, name);
    if(isWeb){
      return decodeURIComponent(path);
    }
    return RNFetchBlob.wrap(decodeURIComponent(path));
  };

  upload = async <T>(query: string, files: any) => {
    console.log('start upload ==========>', query, files);
    await this.updateTokens();
    const method = isWeb
      ? this.client.post(query, files).then(({ data }) => data)
      : RNFetchBlob.fetch(
          'POST',
          `${this.baseURL}/${query}`,
          {
            Authorization: `${this.tokenType} ${this.accessToken}`,
            'Content-Type': 'multipart/form-data',
          },
          files
        ).then(async (res) => {
          const json = await res.json();
          console.log('end upload ==========>', query, json);
          if (get(json, 'error')) {
            throw json;
          }
          return json as T;
        });
    return method;
  };

  download = async (
    query: string,
    fileName: string,
    mimeType: string = 'application/pdf',
    appendExt: string = 'pdf'
  ) => {
    console.log('start download ==========>', query);
    await this.updateTokens();
    return RNFetchBlob.config({
      fileCache: false,
      appendExt,
      path: RNFetchBlob.fs.dirs.DocumentDir + '/' + fileName,
    })
      .fetch('GET', `${this.baseURL}/${query}`, {
        Authorization: `${this.tokenType} ${this.accessToken}`,
      })
      .then(async (res) => {
        const path = res.path();
        console.log('end download ==========>', query, path);
        if (isAndroid) {
          await RNFetchBlob.android.actionViewIntent(path, mimeType);
        } else {
          await RNFetchBlob.ios.openDocument(path);
        }
        return path;
      });
  };

  private updateTokens = async () => {
    const data = {
      token: this.refreshToken,
    };
    const config = { params: { locale }, headers: { Authorization: '' } };
    return this.post(`auth/token`, data, config);
  };

  private authByRefreshToken = async (e: any) => {
    console.log('authByRefreshToken', !this.refreshToken, e.config.url);

    if (!this.refreshToken || e.config.url.includes('auth/token')) {
      this.resetTokens();
      throw this.parseError(e);
    }
    return this.updateTokens()
      .then(() => {
        const config = omit(e.config, ['headers']);
        return this.client.request(config);
      })
      .catch(this.parseError);
  };

  private responseResolve = (r: AxiosResponse<any>) => {
    if (r?.data) {
      r.data = camelizeKeys(r.data);
    }
    if (r?.data?.accessToken) {
      this.setTokens(r.data.accessToken, r.data.refreshToken, r.data.tokenType);
      const storeData = r.data;
      localStorage('user', storeData).set();
    }
    console.log('response', r.config.url, r);
    return r;
  };

  private responseReject = (e: any) => {
    console.log('reject', e.config.url, e, e.response?.data);
    if (e.response?.data?.code === 401 || e.response?.data?.code === 403) {
      return this.authByRefreshToken(e);
    }
    if (/timeout/i.test(e.message) || /network/i.test(e.message)) {
      throw new Error(constants.alerts.noInternet);
    }
    this.parseError(e);
  };

  parseError = (e: any) => {
    if (e.response?.data?.code === 401 || e.response?.data?.code === 403) {
      throw e;
    }
    if (e.response?.data?.message) {
      const message = isArray(e.response?.data?.message)
        ? join(e.response?.data?.message, ', ')
        : e.response?.data?.message;
      if (message) {
        throw new Error(upperFirst(message));
      }
    }
    if (e?.error) {
      const message = e?.error;
      throw new Error(upperFirst(message));
    }
    throw e;
  };
}
