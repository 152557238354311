import Web from '../../containers/Web';
import { constants } from '../../common';
import { default as headerStyle } from '../../components/Headers/styles';
import { AirvatFranceKbis, AirvatInfoHowItWorks } from '../../containers/AirvatInfo';
import { More } from '../../containers/More';
import { RateUs } from '../../containers/RateUs';
import { RefundCalculator } from '../../containers/RefundCalculator';
import { commonStackNavigatorConfig } from '../../navigation/config';
import * as SceneNames from '../../navigation/names';
import { INavigationScreenProps } from '../../navigation/types';

export default {
  [SceneNames.MoreSceneName]: {
    screen: More,
    navigationOptions: (props: INavigationScreenProps) => ({
      title: constants.menu,
      ...commonStackNavigatorConfig(props),
      headerStyle: headerStyle.accentHeader,
    }),
  },
  [SceneNames.AirvatInfoHowItWorkSceneName]: {
    screen: AirvatInfoHowItWorks,
    navigationOptions: (props: INavigationScreenProps) => ({
      title: constants.headers.howItWorks,
      ...commonStackNavigatorConfig(props)
    }),
  },
  [SceneNames.RefundCalculatorSceneName]: {
    screen: RefundCalculator,
    navigationOptions: (props: INavigationScreenProps) => ({
      title: props.route.params?.title || constants.headers.refundCalculator,
      ...commonStackNavigatorConfig(props),
    }),
  },
  // [SceneNames.RateUsSceneName]: {
  //   screen: RateUs,
  //   navigationOptions: (props: INavigationScreenProps) => ({
  //     title: props.route.params?.title || constants.headers.rateUs,
  //     ...commonStackNavigatorConfig(props),
  //   }),
  // },
  [SceneNames.AirvatFranceKbisSceneName]: {
    screen: AirvatFranceKbis,
    navigationOptions: (props: INavigationScreenProps) => ({
      title: constants.headers.kbis,
      ...commonStackNavigatorConfig(props),
    }),
  },
};
