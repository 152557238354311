import { StyleSheet } from 'react-native';
import { sketchSize } from '../../common/metrics';

export default StyleSheet.create({
  notifyContainerStyle: {
    alignItems: 'center',
    justifyContent:'center',
  },
  innerContainer: {
    width:'100%',
    height:'100%',
    position: 'absolute',
    alignItems: 'flex-end',
    right: sketchSize(-10),
    top: sketchSize(-10)
  },
});
