import { constants, globalStyles, images } from '../../common';
import React from 'react';
import { View } from 'react-native';
import { RNFastImage } from '../../modules/RNFastImage';
import { AvModal } from '../AvModal';
import { CustomButton } from '../CustomButton';
import { Header } from '../Headers/Header';
import { Label } from '../Labels';
import styles from './styles';
import { IEmptyModalScreenProps } from './types';

export const EmptyModalScreen: React.FC<IEmptyModalScreenProps> = ({
  isVisible,
  title,
  message,
  onUpdate,
  isLoading,
}) => {
  return (
    <AvModal isVisible={isVisible}>
      <Header title={title}>
        <View style={styles.fillImageContainer}>
          <RNFastImage
            style={styles.fillImage}
            resizeMode='cover'
            source={images.airportPlaceholder}
          />
        </View>
        <View style={styles.labelContainer}>
          <Label
            centered
            text={message}
            type={'H1Dark'}
            style={globalStyles.placeholderLabel}
          />
        </View>
        <CustomButton
          accent
          isLoading={isLoading}
          text={constants.labels.update}
          onPress={onUpdate}
          buttonStyle={globalStyles.marginBottomButton}
        />
      </Header>
    </AvModal>
  );
};
