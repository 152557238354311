import { StyleSheet } from "react-native";
import { isAndroid, sketchSize } from "../../../common/metrics";
import { colors } from "../../../common/colors";

const ICON_CONTAINER_SIZE = sketchSize(75);
const BORDER_WIDTH = StyleSheet.hairlineWidth * 2;

export default StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "center",
    height: sketchSize(150),
    backgroundColor: colors.accent,
  },
  itemContainer: {
    width: ICON_CONTAINER_SIZE,
    height: ICON_CONTAINER_SIZE,
    borderRadius: ICON_CONTAINER_SIZE / 2,
    justifyContent: "center",
    alignItems: "center",
    borderWidth: BORDER_WIDTH,
  },
  itemActiveBorderedContainer: {
    backgroundColor: colors.accent,
  },
  itemTouchContainerGradient: {
    position: "absolute",
    bottom: 0,
    height: "100%",
    width: "100%",
  },
  itemTouchContainerWrap: {
    flex: 1,
    paddingHorizontal: sketchSize(40),
    borderTopLeftRadius: sketchSize(40),
    borderTopRightRadius: sketchSize(40),
    // alignItems: "center",
    // justifyContent: "center",
  },
  itemTouchContainer: {
    alignItems: "center",
    flexDirection: "row",
    flex: 1,
  },
  itemLabel: {
    flex: 1,
  },
  itemLabelNum: {
    paddingBottom: isAndroid ? sketchSize(5, true) : 0,
  },
  itemNotify: {
    position: "absolute",
    top: sketchSize(16, true),
    right: -sketchSize(10),
  },
});
