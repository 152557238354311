import { RNPdf } from "modules/RNPdf";
import styles from "./styles";
import React from "react";

export const AirvatFranceKbis = () => {
    return (
        <RNPdf
            source={require('../../assets/info/Airvat-france-kbis.pdf')}
            style={styles.container}
            trustAllCerts={false} />
    );
}