import { StyleSheet } from 'react-native';
import { sketchSize } from '../../common/metrics';

export default StyleSheet.create({
  avatar: {
    resizeMode: 'contain',
    width: sketchSize(240),
    height: sketchSize(240),
    alignSelf: 'center',
    borderRadius: sketchSize(120)
  }
});
