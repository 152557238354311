import { EDirname } from 'api-store';
import { createFileName } from 'common';

export const RNDocumentPickerTypes = {};

export const RNDocumentPicker = {
  pickSingle: () => {
    return new Promise((resolve, reject) => {
      const uploadFile: any = document.querySelector('#upload-file');
      if (uploadFile) {
        uploadFile.onchange = (e: any) => {
          const file = e.target.files[0];
          const uri = window.URL.createObjectURL(file);
          resolve({
            ...file,
            type: 'application/pdf',
            name: Date.now()+'.pdf',
            uri,
          });
        };
        uploadFile.accept = 'application/pdf';
        uploadFile.click();
      } else {
        reject(new Error('No html eleement #upload-file'));
      }
    });
  },
};
