import { useIsFocused } from '@react-navigation/native';
import { filter } from 'lodash';
import React from 'react';
import { LayoutAnimation, View } from 'react-native';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import {
  ETaxformStatus,
  getTrip,
  getTrips,
  getUser,
  updateTrip,
} from '../../api-store';
import {
  fadeLayout,
  getActiveTrip,
  getActualTaxform,
  getCountryCode,
  isIOS,
  setStatusBar,
} from '../../common';
import constants from '../../common/constants';
import { FR } from '../../common/constants/common';
import { getPassport } from '../../containers/AddDetails/documentsState';
import ReceiptsList from '../../containers/Receipt/ReceiptsList';
import { GenerateTaxForms } from '../../containers/TaxForms';
import AllTrips from '../../containers/Trip/components/AllTrips';
import { usePrevious } from '../../hooks/usePrevious';
import * as ScenesNames from '../../navigation/names';
import { getNavigationFirstScreenName } from '../../navigation/utils';
import { IDefaultState } from '../../store/types';
import Header from './Header';
import StepIndicator from './StepIndicator';
import { IStepItem } from './StepIndicator/types';
import { getIsAcceptedReceipt, getIsMenuNotify } from './state';
import styles from './styles';
import { IHomeProps } from './types';

const HomeComponent: React.FC<IHomeProps> = (props) => {
  const isFocused = useIsFocused();
  const prevProps = usePrevious<IHomeProps>(props);

  const [activeIndex, setActiveIndex] = React.useState(0);

  const navigateToTripInfo = () => {
    props.navigation.navigate(ScenesNames.TripInfoSceneName, {
      tripId: props.currentTrip?.id,
    });
  };

  const navigateToMore = () => {
    props.navigation.navigate(ScenesNames.MoreSceneName);
  };
  const handleOnStepPress = (stepNumber: number) => {
    if (isIOS) {
      LayoutAnimation.configureNext(fadeLayout);
    }
    setActiveIndex(stepNumber);
  };

  const getInitActiveIndex = () => {
    if (!props.currentTrip?.id) return 0;
    if (isNotifyTaxform() || props.taxform?.id) {
      return 1;
    }
    return 0;
  };

  const isNotifyTaxform = () => {
    const invoiceAmount = Number(
      props.currentTrip?.paymentDetails?.invoiceAmount
    );
    const isFr = getCountryCode(props.currentTrip?.country) === FR;
    if (isFr && invoiceAmount <= 100) {
      return false;
    }
    return !props.taxform?.id && props.isAcceptedReceipt;
  };

  const focusedUserUpdate = async () => {
    try {
      if (props.currentTrip?.isActive) {
        await props.getTrip(props.currentTrip.id);
      } else {
        await props.getTrips();
      }
      await props.getUser();
    } catch (e) {
      console.error(e);
    }
  };

  React.useEffect(() => {
    setActiveIndex(getInitActiveIndex());
  }, []);

  React.useEffect(() => {
    focusedUserUpdate();
  }, [isFocused]);

  React.useEffect(() => {
    if (
      props.taxform?.status !== prevProps?.taxform?.status &&
      props.currentTrip?.paymentDetails?.totalRefund
    ) {
      setActiveIndex(1);
    }
  }, [prevProps]);

  React.useEffect(() => {
    const { index, routes } = props.navigation.getState();
    const isNextCheckout =
      routes[index]?.name === ScenesNames.AirvatInfoCheckoutSceneName;
    const isLight = isNextCheckout || (isFocused && !props.isShowAllTrips);
    setStatusBar(isLight);
  }, [isFocused, props.isShowAllTrips]);

  if (props.isShowAllTrips) {
    return <AllTrips {...props} />;
  }

  const amount = props.currentTrip?.paymentDetails?.totalRefund || 0;

  const indicatorData: IStepItem[] = [
    {
      label: constants.addReceipts,
      testID: 'homeTabReceipts',
    },
    {
      label: constants.validate,
      testID: 'homeTabTaxForm',
      isNotify: isNotifyTaxform(),
      isDisabled: !props.currentTrip?.id,
    },
  ];

  return (
    <View style={styles.container}>
      <Header
        amount={amount}
        onPress={navigateToTripInfo}
        onMore={navigateToMore}
        currentTrip={props.currentTrip}
        withNotifyRight={props.isMenuNotify}
        withNotifyLeft={props.isChatNotify}
      />
      <StepIndicator
        onStepPress={handleOnStepPress}
        data={indicatorData}
        currentPosition={activeIndex}
      />
      {activeIndex === 0 && <ReceiptsList {...props} />}
      {props.currentTrip?.id && activeIndex === 1 && (
        <GenerateTaxForms {...props} />
      )}
    </View>
  );
};

const mapStateToProps = (state: IDefaultState) => {
  const currentTrip = getActiveTrip(state);
  const taxform = getActualTaxform(state);
  const initSceneName = getNavigationFirstScreenName(state, 'home');
  const isMenuNotify = getIsMenuNotify(state);
  const isChatNotify = !!state.api.isIntercomNotify;
  const isAcceptedReceipt = getIsAcceptedReceipt(state);
  const isShowAllTrips =
    taxform?.uploadId || taxform?.status === ETaxformStatus.STAMPED;
  const trips = filter(state.api.user?.trips, (v) => !!v?.id);
  const passport = getPassport(state);
  const userFirstName = passport?.firstName;

  return {
    currentTrip,
    initSceneName,
    isMenuNotify,
    isChatNotify,
    isAcceptedReceipt,
    taxform,
    isShowAllTrips,
    userFirstName,
    trips,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    { updateTrip, getTrips, getTrip, getUser },
    dispatch
  );
};

export const Home = compose<any>(connect(mapStateToProps, mapDispatchToProps))(
  HomeComponent
);
