import { ITrip } from '../../api-store';
import { DotList } from '../../components';
import { getCurrentTrip } from '../../containers/Trip/tripState';
import { IDefaultState } from '../../store/types';
import React from 'react';
import { ScrollView, View } from 'react-native';
import { RNFastImage } from '../../modules/RNFastImage';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { getCustomsInfo } from './constants';
import styles from './styles';
import { isWeb } from '../../common';

interface IAirvatInfoCustomsInspectionProps {
  currentTrip?: ITrip;
}

const AirvatInfoCustomsInspectionComponent: React.FC<IAirvatInfoCustomsInspectionProps> = ({
  currentTrip,
}) => {
  const infoCustomsInspection = getCustomsInfo(currentTrip?.country);
  return (
    <ScrollView contentContainerStyle={{ height: 100 }}>
      <View style={styles.listGradientContainer}>
        <View style={styles.imageWrap}>
          <RNFastImage
            style={styles.image}
            resizeMode='contain'
            source={infoCustomsInspection.icon}
          />
        </View>
        <View style={styles.dotListItemMargin}>
          <DotList items={infoCustomsInspection.message} />
        </View>
      </View>
      {isWeb ? <View style={{height: 90, width: '100%'}}/> : undefined}
    </ScrollView>
  );
};

const mapStateToProps = (state: IDefaultState) => {
  const currentTrip = getCurrentTrip(state);
  return {
    currentTrip,
  };
};

export const AirvatInfoCustomsInspection = compose<any>(
  connect(mapStateToProps)
)(AirvatInfoCustomsInspectionComponent);
